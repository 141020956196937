import React, { FC } from 'react';
import styled from './ContextMenuReset.module.css';
import { ReactComponent as FilterIcon } from '../../../assets/images/filterIcon.svg';
import { ReactComponent as FilterActiveIcon } from '../../../assets/images/filterActiveIcon.svg';
import Button from '../../Button/Button';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    open: boolean;
    visibleDataCount: number;
    totalDataCount: number;
    btnText?: string;
    onResetAllFilters: () => void;
}

const ContextMenuReset: FC<Props> = ({ open, visibleDataCount, totalDataCount, onResetAllFilters, btnText }) => {
    const { t } = useTranslation();

    return (
        <div className={[styled.ContextMenuReset, open ? styled.Open : ''].join(' ')}>
            <div className={styled.Info}>
                {visibleDataCount < totalDataCount ? <FilterActiveIcon /> : <FilterIcon />}
                {open && (
                    <p>
                        <Trans i18nKey="contextMenuFilterShows" values={{ visibleDataCount, totalDataCount }} />
                    </p>
                )}
            </div>
            {open && (
                <Button variant="Outline" onClick={onResetAllFilters}>
                    {btnText || t('contextMenuResetFilter')}
                </Button>
            )}
        </div>
    );
};

export default ContextMenuReset;
