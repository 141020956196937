import React, { FC, useMemo } from 'react';
import styled from './EditFullViewFooter.module.css';
import Button from '../../../Button/Button';
import { RevisionObjStatus, isAcceptableRevisionStatus } from '../../../../models/RevisionObjStatus';
import useAuth from '../../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import useMobileDevice from '../../../../hooks/useMobileDevice';

interface Props {
    finished?: boolean;
    status?: RevisionObjStatus;
    onClick: () => void;
    onClose?: () => void;
}

const EditFullViewFooter: FC<Props> = ({ finished, status, onClick, onClose }) => {
    const { t, i18n } = useTranslation();
    const { isRevisor } = useAuth();
    const isMobileDevice = useMobileDevice();

    const title = useMemo(() => {
        if (status === 'NO_VALUE' || !finished) {
            return t('navbarRevision');
        } else if (status === 'NOT_ACCEPTED') {
            return t('revisionStatusTitleNotAccepted');
        } else if (status === 'OBSERVATION') {
            return t('revisionStatusTitleObservation');
        } else if (status === 'ACCEPTED') {
            return t('revisionStatusTitleAccepted');
        } else if (status === 'NOT_RELEVANT') {
            return t('revisionStatusTitleNotRelevant');
        } else if (status === 'ACCEPTED_AFTER_OBSERVATION') {
            return t('revisionStatusTitleAcceptedAfterObservation');
        } else if (status === 'ACCEPTED_AFTER_NOT_ACCEPTED') {
            return t('revisionStatusTitleAcceptedAfterNotAccepted');
        }
        return null;
    }, [status, finished, i18n.language]);

    const text = useMemo(() => {
        if (!isRevisor()) {
            return t('revisionStatusTextInsufficientRights');
        } else if (status === 'NO_VALUE' || !finished) {
            return t('revisionStatusTextNoValue');
        } else if (status === 'NOT_ACCEPTED') {
            return t('revisionStatusTextNotAccepted');
        } else if (status === 'OBSERVATION') {
            return t('revisionStatusTextObservation');
        } else if (isAcceptableRevisionStatus(status)) {
            return t('revisionStatusTextComment');
        }
        return null;
    }, [status, finished, i18n.language]);

    const button = useMemo(() => {
        if (!isRevisor()) {
            return null;
        } else if (status === 'NO_VALUE' || !finished) {
            return t('revisionStatusButtonNoValue');
        } else if (status === 'NOT_ACCEPTED') {
            return t('revisionStatusButtonNotAccepted');
        } else if (status === 'OBSERVATION') {
            return t('revisionStatusButtonObservation');
        } else if (isAcceptableRevisionStatus(status)) {
            return t('revisionStatusButtonComment');
        }
        return null;
    }, [status, finished, i18n.language]);

    const style = useMemo(() => {
        if (finished && (status === 'NOT_ACCEPTED' || status === 'OBSERVATION')) {
            return styled.RED;
        }
        if (finished && isAcceptableRevisionStatus(status)) {
            return styled.GREEN;
        }
        return '';
    }, [status]);

    if (title === null || text === null) {
        return null;
    }

    return (
        <>
            {isMobileDevice ? (
                <div className={styled.MobileButtons}>
                    <Button variant="Outline" onClick={onClose}>
                        {t('buttonClose')}
                    </Button>
                    {button && (
                        <Button variant="Primary" onClick={onClick}>
                            {button}
                        </Button>
                    )}
                </div>
            ) : (
                <div className={[styled.EditFullViewFooter, style].join(' ')}>
                    <h4 className={styled.Title}>{title}</h4>
                    <p className={styled.Text}>{text}</p>
                    {button && <small>{t('revisionStatusClickInfo')}</small>}
                    <div className={styled.Buttons}>
                        {button && (
                            <Button variant="Outline" onClick={onClick}>
                                {button}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default EditFullViewFooter;
