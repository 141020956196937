import React, { FC, useState } from 'react';
import styled from './FilterbarMenuItem.module.css';
import accordionIcon from '../../../../assets/images/accordionIcon.svg';
import Checkbox from '../../../Checkbox/Checkbox';
import { Column } from 'react-table';

interface Props {
    title: string;
    columns?: Array<Partial<Column & { visible: boolean }>>;
    onColumnChange?: (id: string) => void;
    onSelect?: () => void;
    onClose?: () => void;
    subItems?: { title: string; function: () => void }[];
}

const FilterbarMenuItem: FC<Props> = ({ title, columns = [], subItems, onColumnChange, onSelect }) => {
    const [open, setOpen] = useState(false);

    const handleMouseOver = (open: boolean) => {
        if ((columns && onColumnChange) || subItems) {
            setOpen(open);
        }
    };

    const handleSelect = () => {
        if (!onColumnChange && onSelect) {
            onSelect();
        }
    };

    return (
        <li
            className={[styled.FilterbarMenuItem, columns ? styled.HasChildren : '', open ? styled.Open : ''].join(' ')}
            onMouseEnter={() => handleMouseOver(true)}
            onMouseLeave={() => handleMouseOver(false)}
            onClick={handleSelect}
        >
            {((columns && onColumnChange) || subItems) && (
                <div className={styled.OpenIcon}>
                    <img src={accordionIcon} />
                </div>
            )}
            {title}

            {/* Column List */}
            {open && onColumnChange && (
                <ul className={styled.ColumnList}>
                    {columns.map(column => (
                        <li key={column.id}>
                            <span className={styled.ColumnTitle} onClick={() => (column.id !== undefined ? onColumnChange(column.id) : undefined)}>
                                {column.Header}
                            </span>
                            <Checkbox checked={column.visible} onChange={() => (column.id !== undefined ? onColumnChange(column.id) : undefined)} />
                        </li>
                    ))}
                </ul>
            )}

            {open && subItems && (
                <ul className={styled.SubItemList}>
                    {subItems.map((subItem, index) => (
                        <li key={index}>
                            <span className={styled.SubItemTitle} onClick={subItem.function}>
                                {subItem.title}
                            </span>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

export default FilterbarMenuItem;
