import React, { FC, useState } from 'react';
import styled from './EditKeywordModal.module.css';
import Modal from '../Modal';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import TextEditor from '../../TextEditor/TextEditor';
import KeywordModel, { EditKeywordModel } from '../../../models/KeywordModel';
import { keywordDescriptionMaxLength, keywordTextMaxLength, lawListGroupNameMaxLength } from '../../../constants/Validation';
import { useTranslation } from 'react-i18next';

interface Props {
    edit: KeywordModel | undefined; // If undefined, this component will be used to create a new object. If this value is not undefined this component will be used to edit the defined object.
    onCancel: () => void;
    onSave: (keyword: EditKeywordModel) => void;
}

const EditKeywordModal: FC<Props> = ({ edit, onCancel, onSave }) => {
    const { t } = useTranslation();
    const [text, setText] = useState<{ value: string; error?: string }>({ value: edit ? edit.text : '', error: undefined });
    const [description, setDescription] = useState<{ value: string; errorMsg?: string }>({ value: edit ? edit.description : '', errorMsg: undefined });
    //Same regexp exists in backend code, but is only used there as a safety measure, input validation is foremost done in frontend.
    const validationRegExp = new RegExp(
        "^[a-zA-ZàáâäæãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÆÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒČŠŽ∂ð0-9 \\t,\\.\\'`:;´#&\\(\\)@\\/\\*\\s\\_\\-]*$",
    );
    const descriptionValidationRegExp = new RegExp(
        '^[a-zA-ZàáâäæãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÆÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒČŠŽ∂ð0-9:…·•,„¸˛\\.\\\'`¨’´˙‘’°˝˜ˆ¯˘¬º⁄ˇ«»“”\\(\\)\\*;:\\+@#\\/\\&\\|§\\<\\>€£\\$\\!\\?\\{\\}¶\\\\\\"\\=≠≈~≤≥÷±√πΩ%‰∞#¡αβγ‡¥¢Œ∏˚◊∑∆∫©™®ß∂ƒªﬁﬂ†µıœ‹›\\^\\[\\]\\t\\r\\n\\s\\_\\—\\–\\-]*$',
    );
    const maxLengthError = t('inputFieldErrorTooLong', { max: lawListGroupNameMaxLength });
    const descrError = t('inputFieldKeywordValidatonError2');

    const handleTextChange = (value: string) => {
        let error;
        if (value.length < 1) {
            error = t('inputFieldErrorRequired');
        } else if (value.length > keywordTextMaxLength) {
            error = t('inputFieldErrorTooLong', { max: keywordTextMaxLength });
            value = text.value;
        } else if (!validationRegExp.test(value)) {
            error = 'inputFieldKeywordValidatonError';
        }
        setText({ value, error });
    };

    const handleDescriptionChange = (value: string) => {
        let errorMsg;
        if (!descriptionValidationRegExp.test(value)) {
            errorMsg = descrError;
        }
        setDescription({ value, errorMsg });
    };

    const handleSave = () => {
        const keyword: EditKeywordModel = {
            id: edit ? edit.id : undefined,
            text: text.value,
            description: description.value,
        };
        onSave(keyword);
    };

    return (
        <Modal title={edit ? t('editKeywordModalTitle') : t('createKeywordModalTitle')} className={styled.Modal}>
            <div className={styled.Container}>
                <Input
                    value={text.value}
                    onChange={handleTextChange}
                    error={text.error}
                    label={t('inputFieldKeyword')}
                    className={styled.Input}
                    placeholder={t('inputFieldPlaceholderKeyword')}
                    autoFocus
                />

                <div className={styled.TextInput}>
                    <p>{t('inputFieldDescription')}</p>
                    <TextEditor
                        initialValue={description.value}
                        onChange={handleDescriptionChange}
                        errorMsg={description.errorMsg}
                        noOptions
                        maxLength={keywordDescriptionMaxLength}
                        onMaxLengthError={error => setDescription(s => ({ ...s, maxLengthError }))}
                    />
                </div>

                <div className={styled.Buttons}>
                    <Button variant="Outline" onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Primary" onClick={handleSave} disabled={text.error !== undefined || text.value.length < 1 || description.errorMsg !== undefined}>
                        {edit ? t('buttonSave') : t('buttonCreateKeyword')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EditKeywordModal;
