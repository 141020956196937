import React, { FC } from 'react';
import styled from './Contract.module.css';
import useAuth from '../../hooks/useAuth';
import { useTranslation, Trans } from 'react-i18next';

const Contract: FC = () => {
    const { t } = useTranslation();
    const { company } = useAuth();

    return (
        <div className={styled.ContractPage}>
            <h4>{t('contractTitle')}</h4>
            <div className={styled.Contract}>
                <h4>{t('contractParties')}</h4>
                <table>
                    <tr>
                        <td>
                            <strong>{t('contractCompanyName')}</strong>
                        </td>
                        <td>
                            <strong>{t('contractBelowCalled')}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>Ramboll</td>
                        <td>Ramboll</td>
                    </tr>
                    <tr>
                        <td>{company?.name}</td>
                        <td>({t('contractTheCompany')})</td>
                    </tr>
                </table>
                <br />

                <h4>{t('contractHeader1')}</h4>
                <Trans i18nKey="contractText1" components={{ 0: <p />, 1: <ul />, 2: <li /> }} />
                <br />

                <h4>{t('contractHeader2')}</h4>
                <Trans i18nKey="contractText2" components={[<p key={0} />]} />
                <br />

                <h4>{t('contractHeader3')}</h4>
                <Trans i18nKey="contractText3" components={[<p key={0} />]} />
                <br />

                <h4>{t('contractHeader4')}</h4>
                <Trans i18nKey="contractText4" components={[<p key={0} />]} />
                <br />

                <h4>{t('contractHeader5')}</h4>
                <Trans i18nKey="contractText5" components={[<p key={0} />]} />
                <br />

                <h4>{t('contractHeader6')}</h4>
                <Trans i18nKey="contractText6" components={[<p key={0} />]} />
                <br />

                <h4>{t('contractHeader7')}</h4>
                <Trans i18nKey="contractText7" components={[<p key={0} />]} />
                <br />

                <h4>{t('contractHeader8')}</h4>
                <Trans i18nKey="contractText8-1" components={[<p key={0} />]} />
                <h4>
                    {company?.agreementFrom} - {company?.agreementTo}
                </h4>
                <Trans i18nKey="contractText8-2" components={[<p key={0} />]} />
                <br />

                <h4>{t('contractHeader9')}</h4>
                <p>{t('contractText9')}</p>
                <br />

                {company?.contractAcceptedBy && (
                    <h4>{t('contractAcceptedBy', { contractAcceptedBy: company.contractAcceptedBy, contractAcceptedAt: company.contractAcceptedAt })}</h4>
                )}
                {!company?.contractAcceptedBy && <h4>{t('contractNotAccepted')}</h4>}
            </div>
        </div>
    );
};

export default Contract;
