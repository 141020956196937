import React, { ReactNode, useMemo, useCallback, useState } from 'react';
import styled from './Table.module.css';
import {
    useTable,
    Column,
    ColumnInstance,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout,
    useRowSelect,
    SortingRule,
    useMountedLayoutEffect,
    useExpanded,
} from 'react-table';
import { ReactComponent as SortIcon } from '../../assets/images/accordionIcon.svg';
import { ReactComponent as NoSortIcon } from '../../assets/images/unsortedIcon.svg';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import CheckboxCell from './CheckboxCell/CheckboxCell';
import _ from 'lodash';
import HtmlCell from './HtmlCell/HtmlCell';
import useAuth from '../../hooks/useAuth';
import useClickPreventionOnDoubleClick from '../../hooks/useClickPreventionOnDoubleClick';
import { useTranslation } from 'react-i18next';
import AcceptanceStatus from '../../models/AcceptanceStatus';

interface Props {
    columns: Column<any>[];
    data: any[];
    selected?: number;
    onSelect?: (selected: number) => void;
    onDoubleClickSelect?: (selected: number) => void;
    rowHeight?: number;
    dense?: boolean;

    showColumnSearch: boolean;
    columnSearch: {
        id: string;
        value: string | undefined;
    }[];
    onColumnSearchChange?: (filters: Props['columnSearch']) => void;
    globalSearch: string;

    hiddenColumns?: string[];

    onVisibleDataCountChange?: (visibleDataCount: number) => void;

    multiSelect?: boolean;
    disableMultiSelect?: boolean;
    singleSelect?: boolean;

    sorting?: SortingRule<object>[];
    onSortingChange?: (sorting: SortingRule<object>[]) => void;
    showSubscriptions?: boolean;
    lockedRows?: number[];
    selectedRows?: Record<string, boolean>;
    selectedRow?: number;
    onSelectedRowsChange?: (selectedRows: any) => void;
    onSelectedRowChange?: (selectedRow: string) => void;
    expandable?: boolean;
    useDivider?: boolean;
    scrollToId?: number;
    getRowIdFunction?: (row: any) => number;
    headerClassName?: string;
    disableDetachedCopies?: boolean;
    hideMultiSelect?: boolean;
    disableSorting?: boolean;
    hideDisabledCheckboxes?: boolean;
}

const ColumnFilter = ({ column: { filterValue, setFilter } }: { column: ColumnInstance }): ReactNode => {
    const { t } = useTranslation();
    return (
        <input
            className={styled.ColumnSearchField}
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
            placeholder={t('filterbarColumnSearchPlaceholder')}
        />
    );
};

const Table: React.FC<Props> = ({
    columns,
    data,
    selected,
    onSelect,
    onDoubleClickSelect,
    rowHeight = 119,
    dense = false,
    showColumnSearch,
    globalSearch,
    onVisibleDataCountChange,
    columnSearch,
    onColumnSearchChange,
    hiddenColumns,
    multiSelect = false,
    disableMultiSelect = false,
    singleSelect = false,
    sorting,
    onSortingChange,
    showSubscriptions = true,
    lockedRows,
    selectedRows,
    selectedRow,
    onSelectedRowsChange,
    onSelectedRowChange,
    expandable,
    useDivider,
    scrollToId,
    headerClassName,
    disableDetachedCopies,
    hideMultiSelect,
    disableSorting,
    hideDisabledCheckboxes,
    getRowIdFunction,
}) => {
    const { isAboveUser } = useAuth();
    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onSelect, onDoubleClickSelect);

    const [dividerIndex, setDividerIndex] = useState<number>(-1);

    const defaultColumn = useMemo(
        () => ({
            Filter: ColumnFilter,
            filter: 'text',
            Cell: HtmlCell,
            width: 1,
        }),
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        setHiddenColumns,
        filteredRows,
        setAllFilters,
        state: { sortBy },
    } = useTable(
        {
            columns: useMemo(
                () =>
                    columns.map(c => ({
                        ...c,
                        sortType: 'caseInsensitive',
                    })),
                [columns],
            ),
            data,
            defaultColumn,
            disableSortBy: !sorting,
            initialState: {
                hiddenColumns: hiddenColumns || [],
                globalFilter: globalSearch,
                sortBy: sorting || undefined,
            },
            autoResetFilters: false,
            autoResetExpanded: false,
            lockedRows,
            selectedRows,
            selectedRow,
            onSelectedRowsChange,
            onSelectedRowChange,
            getSubRows: (row: any) => (row.subRows ? row.subRows : []),
            getRowId: (row, relativeIndex, parent) => {
                if (multiSelect || singleSelect) {
                    return _.get(row, 'subscriptionId') || _.get(row, 'lawId') || _.get(row, 'customLawId');
                }
                return parent ? [parent.id, relativeIndex].join('.') : relativeIndex;
            },
            sortTypes: {
                caseInsensitive: (a, b, id) => {
                    let valueA = '';
                    let valueB = '';
                    //Null and undefined check. Defaults to empty an string if null.
                    if (a.values[id] !== null && a.values[id] !== undefined) {
                        valueA = typeof a.values[id] === 'string' ? a.values[id].toLowerCase() : a.values[id].toString();
                    }
                    if (b.values[id] !== null && b.values[id] !== undefined) {
                        valueB = typeof b.values[id] === 'string' ? b.values[id].toLowerCase() : b.values[id].toString();
                    }
                    return valueA.localeCompare(valueB, 'sv-SE', { numeric: true });
                },
            },
            useControlledState: state => {
                return useMemo(() => {
                    // Handler for sorting newLaws always at top in ChangeList
                    if (useDivider && sorting && !_.find(state.sortBy, s => s.id === 'newLaw')) {
                        state.sortBy = [{ id: 'newLaw', desc: true }, ...state.sortBy];
                    }

                    if (onColumnSearchChange && !_.isEqual(columnSearch, state.filters)) {
                        onColumnSearchChange(state.filters);
                    }

                    // Handler for ShowSubscription in LawLibrary
                    if (multiSelect && showSubscriptions !== undefined) {
                        if (showSubscriptions && !_.find(state.filters, f => f.id === 'subscribed')) {
                            state.filters = [{ id: 'subscribed', value: showSubscriptions }, ...state.filters];
                        } else if (!showSubscriptions && _.find(state.filters, f => f.id === 'subscribed')) {
                            state.filters = state.filters.filter(f => f.id !== 'subscribed');
                        }
                    }

                    return state;
                }, [state]);
            },
        },
        useFlexLayout,
        useGlobalFilter,
        useFilters,
        ...(sorting ? [useSortBy] : []),
        ...(expandable ? [useExpanded] : []),
        useCallback(() => (multiSelect || singleSelect ? useRowSelect : undefined), [multiSelect, singleSelect]),
        useCallback(
            hooks => {
                if ((!multiSelect && !singleSelect) || hideMultiSelect) {
                    return;
                }
                // Column for checkbox in LawLibrary
                hooks.visibleColumns.push(columns => [
                    {
                        id: 'monitored',
                        Header: ({ onSelectedRowsChange, selectedRows, filteredRowsById }) => {
                            if (singleSelect) {
                                return null;
                            }
                            const filteredRowIds = Object.keys(filteredRowsById);
                            const checked = _.every(filteredRowIds, id => selectedRows[id] === true) && filteredRowIds.length > 0;
                            const indeterminate = _.some(filteredRowIds, id => selectedRows[id] === true) && !_.every(filteredRowIds, id => selectedRows[id] === true);

                            const CheckboxComponent = (
                                <CheckboxCell checked={checked} indeterminate={indeterminate} onChange={() => onSelectedRowsChange(filteredRowIds)} disabled={disableMultiSelect} />
                            );
                            return CheckboxComponent;
                        },
                        Cell: ({ onSelectedRowChange, selectedRows, selectedRow, lockedRows, row }) => {
                            const id: string = row.id;
                            const acceptanceStatus: AcceptanceStatus = _.get(row.original, 'acceptanceStatus');
                            const isNewLaw: boolean = _.get(row.original, 'newLaw');
                            const unacceptedNewLaw = acceptanceStatus != 'ACCEPTED' && isNewLaw && acceptanceStatus != undefined;
                            const isDetachedCopy: boolean = _.get(row.original, 'detachedCopy');
                            const checked = multiSelect ? selectedRows[id] : selectedRow === +id;
                            const locked = lockedRows ? lockedRows.includes(id) : false;

                            const disabled =
                                unacceptedNewLaw ||
                                (isDetachedCopy && disableDetachedCopies) ||
                                (singleSelect && selectedRow !== undefined && selectedRow > -1 && selectedRow !== +id) ||
                                disableMultiSelect ||
                                locked;

                            if (hideDisabledCheckboxes && disabled && !locked) {
                                return <div />;
                            }

                            const CheckboxCellComponent = (
                                <CheckboxCell disabled={disabled} locked={locked} checked={checked} indeterminate={false} onChange={() => onSelectedRowChange(row.id)} />
                            );
                            return CheckboxCellComponent;
                        },
                        width: '65px',
                    },
                    ...columns,
                ]);
            },
            [multiSelect, singleSelect, isAboveUser, columns],
        ),
    );

    // Handler for sorting
    useMountedLayoutEffect(() => {
        onSortingChange && onSortingChange(sortBy);
    }, [sortBy]);

    // Handler for visible columns
    useMountedLayoutEffect(() => {
        hiddenColumns && setHiddenColumns(hiddenColumns);
    }, [hiddenColumns]);

    // Handler for global search
    useMountedLayoutEffect(() => {
        setGlobalFilter(globalSearch);
    }, [globalSearch]);

    // Handler for column search
    useMountedLayoutEffect(() => {
        setAllFilters(columnSearch);
    }, [columnSearch]);

    // Handler for visibleDataCount and newLawDivider
    useMountedLayoutEffect(() => {
        onVisibleDataCountChange !== undefined && onVisibleDataCountChange(filteredRows.length);
        onSelect && !scrollToId && onSelect(-1); // Reset selection when data changes
        if (useDivider) {
            let dividerIndex = 0;
            filteredRows.forEach(row => {
                if (row.values.newLaw === 'true') {
                    dividerIndex++;
                }
            });

            if (dividerIndex > 0) {
                setDividerIndex(dividerIndex - 1);
            } else {
                setDividerIndex(-1);
            }
        }
    }, [filteredRows.length]);

    const rowRenderer = ({ index, key, style }: ListRowProps): React.ReactNode => {
        const row = rows[index];
        prepareRow(row);

        return (
            <div
                key={key}
                className={[styled.TR, selected === row.index ? styled.Selected : '', useDivider && dividerIndex === index ? styled.LastNewLaw : ''].join(' ')}
                onClick={() => {
                    if (getRowIdFunction) {
                        handleClick && handleDoubleClick
                            ? handleClick(selected === getRowIdFunction(row) ? -1 : getRowIdFunction(row))
                            : onSelect && onSelect(selected === getRowIdFunction(row) ? -1 : getRowIdFunction(row));
                    } else {
                        handleClick && handleDoubleClick ? handleClick(selected === row.index ? -1 : row.index) : onSelect && onSelect(selected === row.index ? -1 : row.index);
                    }
                }}
                onDoubleClick={() => handleClick && handleDoubleClick && handleDoubleClick(row.index)}
                {...row.getRowProps({ style })}
            >
                {row.cells.map((cell, cellIndex) => (
                    <div
                        key={cellIndex}
                        {...cell.getCellProps({
                            className: [
                                cell.column.id === 'monitored' ? styled.CheckboxCell : '',
                                cell.column.id === 'law' ? styled.LawCell : '',
                                cell.column.id === 'changeText' ? styled.ChangeCell : '',
                                cell.column.id === 'menu' ? styled.MenuCell : '',
                                cell.column.id === 'openButton' ? styled.OpenButtonCell : '',
                                cell.column.id === 'createdAt' || cell.column.id === 'lastLogin' ? styled.DateCell : '',
                                cell.column.id === 'externalUsers' ? styled.ExternalUsersCell : '',
                                styled.TD,
                                dense ? styled.Dense : '',
                            ].join(' '),
                        })}
                    >
                        {cell.render('Cell')}
                    </div>
                ))}
            </div>
        );
    };

    const getSortingIcon = (col: ColumnInstance) => {
        if (!sorting || !col.canSort || disableSorting) {
            return null;
        }
        if (sorting.filter(s => s.id !== 'newLaw').length < 1 && col.canSort) {
            return (
                <span className={styled.SortNone}>
                    <NoSortIcon />
                </span>
            );
        }
        if (col.isSorted) {
            return (
                <span className={col.isSortedDesc ? styled.SortDesc : styled.SortAsc}>
                    <SortIcon />
                </span>
            );
        }
    };

    return (
        <div className={styled.Wrapper}>
            <div className={styled.Table} {...getTableProps()}>
                <div className={styled.THead}>
                    {headerGroups.map((headerGroup, headIndex) => (
                        <React.Fragment key={headIndex}>
                            {showColumnSearch && (
                                <div {...headerGroup.getHeaderGroupProps()} key={'filterGroup' + headIndex} className={[styled.TR, styled.SearchBar].join(' ')}>
                                    {(multiSelect || singleSelect) && <div className={styled.CheckboxSpacing} />}
                                    {expandable && <div className={styled.ExpanderSpacing} />}
                                    {headerGroup.headers.map(
                                        (column, colIndex) =>
                                            column.id !== 'monitored' &&
                                            column.id !== 'expander' && (
                                                <div
                                                    key={'filter-' + colIndex}
                                                    {...column.getHeaderProps()}
                                                    className={[
                                                        styled.TH,
                                                        column.id === 'law' ? styled.LawCell : '',
                                                        column.id === 'changeText' ? styled.ChangeCell : '',
                                                        column.id === 'menu' ? styled.MenuCell : '',
                                                        column.id === 'externalUsers' ? styled.ExternalUsersCell : '',
                                                        dense ? styled.Dense : '',
                                                    ].join(' ')}
                                                >
                                                    {!column.disableFilters && column.render('Filter')}
                                                </div>
                                            ),
                                    )}
                                    <div className={styled.ScrollbarSpacing} />
                                </div>
                            )}
                            <div {...headerGroup.getHeaderGroupProps()} key={'headerGroup' + headIndex} className={styled.TR}>
                                {singleSelect && <div className={styled.CheckboxSpacing} />}
                                {headerGroup.headers.map((column, colIndex) =>
                                    column.id === 'monitored' && singleSelect ? null : (
                                        <div
                                            key={'header-' + colIndex}
                                            {...(onSortingChange ? column.getHeaderProps(column.getSortByToggleProps()) : column.getHeaderProps())}
                                            title={column.Header && typeof column.Header === 'string' ? column.Header.toString() : ''}
                                            className={[
                                                styled.TH,
                                                column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : '',
                                                column.id === 'expander' ? styled.ExpanderSpacing : '',
                                                column.id === 'law' ? styled.LawCell : '',
                                                column.id === 'changeText' ? styled.ChangeCell : '',
                                                column.id === 'menu' ? styled.MenuCell : '',
                                                column.id === 'openButton' ? styled.OpenButtonCell : '',
                                                column.id === 'createdAt' || column.id === 'lastLogin' ? styled.DateCell : '',
                                                column.id === 'externalUsers' ? styled.ExternalUsersCell : '',
                                                headerClassName ? headerClassName : '',
                                                dense ? styled.Dense : '',
                                            ].join(' ')}
                                        >
                                            <div>{column.render('Header')}</div>
                                            {getSortingIcon(column)}
                                        </div>
                                    ),
                                )}
                                <div className={[styled.ScrollbarSpacing, headerClassName ? headerClassName : ''].join(' ')} />
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                <div {...getTableBodyProps()} className={styled.TBody}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                key={dividerIndex}
                                height={height}
                                rowCount={rows.length}
                                rowHeight={({ index }) => {
                                    if (useDivider && dividerIndex === index) {
                                        return rowHeight + 40;
                                    }
                                    return rowHeight;
                                }}
                                scrollToIndex={scrollToId ? _.findIndex(rows, ({ original }) => _.get(original, 'subscriptionId') === scrollToId) : undefined}
                                width={width}
                                rowRenderer={rowRenderer}
                                style={{ outline: 'none' }}
                            />
                        )}
                    </AutoSizer>
                </div>
            </div>
        </div>
    );
};

export default Table;
