import React, { FC, useState } from 'react';

import styled from './ConfirmModal.module.css';
import { useTranslation } from 'react-i18next';
import warningIcon from '../../../assets/images/statusWarningIcon.svg';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Modal/Modal';
import LawModel from '../../../models/LawModel';

interface Props {
    companyCount: number;
    selectedLaws: LawModel[];
    destinationLaw?: LawModel;
    onSubmit: () => void;
    onClose: () => void;
}

const ConfirmModal: FC<Props> = ({ onSubmit, onClose, selectedLaws, destinationLaw }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    return (
        <Modal title={'Varning'} subtitle={'Du är påväg att slå samman valda lagar på alla berörda företag'}>
            <div className={styled.Content}>
                <div className={styled.Warning}>
                    <img src={warningIcon} alt="!" />

                    {selectedLaws.length > 0 && (
                        <div className={styled.SelectedLaws}>
                            <label>Valda lagar:</label>
                            <div>
                                {selectedLaws.map(law => (
                                    <li key={law.lawId}>
                                        <strong>{law.name}</strong>, Ändrad t.o.m: {law.subId}, id: {law.lawId}
                                    </li>
                                ))}
                            </div>
                        </div>
                    )}

                    {destinationLaw && (
                        <div className={styled.SelectedLaws}>
                            <label>Vald destination:</label>
                            <li key={destinationLaw.lawId}>
                                <strong>{destinationLaw.name}</strong>, Ändrad t.o.m: {destinationLaw.subId}, id: {destinationLaw.lawId}
                            </li>
                        </div>
                    )}
                </div>

                <div className={styled.ButtonRow1}>
                    <Button variant="Primary" onClick={onClose}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Outline" onClick={onSubmit} disabled={loading}>
                        {t('buttonOk')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
