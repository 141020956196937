import React, { FC } from 'react';
import styled from './RevisionCell.module.css';
import { CellProps } from 'react-table';

const RevisionCell: FC<CellProps<any>> = ({ multiRow, ...props }) => {
    const isNameColumn = props.column.id === 'name';

    return (
        <div className={styled.RevisionCell} title={isNameColumn ? props.cell.value : ''}>
            <div className={[styled.Content, isNameColumn ? styled.Name : '', multiRow ? styled.MultiRow : ''].join(' ')}>{props.cell.value}</div>
        </div>
    );
};

export default RevisionCell;
