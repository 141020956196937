import { useContext, useMemo } from 'react';
import { UnfinishedRevisionContext } from '../contexts/UnfinishedRevisionContext';
import RevisionService from '../services/RevisionService';
import useAuth from './useAuth';
import LawTypeFilter, { convertToLawTypeFilter } from '../models/LawTypeFilter';
import _ from 'lodash';
import { LawType } from '../models/LawType';
import LawListGroupFilter, { convertToLawListGroupFilter } from '../models/LawListGroupFilter';
import { SortingRule } from 'react-table';
import CreateRevisionModel from '../models/CreateRevisionModel';
import useExport from './useExport';
import ReportService from '../services/ReportService';
import { getUserSettingsSelectedLawGroupsFallback } from '../models/UserSettings';
import AlterRevisionModel from '../models/AlterRevisionModel';
import { REVISION_UNFINISHED } from '../constants/Routes';
import { useHistory } from 'react-router';

const useUnfinishedRevision = () => {
    const { state, setState } = useContext(UnfinishedRevisionContext);
    const { company, user, getUserSettings } = useAuth();
    const { exportRevisionList } = useExport();
    const history = useHistory();

    const setLoading = (loading: boolean): void => {
        setState(s => ({
            ...s,
            loading,
        }));
    };

    const fetchRevisions = async () => {
        try {
            if (company && user) {
                setLoading(true);

                const revisions = await RevisionService().getUnfinishedRevisions(company.id);
                setState(s => ({
                    ...s,
                    revisions,
                    loading: revisions.length < 1,
                }));
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchSubscriptions = async () => {
        try {
            if (company && user) {
                setLoading(true);
                const { lawTypes, subscriptions, customLaws } = await RevisionService().getRevisionSubscriptions(company.id);

                const lawTypeFilter: LawTypeFilter[] = convertToLawTypeFilter(lawTypes, customLaws.length > 0);
                const lawListGroupFilter: LawListGroupFilter[] = convertToLawListGroupFilter(company.lawLists);

                setState(s => ({
                    ...s,
                    hasCustomLaws: customLaws.length > 0,
                    subscriptions: [...subscriptions, ...customLaws],
                    lawTypeFilter,
                    lawListGroupFilter,
                    selectedKeywords: company.keyWords.map(kw => ({
                        ...kw,
                        checked: false,
                    })),
                    customLawsSelected: true,
                    loading: false,
                }));

                return [...subscriptions, ...customLaws];
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleGlobalSearchChange = (globalSearch: string): void => {
        setState(s => ({
            ...s,
            globalSearch,
        }));
    };

    const setCreateRevisionInfo = (createRevision: { name: string; userId: number; username: string } | undefined): void => {
        setState(s => ({
            ...s,
            createRevision,
            globalSearch: '',
        }));
    };

    const handleLawTypeOpen = (type: LawType): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                open: lawType.name === type ? !lawType.open : lawType.open,
            })),
        }));
    };

    const handleLawTypeChecked = (type: LawType): void => {
        if (type === 'CUSTOM_LAW') {
            setState(s => ({
                ...s,
                customLawsSelected: !s.customLawsSelected,
                customFilterActive: false,
            }));
        } else {
            setState(s => ({
                ...s,
                lawTypeFilter: s.lawTypeFilter.map(lawType => {
                    if (lawType.name === type) {
                        return {
                            ...lawType,
                            lawGroups: lawType.lawGroups.map(lawGroup => ({
                                ...lawGroup,
                                checked: !lawType.lawGroups.every(l => l.checked),
                            })),
                        };
                    }
                    return lawType;
                }),
                customFilterActive: false,
            }));
        }
    };

    const handleAllLawTypesChecked = (allChecked: boolean): void => {
        setState(s => ({
            ...s,
            customLawsSelected: !allChecked,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: !allChecked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleLawGroupChecked = (lawGroupId: number): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: lawGroup.lawGroupId === lawGroupId ? !lawGroup.checked : lawGroup.checked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleLawListGroupFilterChange = (lawListGroupId: number): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => {
                if (lawListGroup.lawListGroupId === lawListGroupId) {
                    return {
                        ...lawListGroup,
                        lawLists: lawListGroup.lawLists.map(lawList => ({
                            ...lawList,
                            checked: !lawListGroup.lawLists.every(l => l.checked),
                        })),
                    };
                }
                return lawListGroup;
            }),
            customFilterActive: false,
        }));
    };

    const handleLawListFilterChange = (lawListId: number): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: lawList.lawListId === lawListId ? !lawList.checked : lawList.checked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleAllLawListChange = (checked: boolean): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleSelectedKeywordChange = (keywordId: number): void => {
        setState(s => ({
            ...s,
            selectedKeywords: s.selectedKeywords.map(keyword => ({
                ...keyword,
                checked: keyword.id === keywordId ? !keyword.checked : keyword.checked,
            })),
            customFilterActive: false,
        }));
    };

    const handleSelectAllKeywords = (checked: boolean): void => {
        setState(s => ({
            ...s,
            selectedKeywords: s.selectedKeywords.map(kw => ({
                ...kw,
                checked,
            })),
            customFilterActive: false,
        }));
    };

    const handleColumnSearchToggle = () => {
        setState(s => ({
            ...s,
            columnSearch: s.showColumnSearch ? s.columnSearch.map(c => ({ ...c, value: undefined })) : s.columnSearch,
            showColumnSearch: !s.showColumnSearch,
        }));
    };

    const handleSelection = (selectedIndex: number) => {
        setState(s => ({
            ...s,
            selectedIndex,
        }));
    };

    const handleColumnVisibleChange = (id: string): void => {
        setState(s => ({
            ...s,
            columns: s.columns.map(column => ({
                ...column,
                visible: column.id === id ? !column.visible : column.visible,
            })),
            customFilterActive: false,
        }));
    };

    const handleCreateRevision = (selectedSubscriptionIds: number[], selectedCustomLawIds: number[]) => {
        const revision: CreateRevisionModel = {
            responsibleUserId: _.get(state.createRevision, 'userId', -1),
            name: _.get(state.createRevision, 'name', ''),
            subscriptionIds: selectedSubscriptionIds,
            customLawIds: selectedCustomLawIds,
        };

        if (company) {
            RevisionService()
                .createRevision(company?.id, revision)
                .then(() => {
                    setCreateRevisionInfo(undefined);
                });
        }
    };

    const handleAlterRevision = (selectedSubscriptionIds: number[], selectedCustomLawIds: number[], revisionId: number) => {
        const revision: AlterRevisionModel = {
            revisionId: revisionId,
            subscriptionIds: selectedSubscriptionIds,
            customLawIds: selectedCustomLawIds,
        };

        if (company) {
            RevisionService()
                .alterRevision(company?.id, revision)
                .then(() => {
                    history.push(REVISION_UNFINISHED);
                });
        }
    };

    const handleVisibleSubscriptionCount = (visibleSubscriptionCount: number): void => {
        setState(s => ({
            ...s,
            visibleSubscriptionCount,
        }));
    };

    const handleResetLawTypeFilter = (): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: true,
                })),
            })),
            customLawsSelected: true,
            customFilterActive: false,
        }));
    };

    const handleResetAllFilters = () => {
        setState(s => ({
            ...s,
            globalSearch: '',
            selectedKeywords: s.selectedKeywords.map(kw => ({ ...kw, checked: false })),
            columnSearch: s.columnSearch.map(c => ({ ...c, value: undefined })),
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: false,
                })),
            })),
            customFilterActive: false,
        }));
        handleResetLawTypeFilter();
    };

    const handleCustomFilterToggle = () => {
        const userSettings = getUserSettings();

        if (!userSettings?.id) {
            return;
        }

        if (!state.customFilterActive) {
            setState(s => ({
                ...s,
                customFilterActive: true,
                columns: s.columns.map(c => ({
                    ...c,
                    visible: c.alwaysVisible
                        ? true
                        : (userSettings.bffColumnVisible && c.id === 'text') ||
                          (userSettings.descColumnVisible && c.id === 'description') ||
                          (userSettings.column1Visible && c.id === 'customerText1') ||
                          (userSettings.column2Visible && c.id === 'customerText2') ||
                          (userSettings.column3Visible && c.id === 'customerText3') ||
                          (userSettings.column4Visible && c.id === 'customerText4') ||
                          (userSettings.column5Visible && c.id === 'customerText5') ||
                          (userSettings.keywordColumnVisible && c.id === 'keywords') ||
                          (userSettings.lawListColumnVisible && c.id === 'lawLists') ||
                          (userSettings.latestRevisionDateColumnVisible && c.id === 'latestRevisionDate'),
                })),
                selectedKeywords: s.selectedKeywords.map(kw => ({
                    ...kw,
                    checked: userSettings.selectedKeywords.includes(kw.id),
                })),
                lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                    ...lawType,
                    lawGroups: lawType.lawGroups.map(lawGroup => ({
                        ...lawGroup,
                        checked: getUserSettingsSelectedLawGroupsFallback(userSettings.selectedLawGroups, s.subscriptions).includes(lawGroup.lawGroupId),
                    })),
                })),
                customLawsSelected: userSettings.customLawsSelected,
                lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                    ...lawListGroup,
                    lawLists: lawListGroup.lawLists.map(lawList => ({
                        ...lawList,
                        checked: userSettings.selectedLawLists.includes(lawList.lawListId),
                    })),
                })),
            }));
        } else {
            setState(s => ({
                ...s,
                customFilterActive: false,
                columns: s.columns.map(c => ({ ...c, visible: true })),
                selectedKeywords: s.selectedKeywords.map(kw => ({ ...kw, checked: false })),
                lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                    ...lawType,
                    lawGroups: lawType.lawGroups.map(lawGroup => ({
                        ...lawGroup,
                        checked: true,
                    })),
                })),
                lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                    ...lawListGroup,
                    lawLists: lawListGroup.lawLists.map(lawList => ({
                        ...lawList,
                        checked: false,
                    })),
                })),
                customLawsSelected: true,
            }));
        }
    };

    const handleSortingChange = (sorting: SortingRule<object>[]): void => {
        setState(s => ({
            ...s,
            sorting,
        }));
    };

    const handleUpdateRevision = (id: number, version: number, name: string, responsibleUserId: number, callback: () => void): void => {
        if (company) {
            RevisionService()
                .updateRevision(company.id, id, version, name, responsibleUserId)
                .then(updatedRevision => {
                    setState(s => ({
                        ...s,
                        revisions: s.revisions.map(revision =>
                            revision.id === id
                                ? {
                                      ...revision,
                                      name: updatedRevision.name,
                                      responsibleName: updatedRevision.responsibleName,
                                      responsibleUserId: updatedRevision.responsibleUserId,
                                      version: updatedRevision.version,
                                  }
                                : revision,
                        ),
                    }));
                    callback();
                });
        }
    };

    const handleRemoveRevision = (id: number, callback: () => void): void => {
        RevisionService()
            .removeRevision(id)
            .then(() => {
                setState(s => ({
                    ...s,
                    revisions: s.revisions.filter(rev => rev.id !== id),
                }));
                callback();
            });
    };

    const handleExport = async (revisionId: number) => {
        try {
            if (company && user) {
                setLoading(true);
                const revision = await RevisionService().getRevision(revisionId);
                const { subscriptionRevisions, customLawRevisions } = revision.revisionSubscriptionsListModel;
                const revisionName = 'Revision: ' + revision.name;
                const data = [...subscriptionRevisions, ...customLawRevisions];

                await exportRevisionList(data, `Revision pågående ${revision.name}`, revision.comment, revisionName);

                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const hiddenColumns: string[] = useMemo(() => state.columns.filter(column => !column.visible).map(column => column.id), [state.columns]);

    return {
        ...state,
        hiddenColumns,
        fetchRevisions,
        fetchSubscriptions,
        handleGlobalSearchChange,
        setCreateRevisionInfo,
        handleLawTypeOpen,
        handleLawTypeChecked,
        handleLawGroupChecked,
        handleLawListFilterChange,
        handleLawListGroupFilterChange,
        handleSelectedKeywordChange,
        handleColumnSearchToggle,
        handleSelection,
        handleColumnVisibleChange,
        handleCreateRevision,
        handleAlterRevision,
        handleVisibleSubscriptionCount,
        handleResetAllFilters,
        handleResetLawTypeFilter,
        handleCustomFilterToggle,
        handleSortingChange,
        handleUpdateRevision,
        handleRemoveRevision,
        handleExport,
        setLoading,
        handleAllLawListChange,
        handleSelectAllKeywords,
        handleAllLawTypesChecked,
    };
};

export default useUnfinishedRevision;
