import { RevisionObjStatus } from './RevisionObjStatus';
import RevisionCommentModel from './RevisionCommentModel';
import SubscriptionBaseModel from './SubscriptionBaseModel';
import CustomLawRevisionModel from './CustomLawRevisionModel';
import RevisionMediaAttachmentModel from './RevisionMediaAttachmentModel';

interface RevisionSubscriptionModel extends SubscriptionBaseModel {
    revisionStatus: RevisionObjStatus;
    comments: RevisionCommentModel[];
    mediaAttachments: RevisionMediaAttachmentModel[];
    lawTemplateText: string;
}

export const isRevisionSubscription = (obj: RevisionSubscriptionModel | CustomLawRevisionModel | any): obj is RevisionSubscriptionModel => {
    return (obj as RevisionSubscriptionModel).subscriptionId !== undefined;
};

export default RevisionSubscriptionModel;
