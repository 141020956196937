import React, { FC, useState } from 'react';
import styled from './ExportRevisionModal.module.css';
import Modal from '../Modal';
import Button from '../../Button/Button';
import RadioButton from '../../RadioButton/RadioButton';
import DateRangePicker from '../../DateRangePicker/DateRangePicker';
import ReportService from '../../../services/ReportService';
import useAuth from '../../../hooks/useAuth';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';

interface Props {
    initialStartDate: Date;
    initialEndDate: Date;
    onClose: () => void;
}

const ExportRevisionModal: FC<Props> = ({ initialStartDate, initialEndDate, onClose }) => {
    const { t } = useTranslation();
    const { company } = useAuth();

    const [loading, setLoading] = useState(false);
    const [selectedReport, setSelectedReport] = useState<'RESULT' | 'STATUS'>('RESULT');
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);

    const handleExport = async () => {
        if (company) {
            try {
                const from = startDate.toLocaleDateString('sv');
                const to = endDate.toLocaleDateString('sv');
                setLoading(true);
                if (selectedReport === 'RESULT') {
                    await ReportService().getRevisionResultReport(company.id, from, to);
                } else {
                    await ReportService().getRevisionStatusReport(company.id, from, to);
                }
                setLoading(false);
                onClose();
            } catch (error) {
                setLoading(false);
            }
        }
    };

    return (
        <Modal title={t('buttonCreateReport')} className={styled.ExportRevisionModal}>
            {loading && <LoadingSpinner />}
            <div className={styled.Options}>
                <div className={styled.RadioButtonContainer}>
                    <RadioButton text={t('createRevisionReportResultTitle')} checked={selectedReport === 'RESULT'} onChange={() => setSelectedReport('RESULT')} group="report" />
                    <p>{t('createRevisionReportModalResultOption')}</p>
                </div>
                <div className={styled.RadioButtonContainer}>
                    <RadioButton text={t('createRevisionReportStatusTitle')} checked={selectedReport === 'STATUS'} onChange={() => setSelectedReport('STATUS')} group="report" />
                    <p>{t('createRevisionReportModalStatusOption')}</p>
                </div>
            </div>

            <div className={styled.DatePicker}>
                <p>{t('columnDate')}</p>
                <DateRangePicker startDate={startDate} onStartDateChange={setStartDate} endDate={endDate} onEndDateChange={setEndDate} />
            </div>

            <div className={styled.Buttons}>
                <Button variant="Outline" onClick={onClose}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Primary" onClick={handleExport}>
                    {t('buttonExport')}
                </Button>
            </div>
        </Modal>
    );
};

export default ExportRevisionModal;
