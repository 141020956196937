import { AxiosResponse, AxiosError } from 'axios';
import _ from 'lodash';
import { getAuthApi } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';
import RevisionModel from '../models/RevisionModel';
import LawTypeModel from '../models/LawTypeModel';
import LawListModel from '../models/LawListModel';
import SubscriptionModel from '../models/SubscriptionModel';
import CustomLawModel from '../models/CustomLawModel';
import CreateRevisionModel from '../models/CreateRevisionModel';
import { RevisionObjStatus } from '../models/RevisionObjStatus';
import FullRevisionModel from '../models/FullRevisionModel';
import RevisionObjUpdateModel from '../models/RevisionObjUpdateModel';
import AlterRevisionModel from '../models/AlterRevisionModel';
import RevisionMediaAttachmentModel from '../models/RevisionMediaAttachmentModel';

interface LawListContentModel {
    lawTypes: LawTypeModel[];
    lawLists: LawListModel[];
    subscriptions: SubscriptionModel[];
    customLaws: CustomLawModel[];
}

const RevisionService = () => {
    const axiosInstance = getAuthApi();

    function getUnfinishedRevisions(companyId: number): Promise<RevisionModel[]> {
        return axiosInstance
            .get<RevisionModel[]>(`/revision/unfinished?companyId=${companyId}`)
            .then((response: AxiosResponse<RevisionModel[]>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getFinishedRevisions(companyId: number, from: string, to: string): Promise<RevisionModel[]> {
        return axiosInstance
            .get<RevisionModel[]>(`/revision/finished?companyId=${companyId}&from=${from}&to=${to}`)
            .then((response: AxiosResponse<RevisionModel[]>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getRevisionSubscriptions(companyId: number): Promise<LawListContentModel> {
        return axiosInstance
            .get<LawListContentModel>(`/revision/subscriptions?companyId=${companyId}`)
            .then((response: AxiosResponse<LawListContentModel>) => {
                // Here we map empty string in text and customerText fields to null. We do this patch here instead of
                // in the backend because, the proper backend solution would be a migration script that changes all
                // empty strings to null.
                //
                // TODO: Migrate and update these fields in backend and remove this patch

                const isTextField = (key: string): boolean => !!key.match(/text|customerText*/);
                const updateObjVal = (val: any, key: string): any => (isTextField(key) && val === '' ? null : val);

                return {
                    ...response.data,
                    subscriptions: response.data.subscriptions.map(obj => _.mapValues(obj, updateObjVal) as SubscriptionModel),
                    customLaws: response.data.customLaws.map(obj => _.mapValues(obj, updateObjVal) as CustomLawModel),
                };
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function createRevision(companyId: number, revision: CreateRevisionModel): Promise<RevisionModel> {
        return axiosInstance
            .post<RevisionModel>(`/revision?companyId=${companyId}`, revision)
            .then((response: AxiosResponse<RevisionModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function alterRevision(companyId: number, revision: AlterRevisionModel): Promise<RevisionModel> {
        return axiosInstance
            .post<RevisionModel>(`/revision/alter?companyId=${companyId}`, revision)
            .then((response: AxiosResponse<RevisionModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    // function editRevisionName(companyId: number, id: number, version: number, name: string): Promise<RevisionModel> {
    //     return axiosInstance
    //         .post<RevisionModel>(`/revision/name?companyId=${companyId}&id=${id}&version=${version}&name=${name}`)
    //         .then((response: AxiosResponse<RevisionModel>) => {
    //             return response.data;
    //         })
    //         .catch((error: AxiosError<ApiErrorModel>) => {
    //             throw error;
    //         });
    // }

    function updateRevision(companyId: number, id: number, version: number, name: string, responsibleUserId: number): Promise<RevisionModel> {
        return axiosInstance
            .put<RevisionModel>(`/revision?companyId=${companyId}&id=${id}&version=${version}&name=${name}&responsibleUserId=${responsibleUserId}`)
            .then((response: AxiosResponse<RevisionModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateRevisionName(companyId: number, id: number, version: number, name: string): Promise<RevisionModel> {
        return axiosInstance
            .put<RevisionModel>(`/revision/name?companyId=${companyId}&id=${id}&version=${version}&name=${name}`)
            .then((response: AxiosResponse<RevisionModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateRevisionComment(id: number, version: number, comment: string): Promise<RevisionModel> {
        const data = new FormData();
        data.append('id', id.toString());
        data.append('version', version.toString());
        data.append('comment', comment);
        return axiosInstance
            .post<RevisionModel>(`/revision/comment`, data)
            .then((response: AxiosResponse<RevisionModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getRevision(revisionId: number): Promise<FullRevisionModel> {
        return axiosInstance
            .get<FullRevisionModel>(`/revision?revisionId=${revisionId}`)
            .then((response: AxiosResponse<FullRevisionModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateSubscriptionRevisionStatus(revisionId: number, subscriptionId: number, commentText: string, status: RevisionObjStatus): Promise<RevisionObjUpdateModel> {
        const data = new FormData();
        data.append('revisionId', revisionId.toString());
        data.append('subscriptionId', subscriptionId.toString());
        data.append('commentText', commentText);
        data.append('status', status);
        return axiosInstance
            .put<RevisionObjUpdateModel>(`/revision/subscription/status`, data)
            .then((response: AxiosResponse<RevisionObjUpdateModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateCustomLawRevisionStatus(revisionId: number, customLawId: number, commentText: string, status: RevisionObjStatus): Promise<RevisionObjUpdateModel> {
        const data = new FormData();
        data.append('revisionId', revisionId.toString());
        data.append('customLawId', customLawId.toString());
        data.append('commentText', commentText);
        data.append('status', status);
        return axiosInstance
            .put<RevisionObjUpdateModel>(`/revision/customlaw/status`, data)
            .then((response: AxiosResponse<RevisionObjUpdateModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function closeRevision(revisionId: number): Promise<void> {
        return axiosInstance.post(`/revision/close?revisionId=${revisionId}`, undefined);
    }

    function removeRevision(id: number): Promise<void> {
        return axiosInstance.delete(`/revision?id=${id}`);
    }

    function getNumberOfUnfinishedRevisionsForCompany(companyId: number): Promise<number> {
        return axiosInstance
            .get<number>(`/revision/unfinished/count?companyId=${companyId}`)
            .then((response: AxiosResponse<number>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getNumberOfRevisionWarnings(companyId: number): Promise<number> {
        return axiosInstance
            .get<number>(`/revision/revisionwarnings?companyId=${companyId}`)
            .then((response: AxiosResponse<number>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function addRevisionMedia(companyId: number, revisionId: number, subRevisionId: number, file: File, comment: string, custom?: boolean): Promise<RevisionMediaAttachmentModel> {
        const data = new FormData();
        data.append('companyId', companyId.toString());
        data.append('revisionId', revisionId.toString());
        let path;
        if (!custom) {
            data.append('subRevisionId', subRevisionId.toString());
            path = '/revision/media';
        } else {
            data.append('customLawId', subRevisionId.toString());
            path = '/revision/customlaw/media';
        }
        data.append('comment', comment);
        data.append('file', file);

        // debugger;
        return axiosInstance
            .post<RevisionMediaAttachmentModel>(path, data)
            .then((response: AxiosResponse<RevisionMediaAttachmentModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function editRevisionMedia(companyId: number, mediaId: number, comment: string): Promise<RevisionMediaAttachmentModel> {
        const data = new FormData();
        data.append('companyId', companyId.toString());
        data.append('mediaId', mediaId.toString());
        data.append('comment', comment);

        return axiosInstance
            .put<RevisionMediaAttachmentModel>(`/revision/media/comment`, data)
            .then((response: AxiosResponse<RevisionMediaAttachmentModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getRevisionMediaFullData(companyId: number, mediaId: number): Promise<any> {
        const data = new FormData();
        data.append('companyId', companyId.toString());
        data.append('mediaId', mediaId.toString());

        return axiosInstance
            .get<string>(`/revision/media/data?companyId=${companyId}&mediaId=${mediaId}`)
            .then((response: AxiosResponse<string>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function deleteRevisionMedia(companyId: number, mediaId: number): Promise<RevisionMediaAttachmentModel> {
        const data = new FormData();
        data.append('companyId', companyId.toString());
        data.append('mediaId', mediaId.toString());

        console.log(data);
        // debugger;
        return axiosInstance
            .delete<RevisionMediaAttachmentModel>(`/revision/media?companyId=${companyId}&mediaId=${mediaId}`)
            .then((response: AxiosResponse<RevisionMediaAttachmentModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getUnfinishedRevisions,
        getFinishedRevisions,
        getRevisionSubscriptions,
        createRevision,
        alterRevision,
        updateRevision,
        updateRevisionComment,
        getRevision,
        updateSubscriptionRevisionStatus,
        updateCustomLawRevisionStatus,
        closeRevision,
        removeRevision,
        getNumberOfUnfinishedRevisionsForCompany,
        getNumberOfRevisionWarnings,
        addRevisionMedia,
        editRevisionMedia,
        getRevisionMediaFullData,
        deleteRevisionMedia,
    };
};

export default RevisionService;
