import { LawType, sortLawTypes } from './LawType';
import LawTypeModel from './LawTypeModel';
import UserSettings from './UserSettings';
import _ from 'lodash';

interface LawTypeFilter {
    name: LawType;
    open: boolean;
    lawGroups: {
        lawGroupId: number;
        lawGroupName: string;
        checked: boolean;
    }[];
}

export const convertToLawTypeFilter = (lawTypes: LawTypeModel[], hasCustomLaws?: boolean, userSettings?: UserSettings, defaultChecked?: boolean): LawTypeFilter[] => {
    // Quickfix for admin func..
    let checked: boolean;
    if (defaultChecked === undefined) {
        checked = true;
    } else {
        checked = defaultChecked;
    }

    const lawTypeFilter: LawTypeFilter[] = lawTypes
        .map(lawType => ({
            name: lawType.name,
            open: false,
            lawGroups: _.sortBy(
                lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: userSettings && userSettings.selectedLawGroups.length > 0 ? userSettings.selectedLawGroups.includes(lawGroup.lawGroupId) : checked, // This is used because old application uses empty list for selecting all lawGroups.
                })),
                'lawGroupName',
            ),
        }))
        .sort((a, b) => sortLawTypes(a.name, b.name));

    if (hasCustomLaws) {
        const customLawTypeFilter: LawTypeFilter = { name: 'CUSTOM_LAW', open: false, lawGroups: [] };
        lawTypeFilter.push(customLawTypeFilter);
    }

    return lawTypeFilter;
};

export const getActiveLawTypeFilters = (lawTypeFilters: LawTypeFilter[]): LawTypeFilter[] => {
    const activeLawTypeFilters: LawTypeFilter[] = [];
    lawTypeFilters.forEach(ltf => {
        if (ltf.lawGroups.some(lg => lg.checked)) {
            const redusedLawGroupList = ltf.lawGroups.filter(lg => lg.checked);
            ltf.lawGroups = redusedLawGroupList;
            activeLawTypeFilters.push(ltf);
        }
    });
    return activeLawTypeFilters;
};

export default LawTypeFilter;
