import React, { FC, useMemo, ReactNode, useState } from 'react';
import styled from './EditDetachedCopiesFullView.module.css';
import _ from 'lodash';
import AccordionTextItem from '../../Accordion/AccordionTextItem/AccordionTextItem';
import AccordionListItem from '../../Accordion/AccordionListItem/AccordionListItem';
import useAuth from '../../../hooks/useAuth';
import SubscriptionModel from '../../../models/SubscriptionModel';
import FullView from '../FullView';
import Accordion from '../../Accordion/Accordion';
import DetachedCopyFullViewHeader from '../../DetachedCopyFullViewHeader/DetachedCopyFullViewHeader';
import { useTranslation } from 'react-i18next';

interface Props {
    data?: SubscriptionModel;
    open: boolean;
    onClose: () => void;
}

const EditDetachedCopiesFullView: FC<Props> = ({ data, open, onClose }) => {
    const { t, i18n } = useTranslation();
    const { company } = useAuth();

    const [openAccordions, setOpenAccordions] = useState<number[]>([]);

    const accordionItems = useMemo(() => {
        const items: { title: string; value: ReactNode }[] = [];

        items.push({
            title: t('columnDescription'),
            value: <AccordionTextItem data={data?.description || ''} />,
        });

        items.push({
            title: t('columnText'),
            value: <AccordionTextItem data={data?.text || ''} />,
        });

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                items.push({
                    title,
                    value: <AccordionTextItem data={_.get(data, `customerText${i}`)} />,
                });
            }
        }

        if (company?.hasLawLists) {
            items.push({
                title: t('columnLawLists'),
                value: <AccordionListItem data={data?.lawLists?.map(ll => (ll.lawListGroup ? ll.lawListGroup.name + ': ' : '') + ll.name) || []} />,
            });
        }

        if (company?.hasKeyWords) {
            items.push({
                title: t('columnKeywords'),
                value: <AccordionListItem data={data?.keywordIds?.map(kwId => _.find(company.keyWords, kw => kw.id === kwId)?.text || '') || []} />,
            });
        }

        return items;
    }, [data, i18n.language]);

    const handleToggleAccordion = (index: number) => {
        setOpenAccordions(s => (s.includes(index) ? s.filter(i => i !== index) : [...s, index]));
    };

    return (
        <FullView
            lawSourceId={data && data.lawId}
            title={data?.name || ''}
            subId={data?.subId || ''}
            isCustomLaw={false}
            isExpired={false}
            lawSource={data?.lawSources.length ? data.lawSources[0] : undefined}
            open={open}
            onClose={onClose}
            headerChildren={data?.detachedCopy ? <DetachedCopyFullViewHeader /> : undefined}
        >
            <div className={styled.Content}>
                <Accordion id={data ? data.subscriptionId : -1} data={accordionItems} initiallyOpenIndicies={openAccordions} onToggle={handleToggleAccordion} />
            </div>
        </FullView>
    );
};

export default EditDetachedCopiesFullView;
