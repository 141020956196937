import React, { FC, useState } from 'react';

import styled from './EditDescriptionModal.module.css';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import TextEditor from '../../../../components/TextEditor/TextEditor';

interface Props {
    title: string;
    value: string;
    maxLength?: number;
    onCancel: () => void;
    onConfirm: (value: string) => void;
}

const EditDescriptionModal: FC<Props> = ({ title, value, onCancel, onConfirm, maxLength }) => {
    const { t } = useTranslation();
    const [fieldValue, setFieldValue] = useState(value);

    function handleOnClickConfirm() {
        onConfirm(fieldValue);
    }

    return (
        <Modal title={''} className={styled.Modal}>
            <div className={styled.Content}>
                <div className={styled.Editor}>
                    <TextEditor title={title} initialValue={value} onChange={value => setFieldValue(value)} maxLength={maxLength} />
                </div>
                <div className={styled.Options}>
                    <Button variant="Outline" onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Primary" onClick={handleOnClickConfirm}>
                        {t('buttonSave')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EditDescriptionModal;
