import React, { FC, useState } from 'react';
import styled from './MonitorButton.module.css';
import accordionIcon from '../../assets/images/accordionIcon.svg';
import { useTranslation } from 'react-i18next';

interface Props {
    checked: boolean;
    disabled?: boolean;
    onChange: (() => void) | undefined;
    title?: string;
}

const MonitorButton: FC<Props> = ({ checked, disabled, onChange, title }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleSelect = () => {
        if (!disabled && onChange) {
            onChange();
            setOpen(false);
        }
    };

    const handleOpen = (open: boolean): void => {
        if (!disabled && onChange) {
            setOpen(open);
        }
    };

    return (
        <div className={[styled.MonitorButton, open ? styled.Open : ''].join(' ')} title={title}>
            <div className={[styled.Header, checked ? styled.Monitored : '', disabled ? styled.Disabled : ''].join(' ')} onClick={() => handleOpen(!open)}>
                <div>{checked ? t('fullviewMonitored') : t('fullviewUnmonitored')}</div>
                {onChange && <img src={accordionIcon} alt="Accordion" />}
            </div>
            <div className={styled.List} onClick={handleSelect}>
                {checked ? t('fullviewUnmonitor') : t('fullviewMonitor')}
            </div>
        </div>
    );
};

export default MonitorButton;
