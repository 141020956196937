import React, { FC, ReactNode } from 'react';

import styled from './AccordionTextItem.module.css';

interface Props {
    data: string;
    link?: ReactNode;
}

const AccordionTextItem: FC<Props> = ({ data, link }) => {
    return data ? (
        <>
            {link && link}
            <div className={styled.AccordionTextItem} dangerouslySetInnerHTML={{ __html: data }} />
        </>
    ) : (
        <div />
    );
};

export default AccordionTextItem;
