import React, { FC } from 'react';
import useAuth from '../../hooks/useAuth';
import Checkbox from '../Checkbox/Checkbox';
import styled from './KeywordEditor.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    values: number[];
    onChange: (value: number[]) => void;
}

const KeywordEditor: FC<Props> = ({ values, onChange }) => {
    const { t } = useTranslation();
    const { company } = useAuth();

    const handleKeywordChange = (keywordId: number): void => {
        if (values.includes(keywordId)) {
            onChange(values.filter(id => id !== keywordId));
        } else {
            onChange([...values, keywordId]);
        }
    };

    return (
        <div className={styled.KeywordEditor}>
            <div className={styled.Header}>
                <h5>{t('columnKeywords')}</h5>
            </div>
            <ul className={styled.List}>
                {company?.keyWords.map(keyword => (
                    <li key={keyword.id} onClick={() => handleKeywordChange(keyword.id)}>
                        <Checkbox checked={values.includes(keyword.id)} onChange={() => handleKeywordChange(keyword.id)} /> {keyword.text}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default KeywordEditor;
