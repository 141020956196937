import React, { createContext, useState, SetStateAction, Dispatch, FC } from 'react';

interface State {
    envName: string | undefined;
    commit: string | undefined;
    oldApplicationBaseURL: string | undefined;
    logoutURL: string;
    dbTimestamp: string | undefined;
}

interface Context {
    state: State;
    setState: Dispatch<SetStateAction<State>>;
}

const initialState: State = { envName: undefined, commit: undefined, oldApplicationBaseURL: undefined, logoutURL: '/login', dbTimestamp: undefined};

const MiscContext = createContext<Context>({
    state: initialState,
    setState: () => undefined,
});

const MiscContextProvider: FC = ({ children }) => {
    const [state, setState] = useState<State>({ ...initialState });
    return <MiscContext.Provider value={{ state, setState }}>{children}</MiscContext.Provider>;
};

export { MiscContext, MiscContextProvider };
