import React from 'react';
import styled from './Revision.module.css';
import { Route, useHistory } from 'react-router-dom';
import { REVISION_UNFINISHED, REVISION_FINISHED, REVISION_CREATE, REVISION_EDIT, REVISION_ALTER } from '../../constants/Routes';
import UnfinishedRevision from './UnfinishedRevision/UnfinishedRevision';
import FinishedRevision from './FinishedRevision/FinishedRevision';
import { UnfinishedRevisionContextProvider } from '../../contexts/UnfinishedRevisionContext';
import CreateRevision from './CreateRevision/CreateRevision';
import { FinishedRevisionContextProvider } from '../../contexts/FinishedRevisionContext';
import EditRevision from './EditRevision/EditRevision';
import { EditRevisionContextProvider } from '../../contexts/EditRevisionContext';
import useAuth from '../../hooks/useAuth';

const UnfinishedRevisionComponent = () => (
    <UnfinishedRevisionContextProvider>
        <Route path={REVISION_UNFINISHED} component={UnfinishedRevision} />
        <Route path={REVISION_CREATE} component={CreateRevision} />
        <Route path={REVISION_ALTER + '/:revisionId?'} component={CreateRevision} />
    </UnfinishedRevisionContextProvider>
);

const FinishedRevisionComponent = () => (
    <FinishedRevisionContextProvider>
        <FinishedRevision />
    </FinishedRevisionContextProvider>
);

const EditRevisionComponent = () => (
    <EditRevisionContextProvider>
        <EditRevision />
    </EditRevisionContextProvider>
);

const Revision: React.FC = () => {
    const { isAnonymousWithRevisions } = useAuth();
    const history = useHistory();

    if (!isAnonymousWithRevisions()) {
        history.goBack();
        return null;
    }

    return (
        <div className={styled.Revision}>
            <UnfinishedRevisionComponent />
            <Route path={REVISION_FINISHED} component={FinishedRevisionComponent} />
            <Route path={REVISION_EDIT + '/:revisionId?'} component={EditRevisionComponent} />
        </div>
    );
};

export default Revision;
