import React, { FC, useState } from 'react';
import styled from './EditAdminInfoModal.module.css';
import Modal from '../Modal';
import Button from '../../Button/Button';
import { Data } from '../../../containers/CompanyLogin/CompanyLogin';
import { useTranslation } from 'react-i18next';

interface Props {
    data: Data;
    onClose: () => void;
    onSave: (data: Data) => void;
}
const EditAdminInfoModal: FC<Props> = ({ onClose, onSave, data }) => {
    const [adminInfo, setAdminInfo] = useState<string>(data.adminInfo! || '');
    function removeTags(str: string) {
        return str
            .replace(/&amp;/g, '&')
            .replace(/;;;/g, '')
            .replace(/&nbsp;/g, ' ')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/<p>/g, '')
            .replace(/(<([^>]+)>)/gi, '');
    }
    const { t } = useTranslation();

    return (
        <Modal title={t('companyLoginModalAdminInfoTitle')} className={styled.Modal}>
            <textarea value={removeTags(adminInfo)} onChange={e => setAdminInfo(e.target.value)} className={styled.TextEditor} autoFocus />
            <div className={styled.Buttons}>
                <Button variant="Outline" onClick={onClose}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Primary" onClick={() => onSave({ ...data, adminInfo })}>
                    {t('buttonSave')}
                </Button>
            </div>
        </Modal>
    );
};

export default EditAdminInfoModal;
