import React, { FC } from 'react';
import styled from './AccordionInputItem.module.css';
import TextEditor from '../../TextEditor/TextEditor';

interface Props {
    value: string;
    onChange: (value: string) => void;
}

const AccordionInputItem: FC<Props> = ({ value, onChange }) => {
    return (
        <div className={styled.AccordionInputItem}>
            <TextEditor initialValue={value} onChange={onChange} />
        </div>
    );
};

export default AccordionInputItem;
