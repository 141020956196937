import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { REVISION_UNFINISHED, REVISION_FINISHED, REVISION_EDIT } from '../../../constants/Routes';
import { UnfinishedRevisionContextProvider } from '../../../contexts/UnfinishedRevisionContext';
import { FinishedRevisionContextProvider } from '../../../contexts/FinishedRevisionContext';
import useAuth from '../../../hooks/useAuth';
import MobileUnfinishedRevision from '../../Mobile/Revision/MobileUnfinishedRevision/MobileUnfinishedRevision';
import MobileFinishedRevision from '../../Mobile/Revision/MobileFinishedRevision/MobileFinishedRevision';
import EditMobileRevision from './EditMobileRevision/EditMobileRevision';
import { EditRevisionContextProvider } from '../../../contexts/EditRevisionContext';

const MobileUnfinishedRevisionComponent = () => (
    <UnfinishedRevisionContextProvider>
        <Route path={REVISION_UNFINISHED} component={MobileUnfinishedRevision} />
    </UnfinishedRevisionContextProvider>
);

const MobileFinishedRevisionComponent = () => (
    <FinishedRevisionContextProvider>
        <MobileFinishedRevision />
    </FinishedRevisionContextProvider>
);

const EditMobileRevisionComponent = () => (
    <EditRevisionContextProvider>
        <EditMobileRevision />
    </EditRevisionContextProvider>
);

const Revision: React.FC = () => {
    const { isAnonymousWithRevisions } = useAuth();
    const history = useHistory();

    if (!isAnonymousWithRevisions()) {
        history.goBack();
        return null;
    }

    return (
        <div>
            <MobileUnfinishedRevisionComponent />
            <Route path={REVISION_FINISHED} component={MobileFinishedRevisionComponent} />
            <Route path={REVISION_EDIT + '/:revisionId?'} component={EditMobileRevisionComponent} />
        </div>
    );
};

export default Revision;
