import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from './SignContract.module.css';
import useAuth from '../../hooks/useAuth';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import CompanyService from '../../services/CompanyService';
// import {useHistory} from 'react-router-dom';
import UserService from '../../services/UserService';
import LoadingSpinnerSmall from '../../components/LoadingSpinner/LoadingSpinnerSmall';
import Contract from '../../components/Contract/Contract';
import Dropdown from '../../components/Dropdown/Dropdown';
import _ from 'lodash';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import SignContractModel from '../../models/SignContractModel';

const SignContract: FC = () => {
    const { t } = useTranslation();
    const { company, logout, user, setCompany } = useAuth();
    // const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string>('');
    const [certifiers, setCertifiers] = useState<{ id: number; text: string }[]>([]);
    const [nominateCertifier, setNominateCertifier] = useState<number>(-1);
    const [nominateOther, setNominateOther] = useState<boolean>(false);

    const fetchData = () => {
        setLoading(true);
        UserService()
            .getCompanySuperUsers(company?.id || -1)
            .then(users => {
                users.forEach(u => {
                    if (u.userId != user?.userId) {
                        certifiers.push({
                            id: u.userId,
                            text: u.fullName,
                        });
                    }
                });

                certifiers.unshift({ id: -1, text: 'Ingen' });
                setCertifiers(certifiers);
            });

        setLoading(false);
    };

    const title = useMemo(() => _.find(certifiers, c => c.id === nominateCertifier)?.text, [certifiers, nominateCertifier]);

    const handleSignature = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        if (company && user && password) {
            setLoading(true);

            const signContractModel = new SignContractModel(company.id, user.userId, password, nominateCertifier != -1 ? nominateCertifier : null);

            CompanyService()
                .signContract(signContractModel)
                .then(res => {
                    setError(undefined);
                    setPassword('');
                    setNominateCertifier(-1);
                    setCompany(res.data);
                    setLoading(false);
                })
                .catch((error: AxiosError) => {
                    console.error(error);
                    setError('Kontraktet kunde tyvärr inte signeras p.g.a. ett fel.');
                    setLoading(false);
                });
        }
    };

    const handlePasswordChange = (value: string): void => {
        setPassword(value);
    };

    const handleNominateCertifierChange = (value: number): void => {
        setNominateCertifier(value);
        setNominateOther(value !== -1 || false);
    };

    useEffect(() => {
        if (!company?.contractRenewalHardLock) {
            fetchData();
        }
    }, []);

    return (
        <div className={styled.SignContractContainer}>
            <div className={styled.ContractPlacement}>
                <Contract />
            </div>

            <p className={styled.SignatureHeader}>{t('contractSignContract')}</p>
            <div className={styled.SignatureContainer}>
                <form onSubmit={handleSignature}>
                    <div className={styled.InputGroup}>
                        <Input className={styled.Input} type="password" label={t('inputFieldPassword')} error={error} value={password} onChange={handlePasswordChange} />
                    </div>

                    <div className={styled.ButtonGroup}>
                        <Button variant="Secondary" className={styled.DeclineButton} onClick={logout}>
                            {t('contractUserNotAccepting')}
                        </Button>
                        <Button variant="Primary" type="submit" className={styled.SignButton} disabled={!password}>
                            {!nominateOther ? t('contractUserAccepting') : t('contractSwitchCertifier')}
                        </Button>
                    </div>
                    {loading && <LoadingSpinnerSmall />}

                    {!company?.contractRenewalHardLock && (
                        <div className={styled.NominateCertifier}>
                            <p>{t('contractNominateOtherInfoText')}</p>
                            <Dropdown
                                className={styled.Dropdown}
                                title={title && nominateOther ? title : t('inputFieldSelectUser').toString()}
                                content={certifiers}
                                onChange={handleNominateCertifierChange}
                            />
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default SignContract;
