import React from 'react';
import styled from './MobileLogo.module.css';
import logo from '../../../assets/images/logo.svg';

const MobileLogo: React.FC = () => {
    return (
        <div className={styled.Container}>
            <img src={logo} alt="RAMBOLL" className={styled.Img} />
        </div>
    );
};

export default MobileLogo;
