import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import UserModel from '../models/UserModel';
import useStorage from './useStorage';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../constants/Routes';
import CompanyModel, { CompanyColumnUpdateModel } from '../models/CompanyModel';
import { Role, OrderedRoleList } from '../models/Role';
import _ from 'lodash';
import UserSettings from '../models/UserSettings';
import KeywordModel from '../models/KeywordModel';
import LawListModel, { UNGROUPED_LAWLIST_ID, UNGROUPED_LAWLIST_NAME } from '../models/LawListModel';
import LawListGroupModel from '../models/LawListGroupModel';
import SubscriptionChangeService from '../services/SubscriptionChangeService';
import ConsultantSubscriptionChangesService from '../services/ConsultantSubscriptionChangesService';
import RevisionService from '../services/RevisionService';
import { useTranslation } from 'react-i18next';
import EmailNotificationFilter from '../models/EmailNotificationFilter';

const useAuth = () => {
    const { state, setState } = useContext(AuthContext);
    const { getToken, clearStorage, setCompanyId, setSignedInAsUserId, isHidePopup } = useStorage();
    const { i18n } = useTranslation();
    const history = useHistory();

    const showWelcomePopup = (): boolean => {
        const firstLogin = state.user?.loginCounter == 1;
        const hidePopup = isHidePopup();
        return firstLogin && !hidePopup;
    };

    const setUser = (user: UserModel): UserModel => {
        i18n.changeLanguage(user.language);
        setState(s => ({
            ...s,
            user,
        }));
        return user;
    };

    const setCompany = (company: CompanyModel | undefined): void => {
        setState(s => ({
            ...s,
            company,
            numberOfChangesForCompany: !company ? undefined : s.numberOfChangesForCompany,
            numberOfConsultantChangesForCompany: !company ? undefined : s.numberOfConsultantChangesForCompany,
        }));
        setCompanyId(company?.id || undefined);
    };

    const setSignedInAsUser = (signedInAsUser: UserModel | undefined): void => {
        setState(s => ({
            ...s,
            signedInAsUser,
        }));
        setSignedInAsUserId(signedInAsUser?.userId || undefined);
    };

    const setNumberOfChangesForCompany = (numberOfChangesForCompany: number): void => {
        setState(s => ({
            ...s,
            numberOfChangesForCompany,
        }));
    };

    const setNumberOfConsultantChangesForCompany = (numberOfConsultantChangesForCompany: number): void => {
        setState(s => ({
            ...s,
            numberOfConsultantChangesForCompany,
        }));
    };

    const setNumberOfUnfinishedRevisionsForCompany = (numberOfUnfinishedRevisionsForCompany: number): void => {
        setState(s => ({
            ...s,
            numberOfUnfinishedRevisionsForCompany,
        }));
    };

    const fetchNumberOfChangesForCompany = async () => {
        if (state.company) {
            const numberOfChangesForCompany = await SubscriptionChangeService().getNumberOfChangesForCompany(state.company.id);
            setNumberOfChangesForCompany(numberOfChangesForCompany);
        }
    };

    const fetchNumberOfConsultantChangesForCompany = async () => {
        if (state.company) {
            const numberOfConsultantChangesForCompany = await ConsultantSubscriptionChangesService().getNumberOfConsultantChangesForCompany(state.company.id);
            setNumberOfConsultantChangesForCompany(numberOfConsultantChangesForCompany);
        }
    };

    const fetchNumberOfUnfinishedRevisionsForCompany = async () => {
        if (state.company) {
            const numberOfUnfinishedRevisionsForCompany = await RevisionService().getNumberOfUnfinishedRevisionsForCompany(state.company.id);
            setNumberOfUnfinishedRevisionsForCompany(numberOfUnfinishedRevisionsForCompany);
        }
    };

    const setUserSettings = (userSettings: UserSettings): void => {
        setState(s => {
            if (s.signedInAsUser) {
                return {
                    ...s,
                    signedInAsUser: {
                        ...s.signedInAsUser,
                        userSettings,
                    },
                };
            } else if (s.user) {
                return {
                    ...s,
                    user: {
                        ...s.user,
                        userSettings,
                    },
                };
            }
            return s;
        });
    };

    const updateRevisionWarning = (revisionWarningDays: number, disableRevisionWarning: boolean): void => {
        setState(s => {
            if (s.company) {
                return {
                    ...s,
                    company: {
                        ...s.company,
                        revisionWarningDays,
                        disableRevisionWarning,
                    },
                };
            }
            return s;
        });
    };

    const hasRoles = (roles: Role[]): boolean => {
        const { user } = state;
        if (user) {
            for (const role of roles) {
                if (_.includes(user.roles, role)) {
                    return true;
                }
            }
        }
        return false;
    };

    const userHasRoles = (user: UserModel, roles: Role[]): boolean => {
        for (const role of roles) {
            if (_.includes(user.roles, role)) {
                return true;
            }
        }
        return false;
    };

    const isExternalUser = (): boolean => {
        let isExternalUser = false;
        if (state.user && state.company) {
            isExternalUser = state.user.companyId !== state.company.id;
        }
        return isExternalUser;
    };

    const isAboveAdmin = (): boolean => {
        return hasRoles(['superadmin']);
    };

    const isAboveConsultant = (): boolean => {
        return hasRoles(['superadmin', 'admin']);
    };

    const isAboveSuperUser = (): boolean => {
        return hasRoles(['superadmin', 'admin', 'consultant']);
    };

    const isAboveUser = (): boolean => {
        return hasRoles(['superadmin', 'admin', 'consultant', 'superuser']);
    };

    const isUserAboveUser = (user: UserModel): boolean => {
        return userHasRoles(user, ['superadmin', 'admin', 'consultant', 'superuser']);
    };

    const isUserOrAbove = (): boolean => {
        return hasRoles(['superadmin', 'admin', 'consultant', 'superuser', 'assessor', 'delegate_assessor', 'editor', 'revisor', 'user']);
    };

    const isSuperAdmin = (): boolean => {
        return hasRoles(['superadmin']);
    };

    const isSuperuser = (): boolean => {
        return hasRoles(['superuser']);
    };

    const isSuperuserForCompany = (): boolean => {
        return hasRoles(['superuser']) && !isExternalUser();
    };

    const isAssessor = (): boolean => {
        return isAboveUser() || hasRoles(['assessor']);
    };

    const isDelegateAssessor = (): boolean => {
        return isAssessor() || hasRoles(['delegate_assessor']);
    };

    const isEditor = (): boolean => {
        return isAboveUser() || hasRoles(['editor']);
    };

    const isRevisor = (): boolean => {
        return isAboveUser() || hasRoles(['revisor']);
    };

    const isAnonymous = (): boolean => {
        return isAboveUser() || hasRoles(['anonymous']);
    };

    const isAboveAnonymous = (): boolean => {
        return hasRoles(['superadmin', 'admin', 'consultant', 'superuser', 'assessor', 'delegate_assessor', 'editor', 'revisor']);
    };

    const isAnonymousWithRevisions = (): boolean => {
        return isUserOrAbove() || hasRoles(['anonymous_see_revisions']);
    };

    const hasConnectedCompanies = (): boolean => {
        return state.user ? state.user.connectedCompanies.length > 0 : false;
    };

    const isPlusCompany = (): boolean => {
        return state.company !== undefined && state.company.type === 'PLUS';
    };

    const isGroupCompany = (): boolean => {
        return state.company !== undefined && state.company.type === 'GROUP';
    };
    const isNotAllowedToModifyLawList = (): boolean => {
        if (isAboveSuperUser() || (isSuperuser() && !isGroupCompany())) {
            return false;
        } else if (isGroupCompany() || !isSuperuser()) {
            return true;
        }
        return false;
    };

    const companyHasConsultant = (): boolean => {
        return isPlusCompany() || isGroupCompany();
    };

    const isStandardCompany = (): boolean => {
        return state.company !== undefined && state.company.type === 'STANDARD';
    };

    const isAdminSubs = (): boolean => {
        return state.company !== undefined && state.company.adminSubs;
    };

    const isGroupHasRevisionQuestions = (): boolean => {
        return state.company !== undefined && state.company.corporationHasRevisionQuestions;
    };

    const isGroupHasExtendedRevisionMedia = (): boolean => {
        return state.company !== undefined && state.company.corporationHasExtendedRevisionMedia;
    };

    const getHighestRole = (roles: Role[]): Role => {
        let highestRole: Role = 'anonymous';
        roles.forEach(role => {
            if (OrderedRoleList.indexOf(role) < OrderedRoleList.indexOf(highestRole)) {
                highestRole = role;
            }
        });
        return highestRole;
    };

    const logoutAndRedirect = (redirectURL: string) => {
        setState(s => ({
            ...s,
            user: undefined,
            company: undefined,
            signedInAsUser: undefined,
            numberOfChangesForCompany: undefined,
            numberOfConsultantChangesForCompany: undefined,
            numberOfUnfinishedRevisionsForCompany: undefined,
        }));
        clearStorage();

        history.push(redirectURL);
    };

    const logoutAndRedirectExternalPage = (redirectURL: string) => {
        clearStorage();
        window.location.href = redirectURL;
    };

    const logout = () => {
        logoutAndRedirect(LOGIN);
    };

    const getUserSettings = (): UserSettings | undefined => {
        if (state.signedInAsUser) {
            return state.signedInAsUser.userSettings;
        } else if (!state.company?.primaryCompany && !state.user?.roles.includes('anonymous')) {
            return undefined;
        } else if (state.user && !isAboveSuperUser()) {
            // If isAboveSuperUser the user is not a customer.
            return state.user.userSettings;
        } else {
            return undefined;
        }
    };

    const needsContractSignature = (): boolean => {
        if (state.user && state.company && state.company.type === 'STANDARD' && state.company.useContract && !isAboveSuperUser()) {
            if (state.company.contractRenewalHardLock) {
                //hardlock affects all users
                return true;
            } else if (isSuperuserForCompany() && (state.company.forceContractRenewal || !state.company.contractAcceptedBy)) {
                //forceContractRenewal affects all superusers
                //Unsigned contract affects all superusers

                if (state.company.nominatedCertifier != null && state.company.nominatedCertifier != state.user.userId) {
                    //forceContractRenewal affects all superusers unless nominated certifier is set to another superuser
                    return false;
                } else {
                    return true;
                }
            }
        }
        return false;
    };

    const hasUserSettings = (): boolean => {
        const userSettings = getUserSettings();
        if (!state.company?.primaryCompany) {
            return false;
        } else if (userSettings && userSettings.id) {
            return true;
        }
        return false;
    };

    const setCompanyColumns = (companyColumns: CompanyColumnUpdateModel): void => {
        setState(s => ({
            ...s,
            company: s.company
                ? {
                      ...s.company,
                      customHeaderName1: companyColumns.customHeaderName1,
                      customHeaderName2: companyColumns.customHeaderName2,
                      customHeaderName3: companyColumns.customHeaderName3,
                      customHeaderName4: companyColumns.customHeaderName4,
                      customHeaderName5: companyColumns.customHeaderName5,
                  }
                : s.company,
        }));
    };

    const removeLawList = (lawListId: number) => {
        setState(s => ({
            ...s,
            company: s.company
                ? {
                      ...s.company,
                      lawLists: s.company.lawLists ? s.company.lawLists.filter(ll => ll.lawListId !== lawListId) : s.company.lawLists,
                  }
                : s.company,
        }));
    };

    const removeLawListGroup = (lawListGroupId: number) => {
        setState(s => ({
            ...s,
            company: s.company
                ? {
                      ...s.company,
                      lawLists: s.company.lawLists
                          ? s.company.lawLists.map(ll => ({
                                ...ll,
                                lawListGroup:
                                    ll.lawListGroup && ll.lawListGroup.lawListGroupId === lawListGroupId
                                        ? {
                                              lawListGroupId: UNGROUPED_LAWLIST_ID,
                                              name: UNGROUPED_LAWLIST_NAME,
                                              description: '',
                                              responsible: '',
                                          }
                                        : ll.lawListGroup,
                            }))
                          : s.company.lawLists,
                  }
                : s.company,
        }));
    };

    const createLawList = (lawList: LawListModel) => {
        setState(s => ({
            ...s,
            company: s.company
                ? {
                      ...s.company,
                      lawLists: s.company.lawLists
                          ? [
                                ...s.company.lawLists,
                                lawList.lawListGroup
                                    ? lawList
                                    : {
                                          ...lawList,
                                          lawListGroup: {
                                              lawListGroupId: UNGROUPED_LAWLIST_ID,
                                              name: UNGROUPED_LAWLIST_NAME,
                                              description: '',
                                              responsible: '',
                                          },
                                      },
                            ]
                          : s.company.lawLists,
                  }
                : s.company,
        }));
    };

    const updateLawList = (lawList: LawListModel) => {
        setState(s => ({
            ...s,
            company: s.company
                ? {
                      ...s.company,
                      lawLists: s.company.lawLists ? s.company.lawLists.map(ll => (ll.lawListId === lawList.lawListId ? { ...ll, ...lawList } : ll)) : s.company.lawLists,
                  }
                : s.company,
        }));
    };

    const updateLawListGroup = (lawListGroup: LawListGroupModel) => {
        setState(s => ({
            ...s,
            company: s.company
                ? {
                      ...s.company,
                      lawLists: s.company.lawLists
                          ? s.company.lawLists.map(ll => ({
                                ...ll,
                                lawListGroup:
                                    ll.lawListGroup && ll.lawListGroup.lawListGroupId === lawListGroup.lawListGroupId ? { ...ll.lawListGroup, ...lawListGroup } : ll.lawListGroup,
                            }))
                          : s.company.lawLists,
                  }
                : s.company,
        }));
    };

    const addKeyword = (keyword: KeywordModel) => {
        setState(s => ({
            ...s,
            company: s.company
                ? {
                      ...s.company,
                      keyWords: _.sortBy([...s.company.keyWords, keyword], kw => kw.text.toLowerCase()),
                  }
                : s.company,
        }));
    };

    const updateKeyword = (keyword: KeywordModel) => {
        setState(s => ({
            ...s,
            company: s.company
                ? {
                      ...s.company,
                      keyWords: _.sortBy(
                          s.company.keyWords.map(kw =>
                              kw.id === keyword.id
                                  ? {
                                        ...kw,
                                        text: keyword.text,
                                        description: keyword.description,
                                    }
                                  : kw,
                          ),
                          kw => kw.text.toLowerCase(),
                      ),
                  }
                : s.company,
        }));
    };

    const removeKeyword = (keywordId: number) => {
        setState(s => ({
            ...s,
            company: s.company
                ? {
                      ...s.company,
                      keyWords: s.company.keyWords.filter(kw => kw.id !== keywordId),
                  }
                : s.company,
        }));
    };

    const updateEmailNoticeSettings = (emailNoticeSettings: EmailNotificationFilter): void => {
        setState(s => ({
            ...s,
            user: s.user
                ? {
                      ...s.user,
                      emailNoticeSettings,
                  }
                : s.user,
        }));
    };

    const updateEmailNotificationActive = (active: boolean): void => {
        setState(s => ({
            ...s,
            user: s.user
                ? {
                      ...s.user,
                      emailNotification: active,
                  }
                : s.user,
        }));
    };

    return {
        ...state,
        isAuthenticated: getToken() && state.user && state.company,
        isPrimaryCompany: state.company?.primaryCompany || false,
        hasUserSettings,
        getUserSettings,
        updateRevisionWarning,
        hasConnectedCompanies,
        setUser,
        setCompany,
        setUserSettings,
        isAboveAdmin,
        isAboveConsultant,
        isAboveSuperUser,
        isAboveUser,
        isUserAboveUser,
        isSuperuser,
        isSuperuserForCompany,
        isAssessor,
        isDelegateAssessor,
        isEditor,
        isRevisor,
        isAnonymous,
        isAnonymousWithRevisions,
        isUserOrAbove,
        isAboveAnonymous,
        getHighestRole,
        setSignedInAsUser,
        setCompanyColumns,
        logout,
        logoutAndRedirect,
        logoutAndRedirectExternalPage,
        removeLawList,
        addKeyword,
        updateKeyword,
        removeKeyword,
        removeLawListGroup,
        updateLawListGroup,
        createLawList,
        updateLawList,
        updateEmailNoticeSettings,
        isPlusCompany,
        isGroupCompany,
        companyHasConsultant,
        isStandardCompany,
        isAdminSubs,
        isGroupHasRevisionQuestions,
        isGroupHasExtendedRevisionMedia,
        fetchNumberOfChangesForCompany,
        fetchNumberOfConsultantChangesForCompany,
        fetchNumberOfUnfinishedRevisionsForCompany,
        setNumberOfChangesForCompany,
        setNumberOfConsultantChangesForCompany,
        updateEmailNotificationActive,
        needsContractSignature,
        isExternalUser,
        showWelcomePopup,
        isSuperAdmin,
        isNotAllowedToModifyLawList,
    };
};

export default useAuth;
