import React, { useState, useEffect } from 'react';
import styled from './Navbar.module.css';
import Logo from '../Logo/Logo';
import {
    HOME,
    CHANGES,
    REVISION,
    LAWS_LISTS,
    LAWS,
    COMPANY_LOGIN,
    CHANGES_LIST,
    REVISION_UNFINISHED,
    SETTINGS,
    CONSULTANT,
    HELP,
    HELP_INSTRUCTIONS,
    SETTINGS_COMPANY_USERS,
    INVOICING,
    MERGE_LAWS,
} from '../../constants/Routes';
import { NavLink, Route, useLocation, useHistory, Link } from 'react-router-dom';
import UserMenu from './UserMenu/UserMenu';
import useAuth from '../../hooks/useAuth';
import backArrowIcon from '../../assets/images/backArrowIcon.svg';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import helpIcon from '../../assets/images/helpIcon.png';
import settingsIcon from '../../assets/images/settingsIcon.svg';
import WelcomePopup from '../WelcomePopup/WelcomePopup';

interface Props {
    envName: string | undefined;
    commit: string | undefined;
    dbTimestamp: string | undefined;
}

const Navbar: React.FC<Props> = ({ envName, commit, dbTimestamp }) => {
    const {
        showWelcomePopup,
        isAuthenticated,
        user,
        isAnonymousWithRevisions,
        isAboveSuperUser,
        companyHasConsultant,
        numberOfChangesForCompany,
        numberOfConsultantChangesForCompany,
        numberOfUnfinishedRevisionsForCompany,
        needsContractSignature,
        isAboveUser,
        isAboveConsultant,
    } = useAuth();

    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    // Used on settings and help page
    const [prevPath, setPrevPath] = useState<{ from?: string; current?: typeof SETTINGS | typeof HELP }>({ from: undefined, current: undefined });

    useEffect(() => {
        if (location.pathname.indexOf(SETTINGS) > -1) {
            const from = _.get(location.state, 'from');
            if (from !== undefined && !from.includes(SETTINGS)) {
                setPrevPath({ from, current: SETTINGS });
            }
        } else if (location.pathname.indexOf(HELP) > -1) {
            const from = _.get(location.state, 'from');
            if (from !== undefined && !from.includes(HELP)) {
                setPrevPath({ from, current: HELP });
            }
        }
    }, [location.pathname]);

    return (
        <nav className={styled.Navbar}>
            {location.pathname.indexOf(SETTINGS) !== -1 || location.pathname.indexOf(HELP) !== -1 ? (
                <div className={styled.Settings}>
                    <img
                        src={backArrowIcon}
                        alt="Back"
                        onClick={() => {
                            if (location.pathname.indexOf(SETTINGS) > -1 && prevPath.from && prevPath.current === SETTINGS) {
                                history.push(prevPath.from);
                            } else if (location.pathname.indexOf(HELP) > -1 && prevPath.from && prevPath.current === HELP) {
                                history.push(prevPath.from);
                            } else {
                                history.push(HOME);
                            }
                            setPrevPath({ from: undefined, current: undefined });
                        }}
                    />
                    <h3>{location.pathname.indexOf(SETTINGS) !== -1 ? t('navbarSettings') : t('navbarHelp')}</h3>
                </div>
            ) : (
                <>
                    <Logo />
                    {isAuthenticated && user && !needsContractSignature() && (
                        <ul className={styled.NavbarList}>
                            <li>
                                <NavLink title="Start" to={HOME} className={styled.NavbarLink} activeClassName={styled.Active} exact>
                                    Start
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    title={t('navbarLaws')}
                                    to={LAWS_LISTS}
                                    className={styled.NavbarLink}
                                    isActive={(_, location) => location.pathname.indexOf(LAWS) !== -1}
                                    activeClassName={styled.Active}
                                >
                                    {t('navbarLaws')}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    title={t('navbarChanges')}
                                    to={CHANGES_LIST}
                                    className={styled.NavbarLink}
                                    isActive={(_, location) => location.pathname.indexOf(CHANGES) !== -1}
                                    activeClassName={styled.Active}
                                >
                                    {t('navbarChanges')} {numberOfChangesForCompany !== undefined && <p className={styled.Badge}>{numberOfChangesForCompany}</p>}
                                </NavLink>
                            </li>
                            {isAnonymousWithRevisions() && (
                                <li>
                                    <NavLink
                                        title={t('navbarRevision')}
                                        to={REVISION_UNFINISHED}
                                        className={styled.NavbarLink}
                                        isActive={(_, location) => location.pathname.indexOf(REVISION) !== -1}
                                        activeClassName={styled.Active}
                                    >
                                        {t('navbarRevision')}{' '}
                                        {numberOfUnfinishedRevisionsForCompany !== undefined && <p className={styled.Badge}>{numberOfUnfinishedRevisionsForCompany}</p>}
                                    </NavLink>
                                </li>
                            )}
                            {isAboveSuperUser() && companyHasConsultant() && (
                                <li>
                                    <NavLink title={t('navbarConsultant')} to={CONSULTANT} className={styled.NavbarLink} activeClassName={styled.Active} exact>
                                        {t('navbarConsultant')}{' '}
                                        {numberOfConsultantChangesForCompany !== undefined && <p className={styled.Badge}>{numberOfConsultantChangesForCompany}</p>}
                                    </NavLink>
                                </li>
                            )}
                            {isAboveConsultant() && (
                                <li>
                                    <NavLink title={t('navbarInvoicing')} to={INVOICING} className={styled.NavbarLink} activeClassName={styled.Active} exact>
                                        {t('navbarInvoicing')}{' '}
                                    </NavLink>
                                </li>
                            )}
                            {isAboveConsultant() && (
                                <li>
                                    <NavLink
                                        title={'Slåihoplag'}
                                        to={MERGE_LAWS}
                                        className={[styled.NavbarLink, styled.NavbarLinkMerge].join(' ')}
                                        activeClassName={styled.Active}
                                        exact
                                    >
                                        {'Slåihoplag'}{' '}
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    )}
                    <Route path={COMPANY_LOGIN}>
                        <div className={styled.CompanyLogin}>
                            <h6>{t('navbarCompanyLoginText')}</h6>
                        </div>
                    </Route>
                </>
            )}
            {envName && (
                <div className={[styled.EnvName, envName === 'prod' ? styled.EnvNameProd : ''].join(' ')}>
                    {envName} {commit && commit.length ? `(${commit})` : ''}
                    {dbTimestamp && dbTimestamp.length > 0 ? `(DB ${dbTimestamp})` : ''}
                </div>
            )}
            {user && !needsContractSignature() && (
                <div className={styled.SubMenu}>
                    {location.pathname.indexOf(HELP) === -1 && location.pathname.indexOf(SETTINGS) === -1 && (
                        <>
                            {isAuthenticated && showWelcomePopup() && <WelcomePopup />}
                            {isAuthenticated && (
                                <Link to={{ pathname: HELP_INSTRUCTIONS, state: { from: location.pathname } }}>
                                    <img src={helpIcon} alt="help" />
                                </Link>
                            )}

                            {isAuthenticated && isAboveUser() && (
                                <Link to={{ pathname: SETTINGS_COMPANY_USERS, state: { from: location.pathname } }}>
                                    <img src={settingsIcon} alt="settings" />
                                </Link>
                            )}
                        </>
                    )}

                    <UserMenu initials={user.firstName.substr(0, 1) + user.lastName.substr(0, 1)} fullName={user.fullName} />
                </div>
            )}
        </nav>
    );
};

export default Navbar;
