import React, { FC, useMemo, ReactNode, useState } from 'react';
import FullView from '../FullView';
import SubscriptionModel, { isNewUnaccepted, isSubscription } from '../../../models/SubscriptionModel';
import Accordion from '../../Accordion/Accordion';
import _ from 'lodash';
import AccordionTextItem from '../../Accordion/AccordionTextItem/AccordionTextItem';
import useAuth from '../../../hooks/useAuth';
import AccordionListItem from '../../Accordion/AccordionListItem/AccordionListItem';
import styled from './LawListFullView.module.css';
import CustomLawModel, { isCustomLaw } from '../../../models/CustomLawModel';
import FullViewHeader from './FullViewHeader/FullViewHeader';
import DetachedCopyFullViewHeader from '../../DetachedCopyFullViewHeader/DetachedCopyFullViewHeader';
import AccordionRevisionQuestionItem from '../../Accordion/AccordionRevisionQuestionItem/AccordionRevisionQuestionItem';
import { useTranslation } from 'react-i18next';
import ChangeTextLink from '../../ChangeTextLink/ChangeTextLink';
import { UNGROUPED_LAWLIST_NAME } from '../../../models/LawListModel';

interface Props {
    data?: SubscriptionModel | CustomLawModel;
    open: boolean;
    onClose: () => void;
    onEdit: (key: string, title: string) => void;
    onEditRevisionQuestions: () => void;
    onMonitorChange: () => void;
}

const LawListFullView: FC<Props> = ({ data, open, onClose, onEdit, onEditRevisionQuestions, onMonitorChange }) => {
    const { t, i18n } = useTranslation();
    const { company, isAdminSubs, isAboveSuperUser, isGroupCompany, isGroupHasRevisionQuestions, companyHasConsultant, isRevisor } = useAuth();
    const isUnacceptedNewLaw = data && isSubscription(data) && isNewUnaccepted(data);
    const isDetachedCopy = data && isSubscription(data) && data.detachedCopy;

    const [openAccordions, setOpenAccordions] = useState<number[]>([1]);

    const accordionItems = useMemo(() => {
        const items: { title: string; value: ReactNode; edit?: { onEdit?: () => void; disabled?: boolean; title?: string } }[] = [];

        if (data && !isCustomLaw(data)) {
            items.push({
                title: t('columnDescription'),
                value: <AccordionTextItem data={data?.description || ''} />,
            });
        }

        items.push({
            title: t('columnText'),
            value: <AccordionTextItem data={data?.text || ''} />,
            edit: {
                onEdit: !isUnacceptedNewLaw ? () => onEdit('text', t('columnText')) : undefined,
                disabled: (isGroupCompany() && !isAdminSubs() && !isAboveSuperUser()) || isUnacceptedNewLaw,
                title: isUnacceptedNewLaw ? 'Författningen reodivsas som ny under Ändringar och hanteras där' : undefined,
            },
        });

        if (data && isAboveSuperUser() && isSubscription(data)) {
            const link = data ? <ChangeTextLink lawId={data.lawId} lawSources={data.lawSources} /> : null;
            items.push({
                title: t('columnChange'),
                value: <AccordionTextItem data={data?.changeText || ''} link={link} />,
                // Deactivated edit function on changeText in the Law list. Saves the code of the requirements change.
                // edit: {
                //     onEdit: !isUnacceptedNewLaw ? () => onEdit('originalChangeText', t('columnChange')) : undefined,
                //     disabled: isUnacceptedNewLaw,
                //     title: isUnacceptedNewLaw ? 'Författningen reodivsas som ny under Ändringar och hanteras där' : undefined,
                // },
            });
        }

        if (
            (data && company && company.hasRevisionQuestions && !isGroupCompany()) ||
            (data && company && company.hasRevisionQuestions && isGroupCompany() && isGroupHasRevisionQuestions())
        ) {
            if (isSubscription(data)) {
                const revisionQuestionGroups: { title: string; revisionQuestions: string[] }[] = [];

                data.revisionQuestionGroups
                    .sort((a, b) => (a.titleOrder > b.titleOrder ? 1 : a.titleOrder < b.titleOrder ? -1 : 0))
                    .map(rqg => ({
                        ...rqg,
                        revisionQuestions: rqg.revisionQuestions
                            .filter(rq => !rq.excluded)
                            .sort((a, b) =>
                                a.isCustomQuestion && b.isCustomQuestion
                                    ? a.id > b.id
                                        ? 1
                                        : a.id < b.id
                                        ? -1
                                        : 0
                                    : a.questionOrder! > b.questionOrder!
                                    ? 1
                                    : a.questionOrder! < b.questionOrder!
                                    ? -1
                                    : 0,
                            ),
                    }))
                    .forEach(rqg => {
                        if (rqg.revisionQuestions.length > 0) {
                            const groupIndex = revisionQuestionGroups.findIndex(({ title }) => title === rqg.title);
                            if (groupIndex > -1) {
                                revisionQuestionGroups[groupIndex] = {
                                    ...revisionQuestionGroups[groupIndex],
                                    revisionQuestions: [...revisionQuestionGroups[groupIndex].revisionQuestions, ...rqg.revisionQuestions.map(rq => rq.question)],
                                };
                            } else {
                                revisionQuestionGroups.push({ title: rqg.title, revisionQuestions: rqg.revisionQuestions.map(rq => rq.question) });
                            }
                        }
                    });

                const customRevisionQuestions = data.customRevisionQuestions.filter(crq => !crq.excluded);
                if (customRevisionQuestions.length > 0) {
                    const index = revisionQuestionGroups.push({ title: 'Egna frågor', revisionQuestions: [] });
                    customRevisionQuestions.forEach(crq => {
                        revisionQuestionGroups[index - 1].revisionQuestions.push(crq.question);
                    });
                }

                items.push({
                    title: t('columnRevisionQuestions'),
                    value: <AccordionRevisionQuestionItem data={revisionQuestionGroups} />,
                    edit: {
                        onEdit: !isUnacceptedNewLaw ? onEditRevisionQuestions : undefined,
                        disabled: isUnacceptedNewLaw || !isRevisor() || (isGroupCompany() && !isAdminSubs() && !isAboveSuperUser()),
                        title: isUnacceptedNewLaw ? 'Författningen redovisas som ny under Ändringar och hanteras där' : undefined,
                    },
                });
            } else {
                items.push({
                    title: t('columnRevisionQuestions'),
                    value: (
                        <AccordionRevisionQuestionItem
                            data={[{ title: undefined, revisionQuestions: data.customRevisionQuestions.filter(crq => !crq.excluded).map(crq => crq.question) }]}
                        />
                    ),
                    edit: {
                        onEdit: !isUnacceptedNewLaw ? onEditRevisionQuestions : undefined,
                        disabled: isUnacceptedNewLaw || !isRevisor(),
                        title: isUnacceptedNewLaw ? 'Författningen redovisas som ny under Ändringar och hanteras där' : undefined,
                    },
                });
            }
        }

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                items.push({
                    title,
                    value: <AccordionTextItem data={_.get(data, `customerText${i}`)} />,
                    edit: {
                        onEdit: !isUnacceptedNewLaw ? () => onEdit(`customerText${i}`, title) : undefined,
                        disabled: isUnacceptedNewLaw,
                        title: isUnacceptedNewLaw ? 'Författningen reodivsas som ny under Ändringar och hanteras där' : undefined,
                    },
                });
            }
        }

        if (company?.hasLawLists) {
            items.push({
                title: t('columnLawLists'),
                value: (
                    <AccordionListItem
                        data={
                            data?.lawLists?.map(lawList => {
                                if (lawList.lawListGroup && lawList.lawListGroup.name !== UNGROUPED_LAWLIST_NAME) {
                                    return lawList.lawListGroup.name + ': ' + lawList.name;
                                }
                                return lawList.name;
                            }) || []
                        }
                    />
                ),
                edit: {
                    onEdit: !isUnacceptedNewLaw ? () => onEdit('lawLists', t('columnLawLists')) : undefined,
                    disabled: isUnacceptedNewLaw,
                    title: isUnacceptedNewLaw ? 'Författningen reodivsas som ny under Ändringar och hanteras där' : undefined,
                },
            });
        }

        if (company?.hasKeyWords) {
            items.push({
                title: t('columnKeywords'),
                value: <AccordionListItem data={data?.keywordIds?.map(kwId => _.find(company.keyWords, kw => kw.id === kwId)?.text || '') || []} />,
                edit: {
                    onEdit: !isUnacceptedNewLaw ? () => onEdit('keywordIds', t('columnKeywords')) : undefined,
                    disabled: isUnacceptedNewLaw,
                    title: isUnacceptedNewLaw ? 'Författningen reodivsas som ny under Ändringar och hanteras där' : undefined,
                },
            });
        }

        return items;
    }, [data, i18n.language]);

    const handleToggleAccordion = (index: number) => {
        setOpenAccordions(s => (s.includes(index) ? s.filter(i => i !== index) : [...s, index]));
    };

    const renderHeader = () => {
        if (companyHasConsultant() && data && isSubscription(data) && !data.sentFromConsultant) {
            return null;
        } else if (data && isSubscription(data) && data.acceptanceStatus != 'ACCEPTED') {
            return <FullViewHeader isNew={data.newLaw === true} subscriptionId={data.subscriptionId} />;
        }
        return null;
    };

    return (
        <FullView
            hiddenIdBar={data && (isSubscription(data) ? 'Sid:' + data?.subscriptionId + ', Lid:' + data?.lawId : 'CLid: ' + data?.customLawId)}
            lawSourceId={data && (isSubscription(data) ? data.lawId : data.customLawId)}
            title={data?.name || ''}
            subId={(data && (isSubscription(data) ? data.subId : data.customLawEndDate)) || ''}
            isCustomLaw={data !== undefined && !isSubscription(data)}
            isExpired={data && !isSubscription(data) && data.expired}
            lawSource={data?.lawSources.length ? data.lawSources[0] : undefined}
            open={open}
            onClose={onClose}
            monitorButton={{
                isMonitored: true,
                onMonitorChange,
                disabled: isUnacceptedNewLaw || isDetachedCopy,
                title: isUnacceptedNewLaw ? 'Författningen reodivsas som ny under Ändringar och hanteras där' : undefined,
            }}
            headerChildren={
                <>
                    {renderHeader()}
                    {isDetachedCopy && <DetachedCopyFullViewHeader withLink />}
                </>
            }
        >
            <div className={styled.Content}>
                <Accordion
                    id={data ? (isSubscription(data) ? data.subscriptionId : data.customLawId) : -1}
                    data={accordionItems}
                    initiallyOpenIndicies={openAccordions}
                    onToggle={handleToggleAccordion}
                />
            </div>
        </FullView>
    );
};

export default LawListFullView;
