import React, { FC } from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import useAuth from '../../hooks/useAuth';
import styled from './SaveSubscriptionsModal.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    onConfirm: () => void;
    onDecline: () => void;
    increasedSubscriptionsBy: number;
}

const SaveSubscriptionsModal: FC<Props> = ({ onConfirm, onDecline, increasedSubscriptionsBy }) => {
    const { t } = useTranslation();
    const { company } = useAuth();

    return (
        <Modal
            title={t('lawLibrarySaveModalTitle')}
            subtitle={t('lawLibrarySaveModalSubTitle', { count: increasedSubscriptionsBy, increasedSubscriptionsBy })}
            text={company?.type === 'STANDARD' ? t('lawLibrarySaveModalText') : t('lawLibrarySaveModalTextPlus')}
        >
            <div className={styled.Options}>
                <Button variant="Primary" onClick={onDecline}>
                    {t('buttonNo')}
                </Button>
                <Button variant="Outline" onClick={onConfirm}>
                    {t('buttonYes')}
                </Button>
            </div>
        </Modal>
    );
};

export default SaveSubscriptionsModal;
