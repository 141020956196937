import React, { ReactNode } from 'react';
import { CellProps, Cell } from 'react-table';
import _ from 'lodash';
import styled from './RevisionQuestionsCell.module.css';
import RevisionQuestionGroup from '../../../models/RevisionQuestionGroup';
import RevisionQuestion from '../../../models/RevisionQuestion';

const LIST_MAX_LENGTH = 4;

const RevisionQuestionsCell = (props: CellProps<{ revisionQuestions: RevisionQuestionGroup; customRevisionQuestions: RevisionQuestion[]; titleIndexes: number[] }>) => {
    const { revisionQuestions, customRevisionQuestions, titleIndexes } = props.cell.value;

    let list = [...revisionQuestions, ...customRevisionQuestions];

    const revisionQuestionsTitle = list.join('\n');

    if (list.length > LIST_MAX_LENGTH + 1) {
        list = [...list.slice(0, LIST_MAX_LENGTH), '...'];
    }

    return (
        <div className={styled.RevisionQuestionsCell} title={revisionQuestionsTitle}>
            {list.map((rq: string, i: number) => (
                <div key={rq + '-' + i} className={[styled.RevisionQuestion, i < LIST_MAX_LENGTH && titleIndexes.includes(i) ? styled.Title : ''].join(' ')}>
                    {rq}
                </div>
            ))}
        </div>
    );
};

export default RevisionQuestionsCell;
