import React, { FC, useState, useEffect } from 'react';
import styled from './DelegateEditor.module.css';
import useChangeList from '../../hooks/useChangeList';
import Checkbox from '../Checkbox/Checkbox';
import TextEditor from '../TextEditor/TextEditor';
import { ReactComponent as CloseIcon } from '../../assets/images/closeIcon.svg';
import Toggle from '../Toggle/Toggle';
import Button from '../Button/Button';
import _ from 'lodash';
import SubscriptionModel from '../../models/SubscriptionModel';
import { acceptanceLogCommentMaxLength } from '../../constants/Validation';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface Props {
    data: SubscriptionModel;
    onClose: () => void;
    onSubmit: (subscription: SubscriptionModel) => void;
}

const DelegateEditor: FC<Props> = ({ data, onSubmit, onClose }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const { assessmentUsers, handleChangeDelegates } = useChangeList();

    const [selectedUsers, setSelectedUsers] = useState(
        assessmentUsers.map(u => ({
            userId: u.userId,
            fullName: u.fullName,
            userName: u.userName,
            checked: data.waitingForAcceptance.find(wfa => wfa.userId === u.userId) !== undefined,
        })),
    );
    const [delegatesChanged, setDelegatesChanged] = useState(false);

    const [comment, setComment] = useState<{ value: string; error?: boolean }>({ value: '', error: false });
    const [commentChanged, setCommentChanged] = useState(false);
    const [notifyEmail, setNotifyEmail] = useState(true);

    useEffect(() => {
        const selectedUserIds = selectedUsers.filter(u => u.checked).map(u => u.userId);
        const waitingForAcceptanceIds = data.waitingForAcceptance.map(u => u.userId);
        setDelegatesChanged(!_.isEqual(_.sortBy(selectedUserIds), _.sortBy(waitingForAcceptanceIds)));
    }, [selectedUsers]);

    const handleAssessmentUserChange = (userId: number) => {
        setSelectedUsers(s =>
            s.map(user => {
                if (user.userId === userId) {
                    return {
                        ...user,
                        checked: !user.checked,
                    };
                }
                return user;
            }),
        );
    };

    const handleSubmit = () => {
        setLoading(true);
        setTimeout(() => {
            setLoadingSpinner(true);
        }, 1000);

        const selectedUserIds = selectedUsers.filter(u => u.checked).map(u => u.userId);
        handleChangeDelegates(data.subscriptionId, data.subscriptionVersion, commentChanged ? comment.value : '', selectedUserIds, notifyEmail).then(subscription => {
            if (subscription) {
                onSubmit(subscription);
            }
        });
    };

    return (
        <div className={styled.DelegateEditor}>
            {loadingSpinner && <LoadingSpinner message={t('spinnerChangeDelegatesLoading')} />}
            <div className={styled.CloseBar}>
                <CloseIcon onClick={onClose} />
            </div>
            <div className={styled.AssessmentSection}>
                <div className={styled.AssessorEditor}>
                    <div className={styled.Header}>
                        <h5>{t('changeDelegatesTitle')}</h5>
                    </div>
                    <ul className={styled.List}>
                        {selectedUsers.map(user => (
                            <li key={user.userId} onClick={() => handleAssessmentUserChange(user.userId)}>
                                <Checkbox checked={user.checked} onChange={() => handleAssessmentUserChange(user.userId)} />{' '}
                                <span className={styled.FullName}>{user.fullName}</span>
                                <span className={styled.Email}>{user.userName}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className={styled.CommentSection}>
                <div className={styled.TextEditor}>
                    <TextEditor
                        title={t('changeDelegatesCommentTitle')}
                        initialValue={comment.value}
                        onChange={value => setComment(s => ({ ...s, value }))}
                        hasText={hasText => setCommentChanged(hasText)}
                        maxLength={acceptanceLogCommentMaxLength}
                        onMaxLengthError={error => setComment(s => ({ ...s, error }))}
                    />
                </div>
            </div>
            <div className={styled.Footer}>
                <div className={styled.SelectUsers}>
                    <Toggle checked={notifyEmail} onChange={() => setNotifyEmail(!notifyEmail)} title={t('buttonNotifyByEmail')} />
                </div>
                <Button variant="Outline" disabled={loading} onClick={onClose}>
                    {t('buttonCancel')}
                </Button>
                <Button
                    variant="Primary"
                    onClick={handleSubmit}
                    disabled={(!selectedUsers.filter(u => u.checked).length && !commentChanged) || !delegatesChanged || comment.error || loading}
                >
                    {t('buttonSave')}
                </Button>
            </div>
        </div>
    );
};

export default DelegateEditor;
