import React, { FC, useMemo, useState } from 'react';
import styled from './AccordionCompanyDocumentItem.module.css';
import { useTranslation } from 'react-i18next';
import CompanyDocumentModel from '../../../models/CompanyDocumentModel';
import removeIcon from '../../../../assets/images/removeIcon.svg';
import WarningModal from '../../modals/WarningModal/WarningModal';
import { getDocumentTypesTranslationKeyWithString } from '../../../models/CompanyDocumentType';
import linkIcon from '../../../../assets/images/linkIcon.svg';

interface Props {
    data: CompanyDocumentModel[];
    onRemove: (documentId: number) => void;
    onDownload: (companyDocumentModel: CompanyDocumentModel) => void;
}

const AccordionCompanyDocumentItem: FC<Props> = ({ data, onRemove, onDownload }) => {
    const { t, i18n } = useTranslation();
    const [showWarningModal, setShowWarningModal] = useState<{ documentId: number; show: boolean }>({ documentId: -1, show: false });

    return (
        <>
            {data && data.length
                ? data.map(doc => (
                      <div key={doc?.id} className={styled.Container}>
                          <table>
                              <tr className={styled.Row}>
                                  <td className={styled.FullName}>{t(getDocumentTypesTranslationKeyWithString(doc?.type))}</td>
                              </tr>

                              <tr className={styled.Row}>
                                  <td>
                                      <div>
                                          <div className={[styled.FileNameLink].join(' ')} title={doc.fileName} onClick={() => onDownload(doc)}>
                                              {doc.fileName}
                                              <img src={linkIcon} className={styled.LinkIcon} alt="Link" />
                                          </div>
                                      </div>
                                  </td>

                                  <td className={styled.Roles} title={'Radera dokument'}>
                                      <img
                                          src={removeIcon}
                                          className={styled.RemoveIcon}
                                          alt="Remove"
                                          onClick={() => (doc.id ? setShowWarningModal({ documentId: doc.id, show: true }) : undefined)}
                                      />
                                  </td>
                              </tr>
                              {showWarningModal.show && doc && showWarningModal.documentId > -1 && (
                                  <WarningModal
                                      onClose={() => setShowWarningModal({ documentId: -1, show: false })}
                                      onAccept={() => {
                                          onRemove(showWarningModal.documentId);
                                          setShowWarningModal({ documentId: -1, show: false });
                                      }}
                                      title={'Radera dokument'}
                                      subtitle={'Är du säker på att du vill radera dokumentet?'}
                                  />
                              )}
                          </table>
                      </div>
                  ))
                : 'Inga dokument'}
        </>
    );
};

export default AccordionCompanyDocumentItem;
