import React, { FC } from 'react';
import styled from './DenseCell.module.css';
import { CellProps } from 'react-table';
import _ from 'lodash';

const DenseCell: FC<CellProps<any>> = ({ cell: { value }, column: { id }, row, noBold, onClick, placeholder, className, multiRow }) => {
    const isNameColumn = id === 'name';
    const isPrimaryCompany = _.get(row.original, 'primaryCompany');
    return (
        <div
            onClick={onClick ? onClick : undefined}
            {...(row.canExpand ? row.getToggleRowExpandedProps() : {})}
            className={[
                styled.DenseCell,
                isNameColumn && !noBold ? styled.NameColumn : '',
                isPrimaryCompany || onClick ? styled.PrimaryCompany : '',
                className ? className : '',
                multiRow ? styled.MultiRow : '',
            ].join(' ')}
            title={placeholder ? placeholder : value}
        >
            <span>{value}</span>
        </div>
    );
};

export default DenseCell;
