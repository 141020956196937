import React, { useMemo, FC, useState } from 'react';
import { Column, SortingRule } from 'react-table';
import RevisionModel from '../../../models/RevisionModel';
import RevisionCell from '../RevisionCell/RevisionCell';
import Button from '../../Button/Button';
import Table from '../Table';
import styled from './UnfinishedRevisionTable.module.css';
import MenuCell, { isLastRow } from '../MenuCell/MenuCell';
import ProgressBar from '../../ProgressBar/ProgressBar';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

interface Props {
    data: RevisionModel[];
    globalSearch: string;
    loading: boolean;
    onOpen: (id: number) => void;
    onEdit: (id: number) => void;
    onAlterRevision: (id: number) => void;
    onRemove: (id: number) => void;
    onExport: (id: number) => void;
    onVisibleDataCountChange: (visibleDataCount: number) => void;
}

const UnfinishedRevisionTable: FC<Props> = ({ data, globalSearch, onOpen, onEdit, onAlterRevision, onRemove, onExport, onVisibleDataCountChange }) => {
    const { t, i18n } = useTranslation();
    const { isRevisor, company } = useAuth();

    const [sorting, setSorting] = useState<SortingRule<object>[]>([{ id: 'createdAt', desc: false }]);

    const columns = useMemo(() => {
        const columns: Array<Column<RevisionModel> & { Header: string; languageId?: string }> = [
            {
                id: 'name',
                Header: t('columnName'),
                languageId: 'columnName',
                Cell: function nameCell(props) {
                    return <RevisionCell {...props} multiRow></RevisionCell>;
                },
                accessor: row => row.name,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.original.name.toLowerCase();
                    const lawB = rowB.original.name.toLowerCase();
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                id: 'createdAt',
                Header: t('columnDate'),
                languageId: 'columnDate',
                Cell: RevisionCell,
                accessor: row => row.createdAt,
                sortInverted: true,
            },
            {
                id: 'responsible',
                Header: t('columnResponsible'),
                languageId: 'columnResponsible',
                Cell: RevisionCell,
                accessor: row => row.responsibleName,
            },
            {
                id: 'progress',
                Header: t('columnRevisedLaws'),
                languageId: 'columnRevisedLaws',
                accessor: function Accessor({ revisedSubscriptionCount, subscriptionCount }) {
                    return <ProgressBar progress={revisedSubscriptionCount} total={subscriptionCount} />;
                },
                Cell: RevisionCell,
                disableSortBy: true,
            },
        ];

        columns.push({
            id: 'openButton',
            Header: '',
            Cell: RevisionCell,
            accessor: function Accessor({ id }) {
                return (
                    <Button variant="Outline" className={styled.OpenButton} onClick={() => onOpen(id)}>
                        {t('buttonOpen')}
                    </Button>
                );
            },
            disableSortBy: true,
        });

        columns.push({
            id: 'menu',
            Header: '',
            accessor: row => row.id,
            Cell: function Cell({ row, sortedRows }) {
                const { id } = row.original;
                const lastRow = isLastRow(row, sortedRows);
                return (
                    <MenuCell
                        title={t('buttonManageRevision')}
                        lastRow={lastRow}
                        items={[
                            ...(isRevisor() ? [{ text: t('alterRevisionMenuText'), function: () => onAlterRevision(id) }] : []),
                            ...(isRevisor() ? [{ text: t('buttonEdit'), function: () => onEdit(id) }] : []),
                            ...(isRevisor() ? [{ text: t('buttonRemove'), function: () => onRemove(id) }] : []),
                            { text: t('filterbarExportToExcel'), function: () => onExport(id) },
                        ]}
                    />
                );
            },
            disableSortBy: true,
        });

        return columns;
    }, [onEdit, onRemove, onExport, company, i18n.language]);

    return (
        <Table
            data={data}
            dense
            columns={columns}
            globalSearch={globalSearch}
            sorting={sorting}
            onSortingChange={setSorting}
            showColumnSearch={false}
            columnSearch={[]}
            rowHeight={64}
            onVisibleDataCountChange={onVisibleDataCountChange}
        />
    );
};

export default UnfinishedRevisionTable;
