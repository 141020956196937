import React, { FC } from 'react';
import { AssessmentChoice } from '../../../../services/SubscriptionChangeService';
import styled from './ConsultantMultiAcceptConfirmModal.module.css';
import Modal from '../../Modal';
import Button from '../../../Button/Button';

interface Props {
    count: number;
    option: AssessmentChoice;
    onCancel: () => void;
    onConfirm: () => void;
}

const ConsultantMultiAcceptConfirmModal: FC<Props> = ({ count, option, onCancel, onConfirm }) => {
    const title = option === 'DECLINE' ? 'Skicka till Arkivet' : 'Skicka till kund';
    const text =
        option === 'DECLINE'
            ? `Är du säker att ${count} lagar saknar betydelse för företaget. Samtliga av dessa författningar kommer att skickas till arkivet.`
            : `Är du säker att ${count} lagar skall skickas till kund utan beslut?`;

    return (
        <Modal title={title} subtitle={text}>
            <div className={styled.Options}>
                <Button variant="Primary" onClick={onCancel}>
                    Avbryt
                </Button>
                <Button variant="Outline" onClick={onConfirm}>
                    OK, {title}
                </Button>
            </div>
        </Modal>
    );
};

export default ConsultantMultiAcceptConfirmModal;
