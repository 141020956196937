import { CHANGES_LIST, HOME, LAWS_LISTS, REVISION_FINISHED, REVISION_UNFINISHED, SETTINGS_COMPANY_CUSTOMLAWS } from '../constants/Routes';

interface DashboardStatus {
    statusType: 'CHANGES' | 'CUSTOM_LAW' | 'REVISION_WARNING' | 'ONGOING_REVISIONS' | 'FINISHED_REVISIONS';
    warningLevel: 'OK' | 'LOW_WARNING' | 'HIGH_WARNING';
    titleTextKey: string;
    textKey: string;
    placeholderValue: string | null;
}

export const getStatusTypeRoute = (statusType: DashboardStatus['statusType']): string => {
    switch (statusType) {
        case 'CHANGES':
            return CHANGES_LIST;
        case 'CUSTOM_LAW':
            return SETTINGS_COMPANY_CUSTOMLAWS;
        case 'REVISION_WARNING':
            return LAWS_LISTS;
        case 'ONGOING_REVISIONS':
            return REVISION_UNFINISHED;
        case 'FINISHED_REVISIONS':
            return REVISION_FINISHED;
        default:
            return HOME;
    }
};

export default DashboardStatus;
