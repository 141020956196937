class SignContractModel {
    companyId: number;
    userId: number;
    password: string;
    nominateCertifier: any;

    constructor(companyId: number, userId: number, password: string, nominateCertifier: any) {
        this.companyId = companyId;
        this.userId = userId;
        this.password = password;
        this.nominateCertifier = nominateCertifier;
    }
}

export default SignContractModel;
