import { AxiosResponse } from 'axios';
import { getAuthApi } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';
import RevisionQuestion from '../models/RevisionQuestion';
import RevisionQuestionGroup from '../models/RevisionQuestionGroup';
import SubscriptionModel from '../models/SubscriptionModel';

const RevisionQuestionService = () => {
    const axiosInstance = getAuthApi();

    function createCustomRevisionQuestion(subscriptionId: number, revisionQuestion: Partial<RevisionQuestion>): Promise<RevisionQuestion> {
        return axiosInstance
            .post<RevisionQuestion>(`/revisionquestion/custom/subscription?subscriptionId=${subscriptionId}`, revisionQuestion)
            .then((response: AxiosResponse<RevisionQuestion>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function createCustomLawCustomRevisionQuestion(customLawId: number, revisionQuestion: Partial<RevisionQuestion>): Promise<RevisionQuestion> {
        return axiosInstance
            .post<RevisionQuestion>(`/revisionquestion/custom/customlaw?customLawId=${customLawId}`, revisionQuestion)
            .then((response: AxiosResponse<RevisionQuestion>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateCustomRevisionQuestions(revisionQuestions: RevisionQuestion[]): Promise<RevisionQuestion[]> {
        return axiosInstance
            .put<RevisionQuestion[]>('/revisionquestion/list', revisionQuestions)
            .then((response: AxiosResponse<RevisionQuestion[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateCustomRevisionQuestion(revisionQuestion: RevisionQuestion): Promise<RevisionQuestion> {
        return axiosInstance
            .put<RevisionQuestion>('/revisionquestion', revisionQuestion)
            .then((response: AxiosResponse<RevisionQuestion>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function removeCustomRevisionQuestion(revisionQuestionId: number): Promise<AxiosResponse> {
        return axiosInstance.delete(`/revisionquestion?revisionQuestionId=${revisionQuestionId}`).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function updateExcludedRevisionQuestions(subscriptionId: number, excludedIds: number[]): Promise<SubscriptionModel> {
        return axiosInstance
            .post<SubscriptionModel>(`/revisionquestion/subscription/excluded?subscriptionId=${subscriptionId}&excludedIds=${excludedIds}`, undefined)
            .then((response: AxiosResponse<SubscriptionModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getRevisionQuestionsForLaw(lawId: number): Promise<RevisionQuestionGroup[]> {
        return axiosInstance
            .get<RevisionQuestionGroup[]>(`/revisionquestion?lawId=${lawId}`)
            .then((response: AxiosResponse<RevisionQuestionGroup[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        createCustomRevisionQuestion,
        createCustomLawCustomRevisionQuestion,
        updateCustomRevisionQuestions,
        updateCustomRevisionQuestion,
        removeCustomRevisionQuestion,
        updateExcludedRevisionQuestions,
        getRevisionQuestionsForLaw,
    };
};

export default RevisionQuestionService;
