import React, { FC, useState, useMemo } from 'react';
import styled from './EditRevisionQuestionView.module.css';
import RevisionQuestionItem from '../RevisionQuestionItem/RevisionQuestionItem';
import SubscriptionModel, { isSubscription } from '../../models/SubscriptionModel';
import _ from 'lodash';
import Button from '../Button/Button';
import RevisionQuestion from '../../models/RevisionQuestion';
import CreateRevisionQuestionItem from '../RevisionQuestionItem/CreateRevisionQuestionItem';
import RemoveRevisionQuestionModal from '../Modal/RemoveRevisionQuestionModal/RemoveRevisionQuestionModal';
import CustomLawModel from '../../models/CustomLawModel';
import { useTranslation } from 'react-i18next';

interface Props {
    data: SubscriptionModel | CustomLawModel;
    onCreateRevisionQuestion: (revisionQuestion: Partial<RevisionQuestion>) => Promise<number>;
    onUpdateRevisionQuestion: (revisionQuestion: RevisionQuestion) => void;
    onRemoveRevisionQuestion: (id: number) => void;
    onCancel: () => void;
    onSubmit: (excludedRevisionQuestionIds: number[], excludedCustomRevisionQuestionIds: number[]) => void;
}

const EditRevisionQuestionView: FC<Props> = ({ data, onCreateRevisionQuestion, onUpdateRevisionQuestion, onRemoveRevisionQuestion, onCancel, onSubmit }) => {
    const { t } = useTranslation();
    const [removeModal, setRemoveModal] = useState<{ open: boolean; revisionQuestionId?: number }>({ open: false });
    const [excludedRevisionQuestionIds, setExcludedRevisionQuestionIds] = useState<number[]>(
        isSubscription(data) ? data.revisionQuestionGroups.flatMap(rqg => rqg.revisionQuestions.filter(rq => rq.excluded).map(rq => rq.questionBaseId || rq.id)) : [],
    );
    const [excludedCustomRevisionQuestionIds, setExcludedCustomRevisionQuestionIds] = useState<number[]>(
        data.customRevisionQuestions ? data.customRevisionQuestions.filter(crq => crq.excluded).map(crq => crq.id) : [],
    );

    const handleCreate = async (question: string, excluded: boolean) => {
        const questionId = await onCreateRevisionQuestion({
            question,
            excluded,
        });
        if (excluded) {
            setExcludedCustomRevisionQuestionIds(s => [...s, questionId]);
        }
    };

    const handleUpdate = (revisionQuestion: RevisionQuestion): void => {
        onUpdateRevisionQuestion(revisionQuestion);
    };

    const handleRemove = async () => {
        const revisionQuestionId = removeModal.revisionQuestionId;
        if (revisionQuestionId) {
            onRemoveRevisionQuestion(revisionQuestionId);
            setRemoveModal({ open: false });
            setExcludedCustomRevisionQuestionIds(s => s.filter(id => id !== revisionQuestionId));
        }
    };

    const handleCheckedChange = (baseQuestionId: number) => {
        setExcludedRevisionQuestionIds(s => (s.includes(baseQuestionId) ? s.filter(id => id !== baseQuestionId) : [...s, baseQuestionId]));
    };

    const handleCustomCheckedChange = (questionId: number) => {
        setExcludedCustomRevisionQuestionIds(s => (s.includes(questionId) ? s.filter(id => id !== questionId) : [...s, questionId]));
    };

    const handleSubmit = (): void => {
        onSubmit(excludedRevisionQuestionIds, excludedCustomRevisionQuestionIds);
    };

    // Should be checked when not excluded
    const isChecked = (questionBaseId: number): boolean => {
        const isExcluded = excludedRevisionQuestionIds.includes(questionBaseId);
        if (isExcluded) {
            return false;
        }
        return true;
    };

    const isCustomChecked = (questionId: number): boolean => {
        const isExcluded = excludedCustomRevisionQuestionIds.includes(questionId);
        if (isExcluded) {
            return false;
        }
        return true;
    };

    const isChanged = useMemo(() => {
        if (isSubscription(data)) {
            if (
                !_.isEqual(
                    excludedCustomRevisionQuestionIds.sort(),
                    data.customRevisionQuestions
                        .filter(crq => crq.excluded)
                        .map(crq => crq.id)
                        .sort(),
                ) ||
                !_.isEqual(
                    excludedRevisionQuestionIds.sort(),
                    data.revisionQuestionGroups.flatMap(rqg =>
                        rqg.revisionQuestions
                            .filter(rq => rq.excluded)
                            .map(rq => rq.id)
                            .sort(),
                    ),
                )
            ) {
                return true;
            }
        } else {
            if (
                !_.isEqual(
                    excludedCustomRevisionQuestionIds.sort(),
                    data.customRevisionQuestions
                        .filter(crq => crq.excluded)
                        .map(crq => crq.id)
                        .sort(),
                )
            ) {
                return true;
            }
        }

        return false;
    }, [excludedCustomRevisionQuestionIds, excludedRevisionQuestionIds, data]);

    return (
        <div className={styled.EditRevisionQuestionView}>
            <div className={styled.Header}>
                <span>{t('revisionQuestions')}</span>
            </div>

            <div className={styled.Content}>
                <h4>{t('revisionQuestions')}</h4>
                {isSubscription(data) &&
                    data.revisionQuestionGroups.flatMap(rqg => (
                        <React.Fragment key={rqg.id}>
                            {rqg.title && (
                                <div className={styled.Header}>
                                    <p>{rqg.title}</p>
                                </div>
                            )}
                            {rqg.revisionQuestions.map(rq => (
                                <RevisionQuestionItem
                                    key={rqg.id + '-' + rq.id}
                                    data={rq}
                                    checked={isChecked(rq.questionBaseId!)}
                                    onCheckedChange={() => handleCheckedChange(rq.questionBaseId!)}
                                />
                            ))}
                        </React.Fragment>
                    ))}

                <div className={styled.Customs}>
                    <div className={styled.Header}>
                        <p>{t('ownQuestions')}</p>
                    </div>
                    {data.customRevisionQuestions.map(rq => (
                        <RevisionQuestionItem
                            key={rq.id}
                            data={rq}
                            onSave={handleUpdate}
                            onRemove={revisionQuestionId => setRemoveModal({ open: true, revisionQuestionId })}
                            checked={isCustomChecked(rq.id)}
                            onCheckedChange={() => handleCustomCheckedChange(rq.id)}
                            custom
                        />
                    ))}
                    <CreateRevisionQuestionItem onSave={handleCreate} />
                </div>
            </div>

            <div className={styled.Buttons}>
                <Button variant="Outline" onClick={onCancel}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Primary" onClick={isChanged ? handleSubmit : onCancel}>
                    {isChanged ? t('buttonSaveSelection') : t('buttonClose')}
                </Button>
            </div>

            {removeModal.open && removeModal.revisionQuestionId !== undefined && (
                <RemoveRevisionQuestionModal onClose={() => setRemoveModal({ open: false })} onConfirm={handleRemove} />
            )}
        </div>
    );
};

export default EditRevisionQuestionView;
