import React, { createContext, useState, SetStateAction, Dispatch, FC, useMemo, useEffect } from 'react';
import SubscriptionModel from '../models/SubscriptionModel';
import LawTypeFilter from '../models/LawTypeFilter';
import LawListGroupFilter from '../models/LawListGroupFilter';
import { Column, SortingRule } from 'react-table';
import _ from 'lodash';
import KeywordModel from '../models/KeywordModel';
import useAuth from '../hooks/useAuth';
import LawCell from '../components/Table/LawCell/LawCell';
import KeywordCell from '../components/Table/KeywordCell/KeywordCell';
import UserModel from '../models/UserModel';
import { useTranslation } from 'react-i18next';
import { columnKeys } from '../constants/ExcelColumnWidth';

export interface State {
    data: SubscriptionModel[];
    filteredData: SubscriptionModel[];
    visibleDataCount: number;
    selectedIndex: number;
    lawTypeFilter: LawTypeFilter[];
    lawListGroupFilter: LawListGroupFilter[];
    customFilterActive: boolean;
    myChangesFilterActive: boolean;
    delegatedFilterActive: boolean;
    selectedKeywords: (KeywordModel & { checked: boolean })[];
    columns: Array<
        Column<SubscriptionModel> & { id: columnKeys; Header: string; languageId?: string; visible: boolean; field?: string; alwaysVisible?: boolean; showNotification?: boolean }
    >;
    customColumns: State['columns'];
    sorting: SortingRule<object>[];
    globalSearch: string;
    columnSearch: {
        id: string;
        value: string | undefined;
    }[];
    showColumnSearch: boolean;
    assessmentUsers: UserModel[];
    loading: boolean;
    editRevisionQuestionsOpen: boolean;
}

interface Context {
    state: State;
    setState: Dispatch<SetStateAction<State>>;
}

const initialState: State = {
    filteredData: [],
    data: [],
    visibleDataCount: 0,
    lawTypeFilter: [],
    lawListGroupFilter: [],
    myChangesFilterActive: false,
    customFilterActive: false,
    delegatedFilterActive: false,
    selectedKeywords: [],
    selectedIndex: -1,
    columns: [],
    customColumns: [],
    sorting: [
        { id: 'acceptableBy', desc: false },
        { id: 'publishedDate', desc: false },
        { id: 'delegated', desc: true },
    ],
    globalSearch: '',
    columnSearch: [],
    showColumnSearch: true,
    assessmentUsers: [],
    loading: false,
    editRevisionQuestionsOpen: false,
};

const ChangeListContext = createContext<Context>({
    state: initialState,
    setState: () => null,
});

const ChangeListContextProvider: FC = ({ children }) => {
    const { t, i18n } = useTranslation();
    const { company, isAssessor } = useAuth();

    const columns = useMemo(() => {
        const columns: State['columns'] = [
            {
                id: 'law',
                Header: t('columnLaw'),
                languageId: 'columnLaw',
                accessor: row => `${row.name} ${row.subId}`,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.values.law;
                    const lawB = rowB.values.law;
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
                showNotification: true,
                visible: true,
                width: 2,
                alwaysVisible: true,
                Cell: LawCell,
            },
            {
                id: 'changeText',
                Header: t('columnChange'),
                languageId: 'columnChange',
                accessor: 'changeText',
                visible: true,
                width: 2,
                alwaysVisible: true,
                disableSortBy: true,
            },
            {
                id: 'delegated',
                Header: t('columnDelegated'),
                accessor: row => (row.waitingForAcceptance ? row.waitingForAcceptance.map(acceptance => acceptance.fullName) : ''),
                Cell: KeywordCell,
                visible: false,
                width: 1,
                alwaysVisible: false,
                disableSortBy: false,
            },
            {
                id: 'publishedDate',
                Header: t('columnDate'),
                languageId: 'publishedDate',
                accessor: row => (row.publishedDate ? row.publishedDate : ''),
                alwaysVisible: true,
                visible: true,
                sortInverted: true,
            },
            {
                id: 'description',
                Header: t('columnDescription'),
                languageId: 'columnDescription',
                accessor: 'description',
                disableSortBy: true,
                visible: false,
            },
            {
                id: 'text',
                Header: t('columnText'),
                languageId: 'columnText',
                accessor: 'text',
                disableSortBy: true,
                field: 'text',
                visible: false,
            },
            {
                id: 'newLaw',
                Header: 'newLaw',
                accessor: row => `${row.newLaw}`,
                visible: false,
                alwaysVisible: true,
                defaultCanSort: true,
            },
            {
                id: 'acceptableBy',
                Header: 'acceptableBy',
                accessor: ({ acceptanceStatus }) => {
                    if (acceptanceStatus.includes('ME')) {
                        return 'ME';
                    } else if (acceptanceStatus.includes('OTHER')) {
                        return 'OTHER';
                    } else {
                        if (isAssessor()) {
                            return 'ME';
                        }
                        return '';
                    }
                },
                visible: false,
                alwaysVisible: true,
                defaultCanSort: true,
                sortType: (rowA, rowB): number => {
                    const aAcceptableBy = rowA.values.acceptableBy;
                    const bAcceptableBy = rowB.values.acceptableBy;

                    const sortOrder = ['ME', 'OTHER', ''];
                    return sortOrder.indexOf(aAcceptableBy) - sortOrder.indexOf(bAcceptableBy);
                },
            },
        ];

        return columns;
    }, []);

    const customColumns = useMemo(() => {
        const customColumns: State['customColumns'] = [];

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                customColumns.push({
                    id: ('customerText' + i) as columnKeys,
                    accessor: 'customerText' + i,
                    visible: false,
                    field: 'customerText' + i,
                    Header: title,
                    disableSortBy: true,
                });
            }
        }

        if (company?.hasLawLists) {
            customColumns.push({
                id: 'lawLists',
                visible: true,
                accessor: row => (row.lawLists ? row.lawLists.map(lawList => (lawList.lawListGroup ? lawList.lawListGroup.name + ': ' : '') + lawList.name) : ''),
                Cell: KeywordCell,
                Header: t('columnLawLists'),
                languageId: 'columnLawLists',
                disableSortBy: true,
                field: 'lawLists',
            });
        }

        if (company?.hasKeyWords) {
            customColumns.push({
                id: 'keywords',
                visible: true,
                accessor: row => row.keywordIds.map((id: number) => _.find(company.keyWords, kw => kw.id === id)?.text),
                Cell: KeywordCell,
                field: 'keywordIds',
                Header: t('columnKeywords'),
                languageId: 'columnKeywords',
                disableSortBy: true,
            });
        }

        return customColumns;
    }, []);

    const [state, setState] = useState<State>({ ...initialState, customColumns, columns });

    // Handle language change
    useEffect(() => {
        setState(s => ({
            ...s,
            columns: s.columns.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
            customColumns: s.customColumns.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
        }));
    }, [i18n.language]);

    // Apply filtering
    useEffect(() => {
        setState(s => {
            const { lawTypeFilter, lawListGroupFilter, selectedKeywords, myChangesFilterActive, data, delegatedFilterActive } = s;

            // Apply lawGroup filtering
            const selectedLawGroupIds = lawTypeFilter.flatMap(lawType => lawType.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupId));
            let filteredData = data.filter(obj => {
                return selectedLawGroupIds.includes(obj.lawGroupId);
            });

            // Apply lawList filtering
            const selectedLawListIds = lawListGroupFilter.flatMap(lawListGroup => lawListGroup.lawLists.filter(lawList => lawList.checked).map(lawList => lawList.lawListId));
            if (selectedLawListIds.length) {
                filteredData = filteredData.filter(f => selectedLawListIds.some(id => f.lawLists.find(lawList => lawList.lawListId === id)));
            }

            // Apply keyword filtering
            const selectedKeywordIds = selectedKeywords.filter(kw => kw.checked).map(kw => kw.id);
            if (selectedKeywordIds.length) {
                filteredData = filteredData.filter(f => selectedKeywordIds.some(id => f.keywordIds.includes(id)));
            }

            if (myChangesFilterActive) {
                filteredData = filteredData.filter(f => f.userCanAccept);
            }

            if (delegatedFilterActive) {
                filteredData = filteredData.filter(f => f.waitingForAcceptance);
            }

            return {
                ...s,
                filteredData,
            };
        });
    }, [state.data, state.lawTypeFilter, state.lawListGroupFilter, state.selectedKeywords, state.myChangesFilterActive, state.delegatedFilterActive]);

    return <ChangeListContext.Provider value={{ state, setState }}>{children}</ChangeListContext.Provider>;
};

export { ChangeListContext, ChangeListContextProvider };
