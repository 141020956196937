import React, { FC, useMemo, useState, useEffect } from 'react';
import styled from './Keywords.module.css';
import Button from '../../../../components/Button/Button';
import useAuth from '../../../../hooks/useAuth';
import Table from '../../../../components/Table/Table';
import KeywordModel, { EditKeywordModel } from '../../../../models/KeywordModel';
import DenseCell from '../../../../components/Table/DenseCell/DenseCell';
import { Column, SortingRule } from 'react-table';
import MenuCell, { isLastRow } from '../../../../components/Table/MenuCell/MenuCell';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import CompanyService from '../../../../services/CompanyService';
import EditKeywordModal from '../../../../components/Modal/EditKeywordModal/EditKeywordModal';
import HtmlCell from '../../../../components/Table/HtmlCell/HtmlCell';
import RemoveKeywordModal from '../../../../components/Modal/RemoveKeywordModal/RemoveKeywordModal';
import { SETTINGS_COMPANY_KEYWORDS_EDIT } from '../../../../constants/Routes';
import { useHistory } from 'react-router-dom';
import Search from '../../../../components/Search/Search';
import Toggle from '../../../../components/Toggle/Toggle';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccordionIcon } from '../../../../assets/images/accordionIcon.svg';

const COLUMN_SEARCH: { id: string; value: string | undefined }[] = [];

const Keywords: FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { company, isAboveUser, addKeyword, updateKeyword, removeKeyword } = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<KeywordModel[]>([]);
    const [showInformation, setShowInformation] = useState(true);
    const [keywordModal, setKeywordModal] = useState<{ open: boolean; edit?: KeywordModel }>({ open: false });
    const [removeModal, setRemoveModal] = useState<{ open: boolean; keywordId?: number }>({ open: false, keywordId: undefined });
    const [sorting, setSorting] = useState<SortingRule<object>[]>([{ id: 'name', desc: false }]);
    const [globalSearch, setGlobalSearch] = useState('');
    const [visibleDataCount, setVisibleDataCount] = useState(0);
    const [showColumnSearch, setShowColumnSearch] = useState(true);

    const fetchData = async () => {
        if (company) {
            try {
                setLoading(true);

                const keywords = await CompanyService().getFullKeywords(company.id);
                setData(keywords);

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleSave = async (keyword: EditKeywordModel) => {
        if (company) {
            try {
                setLoading(true);
                setKeywordModal({ open: false, edit: undefined });
                if (keyword.id) {
                    const updatedKeyword = await CompanyService().updateKeyword(company.id, keyword);
                    setData(s => s.map(kw => (kw.id === updatedKeyword.id ? { ...kw, text: updatedKeyword.text, description: updatedKeyword.description } : kw)));
                    updateKeyword(updatedKeyword);
                } else {
                    const newKeyword = await CompanyService().createKeyword(company.id, keyword);
                    setData(s => [...s, newKeyword]);
                    addKeyword(newKeyword);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleEditKeyword = (keywordId: number) => {
        const keyword = data.find(kw => kw.id === keywordId);
        if (keyword) {
            setKeywordModal({ open: true, edit: keyword });
        }
    };

    const handleRemoveKeyword = async () => {
        const keywordId = removeModal.keywordId;
        if (company && keywordId) {
            try {
                setLoading(true);
                setRemoveModal({ open: false, keywordId: undefined });
                await CompanyService().removeKeyword(company.id, keywordId);
                setData(s => s.filter(kw => kw.id !== keywordId));
                removeKeyword(keywordId);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleEditKeywordContent = (keywordId: number) => {
        history.push(SETTINGS_COMPANY_KEYWORDS_EDIT + `/${keywordId}`);
    };

    const handleToggleShowInformation = () => {
        setShowInformation(s => !s);
    };

    const columns = useMemo<Array<Column<KeywordModel> & { Header: string }>>(
        () => [
            {
                id: 'name',
                Header: t('columnKeywords'),
                Cell: DenseCell,
                accessor: row => row.text,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.original.text.toLowerCase();
                    const lawB = rowB.original.text.toLowerCase();
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                id: 'description',
                Header: t('inputFieldDescription'),
                Cell: HtmlCell,
                accessor: row => row.description,
                disableSortBy: true,
            },
            {
                id: 'lawCount',
                Header: t('columnLawCount'),
                Cell: DenseCell,
                accessor: row => row.lawCount,
            },
            {
                id: 'menu',
                Header: '',
                Cell: function Cell(e) {
                    const { row, sortedRows } = e;
                    const { id } = row.original;
                    const isEditable = isAboveUser();

                    const lastRow = isLastRow(row, sortedRows);

                    return (
                        <MenuCell
                            title={t('buttonManageKeywords')}
                            lastRow={lastRow}
                            items={[
                                { text: t('buttonLaws'), function: isEditable ? () => handleEditKeywordContent(id) : () => undefined },
                                { text: t('buttonEdit'), function: isEditable ? () => handleEditKeyword(id) : () => undefined },
                                { text: t('buttonRemove'), function: isEditable ? () => setRemoveModal({ open: true, keywordId: id }) : () => undefined },
                            ]}
                        />
                    );
                },
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [handleEditKeyword, setRemoveModal, i18n.language],
    );

    useEffect(() => {
        fetchData();
    }, []);

    if (!company) {
        return null;
    }

    return (
        <div className={styled.Keywords}>
            {loading && <LoadingSpinner />}
            <div className={styled.Top}>
                <h3 className={styled.Title}>{t('settingsMenuCompanyKeywords')}</h3>
                <div className={styled.Information} onClick={handleToggleShowInformation}>
                    <h6>
                        {t('keywordsTitle')} <AccordionIcon className={[styled.Icon, showInformation ? styled.Open : ''].join(' ')} />
                    </h6>
                    {showInformation && <p>{t('keywordsText')}</p>}
                    <div className={styled.Buttons}>
                        <Button variant="Primary" onClick={() => setKeywordModal({ open: true, edit: undefined })} disabled={!isAboveUser()}>
                            {t('buttonCreateKeyword')}
                        </Button>
                    </div>
                </div>
            </div>

            <div className={styled.SearchBar}>
                <div className={styled.SearchContainer}>
                    <Search value={globalSearch} onChange={setGlobalSearch} />
                    <p className={[styled.SearchCounter, visibleDataCount < data.length ? styled.Filtered : ''].join(' ')}>
                        {visibleDataCount} {t('of')} {data.length}
                    </p>
                </div>
                <Toggle title={t('columnSearch')} checked={showColumnSearch} onChange={() => setShowColumnSearch(!showColumnSearch)} className={styled.Toggle} />
            </div>

            <div className={styled.TableWrapper}>
                <Table
                    columns={columns}
                    data={data}
                    showColumnSearch={showColumnSearch}
                    columnSearch={COLUMN_SEARCH}
                    globalSearch={globalSearch}
                    rowHeight={48}
                    dense
                    headerClassName={styled.TableHeader}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    onVisibleDataCountChange={setVisibleDataCount}
                />
            </div>

            {keywordModal.open && <EditKeywordModal edit={keywordModal.edit} onCancel={() => setKeywordModal({ open: false, edit: undefined })} onSave={handleSave} />}

            {removeModal.open && removeModal.keywordId && (
                <RemoveKeywordModal onCancel={() => setRemoveModal({ open: false, keywordId: undefined })} onConfirm={handleRemoveKeyword} />
            )}
        </div>
    );
};

export default Keywords;
