import React, { FC, useEffect, useState, useMemo } from 'react';
import Table from '../../../components/Table/Table';
import styled from './LawLibrary.module.css';
import useLawLibrary from '../../../hooks/useLawLibrary';
import Filterbar from '../../../components/Filterbar/Filterbar';
import ContextMenu from '../../../components/ContextMenu/ContextMenu';
import NavigationPrompt from 'react-router-navigation-prompt';
import _ from 'lodash';
import MonitorToaster from '../../../components/MonitorToaster/MonitorToaster';
import { LAWS_LIBRARY, LAWS_LISTS } from '../../../constants/Routes';
import lawlistIcon from '../../../assets/images/lawlistIcon.svg';
import lawlibraryIcon from '../../../assets/images/lawlibraryIcon.svg';
import LeavePageModal from '../../../components/LeavePageModal/LeavePageModal';
import SaveSubscriptionsModal from '../../../components/SaveSubscriptionsModal/SaveSubscriptionsModal';
import LawLibraryFullView from '../../../components/FullView/LawLibraryFullView/LawLibraryFullView';
import { isNewUnaccepted, isSubscription } from '../../../models/SubscriptionModel';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import EmptyListView from '../../../components/EmptyListView/EmptyListView';
import RestrictedAccessToasterModule from '../../../components/CustomToaster/CustomToaster';

const LawLibrary: FC = () => {
    const { t } = useTranslation();

    const CONTEXT_LINKS: {
        title: string;
        url: string;
        icon: string;
        active: boolean;
    }[] = [
        {
            title: t('contextMenuLawLists'),
            url: LAWS_LISTS,
            icon: lawlistIcon,
            active: false,
        },
        {
            title: t('contextMenuLawLibrary'),
            url: LAWS_LIBRARY,
            icon: lawlibraryIcon,
            active: true,
        },
    ];

    const { isGroupCompany, isAboveUser } = useAuth();
    const {
        loading,
        data,
        filteredData,
        fetchData,
        lawTypeFilter,
        hasCustomLaws,
        handleLawTypeOpen,
        handleLawTypeChecked,
        handleLawGroupChecked,
        selectedIndex,
        handleSelection,
        columns,
        showSubscriptions,
        handleShowSubscriptionToggle,
        globalSearch,
        handleGlobalSearchChange,
        lawListGroupFilter,
        showColumnSearch,
        handleColumnSearchToggle,
        handleResetLawTypeFilter,
        handleResetAllFilters,
        visibleDataCount,
        totalDataCount,
        handleVisibleDataCountChange,
        columnSearch,
        sorting,
        handleSortingChange,
        addAndRemoveSubscriptions,
        handleAllLawTypesChecked,
    } = useLawLibrary();

    const [originalSelectedRows, setOriginalSelectedRows] = useState<Record<string, boolean>>({});
    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
    const [saveModal, setSaveModal] = useState({
        open: false,
        accepted: false,
        count: 0,
    });

    const lawIdsToAdd = useMemo(() => {
        return _.filter(data, obj => !isSubscription(obj) && selectedRows[obj.lawId] === true).map(obj => (!isSubscription(obj) ? obj.lawId : -1));
    }, [data, selectedRows, originalSelectedRows]);
    const subscriptionIdsToRemove = useMemo(() => {
        return _.filter(data, obj => isSubscription(obj) && selectedRows[obj.subscriptionId] === false).map(obj => (isSubscription(obj) ? obj.subscriptionId : -1));
    }, [data, selectedRows, originalSelectedRows]);

    const handleCancelSelection = () => {
        setSelectedRows(originalSelectedRows);
    };

    const handleSaveSelection = () => {
        if (lawIdsToAdd.length > subscriptionIdsToRemove.length && !saveModal.accepted) {
            return setSaveModal(s => ({
                ...s,
                open: true,
                count: lawIdsToAdd.length - subscriptionIdsToRemove.length,
            }));
        } else {
            setSaveModal(s => ({ ...s, open: false, accepted: false, count: 0 }));
        }
        addAndRemoveSubscriptions(subscriptionIdsToRemove, lawIdsToAdd);
    };

    const handleRowsChange = (filteredRowIds: string[]) => {
        setSelectedRows(sr => {
            const allChecked = _.every(filteredRowIds, id => sr[id] === true);
            return _.mapValues(sr, (value, id) => {
                if (_.includes(filteredRowIds, id)) {
                    const obj = data.find(d => isSubscription(d) && d.subscriptionId === +id);
                    if (obj && isSubscription(obj)) {
                        const isDetachedCopy = obj.detachedCopy;
                        if (isNewUnaccepted(obj) || isDetachedCopy) {
                            return value;
                        }
                    }
                    return !allChecked;
                }
                return value;
            });
        });
    };

    const handleNavigationSave = () => {
        setSaveModal(s => ({
            ...s,
            accepted: lawIdsToAdd.length < subscriptionIdsToRemove.length,
            count: lawIdsToAdd.length - subscriptionIdsToRemove.length,
            open: lawIdsToAdd.length > subscriptionIdsToRemove.length,
        }));
    };

    const handleRowChange = (id: string) => {
        setSelectedRows(s => ({
            ...s,
            [id]: !s[id],
        }));
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const selection: Record<string, boolean> = {};
        data.forEach(item => {
            if (isSubscription(item)) {
                selection[item.subscriptionId] = true;
            } else {
                selection[item.lawId] = false;
            }
        });

        setOriginalSelectedRows(selection);
        setSelectedRows(selection);
    }, [data]);

    useEffect(() => {
        if (saveModal.accepted && !saveModal.open) {
            handleSaveSelection();
        }
    }, [saveModal]);

    return (
        <>
            {loading && <LoadingSpinner />}
            <ContextMenu
                lawTypeFilter={lawTypeFilter}
                hasCustomLaws={hasCustomLaws}
                onToggleLawTypeOpen={handleLawTypeOpen}
                onToggleLawTypeChecked={handleLawTypeChecked}
                onToggleLawGroupChecked={handleLawGroupChecked}
                onResetLawTypeFilter={handleResetLawTypeFilter}
                onResetAllFilters={handleResetAllFilters}
                onToggleAllLawTypesChecked={handleAllLawTypesChecked}
                visibleDataCount={visibleDataCount}
                totalDataCount={totalDataCount}
                links={CONTEXT_LINKS}
            />
            <div className={styled.List}>
                <Filterbar
                    showSubscriptions={showSubscriptions}
                    onChangeShowSubscription={handleShowSubscriptionToggle}
                    globalSearch={globalSearch}
                    onGlobalSearchChange={handleGlobalSearchChange}
                    lawListGroupFilter={lawListGroupFilter}
                    showColumnSearch={showColumnSearch}
                    onColumnSearchToggle={handleColumnSearchToggle}
                    hideMenu
                />
                <div className={styled.TableWrapper}>
                    {!loading && data && data.length > 0 && visibleDataCount !== undefined && visibleDataCount < 1 ? <EmptyListView emptyFilter /> : null}
                    {useAuth().isNotAllowedToModifyLawList() ? (
                        <RestrictedAccessToasterModule buttonText={'Ok'} headerTextI18Key={'lawLibraryRestrictionHeader'} mainTextI18tKey={'lawLibraryRestrictionText'} />
                    ) : null}
                    <Table
                        columns={columns}
                        data={filteredData}
                        selected={selectedIndex}
                        onSelect={handleSelection}
                        showColumnSearch={showColumnSearch}
                        globalSearch={globalSearch}
                        onVisibleDataCountChange={handleVisibleDataCountChange}
                        columnSearch={columnSearch}
                        hiddenColumns={['subscribed']}
                        sorting={sorting}
                        onSortingChange={handleSortingChange}
                        showSubscriptions={showSubscriptions}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={handleRowsChange}
                        onSelectedRowChange={handleRowChange}
                        disableDetachedCopies
                        disableMultiSelect={isGroupCompany() || !isAboveUser()}
                        multiSelect
                    />
                    <LawLibraryFullView data={filteredData[selectedIndex]} open={selectedIndex > -1} onClose={() => handleSelection(-1)} />

                    {!_.isEqual(selectedRows, originalSelectedRows) && (
                        <MonitorToaster
                            fullViewOpen={selectedIndex > -1}
                            addedCount={lawIdsToAdd.length}
                            removedCount={subscriptionIdsToRemove.length}
                            monitoredLawsCount={_.filter(selectedRows, s => s === true).length}
                            onCancel={handleCancelSelection}
                            onSave={handleSaveSelection}
                        />
                    )}
                </div>

                {saveModal.open && (
                    <SaveSubscriptionsModal
                        onDecline={() =>
                            setSaveModal(s => ({
                                ...s,
                                accepted: false,
                                open: false,
                            }))
                        }
                        onConfirm={() =>
                            setSaveModal(s => ({
                                ...s,
                                accepted: true,
                                open: false,
                            }))
                        }
                        increasedSubscriptionsBy={saveModal.count}
                    />
                )}

                <NavigationPrompt when={!_.isEqual(selectedRows, originalSelectedRows) && !saveModal.open}>
                    {({ onConfirm, onCancel }) => (
                        <LeavePageModal
                            onSave={() => {
                                onCancel();
                                handleNavigationSave();
                            }}
                            onCancel={onCancel}
                            onContinue={onConfirm}
                        />
                    )}
                </NavigationPrompt>
            </div>
        </>
    );
};

export default LawLibrary;
