import React, { FC } from 'react';
import styled from './EditListContentToaster.module.css';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';

interface Props {
    fullViewOpen: boolean;
    addedCount: number;
    removedCount: number;
    title: string;
    isLawList: boolean;
    onCancel: () => void;
    onSave: () => void;
}

const EditListContentToaster: FC<Props> = ({ fullViewOpen, addedCount, removedCount, title, isLawList, onCancel, onSave }) => {
    const { t } = useTranslation();
    return (
        <div className={[styled.EditListContentToaster, fullViewOpen ? styled.FullViewOpen : ''].join(' ')}>
            <div className={styled.Text}>
                <h6>{title}</h6>
                <p>
                    {addedCount === 0 && removedCount === 0
                        ? isLawList
                            ? t('editListContentToasterLawListTitle')
                            : t('editListContentToasterKeywordTitle')
                        : t('editListContentToasterSelectedTitle', { addedCount, removedCount })}
                </p>
            </div>
            <div className={styled.Options}>
                <Button variant="Secondary" onClick={onCancel}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Light" onClick={onSave}>
                    {t('buttonSave')}
                </Button>
            </div>
        </div>
    );
};

export default EditListContentToaster;
