import React, { FC, useState, useEffect } from 'react';
import {
    Editor,
    EditorState,
    ContentState,
    RichUtils,
    CompositeDecorator,
    ContentBlock,
    Modifier,
    SelectionState,
    CharacterMetadata,
    DraftStyleMap,
    DraftInlineStyle,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import styled from './TextEditor.module.css';
import { ReactComponent as BoldIcon } from '../../assets/images/boldIcon.svg';
import { ReactComponent as ItalicIcon } from '../../assets/images/italicIcon.svg';
import { ReactComponent as ListIcon } from '../../assets/images/listIcon.svg';
import { ReactComponent as TemplateTextIcon } from '../../assets/images/templateTextIcon.svg';
import { ReactComponent as HyperlinkIcon } from '../../assets/images/hyperlinkIcon.svg';
import { ReactComponent as UnderlineIcon } from '../../assets/images/underlineIcon.svg';
import { ReactComponent as UndoIcon } from '../../assets/images/undoIcon.svg';
import { ReactComponent as RedoIcon } from '../../assets/images/redoIcon.svg';
import DraftJsExportHtml, { stateToHTML } from 'draft-js-export-html';
import LinkModal from './LinkModal/LinkModal';
import DraftJsImportHtml, { stateFromHTML } from 'draft-js-import-html';
import OptionButton from './OptionButton/OptionButton';
import ColorButton from './ColorButton/ColorButton';

interface Props {
    title?: any;
    optionalTitle?: any;
    initialValue?: string;
    maxLength?: number;
    noOptions?: boolean;
    consultantText?: string;
    onChange?: (htmlString: string) => void;
    onMaxLengthError?: (error: boolean) => void;
    hasText?: (hasText: boolean) => void;
    errorMsg?: string;
}

export const EMPTY_HTML_TEXT = '<p><br></p>';

const styleOptions = [
    { type: 'BOLD', icon: BoldIcon },
    { type: 'ITALIC', icon: ItalicIcon },
    { type: 'UNDERLINE', icon: UnderlineIcon },
];

const colorKey = 'color-';

const importOptions: DraftJsImportHtml.Options = {
    customInlineFn: (element: any, { Style }) => {
        if (element.style.color) {
            return Style('color-' + element.style.color);
        }
    },
};

const exportOptions: DraftJsExportHtml.Options & { inlineStyleFn: any } = {
    inlineStyleFn: (styles: DraftInlineStyle) => {
        const key = 'color-';
        const color = styles.filter(value => !!value && value.startsWith(key)).first();

        if (color) {
            return {
                element: 'span',
                style: {
                    color: color.replace(key, ''),
                },
            };
        }
    },
};

const TextEditor: FC<Props> = ({ title, optionalTitle, initialValue, maxLength, noOptions, consultantText, onChange, onMaxLengthError, hasText, errorMsg }) => {
    const blockOptions = [
        { type: 'undo', icon: UndoIcon },
        { type: 'redo', icon: RedoIcon },
        ...(consultantText !== undefined ? [{ type: 'consultant-text', icon: TemplateTextIcon }] : []),
        { type: 'LINK', icon: HyperlinkIcon },
        { type: 'unordered-list-item', icon: ListIcon },
    ];

    const decorator = new CompositeDecorator([
        {
            strategy: (contentBlock: ContentBlock, callback: any, contentState: ContentState) => {
                contentBlock.findEntityRanges(character => {
                    const entityKey = character.getEntity();
                    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
                }, callback);
            },
            component: function LinkComponent(props: any) {
                const { url } = props.contentState.getEntity(props.entityKey).getData();
                return <a href={url}>{props.children}</a>;
            },
        },
    ]);

    const [linkModal, setLinkModal] = useState<{ open: boolean; text?: string; url?: string }>({ open: false, text: undefined, url: undefined });
    const [editorState, setEditorState] = useState(() => {
        if (!initialValue) {
            return EditorState.createEmpty();
        }

        const initialStateFromHtml = stateFromHTML(initialValue, importOptions);
        return EditorState.createWithContent(ContentState.createFromBlockArray(initialStateFromHtml.getBlocksAsArray(), initialStateFromHtml.getEntityMap()), decorator);
    });

    useEffect(() => {
        if (initialValue !== stateToHTML(editorState.getCurrentContent(), exportOptions)) {
            if (initialValue) {
                const nextEditorState = EditorState.createWithContent(
                    ContentState.createFromBlockArray(stateFromHTML(initialValue, importOptions).getBlocksAsArray(), stateFromHTML(initialValue, importOptions).getEntityMap()),
                    decorator,
                );
                setEditorState(nextEditorState);
                if (typeof hasText === 'function') {
                    hasText(nextEditorState.getCurrentContent().hasText());
                }
            } else {
                setEditorState(EditorState.createEmpty());
                if (typeof hasText === 'function') {
                    hasText(false);
                }
            }
        }
    }, [initialValue]);

    const selection = editorState.getSelection();
    const currentStyle = editorState.getCurrentInlineStyle();
    const currentBlock = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    const handleChange = (editorState: EditorState): void => {
        setEditorState(editorState);
        if (typeof onChange === 'function') {
            onChange(stateToHTML(editorState.getCurrentContent(), exportOptions));
        }
        if (typeof hasText === 'function') {
            hasText(editorState.getCurrentContent().hasText());
        }
        if (typeof onMaxLengthError === 'function' && maxLength !== undefined) {
            onMaxLengthError(editorState.getCurrentContent().getPlainText().length >= maxLength);
        }
    };

    const getColorsInSelection = (selection: SelectionState, contentState: ContentState): string[] => {
        const colors: string[] = [];
        let selectionKey = selection.getStartKey();
        let startOffset = selection.getStartOffset();
        const endKey = selection.getEndKey();
        const endOffset = selection.getEndOffset();
        while (true) {
            const lastRound = selectionKey == endKey;
            const block = contentState.getBlockForKey(selectionKey);
            const offsetEnd = lastRound ? endOffset : block.getLength();
            const characterList = block.getCharacterList();
            for (let offsetIndex = startOffset; offsetIndex < offsetEnd; offsetIndex++) {
                characterList
                    .get(offsetIndex)
                    .getStyle()
                    .forEach((style: string | undefined) => {
                        if (style && style.startsWith(colorKey) && !colors.includes(style)) {
                            colors.push(style);
                        }
                    });
            }
            if (lastRound) break;
            selectionKey = contentState.getKeyAfter(selectionKey);
            startOffset = 0;
        }
        return colors;
    };

    const toggleColor = (toggledColor: string) => {
        const selection = editorState.getSelection();
        const currentStyle = editorState.getCurrentInlineStyle();

        // Let's just allow one color at a time. Turn off all active colors.
        const colorsToRemove = getColorsInSelection(selection, editorState.getCurrentContent());
        const nextContentState = colorsToRemove.reduce((contentState, color) => {
            return Modifier.removeInlineStyle(contentState, selection, color);
        }, editorState.getCurrentContent());

        let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');

        // Unset style override for current color.
        if (selection.isCollapsed()) {
            nextEditorState = currentStyle.reduce((state, color) => {
                return RichUtils.toggleInlineStyle(state!, color!);
            }, nextEditorState);
        }

        // If the color is being toggled on, apply it.
        if (!currentStyle.has(colorKey + toggledColor) && toggledColor !== 'black') {
            nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, colorKey + toggledColor);
        }

        handleChange(nextEditorState);
    };

    const handleStyleChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, style: string): void => {
        e.preventDefault();
        handleChange(RichUtils.toggleInlineStyle(editorState, style));
    };

    const handleBlockChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, blockType: string): void => {
        e.preventDefault();
        if (blockType === 'consultant-text' && consultantText !== undefined) {
            const nextEditorState = EditorState.createWithContent(
                ContentState.createFromBlockArray(stateFromHTML(consultantText, importOptions).getBlocksAsArray(), stateFromHTML(consultantText, importOptions).getEntityMap()),
                decorator,
            );
            handleChange(nextEditorState);
        } else if (blockType === 'LINK') {
            const selectionState = editorState.getSelection();
            const anchorKey = selectionState.getAnchorKey();
            const focusKey = selectionState.getFocusKey();
            const currentContent = editorState.getCurrentContent();
            const currentContentBlock = currentContent.getBlockForKey(anchorKey);
            const currentContentBlockEnd = currentContent.getBlockForKey(focusKey);
            const start = selectionState.getStartOffset();
            const end = selectionState.getEndOffset();
            const linkKey = currentContentBlock.getEntityAt(start) || currentContentBlock.getEntityAt(end);
            const selectedText = currentContentBlock.getText().slice(start, end);

            // Return if selection spreads across multiple blocks
            if (currentContentBlock.getKey() !== currentContentBlockEnd.getKey()) {
                return;
            }

            if (selectedText || linkKey) {
                if (linkKey) {
                    // Link selected (Edit url)
                    currentContentBlock.findEntityRanges(
                        (character: CharacterMetadata) => {
                            if (character.getEntity() === linkKey) {
                                return true;
                            }
                            return false;
                        },
                        (start, end) => {
                            // Modify selection to select the whole link
                            if (selectionState.getAnchorOffset() !== start || selectionState.getFocusOffset() !== end) {
                                const updateSelection = new SelectionState({
                                    anchorKey: selectionState.getAnchorKey(),
                                    anchorOffset: start,
                                    focusKey: selectionState.getFocusKey(),
                                    focusOffset: end,
                                    isBackward: false,
                                });
                                let newEditorState = EditorState.acceptSelection(editorState, updateSelection);
                                newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
                                setEditorState(newEditorState);
                            }

                            const linkData = currentContent.getEntity(linkKey).getData();
                            const linkText = currentContentBlock.getText().slice(start, end);
                            setLinkModal({ open: true, text: linkText, url: linkData.url });
                        },
                    );
                } else {
                    // Text selected (Create from text)
                    setLinkModal({ open: true, text: selectedText });
                }
            } else {
                // No text selected (Create new)
                setLinkModal({ open: true });
            }
        } else if (blockType === 'undo') {
            handleChange(EditorState.undo(editorState));
        } else if (blockType === 'redo') {
            handleChange(EditorState.redo(editorState));
        } else {
            handleChange(RichUtils.toggleBlockType(editorState, blockType));
        }
    };

    const handleRemoveLink = () => {
        handleChange(RichUtils.toggleLink(editorState, editorState.getSelection(), null));
        setLinkModal({ open: false });
    };

    const handleAddLink = (text: string, url: string) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { children: text, url, target: '_blank' });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        if (selection.isCollapsed()) {
            const textWithEntity = Modifier.insertText(contentState, selection, text, undefined, entityKey);
            handleChange(EditorState.push(editorState, textWithEntity, 'insert-characters'));
        } else {
            const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
            handleChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
        }

        setLinkModal({ open: false });
    };

    const _getLengthOfSelectedText = () => {
        const currentSelection = editorState.getSelection();
        const isCollapsed = currentSelection.isCollapsed();

        let length = 0;

        if (!isCollapsed) {
            const currentContent = editorState.getCurrentContent();
            const startKey = currentSelection.getStartKey();
            const endKey = currentSelection.getEndKey();
            const startBlock = currentContent.getBlockForKey(startKey);
            const isStartAndEndBlockAreTheSame = startKey === endKey;
            const startBlockTextLength = startBlock.getLength();
            const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
            const endSelectedTextLength = currentSelection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);

            if (isStartAndEndBlockAreTheSame) {
                length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
            } else {
                let currentKey = startKey;

                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } else {
                        length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                    }

                    currentKey = currentContent.getKeyAfter(currentKey);
                }
            }
        }

        return length;
    };

    const _handleBeforeInput = (): Draft.DraftHandleValue => {
        if (maxLength !== undefined) {
            const currentContent = editorState.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('').length;
            const selectedTextLength = _getLengthOfSelectedText();

            const maxLengthExceeded = currentContentLength - selectedTextLength > maxLength - 1;
            if (onMaxLengthError !== undefined) {
                onMaxLengthError(maxLengthExceeded);
            }

            if (maxLengthExceeded) {
                return 'handled';
            }
        }
        return 'not-handled';
    };

    const _handlePastedText = (pastedText: string): Draft.DraftHandleValue => {
        if (maxLength !== undefined) {
            const currentContent = editorState.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('').length;
            const selectedTextLength = _getLengthOfSelectedText();

            if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
                return 'handled';
            }
        }
        return 'not-handled';
    };

    const _handleReturn = (e: any) => {
        if (e.shiftKey) {
            const newEditorState = RichUtils.insertSoftNewline(editorState);
            if (newEditorState !== editorState) {
                handleChange(newEditorState);
                return 'handled';
            }
            return 'not-handled';
        } else {
            const currentContent = editorState.getCurrentContent();
            const selection = editorState.getSelection();
            const textWithEntity = Modifier.splitBlock(currentContent, selection);
            setEditorState(EditorState.push(editorState, textWithEntity, 'split-block'));
            return 'handled';
        }
    };

    return (
        <div className={styled.TextEditor}>
            {linkModal.open && (
                <LinkModal linkText={linkModal.text} linkUrl={linkModal.url} onRemove={handleRemoveLink} onCancel={() => setLinkModal({ open: false })} onSubmit={handleAddLink} />
            )}
            {(title || !noOptions) && (
                <div className={styled.Header}>
                    {title &&
                        (typeof title === 'string' ? (
                            <p>
                                <strong>{title}</strong> {optionalTitle}
                            </p>
                        ) : (
                            title
                        ))}
                    {!noOptions && (
                        <div className={styled.Options}>
                            {blockOptions.map(blockOption => (
                                <OptionButton
                                    key={blockOption.type}
                                    active={currentBlock === blockOption.type}
                                    onClick={e => handleBlockChange(e, blockOption.type)}
                                    Icon={blockOption.icon}
                                />
                            ))}
                            <ColorButton onClick={toggleColor} currentStyle={currentStyle} />
                            {styleOptions.map(styleOption => (
                                <OptionButton
                                    key={styleOption.type}
                                    active={currentStyle.has(styleOption.type)}
                                    onClick={e => handleStyleChange(e, styleOption.type)}
                                    Icon={styleOption.icon}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
            <div className={styled.Editor}>
                <Editor
                    handleReturn={_handleReturn}
                    editorState={editorState}
                    customStyleFn={styles => {
                        const key = 'color-';
                        const color = styles.filter(value => !!value && value.startsWith(key)).first();
                        if (color) {
                            return {
                                color: color.replace(key, ''),
                            };
                        }
                        return {};
                    }}
                    onChange={handleChange}
                    handleBeforeInput={() => _handleBeforeInput()}
                    handlePastedText={text => _handlePastedText(text)}
                />
            </div>

            <div className={styled.ErrorBar}>
                {errorMsg && (
                    //Styling for error div isn't the best right now, but it is not widely used.
                    <div className={[styled.Validation, styled.NotValid].join(' ')}>{errorMsg}</div>
                )}
                {maxLength && (
                    <div className={[styled.Validation, styled.Counter, editorState.getCurrentContent().getPlainText().length > maxLength - 1 ? styled.NotValid : ''].join(' ')}>
                        {editorState.getCurrentContent().getPlainText().length}/{maxLength}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TextEditor;
