import CustomLawModel from './CustomLawModel';
import RevisionCommentModel from './RevisionCommentModel';
import { RevisionObjStatus } from './RevisionObjStatus';
import RevisionSubscriptionModel from './RevisionSubscriptionModel';
import RevisionMediaAttachmentModel from './RevisionMediaAttachmentModel';

interface CustomLawRevisionModel extends CustomLawModel {
    revisionStatus: RevisionObjStatus;
    comments: RevisionCommentModel[];
    mediaAttachments: RevisionMediaAttachmentModel[];
}

export const isCustomLawRevision = (obj: RevisionSubscriptionModel | CustomLawRevisionModel | any): obj is RevisionSubscriptionModel => {
    return (obj as CustomLawRevisionModel).customLawId !== undefined;
};

export default CustomLawRevisionModel;
