import LawListModel, { UNGROUPED_LAWLIST_ID, UNGROUPED_LAWLIST_NAME } from './LawListModel';
import _ from 'lodash';
import { sortLawLists } from '../utils/sorter';

interface LawListGroupFilter {
    lawListGroupId: number;
    name: string;
    lawLists: {
        lawListId: number;
        name: string;
        checked: boolean;
    }[];
}

export const convertToLawListGroupFilter = (lawLists: LawListModel[], selectedLawLists?: number[]): LawListGroupFilter[] => {
    return _.unionBy(
        _.flatMap(_.groupBy(lawLists, 'lawListGroup.name'), lawLists =>
            lawLists.map(lawList => {
                if (!lawList.lawListGroup) {
                    return {
                        lawListGroupId: UNGROUPED_LAWLIST_ID,
                        name: UNGROUPED_LAWLIST_NAME,
                        lawLists: lawLists.map(l => _.omit(l, 'lawListGroup')).map(l => ({ ...l, checked: selectedLawLists ? selectedLawLists.includes(l.lawListId) : false })),
                    };
                }
                return {
                    lawListGroupId: lawList.lawListGroup.lawListGroupId,
                    name: lawList.lawListGroup.name,
                    lawLists: lawLists.map(l => _.omit(l, 'lawListGroup')).map(l => ({ ...l, checked: selectedLawLists ? selectedLawLists.includes(l.lawListId) : false })),
                };
            }),
        ),
        'lawListGroupId',
    ).sort(sortLawLists);
};

export const getActiveLawListGroupFilter = (lawListGroupFilter: LawListGroupFilter[]): LawListGroupFilter[] => {
    const activeLawListGroupFilters: LawListGroupFilter[] = [];
    lawListGroupFilter.forEach(llgf => {
        if (llgf.lawLists.some(ll => ll.checked)) {
            const reducedLawListGroupFilter = llgf.lawLists.filter(ll => ll.checked);
            llgf.lawLists = reducedLawListGroupFilter;
            activeLawListGroupFilters.push(llgf);
        }
    });
    return activeLawListGroupFilters;
};

export default LawListGroupFilter;
