import React, { FC, useState, MouseEvent, useEffect } from 'react';
import AccordionItem from './AccordionItem/AccordionItem';

interface Props {
    id: number;
    data: {
        title: string;
        value: string | string[] | any;
        edit?: {
            onEdit?: () => void;
            disabled?: boolean;
            title?: string;
        };
    }[];
    initiallyOpenIndicies: number[];
    onToggle?: (index: number) => void;
}

const Accordion: FC<Props> = ({ id, data, initiallyOpenIndicies = [], onToggle }) => {
    const [openIndices, setOpenIndices] = useState<number[]>(initiallyOpenIndicies.length ? initiallyOpenIndicies : []);

    useEffect(() => {
        setOpenIndices(initiallyOpenIndicies.length ? initiallyOpenIndicies : []);
    }, [id, initiallyOpenIndicies]);

    const handleOpen = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, index: number): void => {
        e.preventDefault();

        setOpenIndices(indicies => {
            if (indicies.includes(index)) {
                return indicies.filter(i => i !== index);
            }
            return [...indicies, index];
        });
        onToggle && onToggle(index);
    };

    const handleEdit = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>, index: number): void => {
        e.stopPropagation();
        setOpenIndices(indices => {
            if (!indices.includes(index)) {
                return [...indices, index];
            }
            return indices;
        });

        const selected = data[index];
        if (selected && selected.edit && selected.edit.onEdit) {
            selected.edit.onEdit();
        }
    };

    return (
        <>
            {data.map((obj, index) => {
                const isOpen = openIndices.includes(index);
                return (
                    <AccordionItem
                        key={index}
                        title={obj.title}
                        open={isOpen}
                        index={index}
                        onToggleOpen={e => handleOpen(e, index)}
                        edit={
                            obj.edit
                                ? {
                                      ...obj.edit,
                                      onEdit: e => handleEdit(e, index),
                                  }
                                : undefined
                        }
                        content={obj.value}
                    />
                );
            })}
        </>
    );
};

export default Accordion;
