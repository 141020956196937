export const HOME = '/';

export const LAWS = '/laws';
export const LAWS_LISTS = `${LAWS}/lists`;
export const LAWS_LIBRARY = `${LAWS}/library`;

export const CHANGES = '/changes';
export const CHANGES_LIST = `${CHANGES}/list`;
export const CHANGES_ARCHIVE = `${CHANGES}/archive`;

export const REVISION = '/revision';
export const REVISION_UNFINISHED = `${REVISION}/unfinished`;
export const REVISION_FINISHED = `${REVISION}/finished`;
export const REVISION_CREATE = `${REVISION}/create`;
export const REVISION_EDIT = `${REVISION}/edit`;
export const REVISION_ALTER = `${REVISION}/alter`;

export const CONSULTANT = '/consultant';

export const INVOICING = '/invoicing';
export const MERGE_LAWS = '/merge';

export const SETTINGS = '/settings';
export const SETTINGS_USER = `${SETTINGS}/user`;
export const SETTINGS_USER_EMAIL = `${SETTINGS_USER}/email`;
export const SETTINGS_USER_INFO = `${SETTINGS_USER}/info`;

export const SETTINGS_COMPANY = `${SETTINGS}/company`;
export const SETTINGS_COMPANY_REVISIONS = `${SETTINGS_COMPANY}/revisions`;
export const SETTINGS_COMPANY_COLUMNS = `${SETTINGS_COMPANY}/columns`;
export const SETTINGS_COMPANY_USERS = `${SETTINGS_COMPANY}/users`;
export const SETTINGS_COMPANY_CUSTOMLAWS = `${SETTINGS_COMPANY}/customlaws`;
export const SETTINGS_COMPANY_LOGINS = `${SETTINGS_COMPANY}/logins`;
export const SETTINGS_COMPANY_KEYWORDS = `${SETTINGS_COMPANY}/keywords`;
export const SETTINGS_COMPANY_KEYWORDS_EDIT = `${SETTINGS_COMPANY_KEYWORDS}/edit`;
export const SETTINGS_COMPANY_LAWLISTS = `${SETTINGS_COMPANY}/lawlists`;
export const SETTINGS_COMPANY_LAWLISTS_EDIT = `${SETTINGS_COMPANY_LAWLISTS}/edit`;
export const SETTINGS_COMPANY_DETACHED_COPIES = `${SETTINGS_COMPANY}/copies`;
export const SETTINGS_COMPANY_DETACHED_COPIES_CREATE = `${SETTINGS_COMPANY_DETACHED_COPIES}/create`;
export const SETTINGS_COMPANY_DETACHED_COPIES_REMOVE = `${SETTINGS_COMPANY_DETACHED_COPIES}/remove`;

export const SETTINGS_CONTRACT = `${SETTINGS}/contract`;
export const SETTINGS_CONTRACT_AGREEMENT = `${SETTINGS_CONTRACT}/agreement`;
export const SETTINGS_CONTRACT_CONTRACT = `${SETTINGS_CONTRACT}/contract`;

export const HELP = '/help';
export const HELP_INSTRUCTIONS = `${HELP}/instructions`;
export const HELP_CONTACT = `${HELP}/contact`;

export const LOGIN = '/login';
export const CONTRACT = '/contract';
export const HARDLOCK = '/hardlock';
export const FORGOTPASSWORD = '/forgotpassword';
export const RESETPASSWORD = '/resetpassword';

export const COMPANY_LOGIN = '/login/company';
export const READER_LOGIN = '/route/reader';
export const READER_LOGIN_OLD = '/login.jsf';
export const TOKEN_LOGIN = '/login/token_login';
