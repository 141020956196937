export type RevisionObjStatus = 'ACCEPTED' | 'NOT_RELEVANT' | 'NOT_ACCEPTED' | 'OBSERVATION' | 'NO_VALUE' | 'ACCEPTED_AFTER_NOT_ACCEPTED' | 'ACCEPTED_AFTER_OBSERVATION';

export enum RevisionObjStatusEnum {
    'NO_VALUE' = 'revisionStatusTitleNoValue',
    'NOT_ACCEPTED' = 'revisionStatusTitleNotAccepted',
    'OBSERVATION' = 'revisionStatusTitleObservation',
    'ACCEPTED' = 'revisionStatusTitleAccepted',
    'NOT_RELEVANT' = 'revisionStatusTitleNotRelevant',
    'ACCEPTED_AFTER_NOT_ACCEPTED' = 'revisionStatusTitleAcceptedAfterNotAccepted',
    'ACCEPTED_AFTER_OBSERVATION' = 'revisionStatusTitleAcceptedAfterObservation',
}

const revisionStatusOrder = Object.keys(RevisionObjStatusEnum);

const finishedRevisionStatusOrder: RevisionObjStatus[] = [
    'NOT_ACCEPTED',
    'OBSERVATION',
    'NO_VALUE',
    'ACCEPTED_AFTER_NOT_ACCEPTED',
    'ACCEPTED_AFTER_OBSERVATION',
    'ACCEPTED',
    'NOT_RELEVANT',
];

export const sortFinsihedRevisionByRevisionStatus = (a: RevisionObjStatus, b: RevisionObjStatus): number => {
    return finishedRevisionStatusOrder.indexOf(a) - finishedRevisionStatusOrder.indexOf(b);
};

export const sortByRevisionStatus = (a: RevisionObjStatus, b: RevisionObjStatus): number => {
    return revisionStatusOrder.indexOf(a) - revisionStatusOrder.indexOf(b);
};

export const acceptableRevisionStatuses: RevisionObjStatus[] = ['ACCEPTED', 'NOT_RELEVANT', 'ACCEPTED_AFTER_OBSERVATION', 'ACCEPTED_AFTER_NOT_ACCEPTED'];

export const isAcceptableRevisionStatus = (revisionStatus: RevisionObjStatus | undefined): boolean => {
    if (!revisionStatus) {
        return false;
    }
    return acceptableRevisionStatuses.includes(revisionStatus);
};

export const allowedToUploadImageRevisionStatuses: RevisionObjStatus[] = ['NOT_ACCEPTED', 'OBSERVATION', 'ACCEPTED_AFTER_OBSERVATION', 'ACCEPTED_AFTER_NOT_ACCEPTED'];

export const isAllowedImageUploadRevisionStatus = (revisionStatus: RevisionObjStatus | undefined): boolean => {
    if (!revisionStatus) {
        return false;
    }
    return allowedToUploadImageRevisionStatuses.includes(revisionStatus);
};
