import React, { FC } from 'react';
import styled from './ResetAllPricesForGroupWarningModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { Trans, useTranslation } from 'react-i18next';
import statusWarningIcon from '../../../../assets/images/statusWarningIcon.svg';

interface Props {
    onAccept: () => void;
    onClose: () => void;
}

const ResetAllPricesForGroupWarningModal: FC<Props> = ({ onAccept, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal title={t('resetAllPricesForGroupWarningTitle')} subtitle={t('resetAllPricesForGroupWarningText')} className={styled.Modal}>
            <div className={styled.Content}>
                <img src={statusWarningIcon} alt="warning" className={styled.WarningIcon} />

                <h4>{t('resetAllPricesForGroupWarningTextQuestion')}</h4>

                <div className={styled.Options}>
                    <Button variant="Primary" onClick={onClose}>
                        {t('buttonNo')}
                    </Button>
                    <Button variant="Outline" onClick={onAccept}>
                        {t('buttonYes')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ResetAllPricesForGroupWarningModal;
