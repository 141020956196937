import React, { Dispatch, SetStateAction, createContext, FC, useState, useEffect, useMemo } from 'react';
import RevisionModel from '../models/RevisionModel';
import SubscriptionModel, { isSubscription } from '../models/SubscriptionModel';
import CustomLawModel from '../models/CustomLawModel';
import LawTypeFilter from '../models/LawTypeFilter';
import LawListGroupFilter from '../models/LawListGroupFilter';
import KeywordModel from '../models/KeywordModel';
import useAuth from '../hooks/useAuth';
import { Column, SortingRule } from 'react-table';
import LawCell from '../components/Table/LawCell/LawCell';
import _ from 'lodash';
import KeywordCell from '../components/Table/KeywordCell/KeywordCell';
import RevisionDateCell from '../components/Table/RevisionDateCell/RevisionDateCell';
import { useTranslation } from 'react-i18next';
import { columnKeys } from '../constants/ExcelColumnWidth';

interface State {
    revisions: RevisionModel[];

    subscriptions: (SubscriptionModel | CustomLawModel)[];
    filteredSubscriptions: State['subscriptions'];

    selectedIndex: number;

    lawTypeFilter: LawTypeFilter[];
    lawListGroupFilter: LawListGroupFilter[];
    customLawsSelected: boolean;
    selectedKeywords: (KeywordModel & { checked: boolean })[];
    visibleSubscriptionCount: number;
    hasCustomLaws: boolean;

    customFilterActive: boolean;

    loading: boolean;

    globalSearch: string;
    columnSearch: {
        id: string;
        value: string | undefined;
    }[];
    showColumnSearch: boolean;
    sorting: SortingRule<object>[];

    createRevision?: {
        name: string;
        userId: number;
        username: string;
    };

    columns: Array<Column<SubscriptionModel | CustomLawModel> & { Header: string; languageId?: string; id: columnKeys; visible: boolean; alwaysVisible?: boolean }>;
}

interface Context {
    state: State;
    setState: Dispatch<SetStateAction<State>>;
}

const intialState: State = {
    revisions: [],

    subscriptions: [],
    filteredSubscriptions: [],

    selectedIndex: -1,

    lawTypeFilter: [],
    lawListGroupFilter: [],
    customLawsSelected: false,
    selectedKeywords: [],
    visibleSubscriptionCount: 0,
    hasCustomLaws: false,

    customFilterActive: false,

    loading: false,

    globalSearch: '',
    columnSearch: [],
    showColumnSearch: true,
    sorting: [],

    createRevision: undefined,

    columns: [],
};

const UnfinishedRevisionContext = createContext<Context>({
    state: intialState,
    setState: () => null,
});

const UnfinishedRevisionContextProvider: FC = ({ children }) => {
    const { t, i18n } = useTranslation();
    const { company, getUserSettings, user, signedInAsUser } = useAuth();

    const columns = useMemo(() => {
        const columns: State['columns'] = [
            {
                id: 'law',
                Header: t('columnLaw'),
                languageId: 'columnLaw',
                accessor: row => `${row.name} ${isSubscription(row) ? row.subId : row.customLawEndDate}`,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.values.law;
                    const lawB = rowB.values.law;
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
                visible: true,
                width: 2,
                alwaysVisible: true,
                Cell: LawCell,
            },
            {
                id: 'latestRevisionDate',
                Header: t('columnLatestRevisionDate'),
                languageId: 'columnLatestRevisionDate',
                accessor: row => row.latestRevisionDate || 'Aldrig',
                visible: true,
                Cell: RevisionDateCell,
                sortInverted: true,
            },
            {
                id: 'description',
                Header: t('columnDescription'),
                languageId: 'columnDescription',
                accessor: 'description',
                disableSortBy: true,
                visible: false,
            },
            {
                id: 'text',
                Header: t('columnText'),
                languageId: 'columnText',
                accessor: 'text',
                disableSortBy: true,
                visible: true,
            },
        ];

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                columns.push({
                    id: ('customerText' + i) as columnKeys,
                    accessor: 'customerText' + i,
                    visible: true,
                    Header: title,
                    disableSortBy: true,
                });
            }
        }

        if (company?.hasLawLists) {
            columns.push({
                id: 'lawLists',
                visible: true,
                accessor: row => (row.lawLists ? row.lawLists.map(lawList => (lawList.lawListGroup ? lawList.lawListGroup.name + ': ' : '') + lawList.name) : ''),
                Cell: KeywordCell,
                Header: t('columnLawLists'),
                languageId: 'columnLawLists',
                disableSortBy: true,
            });
        }

        if (company?.hasKeyWords) {
            columns.push({
                id: 'keywords',
                visible: true,
                accessor: row => row.keywordIds.map((id: number) => _.find(company.keyWords, kw => kw.id === id)?.text),
                Cell: KeywordCell,
                Header: t('columnKeywords'),
                languageId: 'columnKeywords',
                disableSortBy: true,
            });
        }

        return columns;
    }, []);

    const [state, setState] = useState<State>({ ...intialState, columns });

    useEffect(() => {
        setState(s => ({
            ...s,
            columns: s.columns.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
        }));
    }, [i18n.language]);

    // Apply filtering
    useEffect(() => {
        setState(s => {
            const { lawTypeFilter, customLawsSelected, lawListGroupFilter, selectedKeywords, subscriptions } = s;

            // Apply lawGroup filtering
            const selectedLawGroupIds = lawTypeFilter.flatMap(lawType => lawType.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupId));
            let filteredSubscriptions = subscriptions.filter(obj => {
                if (isSubscription(obj)) {
                    return selectedLawGroupIds.includes(obj.lawGroupId);
                } else {
                    return customLawsSelected;
                }
            });

            // Apply lawList filtering
            const selectedLawListIds = lawListGroupFilter.flatMap(lawListGroup => lawListGroup.lawLists.filter(lawList => lawList.checked).map(lawList => lawList.lawListId));
            if (selectedLawListIds.length) {
                filteredSubscriptions = filteredSubscriptions.filter(f => selectedLawListIds.some(id => f.lawLists.find(lawList => lawList.lawListId === id)));
            }

            // Apply keyword filtering
            const selectedKeywordIds = selectedKeywords.filter(kw => kw.checked).map(kw => kw.id);
            if (selectedKeywordIds.length) {
                filteredSubscriptions = filteredSubscriptions.filter(f => selectedKeywordIds.some(id => f.keywordIds.includes(id)));
            }

            return {
                ...s,
                filteredSubscriptions,
            };
        });
    }, [state.subscriptions, state.lawTypeFilter, state.lawListGroupFilter, state.selectedKeywords, state.customLawsSelected]);

    return <UnfinishedRevisionContext.Provider value={{ state, setState }}>{children}</UnfinishedRevisionContext.Provider>;
};

export { UnfinishedRevisionContext, UnfinishedRevisionContextProvider };
