import React, { FC, useState, useEffect } from 'react';
import styled from './Instructions.module.css';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import InstructionsVideo from '../../../components/InstructionsVideo/InstructionsVideo';
import HelpService from '../../../services/HelpService';
import DynamicVideoContent from '../../../models/DynamicVideoContent';

const Instructions: FC = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<DynamicVideoContent[]>([]);
    const [currentVideoIdPlaying, setCurrentVideoIdPlaying] = useState<number>(-1);
    const fetchData = async () => {
        try {
            setLoading(true);
            const data = await HelpService().getDynamicHelpVideoContents();
            setData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleOnChange = (id: number) => {
        setCurrentVideoIdPlaying(id);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={styled.Instructions}>
            {loading && <LoadingSpinner />}
            {data.map(dvc => (
                <InstructionsVideo currentVideoIdPlaying={currentVideoIdPlaying} onChange={handleOnChange} key={dvc.id} data={dvc} />
            ))}
        </div>
    );
};

export default Instructions;
