import React, { FC } from 'react';
import closeIcon from '../../assets/images/closeIcon.svg';
import linkIcon from '../../assets/images/linkIcon.svg';
import styled from './FullView.module.css';
import MonitorButton from '../MonitorButton/MonitorButton';
import DocumentService from '../../services/DocumentService';
import useAuth from '../../hooks/useAuth';
import LawSourceModel from '../../models/LawSourceModel';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
import useMobileDevice from '../../hooks/useMobileDevice';
import LawStatusIcon from '../Mobile/StatusIcon/LawStatusIcon/LawStatusIcon';

interface Props {
    lawSourceId?: number;
    title: string;
    subId: string;
    isCustomLaw: boolean;
    isExpired?: boolean;
    lawSource?: LawSourceModel;
    file?: {
        id: number;
        name: string;
        isPrimary: boolean;
    };
    open: boolean;
    onClose: () => void;
    monitorButton?: {
        onMonitorChange?: () => void;
        isMonitored: boolean;
        disabled?: boolean;
        title?: string;
    };
    onRevertChange?: () => void;

    headerChildren?: any;
    hiddenIdBar?: string;
    revisionStatus?: string;
}

const FullView: FC<Props> = ({
    lawSourceId,
    title,
    subId,
    isCustomLaw,
    isExpired,
    lawSource,
    open,
    onClose,
    onRevertChange,
    monitorButton,
    headerChildren,
    children,
    hiddenIdBar,
    revisionStatus,
}) => {
    const { t } = useTranslation();
    const { isAboveUser, isGroupCompany, isUserOrAbove, isAboveSuperUser } = useAuth();
    const isMobileDevice = useMobileDevice();

    const handleDownload = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (lawSourceId && lawSource) {
            if (lawSource.file) {
                DocumentService().getLawDocument(lawSourceId, lawSource.primary || true, isCustomLaw, lawSource.fileName);
            } else {
                event.preventDefault();
                window.open(lawSource.externalUrl, '_blank');
            }
        }
    };

    return (
        <div className={[styled.FullView, open ? styled.Open : ''].join(' ')}>
            {!isMobileDevice && (
                <div className={styled.CloseBar}>
                    <img src={closeIcon} alt="Close" onClick={onClose} />
                    {/*Hidden field for Admin and Consultants only.*/}
                    {hiddenIdBar && isAboveSuperUser() && <div className={styled.HiddenIdBar}>{hiddenIdBar}</div>}
                </div>
            )}

            {headerChildren}

            <div className={styled.Header}>
                <div className={styled.Title}>
                    <div
                        className={[styled.LawName, lawSource ? styled.Link : ''].join(' ')}
                        title={lawSource?.file ? lawSource?.fileName : lawSource?.externalUrl}
                        onClick={handleDownload}
                    >
                        {title}
                        {lawSource && <img src={linkIcon} className={styled.LinkIcon} alt="Link" />}
                    </div>
                    {subId && (
                        <div className={styled.SubId}>
                            {isCustomLaw ? t('customLawValidUntil') : t('lawSubId')} <span className={isCustomLaw && isExpired ? styled.Expired : ''}>{subId}</span>
                            {isMobileDevice && revisionStatus && <div className={styled.StatusIcon}>{LawStatusIcon(revisionStatus)}</div>}
                        </div>
                    )}
                </div>
                {monitorButton && (
                    <MonitorButton
                        checked={monitorButton.isMonitored}
                        disabled={!isAboveUser() || isCustomLaw || monitorButton.disabled || isGroupCompany()}
                        onChange={monitorButton.onMonitorChange}
                        title={monitorButton.title}
                    />
                )}
                {onRevertChange !== undefined && isUserOrAbove() && (
                    <Button variant="Primary" onClick={onRevertChange}>
                        {t('buttonRevert')}
                    </Button>
                )}
            </div>

            {children}
        </div>
    );
};

export default FullView;
