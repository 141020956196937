import React, { FC } from 'react';
import styled from './EditDetachedCopiesToaster.module.css';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';

interface Props {
    selectedCount?: number;
    isCreateMode: boolean;
    hasSelected: boolean;
    fullViewOpen: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const EditDetachedCopiesToaster: FC<Props> = ({ selectedCount, isCreateMode, hasSelected, fullViewOpen, onCancel, onConfirm }) => {
    const { t } = useTranslation();

    return (
        <div className={[styled.EditDetachedCopiesToaster, fullViewOpen ? styled.FullViewOpen : ''].join(' ')}>
            <div className={styled.Text}>
                <h6>{isCreateMode ? t('createDetachedCopiesToasterTitle') : t('editDetachedCopiesToasterTitle')}</h6>
                <p>{isCreateMode ? t('createDetachedCopiesToasterText') : t('editDetachedCopiesToasterText', { selectedCount })}</p>
            </div>
            <div className={styled.Buttons}>
                <Button variant="Primary" onClick={onCancel}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Light" onClick={onConfirm} disabled={!hasSelected}>
                    {isCreateMode ? t('buttonSave') : t('buttonRemove')}
                </Button>
            </div>
        </div>
    );
};

export default EditDetachedCopiesToaster;
