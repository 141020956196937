import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from './ReaderLogin.module.css';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Toggle from '../../../../components/Toggle/Toggle';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import useAuth from '../../../../hooks/useAuth';
import _ from 'lodash';
import copyIcon from '../../../../assets/images/copyIcon.svg';
import copy from 'copy-to-clipboard';
import { useHistory, Link } from 'react-router-dom';
import ReaderLoginService from '../../../../services/ReaderLoginService';
import ReaderLoginModel from '../../../../models/ReaderLoginModel';
import LawListDropdown from '../../../../components/LawListDropdown/LawListDropdown';
import LawListGroupFilter, { convertToLawListGroupFilter } from '../../../../models/LawListGroupFilter';
import SubscriptionService from '../../../../services/SubscriptionService';
import { sortLawTypes, LawTypeEnum } from '../../../../models/LawType';
import RemoveReaderLoginModal from '../../../../components/Modal/RemoveReaderLoginModal/RemoveReaderLoginModal';
import { UNGROUPED_LAWLIST_ID } from '../../../../models/LawListModel';
import { HELP_CONTACT } from '../../../../constants/Routes';
import { useTranslation } from 'react-i18next';

const ReaderLogin: FC = () => {
    const { t, i18n } = useTranslation();

    const LANGUAGES = [
        { id: 0, text: t('languageSwedish'), value: 'sv' },
        { id: 1, text: t('languageEnglish'), value: 'en' },
    ];

    const history = useHistory();
    const { company, isAboveUser, isAboveSuperUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(true);
    const [data, setData] = useState<ReaderLoginModel[]>([]);
    const [lawTypes, setLawTypes] = useState<LawListGroupFilter[]>([]);
    const [removeModal, setRemoveModal] = useState<{ open: boolean; index?: number }>({ open: false });
    const [hasChanges, setHasChanges] = useState<number[]>([]);

    const columns = useMemo(() => {
        const columns = [
            { id: 0, text: t('columnText'), value: 'bffColumnVisible' },
            { id: 1, text: t('columnDescription'), value: 'descriptionColumnVisible' },
            { id: 2, text: t('columnLatestRevisionDate'), value: 'latestRevisionDateColumnVisible' },
        ];

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                columns.push({ id: 3 + i, text: title, value: `column${i}Visible` });
            }
        }

        if (company?.hasKeyWords) {
            columns.push({ id: 9, text: t('columnKeywords'), value: 'keywordColumnVisible' });
        }

        if (company?.hasLawLists) {
            columns.push({ id: 10, text: t('columnLawLists'), value: 'lawListColumnVisible' });
        }

        if (company?.hasRevisionQuestions) {
            columns.push({ id: 11, text: t('columnRevisionQuestions'), value: 'revisionQuestionColumnVisible' });
        }

        return columns;
    }, [i18n.language]);

    const keywords = useMemo(
        () =>
            company?.hasKeyWords
                ? company.keyWords.map(kw => ({
                      id: kw.id,
                      text: kw.text,
                      value: kw.id,
                  }))
                : [],
        [],
    );

    const lawListGroups: LawListGroupFilter[] = useMemo(() => (company?.hasLawLists ? convertToLawListGroupFilter(company.lawLists) : []), []);

    const fetchData = async () => {
        try {
            setLoading(true);

            const data = await ReaderLoginService().getReaderLogins(company?.id || -1);
            const lawTypes = await SubscriptionService().getSubscribedLawTypes(company?.id || -1);
            const active = await ReaderLoginService().getReaderLoginActive(company?.id || -1);
            const lawTypeFilter: LawListGroupFilter[] = lawTypes
                .sort((a, b) => sortLawTypes(a.name, b.name))
                .map((lt, i) => ({
                    lawListGroupId: i + 1,
                    name: LawTypeEnum[lt.name],
                    lawLists: lt.lawGroups.map(lg => ({
                        lawListId: lg.lawGroupId,
                        name: lg.lawGroupName,
                        checked: false,
                    })),
                }));
            lawTypeFilter.push({ lawListGroupId: UNGROUPED_LAWLIST_ID, name: '', lawLists: [{ lawListId: UNGROUPED_LAWLIST_ID, name: 'lawTypeCustomLaw', checked: false }] });

            setActive(active);
            setData(data);
            setLawTypes(lawTypeFilter);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleAddLogin = async () => {
        if (company) {
            try {
                setLoading(true);
                const newLogin = await ReaderLoginService().createReaderLogin(company.id);
                setData(s => [...s, newLogin]);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleChangeDescription = (description: string, index: number) => {
        setData(s =>
            s.map((obj, i) => {
                if (i === index) {
                    return {
                        ...obj,
                        description,
                    };
                }
                return obj;
            }),
        );
        setHasChanges(s => Array.from(new Set([...s, index])));
    };

    const handleChangeLawType = (lawTypeId: number, index: number) => {
        const lawType = lawTypes.find(lt => lt.lawListGroupId === lawTypeId);
        if (lawType) {
            const lawGroupIds = lawType.lawLists.map(ll => ll.lawListId);
            setData(s =>
                s.map((obj, i) => {
                    if (i === index) {
                        return {
                            ...obj,
                            lawGroupIds: _.every(lawGroupIds, id => obj.lawGroupIds.includes(id))
                                ? obj.lawGroupIds.filter(id => !lawGroupIds.includes(id))
                                : [...obj.lawGroupIds, ...lawGroupIds.filter(id => !obj.lawGroupIds.includes(id))],
                        };
                    }
                    return obj;
                }),
            );
            setHasChanges(s => Array.from(new Set([...s, index])));
        }
    };

    const handleChangeLawGroup = (lawGroupId: number, index: number) => {
        if (lawGroupId === UNGROUPED_LAWLIST_ID) {
            // CustomLaw
            setData(s =>
                s.map((obj, i) => {
                    if (i === index) {
                        return {
                            ...obj,
                            customLawsSelected: !obj.customLawsSelected,
                        };
                    }
                    return obj;
                }),
            );
            setHasChanges(s => Array.from(new Set([...s, index])));
        } else {
            // LawGroups
            setData(s =>
                s.map((obj, i) => {
                    if (i === index) {
                        return {
                            ...obj,
                            lawGroupIds: obj.lawGroupIds.includes(lawGroupId) ? obj.lawGroupIds.filter(id => id !== lawGroupId) : [...obj.lawGroupIds, lawGroupId],
                        };
                    }
                    return obj;
                }),
            );
            setHasChanges(s => Array.from(new Set([...s, index])));
        }
    };

    const handleChangeKeyword = (id: number, index: number) => {
        const keyword = keywords.find(kw => kw.id === id);
        if (keyword) {
            setData(s =>
                s.map((obj, i) => {
                    if (i === index) {
                        return {
                            ...obj,
                            keywordIds: obj.keywordIds.includes(keyword.id) ? obj.keywordIds.filter(kw => kw !== keyword.id) : [...obj.keywordIds, keyword.id],
                        };
                    }
                    return obj;
                }),
            );
            setHasChanges(s => Array.from(new Set([...s, index])));
        }
    };

    const handleChangeLawListGroup = (lawListGroupId: number, index: number) => {
        const lawListGroup = lawListGroups.find(llg => llg.lawListGroupId === lawListGroupId);
        if (lawListGroup) {
            const lawListIds = lawListGroup.lawLists.map(ll => ll.lawListId);
            setData(s =>
                s.map((obj, i) => {
                    if (i === index) {
                        return {
                            ...obj,
                            lawListIds: _.every(lawListIds, id => obj.lawListIds.includes(id))
                                ? obj.lawListIds.filter(id => !lawListIds.includes(id))
                                : [...obj.lawListIds, ...lawListIds.filter(id => !obj.lawListIds.includes(id))],
                        };
                    }
                    return obj;
                }),
            );
            setHasChanges(s => Array.from(new Set([...s, index])));
        }
    };

    const handleChangeLawList = (lawListId: number, index: number) => {
        setData(s =>
            s.map((obj, i) => {
                if (i === index) {
                    return {
                        ...obj,
                        lawListIds: obj.lawListIds.includes(lawListId) ? obj.lawListIds.filter(id => id !== lawListId) : [...obj.lawListIds, lawListId],
                    };
                }
                return obj;
            }),
        );
        setHasChanges(s => Array.from(new Set([...s, index])));
    };

    const handleChangeColumns = (id: number, index: number) => {
        const column = columns.find(col => col.id === id);
        if (column) {
            setData(s =>
                s.map((obj, i) => {
                    if (i === index) {
                        return {
                            ...obj,
                            [column.value]: !_.get(obj, column.value),
                        };
                    }
                    return obj;
                }),
            );
            setHasChanges(s => Array.from(new Set([...s, index])));
        }
    };

    const handleChangeLanguage = (id: number, index: number) => {
        const language = LANGUAGES.find(lang => lang.id === id);
        if (language) {
            setData(s =>
                s.map((obj, i) => {
                    if (i === index) {
                        return {
                            ...obj,
                            languageId: language.value,
                        };
                    }
                    return obj;
                }),
            );
            setHasChanges(s => Array.from(new Set([...s, index])));
        }
    };

    const handleRevisionToggle = (index: number) => {
        setData(s =>
            s.map((obj, i) => {
                if (i === index) {
                    return {
                        ...obj,
                        hideRevisions: !obj.hideRevisions,
                    };
                }
                return obj;
            }),
        );
        setHasChanges(s => Array.from(new Set([...s, index])));
    };

    const handleOpenRemoveModal = (index: number) => {
        setRemoveModal({ open: true, index });
    };

    const handleRemove = async () => {
        const index = removeModal.index;
        if (index !== undefined) {
            try {
                setLoading(true);
                setRemoveModal({ open: false });
                const obj = data[index];
                if (obj.id !== null && obj.id > -1) {
                    await ReaderLoginService().deleteReaderLogin(obj.id);
                }
                setData(s => s.filter((_, i) => i !== index));
                setHasChanges(s => s.filter(i => i !== index));
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleSave = async (index: number) => {
        try {
            setLoading(true);
            await ReaderLoginService().updateReaderLogin(data[index]);
            setHasChanges(s => s.filter(i => i !== index));
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!isAboveUser()) {
            history.goBack();
        }

        fetchData();
    }, []);

    const handleActivateLogin = async () => {
        try {
            setLoading(true);
            if (!active) {
                await ReaderLoginService().activateReaderLogin(company?.id || -1);
                setActive(true);
            } else {
                await ReaderLoginService().deactivateReaderLogin(company?.id || -1);
                setActive(false);
            }
            const data = await ReaderLoginService().getReaderLogins(company?.id || -1);
            setData(data);
            setHasChanges([]);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const consultantText = <p>{t('companyReaderLoginConsultantText')}</p>;

    const superuserText = (
        <>
            <p>{t('companyReaderLoginSuperuserText1')}</p>

            <p>
                {t('companyReaderLoginSuperuserText2')} <Link to={{ pathname: HELP_CONTACT, state: { subject: 'INFORMATION' } }}>{t('contactUs')}</Link>.
            </p>

            <p>{t('companyReaderLoginSuperuserText3')}</p>
        </>
    );

    return (
        <div className={styled.ReadersLogins}>
            {loading && <LoadingSpinner />}
            <div className={styled.Container}>
                <h3 className={styled.Title}>{t('settingsMenuCompanyLogins')}</h3>

                <div className={styled.Information}>
                    <h6>{t('companyReaderLoginTitle')}</h6>
                    {isAboveSuperUser() ? consultantText : superuserText}
                    {isAboveSuperUser() && (
                        <>
                            <div className={styled.ToggleContainer}>
                                <p>{t('companyReaderLoginToggle')}</p>
                                <Toggle checked={active} onChange={handleActivateLogin} className={styled.Toggle} />
                            </div>
                            <div className={styled.Buttons}>
                                <Button variant="Primary" onClick={handleAddLogin} disabled={!active}>
                                    {t('buttonCreateNew')}
                                </Button>
                            </div>
                        </>
                    )}
                </div>

                {data.map((login, index) => (
                    <div className={styled.LoginSectionWrapper} key={index}>
                        <div key={index} className={[styled.LoginSection, !active ? styled.Disabled : ''].join(' ')}>
                            <div className={styled.TopSection}>
                                <h3>
                                    {t('readerLogin')} {index + 1}
                                </h3>
                                <Input value={login.description || ''} onChange={value => handleChangeDescription(value, index)} placeholder="Namn på läsarinlogg..." />
                                <div className={styled.FlexWrapper}>
                                    <div>
                                        <p className={styled.DropdownTitle}>Standardfilter</p>
                                        <LawListDropdown
                                            title="Standardfilter"
                                            lawListGroupFilter={lawTypes.map(lt => ({
                                                ...lt,
                                                name: t(lt.name),
                                                lawLists: lt.lawLists.map(ll => ({
                                                    ...ll,
                                                    name: ll.lawListId === UNGROUPED_LAWLIST_ID ? t(ll.name) : ll.name,
                                                    checked: ll.lawListId === UNGROUPED_LAWLIST_ID ? data[index].customLawsSelected : login.lawGroupIds?.includes(ll.lawListId),
                                                })),
                                            }))}
                                            className={styled.LawListDropdown}
                                            onLawListFilterChange={lawGroupId => handleChangeLawGroup(lawGroupId, index)}
                                            onLawListGroupFilterChange={lawTypeId => handleChangeLawType(lawTypeId, index)}
                                        />
                                    </div>
                                    {company?.hasLawLists && lawListGroups && login.lawListIds && (
                                        <div>
                                            <p className={styled.DropdownTitle}>{t('filterbarLawLists')}</p>
                                            <LawListDropdown
                                                title={t('filterbarLawLists')}
                                                lawListGroupFilter={lawListGroups.map(llg => ({
                                                    ...llg,
                                                    lawLists: llg.lawLists.map(ll => ({ ...ll, checked: login.lawListIds.includes(ll.lawListId) })),
                                                }))}
                                                className={styled.LawListDropdown}
                                                onLawListFilterChange={lawListId => handleChangeLawList(lawListId, index)}
                                                onLawListGroupFilterChange={lawListGroupId => handleChangeLawListGroup(lawListGroupId, index)}
                                            />
                                        </div>
                                    )}
                                    {company?.hasKeyWords && login.keywordIds && (
                                        <div>
                                            <p className={styled.DropdownTitle}>{t('filterbarKeywords')}</p>
                                            <Dropdown
                                                title={t('filterbarKeywords').toString()}
                                                content={keywords.map(kw => ({ ...kw, checked: login.keywordIds?.includes(kw.id) }))}
                                                onChange={id => handleChangeKeyword(id, index)}
                                                className={styled.List}
                                                dropdownWidth={'100%'}
                                                multiSelect
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <p className={styled.DropdownTitle}>{t('allColumns')}</p>
                                        <Dropdown
                                            title={t('allColumns').toString()}
                                            content={columns.map(col => ({ ...col, checked: _.get(login, col.value) }))}
                                            onChange={id => handleChangeColumns(id, index)}
                                            dropdownWidth={'100%'}
                                            multiSelect
                                        />
                                    </div>
                                </div>
                                <div className={styled.LanguageDropdown}>
                                    <p className={styled.DropdownTitle}>{t('inputFieldLanguage')}</p>
                                    <div className={styled.InputRow}>
                                        <Dropdown
                                            title={LANGUAGES.find(lang => lang.value === login.languageId)?.text || ''}
                                            content={LANGUAGES}
                                            onChange={id => handleChangeLanguage(id, index)}
                                        />
                                    </div>
                                </div>
                                <div className={styled.ToggleContainer}>
                                    <p>{t('readerLoginBlockRevisions')}</p>
                                    <Toggle checked={login.hideRevisions} className={styled.Toggle} onChange={() => handleRevisionToggle(index)} />
                                </div>
                            </div>

                            <p className={styled.LoginLink}>
                                <img src={copyIcon} alt="Link" tabIndex={0} onClick={() => copy(login.url)} title="Kopiera länk" />
                                {login.url}
                            </p>

                            <div className={styled.SectionFooter}>
                                <Button variant="Outline" onClick={() => handleOpenRemoveModal(index)}>
                                    {t('buttonRemoveReaderLogin')}
                                </Button>
                                <Button variant="Primary" onClick={() => handleSave(index)} disabled={!hasChanges.includes(index)}>
                                    {t('buttonSave')}
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {removeModal.open && removeModal.index !== undefined && <RemoveReaderLoginModal onCancel={() => setRemoveModal({ open: false })} onConfirm={handleRemove} />}
        </div>
    );
};

export default ReaderLogin;
