import React, { FC, useMemo } from 'react';
import Modal from '../Modal';
import AcknowledgeEditView from '../../AcknowledgeEditView/AcknowledgeEditView';
import SubscriptionModel from '../../../models/SubscriptionModel';
import styled from './EditLatestSubscriptionModal.module.css';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface Props {
    subscription: SubscriptionModel;
    customColumns: {
        id: number; // index
        accessor: string;
        Header: string;
        data: string;
        changed?: boolean;
    }[];
    onClose: () => void;
    onSave: (subscriptionId: number, subscription?: SubscriptionModel, keywordIds?: number[], lawListIds?: number[]) => void;
}

const EditLatestSubscriptionModal: FC<Props> = ({ subscription, customColumns, onClose, onSave }) => {
    const { t } = useTranslation();
    const editColumns = useMemo(() => customColumns.map(cc => ({ ...cc, data: _.get(subscription, cc.accessor) })), [customColumns]);
    return (
        <Modal title="" className={styled.EditLatestSubscriptionModal}>
            <div className={styled.Content}>
                <AcknowledgeEditView data={subscription} customColumns={editColumns} headerTitle={t('editLatestSubscriptionModalHeaderTitle')} onClose={onClose} onSave={onSave} />
            </div>
        </Modal>
    );
};

export default EditLatestSubscriptionModal;
