import React, { FC, useState } from 'react';
import styled from './RevisionQuestionItem.module.css';
import Checkbox from '../Checkbox/Checkbox';
import editIcon from '../../assets/images/editIcon.svg';
import RevisionQuestion from '../../models/RevisionQuestion';
import useAuth from '../../hooks/useAuth';
import RevisionQuestionService from '../../services/RevisionQuestionService';
import errorIcon from '../../assets/images/errorIcon.svg';
import saveIcon from '../../assets/images/saveIcon.svg';
import removeIcon from '../../assets/images/removeIcon.svg';

interface Props {
    data: RevisionQuestion;
    custom?: boolean;
    checked: boolean;
    onSave?: (data: RevisionQuestion) => void;
    onCheckedChange: (questionId: number) => void;
    onRemove?: (revisionQuestionId: number) => void;
}

const RevisionQuestionItem: FC<Props> = ({ data, custom, checked, onSave, onCheckedChange, onRemove }) => {
    const { company } = useAuth();

    const [editMode, setEditMode] = useState(false);
    const [questionValue, setQuestionValue] = useState(data.question);

    const handleSave = async () => {
        if (company && onSave && questionValue !== data.question) {
            const updatedRevisionQuestion = await RevisionQuestionService().updateCustomRevisionQuestion({ ...data, question: questionValue });
            onSave(updatedRevisionQuestion);
        }
        setEditMode(false);
    };

    return (
        <div className={styled.RevisionQuestionItem}>
            <div className={styled.Checkbox}>
                <Checkbox big checked={checked} onChange={() => onCheckedChange(data.id)} />
            </div>
            {editMode && custom ? (
                <textarea
                    rows={2}
                    autoFocus
                    maxLength={500}
                    value={questionValue}
                    onChange={e => setQuestionValue(e.target.value)}
                    onFocus={e => {
                        const val = e.target.value;
                        e.target.value = '';
                        e.target.value = val;
                    }}
                />
            ) : (
                    <p className={[styled.Question, !checked ? styled.Disabled : ''].join(' ')}>{data.question}</p>
                )}
            {custom && (
                <>
                    {editMode && onRemove && <img src={removeIcon} alt="Remove" onClick={() => onRemove(data.id)} />}
                    {editMode && <img src={saveIcon} alt="Save" onClick={handleSave} />}
                    {!editMode && <img src={editIcon} alt="Edit" onClick={() => setEditMode(true)} />}
                </>
            )}
            {data.changedNotification && <img src={errorIcon} alt="Changed" className={styled.NotificationIcon} />}
        </div>
    );
};

export default RevisionQuestionItem;
