import BaseLawModel from './BaseLawModel';
import LawListModel from './LawListModel';
import SubscriptionModel from './SubscriptionModel';
import LawModel from './LawModel';
import RevisionQuestion from './RevisionQuestion';

interface CustomLawModel extends BaseLawModel {
    customLawId: number;
    version: number;
    companyId: number;
    customLawEndDate: string;
    expired: boolean;
    latestRevisionDate: string;
    hasRevisionWarning: boolean;
    keywordIds: number[];
    lawLists: LawListModel[];

    customRevisionQuestions: RevisionQuestion[];
}

export interface EditCustomLawModel {
    customLawId?: number;
    companyId: number;
    name: string;
    newCustomLawEndDate: string;
    text: string;
    customerText1?: string;
    customerText2?: string;
    customerText3?: string;
    customerText4?: string;
    customerText5?: string;
    keywordIds: number[];
    lawListIds: number[];

    removeFile: boolean;
}

export const isCustomLaw = (obj: CustomLawModel | SubscriptionModel | LawModel): obj is CustomLawModel => {
    return (obj as CustomLawModel).customLawId !== undefined;
};

export default CustomLawModel;
