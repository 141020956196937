import React, { FC } from 'react';
import styled from './InvoiceToaster.module.css';
import { useTranslation, Trans } from 'react-i18next';
import Button from '../../../components/Button/Button';

interface Props {
    fullViewOpen: boolean;
    numberOfCompaniesMarked: number;
    onExport: () => void;
    onSetAsNotInvoiced: () => void;
    onSetAsInvoiced: () => void;
}

const InvoiceToaster: FC<Props> = ({ fullViewOpen, numberOfCompaniesMarked, onExport, onSetAsNotInvoiced, onSetAsInvoiced }) => {
    const { t } = useTranslation();
    return (
        <div className={[styled.InvoiceToaster, fullViewOpen ? styled.FullViewOpen : ''].join(' ')}>
            <div className={styled.Text}>
                <h6> {t('titleToasterInvoicing')}</h6>
                <Trans i18nKey="textToasterInvoicing" values={{ numberOfCompaniesMarked }} />
            </div>
            <div className={styled.Options}>
                <Button variant="Secondary" onClick={onExport}>
                    {t('buttonExport')}
                </Button>
                <Button variant="Secondary" onClick={onSetAsNotInvoiced}>
                    {t('buttonNotInvoiced')}
                </Button>
                <Button variant="Light" onClick={onSetAsInvoiced}>
                    {t('buttonInvoiced')}
                </Button>
            </div>
        </div>
    );
};

export default InvoiceToaster;
