import React from 'react';
import StatusOk from '../../../../assets/images/statusOkMobileIcon.svg';
import StatusNotOk from '../../../../assets/images/statusNotOkMobileIcon.svg';
import StatusObservation from '../../../../assets/images/statusObservationIcon.svg';
import StatusNotRelevant from '../../../../assets/images/statusNotRelevantIcon.svg';
import styled from './LawStatusIcon.module.css';

const LawStatusIcon = (status: string) => {
    if (status === 'ACCEPTED' || status === 'ACCEPTED_AFTER_OBSERVATION' || status === 'ACCEPTED_AFTER_NOT_ACCEPTED') {
        return <img className={styled.StatusIcon} src={StatusOk} />;
    } else if (status === 'NOT_ACCEPTED') {
        return <img className={styled.StatusIcon} src={StatusNotOk} />;
    } else if (status === 'OBSERVATION') {
        return <img className={styled.StatusIcon} src={StatusObservation} />;
    } else if (status === 'NOT_RELEVANT') {
        return <img className={styled.StatusIcon} src={StatusNotRelevant} />;
    } else if (status === 'NO_VALUE') {
        return <img className={styled.NoStatus} src={undefined} />;
    }
};

export default LawStatusIcon;
