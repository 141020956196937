import React, { FC } from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import styled from './SendExternalMailConfirmModal.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    onConfirm: () => void;
}

const SendExternalMailConfirmModal: FC<Props> = ({ onConfirm }) => {
    const { t } = useTranslation();

    return (
        <Modal title={t('changeListMailToExternalModalTitle')} className={styled.SendExternalMailConfirmModal}>
            <div className={styled.Content}>
                <p>{t('changeListMailToExternalModalText1')}</p>
                <p>{t('changeListMailToExternalModalText2')}</p>

                <div className={styled.Buttons}>
                    <Button variant="Primary" onClick={onConfirm}>
                        {t('buttonOk')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default SendExternalMailConfirmModal;
