import SubscriptionModel from './SubscriptionModel';
import CustomLawModel from './CustomLawModel';
import _ from 'lodash';
import RevisionSubscriptionModel from './RevisionSubscriptionModel';
import CustomLawRevisionModel from './CustomLawRevisionModel';

interface UserSettings {
    id?: number;
    bffColumnVisible: boolean;
    delegateColumnVisible: boolean;
    descColumnVisible: boolean;
    dateColumnVisible: boolean;
    column1Visible: boolean;
    column2Visible: boolean;
    column3Visible: boolean;
    column4Visible: boolean;
    column5Visible: boolean;
    keywordColumnVisible: boolean;
    lawListColumnVisible: boolean;
    revisionQuestionColumnVisible: boolean;
    latestRevisionDateColumnVisible: boolean;
    customLawsSelected: boolean;
    useCustomEmailFilter: boolean;
    selectedKeywords: number[];
    selectedLawGroups: number[];
    selectedLawLists: number[];
}

// This is used because old application uses empty list for selecting all lawGroups.
export const getUserSettingsSelectedLawGroupsFallback = (
    userSettingsSelectedLawGroups: number[],
    data: (SubscriptionModel | CustomLawModel | RevisionSubscriptionModel | CustomLawRevisionModel)[],
): number[] => {
    if (userSettingsSelectedLawGroups.length < 1) {
        return _.uniq(data.map(obj => obj.lawGroupId).filter(id => id));
    } else {
        return userSettingsSelectedLawGroups;
    }
};

export default UserSettings;
