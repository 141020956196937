import React, { FC, useEffect, useState } from 'react';
import styled from './Agreement.module.css';
import useAuth from '../../../../hooks/useAuth';
import { LawTypeEnum } from '../../../../models/LawType';
import { HELP_CONTACT } from '../../../../constants/Routes';
import { Link } from 'react-router-dom';
import UserService from '../../../../services/UserService';
import SubscriptionService from '../../../../services/SubscriptionService';
import LoadingSpinnerSmall from '../../../../components/LoadingSpinner/LoadingSpinnerSmall';
import { useTranslation, Trans } from 'react-i18next';

const Agreement: FC = () => {
    const { t } = useTranslation();
    const { company } = useAuth();
    const [wrapper, setWrapper] = useState<{ subs: number; user: number }>({ subs: 0, user: 0 });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (company) {
            setLoading(true);
            UserService()
                .getCompanyUsersCount(company?.id)
                .then(count => {
                    setWrapper(s => ({ ...s, user: count }));
                });
            SubscriptionService()
                .getUniqueSubscriptionCount(company?.id)
                .then(count => {
                    setWrapper(s => ({ ...s, subs: count }));
                    setLoading(false);
                });
        }
    }, []);

    const lawTypes = company?.lawTypes
        .map(lt => {
            return t(LawTypeEnum[lt]);
        })
        .join(', ');

    return (
        <div className={styled.Agreement}>
            <div>
                <h4>{t('settingsMenuContractAgreement')}</h4>
                <div className={styled.Info}>
                    <h4>{t('agreementTitle')}</h4>
                    <p>
                        <Trans i18nKey="agreementText" components={[<Link key={0} to={{ pathname: HELP_CONTACT, state: { subject: 'INFORMATION' } }} />]} />
                    </p>
                </div>
                <div className={styled.AgreementTable}>
                    <h4>{company?.name}</h4>
                    <table>
                        <tr>
                            <td>{t('agreementTableLawTypes')}</td>
                            <td>
                                <strong>{lawTypes}</strong>
                            </td>
                            <td>{t('agreementTableLawTypesInfo')}</td>
                        </tr>
                        <tr>
                            <td>{t('agreementTableLawCount')}</td>
                            <td>
                                {loading && <LoadingSpinnerSmall />} {!loading && <strong>{wrapper.subs}</strong>}
                            </td>
                            <td>{t('agreementTableLawCountInfo')}</td>
                        </tr>
                        <tr>
                            <td>{t('agreementTableUserCount')}</td>
                            <td>
                                <strong>{wrapper.user}</strong>
                            </td>
                            <td>{t('agreementTableUserCountInfo')}</td>
                        </tr>
                        <tr>
                            <td>{t('agreementTableRevisionQuestions')}</td>
                            <td>
                                <strong>{company?.hasRevisionQuestions ? t('buttonYes') : t('buttonNo')}</strong>
                            </td>
                            <td>{t('agreementTableRevisionQuestionsInfo')}</td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td>{t('agreementTableExtendedRevisionMedia')}</td>*/}
                        {/*    <td>*/}
                        {/*        <strong>{company?.hasExtendedRevisionMedia ? t('buttonYes') : t('buttonNo')}</strong>*/}
                        {/*    </td>*/}
                        {/*    <td>{t('agreementTableExtendedRevisionMediaInfo')}</td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td>{t('agreementTableLawLists')}</td>
                            <td>
                                <strong>{company?.hasLawLists ? t('buttonYes') : t('buttonNo')}</strong>
                            </td>
                            <td>{t('agreementTableLawListsInfo')}</td>
                        </tr>
                        <tr>
                            <td>{t('agreementTableKeywords')}</td>
                            <td>
                                <strong>{company?.hasKeyWords ? t('buttonYes') : t('buttonNo')}</strong>
                            </td>
                            <td>{t('agreementTableKeywordsInfo')}</td>
                        </tr>
                        <tr>
                            <td>{t('agreementTableNotifications')}</td>
                            <td>
                                <strong>{company?.useContinousNotification ? t('buttonYes') : t('buttonNo')}</strong>
                            </td>
                            <td>{t('agreementTableNotificationsInfo')}</td>
                        </tr>
                        <tr>
                            <td>{t('agreementTableReaderLogin')}</td>
                            <td>
                                <strong>{company?.anonymousPassword ? t('buttonYes') : t('buttonNo')}</strong>
                            </td>
                            <td>{t('agreementTableReaderLoginInfo')}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Agreement;
