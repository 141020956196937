import React, { FC } from 'react';
import styled from './RevisionTopbar.module.css';
import { useHistory } from 'react-router-dom';
import backArrowIcon from '../../assets/images/backArrowIcon.svg';
import RevisionComment from '../RevisionComment/RevisionComment';

interface Props {
    backUrl: string;
    title: string;
    comment?: string;
    disableComment?: boolean;
    onSubmitComment?: (comment: string) => void;
}

const RevisionTopbar: FC<Props> = ({ backUrl, title, comment, disableComment, onSubmitComment, children }) => {
    const history = useHistory();

    return (
        <div className={styled.Topbar}>
            <img src={backArrowIcon} alt="back" onClick={() => history.push(backUrl)} className={styled.BackIcon} />

            <h3 className={styled.Title}>{title}</h3>

            {comment !== undefined && onSubmitComment && <RevisionComment comment={comment} disabled={disableComment as boolean} onSubmit={onSubmitComment} />}

            {children && <div className={styled.Children}>{children}</div>}
        </div>
    );
};

export default RevisionTopbar;
