import React, { FC, useState, createRef } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import accordionIcon from '../../assets/images/accordionIcon.svg';
import styled from './LawListDropdown.module.css';
import Checkbox from '../Checkbox/Checkbox';
import _ from 'lodash';
import LawListGroupFilter from '../../models/LawListGroupFilter';
import { UNGROUPED_LAWLIST_ID } from '../../models/LawListModel';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    lawListGroupFilter: LawListGroupFilter[];
    className?: string;
    onLawListGroupFilterChange: (lawListGroupId: number) => void;
    onLawListFilterChange: (lawListId: number) => void;
    onAllChange?: (checked: boolean) => void;
    reversed?: boolean;
}

const LawListDropdown: FC<Props> = ({ title, lawListGroupFilter, className, onAllChange, onLawListGroupFilterChange, onLawListFilterChange, reversed }) => {
    const { t } = useTranslation();
    const ref = createRef<HTMLDivElement>();
    const [open, setOpen] = useState(false);
    const [lawListGroupIdOpen, setLawListGroupIdOpen] = useState(-1);

    useClickOutside(ref, () => {
        setOpen(false);
    });

    const lawLists = _.flatMap(lawListGroupFilter, lawListGroup => lawListGroup.lawLists);
    const dynamicTitle = lawLists.some(lawList => lawList.checked)
        ? lawLists
              .filter(lawList => lawList.checked)
              .map(lawList => lawList.name)
              .join(', ')
        : title;

    const handleAllChange = () => {
        if (onAllChange) {
            const someChecked = lawLists.some(c => c.checked);
            onAllChange(!someChecked);
        }
    };

    return (
        <div ref={ref} className={[styled.Dropdown, open ? '' : styled.Closed, reversed ? styled.Reversed : '', className ? className : ''].join(' ')}>
            <div className={styled.Header} onClick={() => setOpen(!open)}>
                <div className={styled.Title}>{dynamicTitle}</div>
                <img src={accordionIcon} alt="Accordion" className={styled.Icon} />
            </div>
            <div className={styled.List}>
                {onAllChange && (
                    <div
                        className={styled.ListItem}
                        onClick={e => {
                            e.preventDefault();
                            handleAllChange();
                        }}
                    >
                        <span>
                            <span className={styled.ListItemText}>
                                <Checkbox
                                    checked={lawLists.every(ll => ll.checked)}
                                    indeterminate={lawLists.some(ll => ll.checked) && !lawLists.every(ll => ll.checked)}
                                    onChange={handleAllChange}
                                />
                                {t('selectAll')}
                            </span>
                        </span>
                    </div>
                )}
                {_.sortBy(
                    lawListGroupFilter.filter(llg => llg.lawListGroupId !== UNGROUPED_LAWLIST_ID),
                    llg => llg.name.toLowerCase(),
                ).map((lawListGroup, lawListGroupIndex) => (
                    <div
                        key={lawListGroupIndex}
                        className={[styled.ListItem, lawListGroupIdOpen === lawListGroup.lawListGroupId ? styled.Open : ''].join(' ')}
                        onMouseEnter={() => setLawListGroupIdOpen(lawListGroup.lawListGroupId)}
                        onMouseLeave={() => setLawListGroupIdOpen(-1)}
                    >
                        <span className={styled.ListItemText} onClick={() => onLawListGroupFilterChange(lawListGroup.lawListGroupId)}>
                            <span>
                                <Checkbox
                                    checked={lawListGroup.lawLists.every(ll => ll.checked)}
                                    indeterminate={lawListGroup.lawLists.some(ll => ll.checked) && !lawListGroup.lawLists.every(ll => ll.checked)}
                                    onChange={() => onLawListGroupFilterChange(lawListGroup.lawListGroupId)}
                                />
                                <p>{lawListGroup.name}</p>
                            </span>
                            <img src={accordionIcon} alt="Accordion" className={styled.Icon} />
                        </span>
                        {lawListGroupIdOpen === lawListGroup.lawListGroupId ? (
                            <div className={styled.SubList}>
                                {_.sortBy(lawListGroup.lawLists, ll => ll.name.toLowerCase()).map(lawList => (
                                    <div key={lawList.lawListId} className={styled.ListItem} onClick={() => onLawListFilterChange(lawList.lawListId)}>
                                        {lawList.name}
                                        <Checkbox checked={lawList.checked} onChange={() => onLawListFilterChange(lawList.lawListId)} />
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                ))}
                {_.sortBy(lawListGroupFilter.find(llg => llg.lawListGroupId === UNGROUPED_LAWLIST_ID)?.lawLists, ll => ll.name.toLowerCase()).map((lawList, lawListIndex) => (
                    <div key={lawListIndex} className={styled.ListItem}>
                        <span>
                            <span className={[styled.ListItemText, styled.Ungrouped].join(' ')} onClick={() => onLawListFilterChange(lawList.lawListId)}>
                                <Checkbox checked={lawList.checked} onChange={() => onLawListFilterChange(lawList.lawListId)} />
                                {lawList.name}
                            </span>
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LawListDropdown;
