import React, { FC } from 'react';
import Button from '../Button/Button';
import styled from './ConsultantToaster.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    fullViewOpen: boolean;
    counter: number;
    onCancel: () => void;
    onSave: () => void;
}

const ConsultantToaster: FC<Props> = ({ fullViewOpen, counter, onCancel, onSave }) => {
    const { t } = useTranslation();
    return (
        <div className={[styled.ConsultantToaster, fullViewOpen ? styled.FullViewOpen : ''].join(' ')}>
            <div className={styled.Text}>
                <h6>{t('consultantToasterTitle')}</h6>
                <p>{t('consultantToasterText', { counter })}</p>
            </div>
            <div className={styled.Options}>
                <Button variant="Secondary" onClick={onCancel}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Light" onClick={onSave}>
                    {t('consultantToasterButton')}
                </Button>
            </div>
        </div>
    );
};

export default ConsultantToaster;
