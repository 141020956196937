import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from './LawLists.module.css';
import Button from '../../../../components/Button/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import EditLawListModal from '../../../../components/Modal/EditLawListModal/EditLawListModal';
import RemoveLawListModal from '../../../../components/Modal/RemoveLawListModal/RemoveLawListModal';
import { EditLawListModel, UNGROUPED_LAWLIST_ID } from '../../../../models/LawListModel';
import CompanyService from '../../../../services/CompanyService';
import useAuth from '../../../../hooks/useAuth';
import { Column, Row } from 'react-table';
import DenseCell from '../../../../components/Table/DenseCell/DenseCell';
import MenuCell, { isLastRow } from '../../../../components/Table/MenuCell/MenuCell';
import Table from '../../../../components/Table/Table';
import HtmlCell from '../../../../components/Table/HtmlCell/HtmlCell';
import EditLawListGroupModal from '../../../../components/Modal/EditLawListGroupModal/EditLawListGroupModal';
import { EditLawListGroupModel } from '../../../../models/LawListGroupModel';
import { useHistory, useLocation } from 'react-router-dom';
import { SETTINGS_COMPANY_LAWLISTS_EDIT } from '../../../../constants/Routes';
import accordionIcon from '../../../../assets/images/accordionIcon.svg';
import RemoveLawListGroupModal from '../../../../components/Modal/RemoveLawListGroupModal/RemoveLawListGroupModal';
import Search from '../../../../components/Search/Search';
import Toggle from '../../../../components/Toggle/Toggle';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccordionIcon } from '../../../../assets/images/accordionIcon.svg';

export interface Data {
    lawListId?: number;
    lawListGroupId?: number;
    name: string;
    responsible: string;
    description: string;
    lawCount?: number;
    subRows?: Data[];
}

const COLUMN_SEARCH: { id: string; value: string | undefined }[] = [];

const LawLists: FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { company, isAboveUser, removeLawList, removeLawListGroup, updateLawListGroup, createLawList, updateLawList } = useAuth();
    const lawGroupToReExpand = useLocation().state;
    const [data, setData] = useState<Data[]>([]);
    const [loading, setLoading] = useState(false);
    const [showInformation, setShowInformation] = useState(true);
    const [lawListModal, setLawListModal] = useState<{ open: boolean; edit?: Data }>({ open: false });
    const [lawListGroupModal, setLawListGroupModal] = useState<{ open: boolean; edit?: Data }>({ open: false });
    const [removeLawListModal, setRemoveLawListModal] = useState<{ open: boolean; lawListId?: number; lawListGroupId?: number }>({
        open: false,
        lawListId: undefined,
        lawListGroupId: undefined,
    });
    const [removeLawListGroupModal, setRemoveLawListGroupModal] = useState<{ open: boolean; lawListGroupId?: number }>({
        open: false,
        lawListGroupId: undefined,
    });
    const [globalSearch, setGlobalSearch] = useState('');
    const [showColumnSearch, setShowColumnSearch] = useState(true);
    const [visibleDataCount, setVisibleDataCount] = useState(0);

    const fetchData = async () => {
        if (company) {
            try {
                setLoading(true);
                const lawListGroups = await CompanyService().getLawListGroups(company.id);
                const lawLists = await CompanyService().getLawLists(company.id);

                const data: Data[] = lawListGroups.map<Data>(lawListGroup => ({
                    lawListGroupId: lawListGroup.lawListGroupId,
                    name: lawListGroup.name,
                    description: lawListGroup.description,
                    responsible: lawListGroup.responsible,
                    subRows: [],
                }));

                lawLists.forEach(lawList => {
                    if (lawList.lawListGroup) {
                        const lawListGroupIndex = data.findIndex(llg => llg.lawListGroupId === lawList.lawListGroup.lawListGroupId);
                        if (lawListGroupIndex > -1 && data[lawListGroupIndex]) {
                            const lawListGroup = data[lawListGroupIndex];

                            if (!lawListGroup.subRows) {
                                lawListGroup.subRows = [];
                            }

                            lawListGroup.subRows.push({
                                lawListId: lawList.lawListId,
                                lawListGroupId: lawList.lawListGroup.lawListGroupId,
                                name: lawList.name,
                                description: lawList.description,
                                responsible: lawList.responsible,
                                lawCount: lawList.lawCount || 0,
                            });
                        }
                    } else {
                        data.push({
                            lawListId: lawList.lawListId,
                            name: lawList.name,
                            description: lawList.description,
                            responsible: lawList.responsible,
                            lawCount: lawList.lawCount || 0,
                        });
                    }
                });

                setData(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const getLawList = (lawListId?: number, lawListGroupId?: number): Data | undefined => {
        let lawList;
        if (lawListGroupId) {
            const lawListGroup = data.find(obj => obj.lawListGroupId === lawListGroupId);
            lawList = lawListGroup && lawListGroup.subRows && lawListGroup.subRows.find(obj => obj.lawListId === lawListId);
        } else if (lawListId) {
            lawList = data.find(obj => obj.lawListId === lawListId);
        }
        return lawList;
    };

    const handleSaveLawList = async (lawList: EditLawListModel) => {
        if (company) {
            try {
                setLoading(true);
                if (lawListModal.edit) {
                    const updatedLawList = await CompanyService().updateLawList(company.id, lawList);
                    const updatedLawListData: Data = {
                        lawListId: updatedLawList.lawListId,
                        lawListGroupId: updatedLawList.lawListGroup?.lawListGroupId || undefined,
                        name: updatedLawList.name,
                        description: updatedLawList.description,
                        responsible: updatedLawList.responsible,
                        lawCount: updatedLawList.lawCount,
                    };

                    setLawListModal({ open: false, edit: undefined });
                    setData(s => {
                        let updatedData = s.filter(obj => obj.lawListId !== updatedLawListData.lawListId);
                        updatedData = updatedData.map(obj => {
                            if (obj.subRows) {
                                return {
                                    ...obj,
                                    subRows: obj.subRows.filter(o => o.lawListId !== updatedLawListData.lawListId),
                                };
                            }
                            return obj;
                        });

                        if (updatedLawListData.lawListGroupId) {
                            updatedData = updatedData.map(obj => {
                                if (obj.lawListGroupId === updatedLawListData.lawListGroupId) {
                                    return {
                                        ...obj,
                                        subRows: obj.subRows ? [...obj.subRows, updatedLawListData] : [updatedLawListData],
                                    };
                                }
                                return obj;
                            });
                        } else {
                            updatedData = [...updatedData, updatedLawListData];
                        }

                        return updatedData;
                    });
                    updateLawList(updatedLawList);
                } else {
                    setLawListModal({ open: false, edit: undefined });
                    const newLawList = await CompanyService().createLawList(company.id, lawList);
                    const newLawListData: Data = {
                        lawListId: newLawList.lawListId,
                        lawListGroupId: newLawList.lawListGroup?.lawListGroupId || UNGROUPED_LAWLIST_ID,
                        name: newLawList.name,
                        description: newLawList.description,
                        responsible: newLawList.responsible,
                        lawCount: newLawList.lawCount || 0,
                    };
                    setData(s => {
                        if (newLawListData.lawListGroupId) {
                            return s.map(row => {
                                if (row.lawListGroupId === newLawListData.lawListGroupId) {
                                    return {
                                        ...row,
                                        subRows: row.subRows ? [...row.subRows, newLawListData] : row.subRows,
                                    };
                                }
                                return row;
                            });
                        }
                        return [...s, newLawListData];
                    });
                    createLawList(newLawList);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleSaveLawListGroup = async (lawListGroup: EditLawListGroupModel) => {
        if (company) {
            try {
                setLoading(true);
                if (lawListGroup.lawListGroupId) {
                    setLawListGroupModal({ open: false, edit: undefined });
                    const updatedLawListGroup = await CompanyService().updateLawListGroup(company.id, lawListGroup);
                    const updatedLawListGroupData: Data = {
                        lawListGroupId: updatedLawListGroup.lawListGroupId,
                        name: updatedLawListGroup.name,
                        description: updatedLawListGroup.description,
                        responsible: updatedLawListGroup.responsible,
                    };
                    setData(s => s.map(obj => (obj.lawListGroupId === updatedLawListGroupData.lawListGroupId ? { ...obj, ...updatedLawListGroupData } : obj)));
                    updateLawListGroup(updatedLawListGroup);
                } else {
                    setLawListGroupModal({ open: false, edit: undefined });
                    const newLawListGroup = await CompanyService().createLawListGroup(company.id, lawListGroup);
                    const newLawListGroupData: Data = {
                        lawListGroupId: newLawListGroup.lawListGroupId,
                        name: newLawListGroup.name,
                        description: newLawListGroup.description,
                        responsible: newLawListGroup.responsible,
                        subRows: [],
                    };
                    setData(s => [...s, newLawListGroupData]);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleRemoveLawList = async () => {
        const lawList = getLawList(removeLawListModal.lawListId, removeLawListModal.lawListGroupId);
        if (lawList && lawList.lawListId && company) {
            try {
                setLoading(true);
                setRemoveLawListModal({ open: false, lawListId: undefined, lawListGroupId: undefined });
                await CompanyService().removeLawList(company.id, lawList.lawListId);

                if (lawList.lawListGroupId) {
                    setData(s =>
                        s.map(obj =>
                            obj.lawListGroupId === lawList.lawListGroupId && obj.subRows ? { ...obj, subRows: obj.subRows.filter(ll => ll.lawListId !== lawList.lawListId) } : obj,
                        ),
                    );
                } else {
                    setData(s => s.filter(ll => ll.lawListId !== lawList.lawListId));
                }
                removeLawList(lawList.lawListId);

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleRemoveLawListGroup = async () => {
        const lawListGroup = data.find(obj => obj.lawListGroupId === removeLawListGroupModal.lawListGroupId);
        if (lawListGroup && lawListGroup.lawListGroupId && company) {
            try {
                setLoading(true);
                setRemoveLawListGroupModal({ open: false, lawListGroupId: undefined });
                await CompanyService().removeLawListGroup(company.id, lawListGroup.lawListGroupId);

                if (lawListGroup.subRows) {
                    const lawLists = lawListGroup.subRows.map(obj => ({ ...obj, lawListGroupId: undefined }));
                    setData(s => [...s.filter(obj => obj.lawListGroupId !== lawListGroup.lawListGroupId), ...lawLists]);
                    removeLawListGroup(lawListGroup.lawListGroupId);
                } else {
                    setData(s => s.filter(obj => obj.lawListGroupId !== lawListGroup.lawListGroupId));
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleEditLawListContent = (lawListId: number | undefined, row: Row<Data>) => {
        history.push({
            pathname: SETTINGS_COMPANY_LAWLISTS_EDIT + `/${lawListId}`,
            state: row.original.lawListGroupId,
        });
    };

    const handleOpenEditLawList = (lawListId: number, lawListGroupId?: number) => {
        const lawList = getLawList(lawListId, lawListGroupId);
        if (lawList) {
            setLawListModal({ open: true, edit: lawList });
        }
    };

    const handleOpenEditLawListGroup = async (lawListGroupId: number) => {
        const lawListGroup = data.find(obj => obj.lawListGroupId === lawListGroupId);
        if (lawListGroup) {
            setLawListGroupModal({ open: true, edit: lawListGroup });
        }
    };

    const handleToggleShowInformation = () => {
        setShowInformation(s => !s);
    };

    const columns = useMemo<Array<Column<Data> & { Header: string }>>(
        () => [
            {
                id: 'expander',
                width: '42px',
                Header: '',
                accessor: row => (row.subRows ? 1 : -1),
                Cell: function expanderCell({ row }) {
                    return row.original.subRows ? (
                        <span {...row.getToggleRowExpandedProps()} className={styled.ToggleCell}>
                            {row.isExpanded ? <img src={accordionIcon} className={styled.ExpandedIcon} /> : <img src={accordionIcon} />}
                        </span>
                    ) : (
                        <span className={styled.ToggleCell} />
                    );
                },
            },
            {
                id: 'name',
                Header: t('columnName'),
                Cell: function nameCell(props) {
                    const value = props.row.original.name;
                    return (
                        <DenseCell
                            {...{
                                ...props,
                                cell: { ...props.cell, value },
                            }}
                            noBold={!props.row.original.subRows}
                        />
                    );
                },
                accessor: row => `${row.name} ${row.subRows ? row.subRows?.map(sr => sr.name).join(' ') : data.find(obj => obj.lawListGroupId === row.lawListGroupId)?.name}`,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.original.name.toLowerCase();
                    const lawB = rowB.original.name.toLowerCase();
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                id: 'responsible',
                Header: t('columnResponsible'),
                Cell: function Cell(props) {
                    const value = props.row.original.responsible;
                    return (
                        <DenseCell
                            {...{
                                ...props,
                                cell: { ...props.cell, value },
                            }}
                            noBold={!props.row.original.subRows}
                        />
                    );
                },
                accessor: row =>
                    `${row.responsible} ${
                        row.subRows ? row.subRows?.map(sr => sr.responsible).join(' ') : data.find(obj => obj.lawListGroupId === row.lawListGroupId)?.responsible
                    }`,
            },
            {
                id: 'description',
                Header: t('columnDescription'),
                Cell: function Cell(props) {
                    const value = props.row.original.description;
                    return (
                        <HtmlCell
                            {...{
                                ...props,
                                cell: { ...props.cell, value },
                            }}
                        />
                    );
                },
                accessor: row =>
                    `${row.description} ${
                        row.subRows ? row.subRows?.map(sr => sr.description).join(' ') : data.find(obj => obj.lawListGroupId === row.lawListGroupId)?.description
                    }`,
            },
            {
                id: 'lawCount',
                Header: t('columnLawCount'),
                Cell: function Cell(props) {
                    const value = props.row.original.lawCount;
                    return (
                        <DenseCell
                            {...{
                                ...props,
                                cell: { ...props.cell, value },
                            }}
                            noBold={!props.row.original.subRows}
                        />
                    );
                },
                accessor: row =>
                    `${row.lawCount} ${row.subRows ? row.subRows?.map(sr => sr.lawCount).join(' ') : data.find(obj => obj.lawListGroupId === row.lawListGroupId)?.lawCount}`,
            },
            {
                id: 'menu',
                Header: '',
                Cell: function Cell(e) {
                    const { row, sortedRows } = e;
                    const { lawListId, lawListGroupId } = row.original;
                    const isEditable = isAboveUser();
                    const lastRow = isLastRow(row, sortedRows);

                    if (lawListId) {
                        return (
                            <MenuCell
                                title={t('buttonManageLawList')}
                                lastRow={lastRow}
                                items={[
                                    { text: t('buttonLaws'), function: isEditable ? () => handleEditLawListContent(lawListId, row) : () => undefined },
                                    { text: t('buttonEdit'), function: () => handleOpenEditLawList(lawListId, lawListGroupId) },
                                    {
                                        text: t('buttonRemove'),
                                        function: isEditable ? () => setRemoveLawListModal({ open: true, lawListId: lawListId, lawListGroupId: lawListGroupId }) : () => undefined,
                                    },
                                ]}
                            />
                        );
                    } else if (lawListGroupId) {
                        return (
                            <MenuCell
                                title={t('buttonManageLawListGroup')}
                                lastRow={lastRow}
                                items={[
                                    { text: t('buttonEdit'), function: isEditable ? () => handleOpenEditLawListGroup(lawListGroupId) : () => undefined },
                                    { text: t('buttonRemove'), function: isEditable ? () => setRemoveLawListGroupModal({ open: true, lawListGroupId }) : () => undefined },
                                ]}
                            />
                        );
                    }
                },
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [handleEditLawListContent, handleOpenEditLawList, handleOpenEditLawListGroup, setRemoveLawListModal, setRemoveLawListGroupModal, data, i18n.language],
    );

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        console.log('Group to reExpand: ' + JSON.stringify(lawGroupToReExpand));
    }, [lawGroupToReExpand]);

    return (
        <div className={styled.LawLists}>
            {loading && <LoadingSpinner />}
            <div className={styled.Top}>
                <h3 className={styled.Title}>{t('settingsMenuCompanyLawLists')}</h3>
                <div className={styled.Information}>
                    <h6 onClick={handleToggleShowInformation}>
                        {t('lawListsTitle')} <AccordionIcon className={[styled.Icon, showInformation ? styled.Open : ''].join(' ')} />
                    </h6>
                    {showInformation && <p>{t('lawListsText')}</p>}
                    <div className={styled.Buttons}>
                        <Button variant="Outline" onClick={() => setLawListGroupModal({ open: true, edit: undefined })} disabled={!isAboveUser()}>
                            {t('buttonCreateLawListGroup')}
                        </Button>
                        <Button variant="Primary" onClick={() => setLawListModal({ open: true, edit: undefined })} disabled={!isAboveUser()}>
                            {t('buttonCreateLawList')}
                        </Button>
                    </div>
                </div>
            </div>

            <div className={styled.SearchBar}>
                <div className={styled.SearchContainer}>
                    <Search value={globalSearch} onChange={setGlobalSearch} />
                    <p className={[styled.SearchCounter, visibleDataCount < data.length ? styled.Filtered : ''].join(' ')}>
                        {visibleDataCount} {t('of')} {data.length}
                    </p>
                </div>
                <Toggle title={t('columnSearch')} checked={showColumnSearch} onChange={() => setShowColumnSearch(!showColumnSearch)} className={styled.Toggle} />
            </div>

            <div className={styled.TableWrapper}>
                <Table
                    columns={columns}
                    data={data}
                    showColumnSearch={showColumnSearch}
                    columnSearch={COLUMN_SEARCH}
                    globalSearch={globalSearch}
                    rowHeight={48}
                    dense
                    sorting={[
                        { id: 'expander', desc: true },
                        { id: 'name', desc: false },
                    ]}
                    headerClassName={styled.TableHeader}
                    onVisibleDataCountChange={setVisibleDataCount}
                    expandable
                />
            </div>

            {lawListModal.open && <EditLawListModal edit={lawListModal.edit} onSave={handleSaveLawList} onCancel={() => setLawListModal({ open: false, edit: undefined })} />}

            {lawListGroupModal.open && (
                <EditLawListGroupModal edit={lawListGroupModal.edit} onSave={handleSaveLawListGroup} onCancel={() => setLawListGroupModal({ open: false, edit: undefined })} />
            )}

            {removeLawListModal.open && removeLawListModal.lawListId && (
                <RemoveLawListModal onCancel={() => setRemoveLawListModal({ open: false, lawListId: undefined, lawListGroupId: undefined })} onConfirm={handleRemoveLawList} />
            )}

            {removeLawListGroupModal.open && removeLawListGroupModal.lawListGroupId && (
                <RemoveLawListGroupModal onCancel={() => setRemoveLawListGroupModal({ open: false, lawListGroupId: undefined })} onConfirm={handleRemoveLawListGroup} />
            )}
        </div>
    );
};

export default LawLists;
