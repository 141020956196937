import React, { FC } from 'react';
import RevisionMediaAttachmentModel from '../../../models/RevisionMediaAttachmentModel';
import RevisionMediaEditor from '../../RevisionMediaEditor/RevisionMediaEditor';
import RevisionSubscriptionModel from '../../../models/RevisionSubscriptionModel';
import CustomLawRevisionModel from '../../../models/CustomLawRevisionModel';
interface Props {
    data?: RevisionMediaAttachmentModel[];
    selectedSub: RevisionSubscriptionModel | CustomLawRevisionModel | undefined;
    editRevisionLeftViewOpen?: boolean;
}

const AccordionMediaItem: FC<Props> = ({ data, selectedSub, editRevisionLeftViewOpen = false }) => {
    return (
        <div>
            <RevisionMediaEditor
                blockEditFunctions
                editRevisionLeftViewOpen={editRevisionLeftViewOpen}
                selectedSub={selectedSub}
                onCancel={() => undefined}
                onConfirm={() => undefined}
            ></RevisionMediaEditor>
        </div>
    );
};

export default AccordionMediaItem;
