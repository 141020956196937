import { useState, useEffect } from 'react';
import useStorage from './useStorage';
import { mobileSizeWidth } from '../constants/Validation';

interface Size {
    width: number | undefined;
    height: number | undefined;
}
const useMobileDevice = () => {
    const [windowSize, setWindowSize] = useState<Size>({
        width: undefined,
        height: undefined,
    });
    const { isDesktopView, setDesktopView } = useStorage();

    if (isDesktopView() == null) {
        setDesktopView(false);
    }

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize.width! <= mobileSizeWidth && !isDesktopView();
};

export default useMobileDevice;
