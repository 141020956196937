import CustomLawModel from './CustomLawModel';
import LawModel from './LawModel';
import SubscriptionModel from './SubscriptionModel';

export type LawType = 'ENV' | 'WORK_ENV' | 'FOOD' | 'SUSTAINABILITY' | 'COUNTY' | 'MISC' | 'CUSTOM_LAW';

export enum LawTypeEnum {
    'ENV' = 'lawTypeEnv',
    'WORK_ENV' = 'lawTypeWorkEnv',
    'FOOD' = 'lawTypeFood',
    'SUSTAINABILITY' = 'lawTypeSustainability',
    'COUNTY' = 'lawTypeCounty',
    'MISC' = 'lawTypeMisc',
    'CUSTOM_LAW' = 'lawTypeCustomLaw',
}

const lawTypeOrder = Object.keys(LawTypeEnum);

export const sortLawTypes = (a: LawType, b: LawType): number => {
    return lawTypeOrder.indexOf(a) - lawTypeOrder.indexOf(b);
};

export const getLawTypeId = (lawType: LawType): number => {
    return lawTypeOrder.indexOf(lawType);
};

export const getLawMonitorDefaultSorting = (data: (SubscriptionModel | LawModel | CustomLawModel)[]): (SubscriptionModel | LawModel | CustomLawModel)[] => {
    return data.sort((a, b) => sortLawTypes(a.lawType, b.lawType) || (a.lawGroupName < b.lawGroupName ? -1 : a.lawGroupName > b.lawGroupName ? 1 : 0));
};
