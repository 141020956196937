import { AxiosError, AxiosResponse } from 'axios';
import ApiErrorModel from '../models/ApiErrorModel';
import { ReaderLoginModel } from '../models/ReaderLoginModel';
import { getAuthApi } from '../config/axios.config';

const ReaderLoginService = () => {
    const axiosInstance = getAuthApi();

    function updateReaderLogin(readerLoginModel: ReaderLoginModel): Promise<AxiosResponse> {
        return axiosInstance.put<void>('/readerlogin/', readerLoginModel).catch((error: AxiosError<ApiErrorModel>) => {
            throw error;
        });
    }

    function createReaderLogin(companyId: number): Promise<ReaderLoginModel> {
        return axiosInstance
            .post<ReaderLoginModel>(`/readerlogin/?companyId=${companyId}`, undefined)
            .then((response: AxiosResponse<ReaderLoginModel>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getReaderLogins(companyId: number): Promise<ReaderLoginModel[]> {
        return axiosInstance
            .get<ReaderLoginModel[]>(`/readerlogin/?companyId=${companyId}`)
            .then((response: AxiosResponse<ReaderLoginModel[]>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function deleteReaderLogin(id: number): Promise<AxiosResponse> {
        return axiosInstance.delete(`/readerlogin/?id=${id}`).catch((error: AxiosError<ApiErrorModel>) => {
            throw error;
        });
    }

    function activateReaderLogin(companyId: number): Promise<AxiosResponse> {
        return axiosInstance.put(`/readerlogin/activate/?companyId=${companyId}`).catch((error: AxiosError<ApiErrorModel>) => {
            throw error;
        });
    }

    function deactivateReaderLogin(companyId: number): Promise<AxiosResponse> {
        return axiosInstance.put(`/readerlogin/deactivate/?companyId=${companyId}`).catch((error: AxiosError<ApiErrorModel>) => {
            throw error;
        });
    }

    function getReaderLoginActive(companyId: number): Promise<boolean> {
        return axiosInstance
            .get<boolean>(`/readerlogin/active/?companyId=${companyId}`)
            .then((response: AxiosResponse<boolean>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        updateReaderLogin,
        createReaderLogin,
        deleteReaderLogin,
        getReaderLogins,
        activateReaderLogin,
        deactivateReaderLogin,
        getReaderLoginActive,
    };
};
export default ReaderLoginService;
