import React, { FC, useEffect, useState } from 'react';
import Modal from '../Modal';
import styled from './EditUserModal.module.css';
import Input from '../../Input/Input';
import Dropdown from '../../Dropdown/Dropdown';
import Toggle from '../../Toggle/Toggle';
import Button from '../../Button/Button';
import { Role, RoleEnum } from '../../../models/Role';
import UserModel, { EditUserModel } from '../../../models/UserModel';
import useAuth from '../../../hooks/useAuth';
import * as VALIDATION from '../../../constants/Validation';
import { useTranslation } from 'react-i18next';
import UserService from '../../../services/UserService';
import DuplicateUserModal from '../../../components/Modal/DuplicateUserModal/DuplicateUserModal';
import { HELP_CONTACT } from '../../../constants/Routes';
import { useHistory } from 'react-router';

interface Props {
    editUser?: UserModel | undefined; // If undefined, this component will be used to create a new object. If this value is not undefined this component will be used to edit the defined object.
    onCancel: () => void;
    onRemove: () => void;
    onSave: (user: EditUserModel) => void;
}

interface InputState {
    value: string;
    error: string | undefined;
}

const EditUserModal: FC<Props> = ({ editUser, onCancel, onRemove, onSave }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const PREVENT_PASSWORD_AUTOFILL_KEY = 'new-password';
    const LANGUAGES = [
        { id: 0, text: t('languageSwedish'), value: 'sv' },
        { id: 1, text: t('languageEnglish'), value: 'en' },
    ];

    const { company, isAboveSuperUser, isUserAboveUser } = useAuth();
    const [roleOptions] = useState<Role[]>(() => {
        const roleOptions: Role[] = ['assessor', 'delegate_assessor', 'revisor', 'editor'];
        if (isAboveSuperUser()) {
            roleOptions.push('superuser');
        }
        return roleOptions;
    });

    const [firstName, setFirstName] = useState<InputState>({
        value: editUser ? editUser.firstName : '',
        error: undefined,
    });
    const [lastName, setLastName] = useState<InputState>({ value: editUser ? editUser.lastName : '', error: undefined });
    const [userName, setUserName] = useState<InputState>({ value: editUser ? editUser.userName : '', error: undefined });
    const [language, setLanguage] = useState<number>(editUser ? LANGUAGES.find(lang => lang.value === editUser.language)?.id || 0 : 0);
    const [password, setPassword] = useState<InputState>({ value: '', error: undefined });
    const [confirmPassword, setConfirmPassword] = useState<InputState>({ value: '', error: undefined });
    const [emailNotification, setEmailNotification] = useState(editUser ? editUser.emailNotification : true);
    const [roles, setRoles] = useState<number[]>(editUser ? roleOptions.reduce((a: number[], e, i) => (editUser.roles.includes(e) ? a.concat(i) : a), []) : []);
    const otherRoles = editUser ? editUser.roles.reduce((a: Role[], e) => (!roleOptions.includes(e) ? a.concat(e) : a), []) : [];
    const [duplicateUserModal, setDuplicateUserModal] = useState<{ open: boolean; duplicateUser?: UserModel; forbiddenEmailChange?: boolean; redundantAccessRequest?: false }>({
        open: false,
        forbiddenEmailChange: false,
        redundantAccessRequest: false,
    });

    // Only Consultant or Admins are allowed to set a custom password on new users.
    // This is mostly used for testing purposes with dummy emails.
    const changePasswordModeIsOn: boolean = isAboveSuperUser() || editUser != undefined;

    const handleRoleChange = (index: number): void => {
        setRoles(s => (s.includes(index) ? s.filter(r => r !== index) : [...s, index]));
    };

    const handleFirstNameChange = (value: string): void => {
        setFirstName(s => ({ ...s, value, error: VALIDATION.name(value) }));
    };

    const handleLastNameChange = (value: string): void => {
        setLastName(s => ({ ...s, value, error: VALIDATION.name(value) }));
    };

    const handleUserNameChange = (value: string): void => {
        setUserName(s => ({ ...s, value, error: VALIDATION.userName(value) }));
    };

    const handlePasswordChange = (value: string): void => {
        setPassword(s => ({ ...s, value, error: VALIDATION.password(value) }));
        setConfirmPassword(s => ({ ...s, error: VALIDATION.confirmPassword(s.value, value) }));
    };

    const handleConfirmPasswordChange = (value: string): void => {
        setConfirmPassword(s => ({ ...s, value, error: VALIDATION.confirmPassword(value, password.value) }));
    };

    const handleRedirectToContactForm = (): void => {
        history.push(HELP_CONTACT, { subject: 'REQUEST_ACCESS', duplicateUser: duplicateUserModal.duplicateUser });
    };

    const checkIfUserExists = (user: EditUserModel): void => {
        UserService()
            .getUserByUserName(userName.value)
            .then(existingUser => {
                if (existingUser) {
                    if (editUser) {
                        if (existingUser.userId === user.userId) {
                            onSave(user);
                        } else {
                            setDuplicateUserModal({ open: true, duplicateUser: existingUser, forbiddenEmailChange: true });
                        }
                    } else {
                        if (existingUser) {
                            let redundantAccessRequest = false;
                            existingUser.connectedCompanies.forEach(c => {
                                if (c.id === user.companyId) {
                                    redundantAccessRequest = true;
                                }
                            });

                            console.log(existingUser);
                            setDuplicateUserModal({ open: true, duplicateUser: existingUser, redundantAccessRequest: redundantAccessRequest });
                        } else {
                            onSave(user);
                        }
                    }
                } else {
                    onSave(user);
                }
            });
    };

    const handleSubmit = (): void => {
        let hasErrors = false;
        if (VALIDATION.name(firstName.value)) {
            setFirstName(s => ({ ...s, error: VALIDATION.name(s.value) }));
            hasErrors = true;
        }
        if (VALIDATION.name(lastName.value)) {
            setLastName(s => ({ ...s, error: VALIDATION.name(s.value) }));
            hasErrors = true;
        }
        if (VALIDATION.userName(userName.value)) {
            setUserName(s => ({ ...s, error: VALIDATION.userName(s.value) }));
            hasErrors = true;
        }
        if (changePasswordModeIsOn) {
            if (VALIDATION.password(password.value)) {
                setPassword(s => ({ ...s, error: VALIDATION.password(s.value) }));
                hasErrors = true;
            }
            if (VALIDATION.confirmPassword(confirmPassword.value, password.value)) {
                setConfirmPassword(s => ({ ...s, error: VALIDATION.confirmPassword(s.value, password.value) }));
                hasErrors = true;
            }
        }

        if (hasErrors) return;
        const user: EditUserModel = {
            companyId: company?.id || -1,
            userId: editUser ? editUser.userId : undefined,
            firstName: firstName.value,
            lastName: lastName.value,
            userName: userName.value,
            language: LANGUAGES[language].value,
            newPassword: password.value.length ? password.value : undefined,
            newPasswordRepeated: confirmPassword.value.length ? confirmPassword.value : undefined,
            emailNotification,
            roles: [...roles.map((index: number) => roleOptions[index]), ...otherRoles],
        };

        checkIfUserExists(user);
    };

    return (
        <div>
            {!duplicateUserModal.open && (
                <Modal title={editUser ? t('editUserModalTitle') : t('createUserModalTitle')} className={styled.Modal}>
                    <div className={styled.Container}>
                        <div className={styled.Content}>
                            <div className={styled.Section}>
                                <Input value={firstName.value} onChange={handleFirstNameChange} label={t('inputFieldFirstName')} error={firstName.error} autoFocus />
                                <Input value={lastName.value} onChange={handleLastNameChange} label={t('inputFieldLastName')} error={lastName.error} />
                            </div>

                            <div className={styled.Section}>
                                <Input value={userName.value} onChange={handleUserNameChange} label={t('inputFieldEmail')} error={userName.error} type="email" />
                                <div className={styled.DropdownInput}>
                                    <p>{t('inputFieldLanguage')}</p>
                                    <Dropdown title={LANGUAGES[language].text} content={LANGUAGES} onChange={setLanguage} />
                                </div>
                            </div>
                        </div>
                        {changePasswordModeIsOn && (
                            <div className={styled.Content}>
                                <div className={styled.Section}>
                                    <Input
                                        value={password.value}
                                        onChange={handlePasswordChange}
                                        label={t('inputFieldPassword')}
                                        subLabel={!editUser ? t('inputFieldLeaveBlankForGeneratePassword') : undefined}
                                        error={password.error}
                                        type="password"
                                        autoComplete={PREVENT_PASSWORD_AUTOFILL_KEY}
                                    />
                                    <Input
                                        value={confirmPassword.value}
                                        onChange={handleConfirmPasswordChange}
                                        label={t('inputFieldConfirmPassword')}
                                        error={confirmPassword.error}
                                        type="password"
                                        autoComplete={PREVENT_PASSWORD_AUTOFILL_KEY}
                                    />
                                </div>
                            </div>
                        )}

                        <div className={styled.Content}>
                            <div className={styled.ToggleContainer}>
                                <p>{t('editUserModalEmailToggle')}</p>
                                <Toggle checked={emailNotification} onChange={() => setEmailNotification(!emailNotification)} />
                            </div>
                        </div>

                        <div className={styled.Content}>
                            <h4>{t('columnRoles')}:</h4>
                            {roleOptions.map((role: Role, index: number) => (
                                <div key={role} className={[styled.ToggleContainer, styled.MT].join(' ')}>
                                    <p>{t(RoleEnum[role])}</p>
                                    <Toggle checked={roles.includes(index)} onChange={() => handleRoleChange(index)} />
                                </div>
                            ))}
                        </div>

                        <div className={styled.Footer}>
                            {editUser && (!isUserAboveUser(editUser) || isAboveSuperUser()) && (
                                <div className={styled.RemoveButton}>
                                    <Button variant="Outline" onClick={onRemove}>
                                        {t('buttonRemoveUser')}
                                    </Button>
                                </div>
                            )}
                            <Button variant="Outline" onClick={onCancel}>
                                {t('buttonCancel')}
                            </Button>
                            <Button
                                variant="Primary"
                                onClick={handleSubmit}
                                disabled={
                                    password.error !== undefined ||
                                    confirmPassword.error !== undefined ||
                                    userName.error !== undefined ||
                                    firstName.error !== undefined ||
                                    lastName.error !== undefined
                                }
                            >
                                {t('buttonSave')}
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
            {duplicateUserModal.open && duplicateUserModal.duplicateUser && (
                <DuplicateUserModal
                    duplicateUser={duplicateUserModal.duplicateUser}
                    onCancel={() => setDuplicateUserModal({ open: false })}
                    onConfirm={() => handleRedirectToContactForm()}
                    forbiddenEmailChange={duplicateUserModal.forbiddenEmailChange}
                    redundantAccessRequest={duplicateUserModal.redundantAccessRequest}
                />
            )}
        </div>
    );
};

export default EditUserModal;
