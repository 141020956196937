import React, { FC } from 'react';
import styled from './OptionButton.module.css';

interface Props {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    active: boolean;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const OptionButton: FC<Props> = ({ active, onClick, Icon }) => {
    return (
        <button tabIndex={-1} className={[styled.OptionButton, active ? styled.Active : ''].join(' ')} onMouseDown={onClick}>
            <Icon />
        </button>
    );
};

export default OptionButton;
