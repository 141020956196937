import { useEffect, useMemo, useState } from 'react';
import InvoiceService from '../services/InvoiceService';
import { Data } from '../containers/InvoiceList/Invoicing';
import CompanyInvoiceFullModel from '../models/CompanyInvoiceFullModel';
import { SortingRule } from 'react-table';
import AgreementPeriodModel from '../models/AgreementPeriodModel';
import ServiceInvoiceModel from '../models/ServiceInvoiceModel';
import CompanyAgreementPeriodModel from '../models/CompanyAgreementPeriodModel';
import { useTranslation } from 'react-i18next';
import UploadCompanyDocumentModel from '../models/UploadCompanyDocumentModel';
import { CompanyDocumentTypeEnum } from '../models/CompanyDocumentType';

const useInvoicing = () => {
    const [data, setData] = useState<Data[]>([]);
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState(t('loadingTextDefault'));
    const [company, setCompany] = useState<CompanyInvoiceFullModel | undefined>(undefined);
    const [checked, setChecked] = useState<Record<number, boolean>>({});
    const [attentionCandidates, setAttentionCandidates] = useState<{ id: number; text: string }[]>([]);
    const [attentionPerson, setAttentionPerson] = useState<{ id: number; text: string }>();

    const [selectedCompanyId, setSelectedCompanyId] = useState<number | undefined>(undefined);

    const [sorting, setSorting] = useState<SortingRule<object>[]>([]);
    const [allCompanyIds, setAllCompanyIds] = useState<number[]>([]);
    const [checkedCorporations, setCheckedCorporations] = useState<Record<number, number>>({});
    const [corporationCompaniesMap, setCorporationCompaniesMap] = useState<Record<number, number[]>>({});

    const allChecked = useMemo(() => {
        const numberOfChecked = Object.keys(checked).length;
        if (numberOfChecked === 0) {
            return 0;
        } else if (Object.keys(allCompanyIds).length === numberOfChecked) {
            return 2;
        } else {
            return 1;
        }
    }, [allCompanyIds, checked]);

    useEffect(() => {
        const keys: string[] = Object.keys(corporationCompaniesMap);
        const checkedCorporationMap: Record<number, number> = {};
        keys.forEach(keyString => {
            const corpIdKey = Number(keyString);
            const idList: number[] = corporationCompaniesMap[corpIdKey];
            let numberOfChecked = 0;
            idList.forEach(id => {
                if (checked[id]) {
                    numberOfChecked++;
                }
            });
            if (numberOfChecked === idList.length) {
                checkedCorporationMap[corpIdKey] = 2;
            } else if (numberOfChecked === 0) {
                checkedCorporationMap[corpIdKey] = 0;
            } else {
                checkedCorporationMap[corpIdKey] = 1;
            }
        });
        setCheckedCorporations(checkedCorporationMap);
    }, [checked, corporationCompaniesMap]);

    function getSelectedCompanyIds(): number[] {
        const companyIds: number[] = [];
        for (const key in checked) {
            companyIds.push(+key);
        }
        return companyIds;
    }

    const checkAll = () => {
        const updatedList: Record<number, boolean> = {};
        if (allChecked < 2) {
            allCompanyIds.forEach(companyId => {
                updatedList[companyId] = true;
            });
            setChecked(updatedList);
        }
        setChecked(updatedList);
    };

    const toggleChecked = (id: number) => {
        if (checked[id]) {
            const idAsString = id.toString();
            const filteredList: Record<number, boolean> = {};
            for (const key in checked) {
                if (key !== idAsString) {
                    filteredList[key] = checked[key];
                }
            }
            setChecked(filteredList);
        } else {
            setChecked(s => {
                return {
                    ...s,
                    [id]: !s[id],
                };
            });
        }
    };

    const checkCorporation = (corporationId: number) => {
        const ids = corporationCompaniesMap ? corporationCompaniesMap[corporationId] : [];
        const updatedList: Record<number, boolean> = { ...checked };
        if (checkedCorporations[corporationId] < 2) {
            ids.forEach(companyId => {
                updatedList[companyId] = true;
            });
            setChecked(updatedList);
        } else {
            const filteredList: Record<number, boolean> = {};
            for (const key in checked) {
                if (ids[key]) {
                    filteredList[key] = checked[key];
                }
            }
            setChecked(filteredList);
        }
    };

    const handleSortingChange = (sorting: SortingRule<object>[]): void => {
        setSorting(sorting);
    };

    const updateCustomerReferenceNumber = async (companyId: number, customerReferenceNumber: string): Promise<void> => {
        await InvoiceService().updateCustomerReferenceNumber(companyId, customerReferenceNumber);
        setCompany(s => {
            if (s) {
                return {
                    ...s,
                    customerReferenceNumber: customerReferenceNumber,
                };
            } else {
                return undefined;
            }
        });
    };

    const updateItemsList = async (companyId: number): Promise<void> => {
        const items: ServiceInvoiceModel[] = await InvoiceService().getItems(companyId);
        setCompany(s => {
            if (s) {
                return {
                    ...s,
                    serviceItems: items,
                };
            } else {
                return undefined;
            }
        });
    };

    const handleUpdatePrice = async (companyId: number): Promise<void> => {
        const corporationId = company?.corporationId;
        const updatedPrice: number = await InvoiceService().updatePrice(companyId);
        setCompany(s => {
            if (s) {
                return {
                    ...s,
                    totalPrice: updatedPrice,
                    concatenatedPrice: undefined,
                };
            } else {
                return undefined;
            }
        });
        setData(s => {
            return s.map(company => {
                if (company.companyId == companyId) {
                    return {
                        ...company,
                        totalPrice: updatedPrice,
                    };
                }
                if (company.corporationId === corporationId && company.subRows) {
                    return {
                        ...company,
                        subRows: company.subRows.map(c => {
                            if (c.companyId == companyId) {
                                return {
                                    ...c,
                                    totalPrice: updatedPrice,
                                };
                            } else return c;
                        }),
                    };
                } else return company;
            });
        });
    };

    const updateDiscount = async (companyId: number, discount: number): Promise<void> => {
        await InvoiceService().updateDiscount(companyId, discount);
        setCompany(s => {
            if (s != undefined) {
                return {
                    ...s,
                    discount: discount,
                };
            } else {
                return undefined;
            }
        });
        updateItemsList(companyId);
    };

    const updateAdditionalFee = async (companyId: number, additionalFee: number): Promise<void> => {
        await InvoiceService().updateAdditionalFee(companyId, additionalFee);
        setCompany(s => {
            if (s) {
                return {
                    ...s,
                    additionalFee: additionalFee,
                };
            } else {
                return undefined;
            }
        });
        updateItemsList(companyId);
    };

    const updateCustomerNumber = async (companyId: number, customerNumber: number): Promise<void> => {
        const corporationId = company?.corporationId;
        await InvoiceService().updateCustomerNumber(companyId, customerNumber);
        setCompany(s => {
            if (s) {
                return {
                    ...s,
                    customerNumberNew: customerNumber,
                };
            } else {
                return undefined;
            }
        });
        setData(s => {
            return s.map(company => {
                if (company.companyId == companyId) {
                    return {
                        ...company,
                        customerNumber: customerNumber,
                    };
                }

                if (company.corporationId === corporationId && company.subRows) {
                    return {
                        ...company,
                        subRows: company.subRows.map(c => {
                            if (c.companyId == companyId) {
                                return {
                                    ...c,
                                    customerNumber: customerNumber,
                                };
                            } else return c;
                        }),
                    };
                } else return company;
            });
        });
    };

    const updateAttentionPerson = async (companyId: number, customerId: number): Promise<void> => {
        await InvoiceService().updateAttentionPerson(companyId, customerId);
        setCompany(s => {
            if (s) {
                return {
                    ...s,
                    attentionPerson: customerId,
                };
            } else {
                return undefined;
            }
        });
        attentionCandidates.forEach(ac => {
            if (ac.id === customerId) {
                setAttentionPerson(ac);
                return;
            }
        });
    };

    const updateInvoiceDescription = async (companyId: number, invoiceDescription: string): Promise<void> => {
        await InvoiceService().updateInvoiceDescription(companyId, invoiceDescription);
        setCompany(s => {
            if (s) {
                return {
                    ...s,
                    invoiceDescription: invoiceDescription,
                };
            } else {
                return undefined;
            }
        });
    };

    const updateAdminInfo = async (companyId: number, adminInfo: string): Promise<void> => {
        await InvoiceService().updateAdminInfo(companyId, adminInfo);
        setCompany(s => {
            if (s) {
                return {
                    ...s,
                    adminInfo: adminInfo,
                };
            } else {
                return undefined;
            }
        });
    };

    function formatStringDateToDate(stringDate: string): Date {
        return new Date(stringDate);
    }

    function formatDateToString(date: Date): string {
        return date ? date.toISOString().substring(0, 10) : '';
    }

    const handleChangeAgreementFrom = async (companyId: number, agreementFrom: Date) => {
        if (company) {
            const corporationId = company?.corporationId;
            const agreementPeriodModel: AgreementPeriodModel = await InvoiceService().updateAgreementFrom(company.id, agreementFrom);

            const newFrom = formatStringDateToDate(agreementPeriodModel.agreementFrom.toString());
            const newTo = formatStringDateToDate(agreementPeriodModel.agreementTo.toString());

            setCompany(s => {
                if (s) {
                    return {
                        ...s,
                        agreementFrom: newFrom,
                        agreementTo: newTo,
                    };
                } else {
                    return undefined;
                }
            });

            const agreementFromFormatted: string = formatDateToString(newFrom);
            const agreementToFormatted: string = formatDateToString(newTo);
            setData(s => {
                return s.map(company => {
                    if (company.companyId == companyId) {
                        return {
                            ...company,
                            agreementFrom: newFrom,
                            agreementTo: newTo,
                            agreementFromTo: agreementFromFormatted + ' - ' + agreementToFormatted,
                        };
                    }

                    if (company.corporationId === corporationId && company.subRows) {
                        return {
                            ...company,
                            subRows: company.subRows.map(c => {
                                if (c.companyId == companyId) {
                                    return {
                                        ...c,
                                        agreementFrom: newFrom,
                                        agreementTo: newTo,
                                        agreementFromTo: agreementFromFormatted + ' - ' + agreementToFormatted,
                                    };
                                } else return c;
                            }),
                        };
                    } else return company;
                });
            });
        }
    };

    const handleChangeAgreementTo = async (companyId: number, agreementTo: Date) => {
        if (company) {
            const corporationId = company?.corporationId;
            await InvoiceService().updateAgreementTo(company.id, agreementTo);
            const agreementFromFormatted: string = company.agreementFrom ? formatDateToString(company.agreementFrom) : '';
            const agreementToFormatted: string = formatDateToString(agreementTo);
            setCompany(s => {
                if (s) {
                    return {
                        ...s,
                        agreementTo: agreementTo,
                    };
                } else {
                    return undefined;
                }
            });
            setData(s => {
                return s.map(company => {
                    if (company.companyId == companyId) {
                        return {
                            ...company,
                            agreementTo: agreementTo,
                            agreementFromTo: agreementFromFormatted + ' - ' + agreementToFormatted,
                        };
                    }
                    if (company.corporationId === corporationId && company.subRows) {
                        return {
                            ...company,
                            subRows: company.subRows.map(c => {
                                if (c.companyId == companyId) {
                                    return {
                                        ...c,
                                        agreementTo: agreementTo,
                                        agreementFromTo: agreementFromFormatted + ' - ' + agreementToFormatted,
                                    };
                                } else return c;
                            }),
                        };
                    } else return company;
                });
            });
        }
    };

    const setSelectedCompaniesAsInvoiced = async () => {
        const companyAgreementPeriodModels: CompanyAgreementPeriodModel[] = await InvoiceService().markCompaniesAsInvoiced(getSelectedCompanyIds());
        const agreementPeriodModelRecord: Record<number, AgreementPeriodModel> = {};
        companyAgreementPeriodModels.forEach(item => (agreementPeriodModelRecord[item.companyId] = item.agreementPeriodModel));
        const newCompanyList = data.map(company => {
            if (company.companyId && !company.corporationId) {
                if (agreementPeriodModelRecord[company.companyId]) {
                    const agreementPeriodModel: AgreementPeriodModel = agreementPeriodModelRecord[company.companyId];
                    const newFrom = agreementPeriodModel.agreementFrom ? agreementPeriodModel.agreementFrom : undefined;
                    const newTo = agreementPeriodModel.agreementTo ? agreementPeriodModel.agreementTo : undefined;

                    return {
                        ...company,
                        invoiced: true,
                        agreementFrom: newFrom,
                        agreementTo: newTo,
                        agreementFromTo: newFrom + ' - ' + newTo,
                    };
                } else return company;
            } else if (!company.companyId && company.corporationId && company.subRows) {
                return {
                    ...company,
                    subRows: company.subRows.map(c => {
                        if (c.companyId && agreementPeriodModelRecord[c.companyId]) {
                            const agreementPeriodModel: AgreementPeriodModel = agreementPeriodModelRecord[c.companyId];
                            const newFrom = agreementPeriodModel.agreementFrom ? agreementPeriodModel.agreementFrom : undefined;
                            const newTo = agreementPeriodModel.agreementTo ? agreementPeriodModel.agreementTo : undefined;
                            return {
                                ...c,
                                invoiced: true,
                                agreementFro: newFrom,
                                agreementTo: newTo,
                                agreementFromTo: newFrom + ' - ' + newTo,
                            };
                        } else return c;
                    }),
                };
            } else return company;
        });
        setData(newCompanyList);
    };

    const setSelectedCompaniesAsNotInvoiced = async () => {
        await InvoiceService().markCompaniesAsNotInvoiced(getSelectedCompanyIds());
        const newCompanyList = data.map(company => {
            if (company.companyId && checked[company.companyId]) {
                return {
                    ...company,
                    invoiced: false,
                };
            } else if (!company.companyId && company.corporationId && company.subRows) {
                return {
                    ...company,
                    subRows: company.subRows.map(c => {
                        if (c.companyId && checked[c.companyId]) {
                            return {
                                ...c,
                                invoiced: false,
                            };
                        } else return c;
                    }),
                };
            } else return company;
        });
        setData(newCompanyList);
    };

    const markCompanyAsNotInvoiced = async (companyId: number) => {
        if (company) {
            const corporationId = company?.corporationId;
            await InvoiceService().markCompaniesAsNotInvoiced([companyId]);
            setCompany(company => (company ? { ...company, invoiceSent: false } : undefined));
            const newCompanyList = data.map(company => {
                if (company.companyId == companyId) {
                    return {
                        ...company,
                        invoiced: false,
                    };
                }

                if (company.corporationId === corporationId && company.subRows) {
                    return {
                        ...company,
                        subRows: company.subRows.map(c => {
                            if (c.companyId == companyId) {
                                return {
                                    ...c,
                                    invoiced: false,
                                };
                            } else return c;
                        }),
                    };
                } else return company;
            });
            setData(newCompanyList);
        }
    };

    //TODO Refactor setSelectedCompaniesAsInvoiced and this method to the same
    const markCompanyAsInvoiced = async (companyId: number) => {
        const corporationId = company?.corporationId;
        const companyAgreementPeriodModels: CompanyAgreementPeriodModel[] = await InvoiceService().markCompaniesAsInvoiced([companyId]);
        let newFrom: Date;
        let newTo: Date;

        if (companyAgreementPeriodModels[0].agreementPeriodModel.agreementFrom && companyAgreementPeriodModels[0].agreementPeriodModel.agreementTo) {
            newFrom = formatStringDateToDate(companyAgreementPeriodModels[0].agreementPeriodModel.agreementFrom.toString());
            newTo = formatStringDateToDate(companyAgreementPeriodModels[0].agreementPeriodModel.agreementTo.toString());
        }

        setCompany(company =>
            company
                ? {
                      ...company,
                      invoiceSent: true,
                      agreementFrom: newFrom,
                      agreementTo: newTo,
                  }
                : undefined,
        );
        const agreementPeriodModelRecord: Record<number, AgreementPeriodModel> = {};
        companyAgreementPeriodModels.forEach(item => (agreementPeriodModelRecord[item.companyId] = item.agreementPeriodModel));

        setData(s => {
            return s.map(company => {
                if (company.companyId) {
                    if (agreementPeriodModelRecord[company.companyId]) {
                        const agreementPeriodModel: AgreementPeriodModel = agreementPeriodModelRecord[company.companyId];
                        const newFrom = agreementPeriodModel.agreementFrom ? agreementPeriodModel.agreementFrom : undefined;
                        const newTo = agreementPeriodModel.agreementTo ? agreementPeriodModel.agreementTo : undefined;
                        return {
                            ...company,
                            invoiced: true,
                            agreementFrom: newFrom,
                            agreementTo: newTo,
                            agreementFromTo: newFrom + ' - ' + newTo,
                        };
                    } else return company;
                }

                if (company.corporationId === corporationId && company.subRows) {
                    return {
                        ...company,
                        subRows: company.subRows.map(c => {
                            if (c.companyId && agreementPeriodModelRecord[c.companyId]) {
                                const agreementPeriodModel: AgreementPeriodModel = agreementPeriodModelRecord[c.companyId];
                                const newFrom = agreementPeriodModel.agreementFrom ? agreementPeriodModel.agreementFrom : undefined;
                                const newTo = agreementPeriodModel.agreementTo ? agreementPeriodModel.agreementTo : undefined;

                                return {
                                    ...c,
                                    invoiced: true,
                                    agreementFro: newFrom,
                                    agreementTo: newTo,
                                    agreementFromTo: newFrom + ' - ' + newTo,
                                };
                            } else return c;
                        }),
                    };
                } else return company;
            });
        });
    };

    const initAttentionPerson = () => {
        const attentionCandidatesList: { id: number; text: string }[] = [];
        const emptyCandidateSlot = { id: -1, text: 'Ingen referens' };

        if (company && company.users) {
            if (company.attentionPerson) {
                company.users.forEach(u => {
                    if (u.userId == company.attentionPerson) {
                        const selectedAttentionPerson = { id: u.userId, text: u.fullName };
                        setAttentionPerson(selectedAttentionPerson);
                    }
                });
            } else {
                setAttentionPerson(undefined);
            }

            company.users.forEach(u => {
                for (const role of u.roles) {
                    if (role === 'superuser') {
                        attentionCandidatesList.push({ id: u.userId, text: u.fullName });
                    }
                }
            });
            attentionCandidatesList.unshift(emptyCandidateSlot);
            setAttentionCandidates(attentionCandidatesList);
        }
    };

    const generateExcelExportForSelectedCompanies = async () => {
        setLoadingText(t('loadingTextExportExcel'));
        setLoading(true);
        try {
            await InvoiceService().generateExcelExportForSelectedCompanies(getSelectedCompanyIds());
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
        setLoadingText(t('loadingTextDefault'));
    };

    const fetchCompany = async (companyId: number) => {
        const response: CompanyInvoiceFullModel = await InvoiceService().getFullCompany(companyId);
        response.agreementFrom = response.agreementFrom ? formatStringDateToDate(response.agreementFrom.toString()) : response.agreementFrom;
        response.agreementTo = response.agreementTo ? formatStringDateToDate(response.agreementTo.toString()) : response.agreementTo;
        setCompany(response);
    };

    useEffect(() => {
        if (selectedCompanyId && selectedCompanyId > -1) {
            fetchCompany(selectedCompanyId);
        } else {
            setCompany(undefined);
        }
    }, [selectedCompanyId]);

    const fetchData = async () => {
        const response = await InvoiceService().getAllCompanies();
        const allCompanyIds: number[] = [];
        try {
            const corporationCompaniesMapREC: Record<number, number[]> = {};
            const initialData: Data[] = [];
            response.companyInvoiceModels?.forEach(company => {
                initialData.push({
                    companyId: company.id,
                    corporationId: company.corporationId,
                    name: company.name,
                    type: company.type,
                    lawTypes: company.lawTypes,
                    features: '',
                    customerNumber: company.customerNumberNew,
                    customerReferenceNumber: '', //TODO UPDATE IT IT SHOULD BE INCLUDED IN THE FETCHED OBJECT
                    agreementFromTo: company.agreementFrom + ' - ' + company.agreementTo,
                    agreementFrom: company.agreementFrom,
                    agreementTo: company.agreementTo,
                    totalPrice: company.totalPrice,
                    invoiced: company.invoiceSent,
                    checked: company.invoiceSent,
                    subRows: [],
                });
                allCompanyIds.push(company.id);
            });
            response.corporationInvoiceModel?.forEach(corporation => {
                initialData.push({
                    companyId: undefined,
                    corporationId: corporation.id,
                    name: corporation.name,
                    type: 'GROUP',
                    lawTypes: [],
                    features: '',
                    customerNumber: undefined,
                    customerReferenceNumber: '', //TODO UPDATE IT IT SHOULD BE INCLUDED IN THE FETCHED OBJECT
                    agreementFromTo: '',
                    agreementFrom: undefined,
                    agreementTo: undefined,
                    totalPrice: undefined,
                    invoiced: undefined,
                    checked: undefined,
                    subRows: corporation.companyList.map(company => ({
                        companyId: company.id,
                        corporationId: company.corporationId,
                        name: company.name,
                        type: company.type,
                        lawTypes: company.lawTypes,
                        features: '',
                        customerNumber: company.customerNumberNew,
                        customerReferenceNumber: '', //TODO UPDATE IT IT SHOULD BE INCLUDED IN THE FETCHED OBJECT
                        agreementFromTo: company.agreementFrom + ' ' + company.agreementTo,
                        agreementFrom: company.agreementFrom,
                        agreementTo: company.agreementTo,
                        totalPrice: company.totalPrice,
                        concatenatedPrice: company.concatenatedPrice,
                        invoiced: company.invoiceSent,
                        checked: company.invoiceSent,
                        subRows: [],
                    })),
                });
                const corporationCompaniesIds: number[] = [];
                corporation.companyList.forEach(company => {
                    allCompanyIds.push(company.id);
                    corporationCompaniesIds.push(company.id);
                });
                corporationCompaniesMapREC[corporation.id] = corporationCompaniesIds;
            });

            setData(initialData);
            setAllCompanyIds(allCompanyIds);
            setCorporationCompaniesMap(corporationCompaniesMapREC);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleResetAllPricesForGroup = async (companyId: number): Promise<void> => {
        setLoadingText(t('loadingTextResetAllPrices'));
        setLoading(true);
        await InvoiceService()
            .resetAllPricesForGroup(companyId)
            .then(n => {
                //TODO See of its possible to only update the affected group companies.
                fetchData();
                fetchCompany(companyId);
                setLoading(false);
                setLoadingText(t('loadingTextDefault'));
            });
    };

    const handleUploadCompanyDocument = async (type: CompanyDocumentTypeEnum, file: File): Promise<void> => {
        if (company) {
            const uploadCompanyDocumentModel: UploadCompanyDocumentModel = {
                type: type,
                companyId: company.id,
            };

            setLoadingText(t('loadingTextSaveDocument'));
            setLoading(true);
            await InvoiceService()
                .uploadCompanyDocument(uploadCompanyDocumentModel, file)
                .then(n => {
                    if (uploadCompanyDocumentModel.companyId) {
                        fetchCompany(uploadCompanyDocumentModel.companyId);
                    }
                    setLoading(false);
                    setLoadingText(t('loadingTextDefault'));
                })
                .catch(err => {
                    setLoading(false);
                    setLoadingText(t('loadingTextDefault'));
                });
        }
    };

    const handleDeleteCompanyDocument = async (documentId: number): Promise<void> => {
        if (company) {
            setLoadingText(t('loadingTextDeleteDocument'));
            setLoading(true);
            await InvoiceService()
                .deleteCompanyDocument(company.id, documentId)
                .then(n => {
                    fetchCompany(company.id);
                    setLoading(false);
                    setLoadingText(t('loadingTextDefault'));
                })
                .catch(err => {
                    setLoading(false);
                    setLoadingText(t('loadingTextDefault'));
                });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return {
        fetchData,
        setLoading,
        loading,
        loadingText,
        data,
        toggleChecked,
        checkCorporation,
        checkedCorporations,
        checked,
        checkAll,
        allChecked,
        fetchCompany,
        initAttentionPerson,
        company,
        setSelectedCompanyId,
        selectedCompanyId,
        updateCustomerReferenceNumber,
        updateCustomerNumber,
        updateAttentionPerson,
        handleChangeAgreementFrom,
        handleChangeAgreementTo,
        attentionCandidates,
        attentionPerson,
        handleUpdatePrice,
        handleResetAllPricesForGroup,
        updateDiscount,
        updateAdditionalFee,
        updateInvoiceDescription,
        updateAdminInfo,
        sorting,
        handleSortingChange,
        setSelectedCompaniesAsInvoiced,
        setSelectedCompaniesAsNotInvoiced,
        markCompanyAsNotInvoiced,
        markCompanyAsInvoiced,
        generateExcelExportForSelectedCompanies,
        handleUploadCompanyDocument,
        handleDeleteCompanyDocument,
    };
};

export default useInvoicing;
