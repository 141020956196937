import React, { FC, useMemo, useEffect, useState } from 'react';
import styled from './CompanyUsers.module.css';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import Table from '../../../../components/Table/Table';
import { Column } from 'react-table';
import UserModel, { EditUserModel, isEditUser } from '../../../../models/UserModel';
import DenseCell from '../../../../components/Table/DenseCell/DenseCell';
import { RoleEnum, sortRoles, getDisplayRoles } from '../../../../models/Role';
import MenuCell, { isLastRow } from '../../../../components/Table/MenuCell/MenuCell';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import UserService from '../../../../services/UserService';
import useAuth from '../../../../hooks/useAuth';
import EditUserModal from '../../../../components/Modal/EditUserModal/EditUserModal';
import RemoveUserModal from '../../../../components/Modal/RemoveUserModal/RemoveUserModal';
import useExport from '../../../../hooks/useExport';
import statusOkIcon from '../../../../assets/images/statusOkIcon.svg';
import { HELP_CONTACT } from '../../../../constants/Routes';
import { useTranslation } from 'react-i18next';
import ExcelColumnWidth, { columnKeys } from '../../../../constants/ExcelColumnWidth';
import { ReactComponent as AccordionIcon } from '../../../../assets/images/accordionIcon.svg';

const CompanyUsers: FC = () => {
    const { t, i18n } = useTranslation();
    const { company, isAboveUser, isAboveSuperUser } = useAuth();
    const { exportUserList } = useExport();

    const [hasExternalUsers, setHasExternalUsers] = useState(false);
    const [data, setData] = useState<(UserModel & { externalUser: boolean })[]>([]);
    const [loading, setLoading] = useState(false);
    const [showInformation, setShowInformation] = useState(true);
    const [userModal, setUserModal] = useState<{ open: boolean; editUser: UserModel | undefined }>({ open: false, editUser: undefined });
    const [removeModal, setRemoveModal] = useState<{ open: boolean; userId?: number; userName?: string; externalUser?: boolean }>({
        open: false,
    });

    const fetchData = async () => {
        try {
            setLoading(true);

            const users = await UserService().getCompanyUsers(company?.id || -1);
            const externalUsers = await UserService().getCompanyExternalUsers(company?.id || -1);

            setData([...users.map(u => ({ ...u, externalUser: false })), ...externalUsers.map(u => ({ ...u, externalUser: true }))]);
            setHasExternalUsers(externalUsers.length > 0);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleEdit = (id: number) => {
        const editUser = data.find(u => u.userId === id);
        if (editUser) {
            setUserModal({ open: true, editUser });
        }
    };

    const handleRemove = async (userId: number) => {
        if (company) {
            try {
                const isExternalUser = removeModal.externalUser as boolean;
                setRemoveModal({ open: false });
                setLoading(true);

                if (isExternalUser) {
                    await UserService().removeExternalUser(company.id, userId);
                } else {
                    await UserService().removeUser(userId);
                }
                setData(s => s.filter(u => u.userId !== userId));

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const openRemoveModal = (userId: number, externalUser: boolean) => {
        const user = data.find(u => u.userId === userId);
        if (user) {
            setUserModal({ open: false, editUser: undefined });
            setRemoveModal({ open: true, userId, userName: user.userName, externalUser });
        }
    };

    const handleSave = async (userData: EditUserModel) => {
        setUserModal({ open: false, editUser: undefined });
        setLoading(true);

        try {
            if (isEditUser(userData)) {
                const user = await UserService().updateUser(userData);
                setData(s => s.map(u => (u.userId === userData.userId ? { ...u, ...user } : u)));
            } else {
                const user = await UserService().createUser(userData);
                setData(s => [...s, { ...user, externalUser: false }]);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const handleExport = () => {
        const exportColumns: Array<{ key: columnKeys; Header: string; width: number }> = [
            { key: 'userFullName', Header: t('columnName'), width: ExcelColumnWidth.userFullName },
            { key: 'email', Header: t('columnEmail'), width: ExcelColumnWidth.email },
            { key: 'roles', Header: t('columnRoles'), width: ExcelColumnWidth.roles },
            { key: 'createdAt', Header: t('columnCreated'), width: ExcelColumnWidth.createdAt },
            { key: 'lastLogin', Header: t('columnLoggedInAt'), width: ExcelColumnWidth.lastLogin },
            ...(hasExternalUsers ? [{ key: 'externalUser' as columnKeys, Header: t('columnExternalUser'), width: ExcelColumnWidth.externalUser }] : []),
        ];

        const exportData = data.map(user => ({
            userFullName: user.fullName,
            email: user.userName,
            roles: getDisplayRoles(user.roles)
                .map(role => t(RoleEnum[role]))
                .join(',\n'),
            createdAt: user.createdAt,
            lastLogin: user.lastLogin || t('never'),
            ...(hasExternalUsers ? { externalUser: user.externalUser ? 'X' : '' } : {}),
        }));

        exportUserList(exportColumns, exportData, 'Användare');
    };

    const handleToggleShowInformation = () => {
        setShowInformation(s => !s);
    };

    const columns = useMemo(() => {
        const columns: Array<Column<UserModel & { externalUser: boolean }> & { Header: string; languageId?: string }> = [
            {
                id: 'name',
                Header: t('columnName'),
                languageId: 'columnName',
                Cell: DenseCell,
                width: 1,
                accessor: row => row.fullName,
            },
            {
                id: 'email',
                Header: t('columnEmail'),
                languageId: 'columnEmail',
                Cell: DenseCell,
                width: 1,
                accessor: row => row.userName,
            },
            {
                id: 'roles',
                Header: t('columnRoles'),
                languageId: 'columnRoles',
                Cell: DenseCell,
                width: 1,
                accessor: row =>
                    getDisplayRoles(row.roles)
                        .sort(sortRoles)
                        .map(role => t(RoleEnum[role]))
                        .join(', '),
            },
            {
                id: 'lastLogin',
                Header: t('columnLoggedInAt'),
                languageId: 'columnLoggedInAt',
                width: 1,
                Cell: DenseCell,
                accessor: row => row.lastLogin || t('never'),
            },
            {
                id: 'createdAt',
                Header: t('columnCreated'),
                languageId: 'columnCreated',
                width: 1,
                Cell: DenseCell,
                accessor: row => row.createdAt,
            },
        ];

        if (hasExternalUsers) {
            columns.push({
                id: 'externalUsers',
                Header: t('columnExternalUser'),
                languageId: 'columnExternalUser',
                width: 1,
                Cell: function ExternalUserCell(props: any) {
                    return <DenseCell {...props} />;
                },
                accessor: function ExternalUserAccessor(row) {
                    return row.externalUser ? <img src={statusOkIcon} /> : '';
                },
            });
        }

        columns.push({
            id: 'menu',
            Header: '',
            width: 1,
            accessor: row => row.userId,
            Cell: function Cell({ row, sortedRows }) {
                const { userId, roles, externalUser } = row.original;
                const isSuperUser = roles.includes('superuser');
                const isEditable = !isSuperUser || isAboveSuperUser();

                const lastRow = isLastRow(row, sortedRows);
                return (
                    <MenuCell
                        title={t('buttonManageUser')}
                        lastRow={lastRow}
                        items={[
                            { text: t('buttonEdit'), function: isEditable && !externalUser ? () => handleEdit(userId) : () => undefined, disabled: !isEditable || externalUser },
                            { text: t('buttonRemove'), function: isEditable ? () => openRemoveModal(userId, externalUser) : () => undefined, disabled: !isEditable },
                        ]}
                    />
                );
            },
        });

        return columns;
    }, [handleEdit, openRemoveModal, hasExternalUsers, i18n.language]);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={styled.CompanyUsers}>
            {loading && <LoadingSpinner />}
            <div className={styled.Top}>
                <h3 className={styled.Title}>{t('settingsMenuCompanyUsers')}</h3>

                <div className={styled.Information}>
                    <h6 onClick={handleToggleShowInformation}>
                        {t('companyUsersTitle')} <AccordionIcon className={[styled.Icon, showInformation ? styled.Open : ''].join(' ')} />
                    </h6>
                    {showInformation && (
                        <>
                            <p>{t('companyUsersText1')}</p>
                            <p>{t('companyUsersText2')}</p>
                            <p>{t('companyUsersText3')}</p>
                            <p>
                                {t('companyUsersText4')} <Link to={{ pathname: HELP_CONTACT, state: { subject: 'SUPERUSER' } }}>{t('contactUs')}</Link>
                            </p>
                        </>
                    )}
                    <div className={styled.Buttons}>
                        <Button variant="Outline" onClick={handleExport} disabled={!isAboveUser()}>
                            {t('buttonExportList')}
                        </Button>
                        {isAboveUser() && (
                            <Button variant="Primary" onClick={() => setUserModal({ open: true, editUser: undefined })}>
                                {t('buttonCreateNewUser')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            <div className={styled.TableWrapper}>
                <Table
                    data={data}
                    columns={columns}
                    columnSearch={[]}
                    sorting={[]}
                    showColumnSearch={false}
                    globalSearch=""
                    headerClassName={styled.TableHeader}
                    rowHeight={48}
                    disableSorting
                />
            </div>

            {userModal.open && (
                <EditUserModal
                    editUser={userModal.editUser}
                    onCancel={() => setUserModal({ open: false, editUser: undefined })}
                    onRemove={() => openRemoveModal(userModal.editUser?.userId || -1, false)}
                    onSave={handleSave}
                />
            )}

            {removeModal.open && removeModal.userName && removeModal.userId && (
                <RemoveUserModal
                    externalUser={removeModal.externalUser as boolean}
                    userName={removeModal.userName}
                    onCancel={() => setRemoveModal({ open: false })}
                    onConfirm={() => handleRemove(removeModal.userId || -1)}
                />
            )}
        </div>
    );
};

export default CompanyUsers;
