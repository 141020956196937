import React, { FC, useEffect, useState } from 'react';
import { REVISION_UNFINISHED, REVISION_FINISHED, REVISION_CREATE, REVISION_EDIT, REVISION_ALTER } from '../../../constants/Routes';
import ongoingRevisionIcon from '../../../assets/images/ongoingRevisionIcon.svg';
import finishedRevisionIcon from '../../../assets/images/finishedRevisionIcon.svg';
import styled from './UnfinishedRevision.module.css';
import ContextMenu from '../../../components/ContextMenu/ContextMenu';
import Filterbar from '../../../components/Filterbar/Filterbar';
import useUnfinishedRevision from '../../../hooks/useUnfinishedRevision';
import Button from '../../../components/Button/Button';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import UnfinishedRevisionTable from '../../../components/Table/UnfinishedRevisionTable/UnfinishedRevisionTable';
import CreateRevisionModal from '../../../components/Modal/CreateRevisionModal/CreateRevisionModal';
import { useHistory } from 'react-router-dom';
import RemoveRevisionModal from '../../../components/Modal/RemoveRevisionModal/RemoveRevisionModal';
import useAuth from '../../../hooks/useAuth';
import EmptyListView from '../../../components/EmptyListView/EmptyListView';
import { useTranslation } from 'react-i18next';
import RevisionService from '../../../services/RevisionService';

const UnfinishedRevision: FC = () => {
    const { t } = useTranslation();

    const CONTEXT_LINKS: {
        title: string;
        url: string;
        icon: string;
        active: boolean;
    }[] = [
        { title: t('contextMenuUnfinishedRevisions'), url: REVISION_UNFINISHED, icon: ongoingRevisionIcon, active: true },
        { title: t('contextMenuFinishedRevisions'), url: REVISION_FINISHED, icon: finishedRevisionIcon, active: false },
    ];

    const history = useHistory();
    const {
        revisions,
        loading,
        globalSearch,
        fetchRevisions,
        handleGlobalSearchChange,
        setCreateRevisionInfo,
        handleUpdateRevision,
        handleRemoveRevision,
        handleExport,
        setLoading,
    } = useUnfinishedRevision();
    const { isRevisor, fetchNumberOfUnfinishedRevisionsForCompany, numberOfUnfinishedRevisionsForCompany, company } = useAuth();
    const [infoModal, setInfoModal] = useState<{ open: boolean; edit: { id: number; version: number; name: string; userId: number } | undefined }>({
        open: false,
        edit: undefined,
    });
    const [removeModal, setRemoveModal] = useState<{ open: boolean; id: number }>({ open: false, id: -1 });
    const [visibleDataCount, setVisibleDataCount] = useState<number>();
    const [revisionWarnings, setRevisionWarnings] = useState<number>();

    useEffect(() => {
        fetchRevisions();
    }, []);

    useEffect(() => {
        if (numberOfUnfinishedRevisionsForCompany !== revisions.length) {
            fetchNumberOfUnfinishedRevisionsForCompany();
        }
        if (revisions.length < 1 && company) {
            setLoading(true);
            RevisionService()
                .getNumberOfRevisionWarnings(company.id)
                .then(revisionWarnings => {
                    setRevisionWarnings(revisionWarnings);
                    setLoading(false);
                });
        }
    }, [revisions, revisions.length]);

    const openCreateModal = () => {
        setInfoModal({ open: true, edit: undefined });
    };

    const openEditModal = (id: number) => {
        const revision = revisions.find(r => r.id === id);

        if (revision) {
            setInfoModal({ open: true, edit: { id, version: revision.version, name: revision.name, userId: revision.responsibleUserId } });
        }
    };

    const closeInfoModal = () => {
        setInfoModal({ open: false, edit: undefined });
    };

    const handleSubmitRevision = (name: string, userId: number, username: string): void => {
        if (infoModal.edit) {
            handleUpdateRevision(infoModal.edit.id, infoModal.edit.version, name, userId, closeInfoModal);
        } else {
            setCreateRevisionInfo({ name, userId, username });
            history.push(REVISION_CREATE);
        }
    };

    const handleAlterRevision = (id: number): void => {
        history.push(REVISION_ALTER + `/${id}`);
    };

    const confirmRemoveModal = () => {
        handleRemoveRevision(removeModal.id, () => {
            setRemoveModal({ open: false, id: -1 });
        });
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            <ContextMenu title={t('contextMenuRevisionTitle')} links={CONTEXT_LINKS} visibleDataCount={0} totalDataCount={1} />
            <div className={styled.List}>
                <Filterbar globalSearch={globalSearch} onGlobalSearchChange={handleGlobalSearchChange}>
                    {isRevisor() ? (
                        <Button variant="Primary" className={styled.CreateButton} onClick={openCreateModal}>
                            {t('buttonCreateNewRevision')}
                        </Button>
                    ) : (
                        <div />
                    )}
                </Filterbar>

                {!loading && revisions.length < 1 ? (
                    <EmptyListView title={t('emptyListViewNoUnfinishedRevisionsTitle')} text={t('emptyListViewNoUnfinishedRevisionsText', { revisionWarnings })} />
                ) : !loading && visibleDataCount !== undefined && visibleDataCount < 1 ? (
                    <EmptyListView emptyFilter />
                ) : null}
                <UnfinishedRevisionTable
                    data={revisions}
                    globalSearch={globalSearch}
                    loading={loading}
                    onOpen={id => history.push(REVISION_EDIT + `/${id}`)}
                    onEdit={id => openEditModal(id)}
                    onAlterRevision={id => handleAlterRevision(id)}
                    onRemove={id => setRemoveModal({ open: true, id })}
                    onExport={id => handleExport(id)}
                    onVisibleDataCountChange={setVisibleDataCount}
                />
            </div>

            {infoModal.open && <CreateRevisionModal edit={infoModal.edit} onCancel={closeInfoModal} onConfirm={handleSubmitRevision} />}
            {removeModal.open && <RemoveRevisionModal onCancel={() => setRemoveModal({ open: false, id: -1 })} onConfirm={confirmRemoveModal} />}
        </>
    );
};

export default UnfinishedRevision;
