import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import styled from './Button.module.css';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant: 'Primary' | 'Secondary' | 'Light' | 'Outline';
    children: ReactNode;
    className?: string;
    big?: boolean;
}

const Button: React.FC<Props> = ({ variant, className = '', big = false, children, ...props }) => {
    return (
        <button type="button" className={[styled.Button, styled[variant], big ? styled.Big : '', className].join(' ')} {...props}>
            {children}
        </button>
    );
};

export default Button;
