import React, { FC } from 'react';
import styled from './WarningModal.module.css';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { Trans, useTranslation } from 'react-i18next';
import statusWarningIcon from '../../../../assets/images/statusWarningIcon.svg';

interface Props {
    onAccept: () => void;
    onClose: () => void;
    title: string;
    subtitle: string;
    className?: string;
}

const WarningModal: FC<Props> = ({ onAccept, onClose, title, subtitle, className }) => {
    const { t } = useTranslation();

    return (
        <Modal title={title} subtitle={subtitle} className={[styled.Modal, className ? className : ''].join(' ')}>
            <div className={styled.Content}>
                <img src={statusWarningIcon} alt="warning" className={styled.WarningIcon} />
                <div className={styled.Options}>
                    <Button variant="Primary" onClick={onClose}>
                        {t('buttonNo')}
                    </Button>
                    <Button variant="Outline" onClick={onAccept}>
                        {t('buttonYes')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default WarningModal;
