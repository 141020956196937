import React, { FC, useEffect, useState } from 'react';
import Button from '../../Button/Button';
import styled from './MediaPreviewModal.module.css';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal/Modal';
import RevisionMediaAttachmentModel from '../../../models/RevisionMediaAttachmentModel';

interface Props {
    selectedMedia: RevisionMediaAttachmentModel | undefined;
    onCancel: () => void;
}
const BASE_64_STRING = 'data:image/png;base64,';
const MediaPreviewModal: FC<Props> = ({ selectedMedia, onCancel }) => {
    const { t } = useTranslation();
    const [filePreview, setFilePreview] = useState<any>();

    useEffect(() => {
        if (selectedMedia) {
            if (selectedMedia.fullData) {
                setFilePreview(BASE_64_STRING + selectedMedia.fullData);
            } else {
                setFilePreview(BASE_64_STRING + selectedMedia.thumbnail);
            }
        }
    }, [selectedMedia, selectedMedia?.fullData]);

    const handleOnCancel = () => {
        setFilePreview(undefined);
        onCancel();
    };

    return (
        <>
            <Modal title={''} className={styled.ImageModal}>
                <div className={styled.Container}>
                    <div className={styled.ModalTopBar}></div>
                    <div className={styled.ImageContainer}>
                        <img className={styled.ImagePreview} src={filePreview} />
                    </div>

                    <div className={styled.Buttons}>
                        <Button variant="Outline" onClick={handleOnCancel}>
                            {t('buttonClose')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default MediaPreviewModal;
