import React, { FC, useMemo } from 'react';
import styled from './ProgressBar.module.css';

interface Props {
    progress: number;
    total: number;
    stacked?: boolean;
    text?: string;
}

const ProgressBar: FC<Props> = ({ progress, total, stacked = false, text }) => {
    const percentage = useMemo(() => (progress / total) * 100, [progress, total]);

    return (
        <div className={[styled.Container, stacked ? styled.Stacked : ''].join(' ')}>
            <p className={styled.Text}>
                {progress}/{total} {text && stacked ? text : ''}
            </p>
            <div className={styled.Bar}>
                <div className={styled.Progress} style={{ width: `${percentage}%` }} />
            </div>
        </div>
    );
};

export default ProgressBar;
