import React, { FC } from 'react';
interface Props {
    data: string[];
}

const AccordionListItem: FC<Props> = ({ data }) => {
    return <div>{data.length ? data.map(item => <div key={item}>{item}</div>) : ''}</div>;
};

export default AccordionListItem;
