import React, { useState, createRef } from 'react';
import styled from './FilterbarMenu.module.css';
import useClickOutside from '../../../hooks/useClickOutside';
import moreIcon from '../../../assets/images/moreIcon.svg';
import moreIconActive from '../../../assets/images/moreIconActive.svg';
import FilterbarMenuItem from './FilterbarMenuItem/FilterbarMenuItem';
import { Column } from 'react-table';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

interface Props {
    columns?: Array<Partial<Column & { visible: boolean }>>;
    onColumnShowChange?: (id: string) => void;
    onSaveUserSettings?: () => void;
    onExport?: () => void;
    onExportRevisionQuestionsExcel?: () => void;
    onAlterRevision?: () => void;
}

const FilterbarMenu: React.FC<Props> = ({ columns, onColumnShowChange, onSaveUserSettings, onExport, onExportRevisionQuestionsExcel, onAlterRevision }) => {
    const { t } = useTranslation();
    const { isPrimaryCompany, company, signedInAsUser, isAboveSuperUser } = useAuth();
    const [open, setOpen] = useState(false);
    const menuRef = createRef<HTMLDivElement>();

    useClickOutside(menuRef, () => {
        setOpen(false);
    });

    return (
        <div ref={menuRef} className={[styled.FilterbarMenu, open ? styled.Active : ''].join(' ')}>
            <div className={styled.FilterbarHeader} onClick={() => setOpen(!open)}>
                <img src={open ? moreIconActive : moreIcon} alt="More" className={styled.Icon} />
            </div>
            <ul className={styled.FilterbarList}>
                {onSaveUserSettings && (isPrimaryCompany || (signedInAsUser !== undefined && isAboveSuperUser())) && (
                    <FilterbarMenuItem
                        title={t('filterbarSavePersonalFilter')}
                        onSelect={() => {
                            setOpen(false);
                            onSaveUserSettings();
                        }}
                    />
                )}
                {columns !== undefined && onColumnShowChange && <FilterbarMenuItem title={t('filterbarSelectColumns')} columns={columns} onColumnChange={onColumnShowChange} />}
                {onExport && (
                    <FilterbarMenuItem
                        title={t('filterbarExportToExcel')}
                        onSelect={() => {
                            setOpen(false);
                            onExport();
                        }}
                    />
                )}
                {onExportRevisionQuestionsExcel && company && company.hasRevisionQuestions && (
                    <FilterbarMenuItem
                        title={t('filterbarExportRevisionQuestions')}
                        subItems={[{ title: t('filterbarExportToExcel'), function: onExportRevisionQuestionsExcel }]}
                    />
                )}
                {onAlterRevision && company && <FilterbarMenuItem title={t('alterRevisionMenuText')} onSelect={onAlterRevision} />}
            </ul>
        </div>
    );
};

export default FilterbarMenu;
