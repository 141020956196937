export type Role = 'superadmin' | 'admin' | 'consultant' | 'superuser' | 'revisor' | 'editor' | 'assessor' | 'delegate_assessor' | 'user' | 'anonymous_see_revisions' | 'anonymous';

export enum RoleEnum {
    'superadmin' = 'roleSuperadmin',
    'admin' = 'roleAdmin',
    'consultant' = 'roleConsultant',
    'superuser' = 'roleSuperuser',
    'revisor' = 'roleRevisor',
    'editor' = 'roleEditor',
    'assessor' = 'roleAssessor',
    'delegate_assessor' = 'roleDelegateAssessor',
    'user' = 'roleUser',
    'anonymous_see_revisions' = 'roleAnonymousSeeRevisions',
    'anonymous' = 'roleAnonymous',
}

export const OrderedRoleList = [
    'superadmin',
    'admin',
    'consultant',
    'superuser',
    'revisor',
    'editor',
    'assessor',
    'delegate_assessor',
    'user',
    'anonymous_see_revisions',
    'anonymous',
];

export const sortRoles = (a: any, b: any): number => {
    return OrderedRoleList.indexOf(a) - OrderedRoleList.indexOf(b);
};

export const getDisplayRoles = (roles: Role[]): Role[] => {
    if (roles.includes('superadmin') || roles.includes('admin') || roles.includes('consultant') || roles.includes('superuser')) {
        let highestRole: Role = 'anonymous';
        roles.forEach(role => {
            if (OrderedRoleList.indexOf(role) < OrderedRoleList.indexOf(highestRole)) {
                highestRole = role;
            }
        });
        return [highestRole];
    }

    return roles.filter(role => {
        return role === 'revisor' || role === 'editor' || role === 'assessor' || role === 'delegate_assessor';
    });
};
