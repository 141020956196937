import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from './ChangeList.module.css';
import ContextMenu from '../../../components/ContextMenu/ContextMenu';
import useChangeList from '../../../hooks/useChangeList';
import Filterbar from '../../../components/Filterbar/Filterbar';
import eyeIcon from '../../../assets/images/eyeIcon.svg';
import archiveIcon from '../../../assets/images/archiveIcon.svg';
import { CHANGES_LIST, CHANGES_ARCHIVE } from '../../../constants/Routes';
import Table from '../../../components/Table/Table';
import Toast from '../../../components/Toast/Toast';
import AcknowledgeChangeView from '../../../components/AcknowledgeChangeView/AcknowledgeChangeView';
import AcknowledgeEditView from '../../../components/AcknowledgeEditView/AcknowledgeEditView';
import _ from 'lodash';
import ChangeFullView from '../../../components/FullView/ChangeFullView/ChangeFullView';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import AcknowledgeNewChangeView from '../../../components/AcknowledgeNewChangeView/AcknowledgeNewChangeView';
import EditView from '../../../components/EditView/EditView';
import LawListModel from '../../../models/LawListModel';
import DelegateEditor from '../../../components/DelegateEditor/DelegateEditor';
import { AssessmentChoice } from '../../../services/SubscriptionChangeService';
import { useParams } from 'react-router-dom';
import SubscriptionModel from '../../../models/SubscriptionModel';
import useAuth from '../../../hooks/useAuth';
import EditOldWarningModal from '../../../components/Modal/EditOldWarningModal/EditOldWarningModal';
import EditLatestSubscriptionModal from '../../../components/Modal/EditLatestSubscriptionModal/EditLatestSubscriptionModal';
import SubscriptionService from '../../../services/SubscriptionService';
import EditRevisionQuestionView from '../../../components/EditRevisionQuestionView/EditRevisionQuestionView';
import EmptyListView from '../../../components/EmptyListView/EmptyListView';
import { useTranslation } from 'react-i18next';
import SaveSignedInAsUserFilterModal from '../../../components/Modal/SaveSignedInAsUserFilterModal/SaveSignedInAsUserFilterModal';
import { useLocation } from 'react-router';

const initialToastState: { open: boolean; title: string | undefined; text: string | undefined; type: 'ACCEPTED' | 'DELEGATED' | undefined } = {
    open: false,
    title: undefined,
    text: undefined,
    type: undefined,
};

const initialEditOldModalState = { warningOpen: false, editOpen: false };

const ChangeList: FC = () => {
    const { t } = useTranslation();

    const CONTEXT_LINKS: {
        title: string;
        url: string;
        icon: string;
        active: boolean;
    }[] = [
        {
            title: t('navbarChanges'),
            url: CHANGES_LIST,
            icon: eyeIcon,
            active: true,
        },
        {
            title: t('navbarArchive'),
            url: CHANGES_ARCHIVE,
            icon: archiveIcon,
            active: false,
        },
    ];

    const [acknowledgeChange, setAcknowledgeChange] = useState(false);
    const [acknowledgeEdit, setAcknowledgeEdit] = useState(false);
    const [acknowledgeNewChange, setAcknowledgeNewChange] = useState(false);
    const [hideFilterbar, setHideFilterbar] = useState(false);
    const [toaster, setToaster] = useState(initialToastState);

    const [editKeywords, setEditKeywords] = useState<{ open: boolean; keywordIds: number[] }>({ open: false, keywordIds: [] });
    const [editLawLists, setEditLawLists] = useState<{ open: boolean; lawLists: LawListModel[] }>({ open: false, lawLists: [] });
    const [editDelegationOpen, setEditDelegationOpen] = useState(false);
    const [editOldModal, setEditOldModal] = useState<{ warningOpen: boolean; editOpen: boolean; subscription?: SubscriptionModel }>(initialEditOldModalState);
    const [saveSignedInAsUserFilterModalOpen, setSaveSignedInAsUserFilterModalOpen] = useState(false);

    const {
        company,
        isAboveSuperUser,
        isGroupCompany,
        isAdminSubs,
        numberOfChangesForCompany,
        fetchNumberOfChangesForCompany,
        setNumberOfChangesForCompany,
        fetchNumberOfConsultantChangesForCompany,
        signedInAsUser,
    } = useAuth();
    const {
        loading,
        setLoading,
        data,
        filteredData,
        editRevisionQuestionsOpen,
        fetchData,
        selectedIndex,
        lawTypeFilter,
        visibleDataCount,
        totalDataCount,
        globalSearch,
        handleGlobalSearchChange,
        showColumnSearch,
        assessmentUsers,
        handleColumnSearchToggle,
        handleLawTypeOpen,
        handleLawTypeChecked,
        handleResetLawTypeFilter,
        handleResetAllFilters,
        handleLawGroupChecked,
        selectedKeywords,
        handleSelectedKeywordChange,
        lawListGroupFilter,
        handleLawListFilterChange,
        handleLawListGroupFilterChange,
        columns,
        customColumns,
        columnSearch,
        handleColumnVisibleChange,
        hiddenColumns,
        customFilterActive,
        handleCustomFilterToggle,
        myChangesFilterActive,
        onMyChangesToggle,
        onDelegatedToggle,
        delegatedFilterActive,
        handleSaveUserSettings,
        handleSelection,
        handleVisibleDataCountChange,
        sorting,
        handleSortingChange,
        handleAcceptSubscription,
        handleDelegateSubscription,
        handleUpdateKeywords,
        handleUpdateLawLists,
        handleAssessmentOnNew,
        handleCreateRevisionQuestion,
        handleUpdateRevisionQuestion,
        handleRemoveRevisionQuestion,
        setEditRevisionQuestionsOpen,
        handleSubmitRevisionQuestionExclusions,
        handleAllLawListChange,
        handleSelectAllKeywords,
        handleUpdateFullSubscription,
        handleAllLawTypesChecked,
        handleExport,
        handleColumnSearchChange,
    } = useChangeList();

    const { subscriptionId } = useParams();
    const selectedData = filteredData[selectedIndex];
    const { state } = useLocation();

    useEffect(() => {
        if (state?.linkFromLandingPage) {
            onMyChangesToggle();
        }
        fetchData(subscriptionId ? +subscriptionId : undefined);
        fetchNumberOfChangesForCompany();
        if (isAboveSuperUser()) {
            fetchNumberOfConsultantChangesForCompany();
        }
    }, []);

    useEffect(() => {
        if (data.length && data.length !== numberOfChangesForCompany) {
            setNumberOfChangesForCompany(data.length);
        }
    }, [data.length]);

    // Reset state on select changes
    useEffect(() => {
        if (selectedIndex < 0) {
            setAcknowledgeChange(false);
            setAcknowledgeEdit(false);
            setAcknowledgeNewChange(false);
            setToaster(initialToastState);
            setEditKeywords({ open: false, keywordIds: [] });
            setEditLawLists({ open: false, lawLists: [] });
            setEditDelegationOpen(false);
            setEditRevisionQuestionsOpen(false);
        }
    }, [selectedIndex]);

    const editViewColumns: { id: number; accessor: string; Header: string; data: string }[] = useMemo(() => {
        const editColumns = [];

        if (selectedData) {
            let index = 0;

            if (!isGroupCompany() || isAdminSubs() || isAboveSuperUser()) {
                editColumns.push({
                    id: index++,
                    accessor: 'text',
                    Header: t('columnText'),
                    data: _.get(selectedData, 'text'),
                });
            }

            if (isAboveSuperUser()) {
                editColumns.push({
                    id: index++,
                    accessor: 'originalChangeText',
                    Header: t('columnChange'),
                    data: _.get(selectedData, 'originalChangeText'),
                });
            }

            const customerCustomColumns = customColumns.filter(col => col.id.startsWith('customer'));
            if (customerCustomColumns.length) {
                editColumns.push(
                    ...customerCustomColumns.map(col => ({
                        id: index++,
                        accessor: typeof col.accessor === 'string' ? col.accessor : col.id,
                        Header: col.Header,
                        data: _.get(selectedData, typeof col.accessor === 'string' ? col.accessor : col.id),
                    })),
                );
            }
        }

        return editColumns;
    }, [selectedData, selectedIndex]);

    const handleSubmitAcceptance = (data: { assessmentChoice: AssessmentChoice; comment: string | undefined; assessmentUsers: number[]; notifyEmail: boolean }) => {
        const { assessmentChoice, comment, assessmentUsers, notifyEmail } = data;
        if (selectedData) {
            const changeHasImpact = assessmentChoice === 'ACCEPT';
            const isDelegate = assessmentChoice !== 'DECLINE' && assessmentUsers.length > 0 && selectedData.waitingForAcceptance.length < 1;
            if (isDelegate) {
                const callback = (subscription: SubscriptionModel) => {
                    setToaster({
                        type: 'DELEGATED',
                        title: 'Delegerad',
                        text: subscription.name,
                        open: true,
                    });
                    setAcknowledgeChange(false);
                };
                handleDelegateSubscription(selectedData.subscriptionId, comment, assessmentUsers, assessmentChoice, notifyEmail, callback);
            } else {
                const addedAssessmentUsers = assessmentUsers.filter(addedUser => !selectedData.waitingForAcceptance.find(existingUser => existingUser.userId === addedUser));
                const callback = (subscription: SubscriptionModel) => {
                    setToaster({
                        type: 'ACCEPTED',
                        title: 'Kvitterad',
                        text: subscription.name,
                        open: true,
                    });
                    handleSelection(-1);
                    setAcknowledgeChange(false);
                };
                handleAcceptSubscription(selectedData.subscriptionId, changeHasImpact, comment, callback, addedAssessmentUsers, notifyEmail);
            }
            setHideFilterbar(false);
        }
    };

    const handleSubmitNewAssessment = async (assessmentChoice: AssessmentChoice, comment = '', assessmentUsers: number[], notifyEmail: boolean) => {
        await handleAssessmentOnNew(selectedData.subscriptionId, assessmentChoice, comment, assessmentUsers, notifyEmail);
        setAcknowledgeNewChange(false);
        setHideFilterbar(false);
    };

    const handleSubmitKeywords = async () => {
        await handleUpdateKeywords(selectedData.subscriptionId, editKeywords.keywordIds);
        setEditKeywords({ open: false, keywordIds: [] });
    };

    const handleSubmitLawLists = async () => {
        const lawListIds = editLawLists.lawLists.map(ll => ll.lawListId);
        await handleUpdateLawLists(selectedData.subscriptionId, lawListIds);
        setEditLawLists({ open: false, lawLists: [] });
    };

    const handleSaveEdit = async (subscriptionId: number, subscription?: SubscriptionModel, keywordIds?: number[], lawListIds?: number[]) => {
        await handleUpdateFullSubscription(subscriptionId, subscription, keywordIds, lawListIds);
        setAcknowledgeEdit(false);
        setEditOldModal(initialEditOldModalState);
    };

    const onSaveUserSettings = () => {
        if (signedInAsUser !== undefined && isAboveSuperUser()) {
            setSaveSignedInAsUserFilterModalOpen(true);
        } else {
            handleSaveUserSettings();
        }
    };

    const handleEdit = async () => {
        async function getLatestSubscription(companyId: number, subscription: SubscriptionModel) {
            if (!selectedData.detachedCopy) {
                return await SubscriptionService().getLatestSubscription(companyId, subscription.baseLawId);
            } else {
                return await SubscriptionService().getLatestDetachedSubscription(subscription.subscriptionId);
            }
        }

        if (selectedData && company) {
            try {
                setLoading(true);
                const latestSubscription = await getLatestSubscription(company.id, selectedData);
                if (latestSubscription.subscriptionId === selectedData.subscriptionId) {
                    setAcknowledgeEdit(true);
                } else {
                    setEditOldModal({ warningOpen: true, editOpen: false, subscription: latestSubscription });
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleWarningEditCurrent = () => {
        setEditOldModal(initialEditOldModalState);
        setAcknowledgeEdit(true);
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            <ContextMenu
                lawTypeFilter={lawTypeFilter}
                hasCustomLaws={false}
                customLawsSelected={false}
                onToggleLawTypeOpen={handleLawTypeOpen}
                onToggleLawTypeChecked={handleLawTypeChecked}
                onToggleLawGroupChecked={handleLawGroupChecked}
                onResetLawTypeFilter={handleResetLawTypeFilter}
                onResetAllFilters={handleResetAllFilters}
                onToggleAllLawTypesChecked={handleAllLawTypesChecked}
                visibleDataCount={visibleDataCount}
                totalDataCount={totalDataCount}
                links={CONTEXT_LINKS}
            />
            <div className={styled.List}>
                {!hideFilterbar && (
                    <Filterbar
                        globalSearch={globalSearch}
                        onGlobalSearchChange={handleGlobalSearchChange}
                        customFilterActive={customFilterActive}
                        onCustomFilterToggle={handleCustomFilterToggle}
                        myChangesFilterActive={myChangesFilterActive}
                        onMyChangesToggle={onMyChangesToggle}
                        delegatedFilterActive={delegatedFilterActive}
                        onDelegatedToggle={onDelegatedToggle}
                        selectedKeywords={selectedKeywords}
                        onSelectedKeywordChange={handleSelectedKeywordChange}
                        showColumnSearch={showColumnSearch}
                        onColumnSearchToggle={handleColumnSearchToggle}
                        lawListGroupFilter={lawListGroupFilter}
                        onLawListFilterChange={handleLawListFilterChange}
                        onLawListGroupFilterChange={handleLawListGroupFilterChange}
                        onColumnShowChange={handleColumnVisibleChange}
                        columns={[...columns, ...customColumns].filter(column => !column.alwaysVisible)}
                        onSaveUserSettings={onSaveUserSettings}
                        onSelectAllKeywords={handleSelectAllKeywords}
                        onAllLawListChange={handleAllLawListChange}
                        onExport={handleExport}
                    />
                )}

                <div className={styled.TableWrapper}>
                    {!loading && data.length < 1 ? (
                        <EmptyListView title={t('emptyListViewNoChangesTitle')} text={t('emptyListViewNoChangesText')} />
                    ) : !loading && visibleDataCount !== undefined && visibleDataCount < 1 ? (
                        <EmptyListView emptyFilter />
                    ) : null}

                    <Table
                        columns={[...columns, ...customColumns]}
                        data={filteredData}
                        selected={selectedIndex}
                        onSelect={handleSelection}
                        showColumnSearch={showColumnSearch}
                        globalSearch={globalSearch}
                        columnSearch={columnSearch}
                        onVisibleDataCountChange={handleVisibleDataCountChange}
                        hiddenColumns={hiddenColumns}
                        sorting={sorting}
                        onSortingChange={handleSortingChange}
                        scrollToId={subscriptionId ? +subscriptionId : undefined}
                        onColumnSearchChange={handleColumnSearchChange}
                        useDivider
                    />
                    {acknowledgeChange && selectedData && (
                        <AcknowledgeChangeView
                            data={selectedData}
                            assessmentUsers={assessmentUsers}
                            editDisabled={editViewColumns.length < 1 && company !== undefined && !company.hasLawLists && !company.hasKeyWords}
                            onClose={() => {
                                setAcknowledgeChange(false);
                                setHideFilterbar(false);
                            }}
                            onEdit={handleEdit}
                            onEditRevisionQuestions={() => setEditRevisionQuestionsOpen(true)}
                            onSubmit={handleSubmitAcceptance}
                            showDelagation={true}
                        />
                    )}
                    {acknowledgeEdit && selectedData && (
                        <AcknowledgeEditView data={selectedData} customColumns={editViewColumns} onClose={() => setAcknowledgeEdit(false)} onSave={handleSaveEdit} />
                    )}
                    {acknowledgeNewChange && selectedData && (
                        <AcknowledgeNewChangeView
                            data={selectedData}
                            assessmentUsers={assessmentUsers}
                            editDisabled={editViewColumns.length < 1}
                            onClose={() => {
                                setAcknowledgeNewChange(false);
                                setHideFilterbar(false);
                            }}
                            onEditTexts={() => setAcknowledgeEdit(true)}
                            onEditKeywords={() => setEditKeywords({ open: true, keywordIds: selectedData.keywordIds })}
                            onEditLawLists={() => setEditLawLists({ open: true, lawLists: selectedData.lawLists })}
                            onEditRevisionQuestions={() => setEditRevisionQuestionsOpen(true)}
                            onSubmit={data => handleSubmitNewAssessment(data.assessmentChoice, data.comment, data.assessmentUsers, data.notifyEmail)}
                            showDelagation={true}
                        />
                    )}
                    {acknowledgeNewChange && editKeywords.open && selectedData && (
                        <EditView
                            title="Nyckelord"
                            value={editKeywords.keywordIds}
                            field="keywordIds"
                            onChange={keywordIds => setEditKeywords({ open: true, keywordIds })}
                            onClose={() => {
                                setEditKeywords({ open: false, keywordIds: [] });
                                setHideFilterbar(false);
                            }}
                            onSubmit={handleSubmitKeywords}
                        />
                    )}
                    {acknowledgeNewChange && editLawLists.open && selectedData && (
                        <EditView
                            title="Laglistor"
                            value={editLawLists.lawLists}
                            field="lawLists"
                            onChange={lawLists => setEditLawLists({ open: true, lawLists })}
                            onClose={() => {
                                setEditLawLists({ open: false, lawLists: [] });
                                setHideFilterbar(false);
                            }}
                            onSubmit={handleSubmitLawLists}
                        />
                    )}
                    {editDelegationOpen && selectedData && (
                        <DelegateEditor
                            data={selectedData}
                            onSubmit={subscription => {
                                setToaster({
                                    type: subscription.acceptanceStatus === 'ACCEPTED' ? 'ACCEPTED' : 'DELEGATED',
                                    title: subscription.acceptanceStatus === 'ACCEPTED' ? 'Kvitterad' : 'Delegering förändrad',
                                    text: subscription.name,
                                    open: true,
                                });
                                setEditDelegationOpen(false);
                            }}
                            onClose={() => setEditDelegationOpen(false)}
                        />
                    )}

                    {editRevisionQuestionsOpen && selectedData && (
                        <EditRevisionQuestionView
                            data={selectedData}
                            onCreateRevisionQuestion={data => handleCreateRevisionQuestion(selectedData.subscriptionId, data)}
                            onUpdateRevisionQuestion={data => handleUpdateRevisionQuestion(selectedData.subscriptionId, data)}
                            onRemoveRevisionQuestion={id => handleRemoveRevisionQuestion(selectedData.subscriptionId, id)}
                            onCancel={() => setEditRevisionQuestionsOpen(false)}
                            onSubmit={handleSubmitRevisionQuestionExclusions}
                        />
                    )}

                    <ChangeFullView
                        data={selectedData}
                        open={!!selectedData}
                        onClose={() => {
                            handleSelection(-1);
                            setHideFilterbar(false);
                            setAcknowledgeChange(false);
                            setAcknowledgeEdit(false);
                            setAcknowledgeNewChange(false);
                            setEditDelegationOpen(false);
                            setEditRevisionQuestionsOpen(false);
                        }}
                        hideFooter={acknowledgeChange || acknowledgeEdit || acknowledgeNewChange || editDelegationOpen || editRevisionQuestionsOpen}
                        onDelegateChange={() => {
                            setEditDelegationOpen(true);
                            setHideFilterbar(true);
                        }}
                        onAcknowledgeChange={() => {
                            setAcknowledgeChange(true);
                            setHideFilterbar(true);
                        }}
                        onAcknowledgeNewChange={() => {
                            setAcknowledgeNewChange(true);
                            setHideFilterbar(true);
                        }}
                        onForceDelegation={() => console.log('forceDelegation')}
                    />
                </div>

                {editOldModal.warningOpen && selectedData && (
                    <EditOldWarningModal
                        onClose={() => setEditOldModal(initialEditOldModalState)}
                        onEditCurrent={handleWarningEditCurrent}
                        onEditLatest={() => setEditOldModal(s => ({ ...s, warningOpen: false, editOpen: true }))}
                    />
                )}

                {editOldModal.editOpen && editOldModal.subscription && (
                    <EditLatestSubscriptionModal
                        subscription={editOldModal.subscription}
                        customColumns={editViewColumns}
                        onClose={() => setEditOldModal(initialEditOldModalState)}
                        onSave={handleSaveEdit}
                    />
                )}

                {toaster.open && <Toast title={toaster.title} text={toaster.text} onClose={() => setToaster(initialToastState)} type={toaster.type} />}

                {saveSignedInAsUserFilterModalOpen && (
                    <SaveSignedInAsUserFilterModal
                        onCancel={() => setSaveSignedInAsUserFilterModalOpen(false)}
                        onConfirm={() => {
                            handleSaveUserSettings();
                            setSaveSignedInAsUserFilterModalOpen(false);
                        }}
                    />
                )}
            </div>
        </>
    );
};

export default ChangeList;
