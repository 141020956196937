import React, { useEffect, useMemo, useState } from 'react';
import useUnfinishedRevision from '../../../../hooks/useUnfinishedRevision';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import RevisionModel from '../../../../models/RevisionModel';
import RevisionCell from '../../../../components/Table/RevisionCell/RevisionCell';
import MobileTable from '../../../../components/Mobile/Table/MobileTable';
import { REVISION_EDIT } from '../../../../constants/Routes';
import OngoingRevisionIcon from '../../../../assets/images/ongoingRevisionIcon.svg';
import styled from './MobileUnfinishedRevision.module.css';
import EmptyListView from '../../../../components/EmptyListView/EmptyListView';
import RevisionService from '../../../../services/RevisionService';
import useAuth from '../../../../hooks/useAuth';
import backArrow from '../../../../assets/images/backIcon.svg';

const MobileUnfinishedRevision = () => {
    const { revisions, fetchRevisions, loading, sorting, setLoading } = useUnfinishedRevision();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { fetchNumberOfUnfinishedRevisionsForCompany, numberOfUnfinishedRevisionsForCompany, company } = useAuth();
    const [revisionWarnings, setRevisionWarnings] = useState<number>();

    useEffect(() => {
        fetchRevisions();
    }, []);

    useEffect(() => {
        if (numberOfUnfinishedRevisionsForCompany !== revisions.length) {
            fetchNumberOfUnfinishedRevisionsForCompany();
        }
        if (revisions.length < 1 && company) {
            setLoading(true);
            RevisionService()
                .getNumberOfRevisionWarnings(company.id)
                .then(revisionWarnings => {
                    setRevisionWarnings(revisionWarnings);
                    setLoading(false);
                });
        }
    }, [revisions.length]);

    const columns = useMemo(() => {
        const columns: Array<Column<RevisionModel> & { Header: string; languageId?: string }> = [
            {
                id: 'name',
                Header: t('columnName'),
                languageId: 'columnName',
                Cell: function nameCell(props) {
                    return <RevisionCell {...props} multiRow></RevisionCell>;
                },
                accessor: row => row.name,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.original.name.toLowerCase();
                    const lawB = rowB.original.name.toLowerCase();
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                id: 'createdAt',
                Header: t('columnDate'),
                languageId: 'columnDate',
                Cell: RevisionCell,
                accessor: row => row.createdAt,
                sortInverted: true,
            },
            {
                id: 'responsible',
                Header: t('columnResponsible'),
                languageId: 'columnResponsible',
                Cell: RevisionCell,
                accessor: row => row.responsibleName,
            },
        ];

        return columns;
    }, [i18n.language]);

    const openRevision = (selectedRevision: any) => {
        return {
            onClick: () => {
                history.push(REVISION_EDIT + `/${selectedRevision.id}`);
            },
        };
    };
    console.log(loading);

    return (
        <>
            <p className={styled.backButton} onClick={history.goBack}>
                <img className={styled.backImage} src={backArrow} />
                {t('back')}
            </p>
            <div className={styled.Header}>
                <img src={OngoingRevisionIcon} alt="ramboll" width="50px" />
                <span className={styled.Title}>{t('contextMenuUnfinishedRevisions')}</span>
            </div>
            {loading && <LoadingSpinner />}
            <MobileTable data={revisions} columns={columns} sorting={sorting} onSelectRevision={openRevision} />
            {!loading && revisions.length < 1 ? (
                <EmptyListView title={t('emptyListViewNoUnfinishedRevisionsTitle')} text={t('emptyListViewNoUnfinishedRevisionsTextMobile', { revisionWarnings })} />
            ) : null}
        </>
    );
};

export default MobileUnfinishedRevision;
