import { useContext, useMemo } from 'react';
import { ChangeArchiveContext } from '../contexts/ChangeArchiveContext';
import { LawType, LawTypeEnum } from '../models/LawType';
import useAuth from './useAuth';
import SubscriptionChangeService from '../services/SubscriptionChangeService';
import { Column, SortingRule } from 'react-table';
import _ from 'lodash';
import useExport from './useExport';
import { ChangeActionEnum } from '../models/ChangeAction';
import LawTypeFilter, { convertToLawTypeFilter } from '../models/LawTypeFilter';
import { useTranslation } from 'react-i18next';
import ExcelColumnWidth, { columnKeys } from '../constants/ExcelColumnWidth';
import { getActiveFilterExportFromState } from '../models/ActiveFilterExport';
import filterExport from '../utils/filterExport';

const useChangeArchive = () => {
    const { state, setState } = useContext(ChangeArchiveContext);
    const { t } = useTranslation();
    const { company, user } = useAuth();
    const { exportList } = useExport();
    const { includeSubscriptionInExport } = filterExport();

    const setLoading = (loading: boolean): void => {
        setState(s => ({
            ...s,
            loading,
        }));
    };

    const handleColumnSearchChange = (
        columnSearch: {
            id: string;
            value: string | undefined;
        }[],
    ): void => {
        setState(s => ({
            ...s,
            columnSearch,
        }));
    };

    const fetchData = async (fromDate: Date, toDate: Date) => {
        try {
            if (company && user) {
                setLoading(true);

                const from = fromDate.toLocaleDateString('sv').replace(/[^ -~]/g, '');
                const to = toDate.toLocaleDateString('sv').replace(/[^ -~]/g, '');
                const { acceptanceLogs, lawTypes } = await SubscriptionChangeService().getArchive(company.id, from, to);

                const lawTypeFilter: LawTypeFilter[] = convertToLawTypeFilter(lawTypes);

                setState(s => ({
                    ...s,
                    data: acceptanceLogs,
                    lawTypeFilter,
                    loading: false,
                }));
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleLawTypeOpen = (type: LawType): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                open: lawType.name === type ? !lawType.open : lawType.open,
            })),
        }));
    };

    const handleLawTypeChecked = (type: LawType): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => {
                if (lawType.name === type) {
                    return {
                        ...lawType,
                        lawGroups: lawType.lawGroups.map(lawGroup => ({
                            ...lawGroup,
                            checked: !lawType.lawGroups.every(l => l.checked),
                        })),
                    };
                }
                return lawType;
            }),
        }));
    };

    const handleLawGroupChecked = (lawGroupId: number): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: lawGroup.lawGroupId === lawGroupId ? !lawGroup.checked : lawGroup.checked,
                })),
            })),
        }));
    };

    const handleAllLawTypesChecked = (allChecked: boolean): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: !allChecked,
                })),
            })),
        }));
    };

    const handleResetLawTypeFilter = (): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: true,
                })),
            })),
        }));
    };

    const handleResetAllFilters = () => {
        setState(s => ({
            ...s,
            globalSearch: '',
            columnSearch: s.columnSearch.map(c => ({ ...c, value: undefined })),
            showColumnSearch: false,
        }));
        handleResetLawTypeFilter();
    };

    const handleGlobalSearchChange = (globalSearch: string): void => {
        setState(s => ({
            ...s,
            globalSearch,
        }));
    };

    const handleColumnSearchToggle = () => {
        setState(s => ({
            ...s,
            columnSearch: s.showColumnSearch ? s.columnSearch.map(c => ({ ...c, value: undefined })) : s.columnSearch,
            showColumnSearch: !s.showColumnSearch,
        }));
    };

    const handleSelection = (selectedIndex: number) => {
        setState(s => ({
            ...s,
            selectedIndex,
        }));
    };

    const handleVisibleDataCountChange = (visibleDataCount: number): void => {
        setState(s => ({
            ...s,
            visibleDataCount,
        }));
    };

    const handleSortingChange = (sorting: SortingRule<object>[]): void => {
        setState(s => ({
            ...s,
            sorting,
        }));
    };

    const handleColumnVisibleChange = (id: string): void => {
        setState(s => ({
            ...s,
            customColumns: s.customColumns.map(column => ({
                ...column,
                visible: column.id === id ? !column.visible : column.visible,
            })),
        }));
    };

    const handleStartDateChange = (startDate: Date): void => {
        if (startDate !== null) {
            setState(s => ({
                ...s,
                startDate: startDate,
            }));
        }
    };

    const handleEndDateChange = (endDate: Date): void => {
        if (endDate !== null) {
            setState(s => ({
                ...s,
                endDate: endDate,
            }));
        }
    };

    const handleRevertAllChange = async (lawId: number): Promise<void> => {
        if (company) {
            setLoading(true);
            await SubscriptionChangeService()
                .revertAllChange(lawId, company.id)
                .then(() => {
                    setState(s => ({
                        ...s,
                        data: s.data.filter(al => al.lawId !== lawId),
                        selectedIndex: -1,
                        loading: false,
                    }));
                });
        }
    };

    const validateSubmitRevertOwnChange = async (lawId: number): Promise<boolean> => {
        if (company && user) {
            return await SubscriptionChangeService().validateSubmitRevertOwnChange(lawId, company.id, user.userId);
        }
        return false;
    };

    const handleRevertOwnChange = async (acceptanceLogId: number, lawId: number): Promise<void> => {
        if (company && user) {
            setLoading(true);
            await SubscriptionChangeService()
                .revertOwnChange(acceptanceLogId, lawId, company.id, user.userId)
                .then(() => {
                    setState(s => ({
                        ...s,
                        data: s.data.filter(al => al.lawId !== lawId),
                        selectedIndex: -1,
                        loading: false,
                    }));
                });
        }
    };

    const handleUpdateAcceptanceLogComment = async (acceptanceLogId: number, comment: string) => {
        if (company) {
            setLoading(true);
            await SubscriptionChangeService().updateAcceptanceLogComment(company.id, acceptanceLogId, comment);
            setState(s => ({
                ...s,
                data: s.data.map(al => (al.acceptanceLogId === acceptanceLogId ? { ...al, comment } : al)),
                loading: false,
            }));
        }
    };

    const handleExport = (): void => {
        const { filteredData, customColumns, sorting, columnSearch, columns, globalSearch } = state;

        const lawLists = customColumns.find(c => c.id === 'lawLists');
        const keyWords = customColumns.find(c => c.id === 'keywords');

        const exportColumns: Array<{ key: columnKeys; Header: string; width: number }> = [
            { key: 'lawType', Header: t('columnLawGroup'), width: ExcelColumnWidth.lawType },
            { key: 'name', Header: t('columnLawName'), width: ExcelColumnWidth.name },
            { key: 'subId', Header: t('columnSubId'), width: ExcelColumnWidth.subId },
            { key: 'logDate', Header: t('columnAcceptanceDate'), width: ExcelColumnWidth.logDate },
            { key: 'changeText', Header: t('columnChange'), width: ExcelColumnWidth.changeText },
            { key: 'changeAction', Header: t('columnAction'), width: ExcelColumnWidth.changeAction },
            { key: 'comment', Header: t('columnComment'), width: ExcelColumnWidth.comment },
            { key: 'userFullName', Header: t('columnAcceptedBy'), width: ExcelColumnWidth.userFullName },
            { key: 'changeHasImpact', Header: t('columnChangeOfImpact'), width: ExcelColumnWidth.changeHasImpact },
            ...(lawLists?.visible && company?.hasLawLists ? [{ key: 'lawLists' as columnKeys, Header: t('columnLawLists'), width: ExcelColumnWidth.lawLists }] : []),
            ...(keyWords?.visible && company?.hasKeyWords ? [{ key: 'keywords' as columnKeys, Header: t('columnKeywords'), width: ExcelColumnWidth.keywords }] : []),
        ];

        let exportData = filteredData
            .filter(obj => {
                const searchColumnIds = columnSearch.map(cs => cs.id);
                const searchColumns = [...columns, ...customColumns].filter(c => searchColumnIds.includes(c.id));
                // Filter data from columnSearch, globalSearch, lawLists and keywords
                return includeSubscriptionInExport(obj, columnSearch, searchColumns, columns, globalSearch);
            })
            .map(obj => ({
                ...obj,
                logDate: obj.logDateWithoutTime,
                lawType: t(LawTypeEnum[obj.lawType]) + ': ' + obj.lawGroupName,
                changeAction: t(ChangeActionEnum[obj.changeAction]),
                changeHasImpact: obj.changeHasImpact === true ? t('buttonYes') : obj.changeHasImpact === false ? t('buttonNo') : '',
                lawLists: company?.hasLawLists && obj.lawLists ? obj.lawLists.join('<br>') : '',
                keywords: company?.hasKeyWords && obj.keyWords ? obj.keyWords.join('<br>') : '',
            }));

        exportData = _.orderBy(
            exportData,
            sorting.map(s => s.id),
            sorting.map(s => {
                // Reverse logDate inverted sorting
                if (s.id === 'logDate') {
                    return s.desc ? 'asc' : 'desc';
                }
                return s.desc ? 'desc' : 'asc';
            }),
        );

        const activeFilterExport = getActiveFilterExportFromState(state, exportData.length);
        exportList(exportColumns, exportData, `Ändringsarkiv`, activeFilterExport, state.startDate, state.endDate);
    };

    const hiddenColumns: string[] = useMemo(() => state.customColumns.filter(column => !column.visible).map(column => column.id), [state.customColumns]);

    return {
        ...state,
        hiddenColumns,
        fetchData,
        handleLawTypeOpen,
        handleLawTypeChecked,
        handleLawGroupChecked,
        handleResetLawTypeFilter,
        handleResetAllFilters,
        handleGlobalSearchChange,
        handleColumnSearchToggle,
        handleSelection,
        handleVisibleDataCountChange,
        handleSortingChange,
        handleColumnVisibleChange,
        handleStartDateChange,
        handleEndDateChange,
        handleRevertAllChange,
        handleRevertOwnChange,
        validateSubmitRevertOwnChange,
        handleExport,
        handleUpdateAcceptanceLogComment,
        handleAllLawTypesChecked,
        handleColumnSearchChange,
    };
};

export default useChangeArchive;
