import React, { FC, useState } from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import styled from './RemoveRevisionModal.module.css';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

interface Props {
    finishedRevision?: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const RemoveRevisionModal: FC<Props> = ({ finishedRevision, onCancel, onConfirm }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    const handleOnConfirm = () => {
        setLoading(true);
        onConfirm();
    };
    return (
        <Modal
            title={finishedRevision ? t('removeFinishedRevisionModalTitle') : t('removeUnfinishedRevisionModalTitle')}
            subtitle={finishedRevision ? t('removeFinishedRevisionModalSubTitle') : t('removeUnfinishedRevisionModalSubTitle')}
        >
            {loading && <LoadingSpinner message={t('spinnerDeleteRevisionLoading')} />}
            <div className={styled.Options}>
                <Button variant="Primary" onClick={onCancel}>
                    {t('buttonNo')}
                </Button>
                <Button variant="Outline" onClick={handleOnConfirm} disabled={loading}>
                    {t('buttonYes')}
                </Button>
            </div>
        </Modal>
    );
};

export default RemoveRevisionModal;
