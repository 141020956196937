import React, { FC } from 'react';
import styled from './Help.module.css';
import { HELP_INSTRUCTIONS, HELP_CONTACT } from '../../constants/Routes';
import { NavLink, Route } from 'react-router-dom';
import contactIcon from '../../assets/images/contactIcon.svg';
import videoIcon from '../../assets/images/videoIcon.svg';
import Contact from './Contact/Contact';
import Instructions from './Instructions/Instructions';
import { useTranslation } from 'react-i18next';

const Help: FC = () => {
    const { t } = useTranslation();
    return (
        <div className={styled.Help}>
            <div className={styled.Menu}>
                <NavLink to={HELP_INSTRUCTIONS} activeClassName={styled.Active} className={styled.Link}>
                    <div className={styled.Header}>
                        <img src={videoIcon} />
                        <p>{t('helpMenuVideos')}</p>
                    </div>
                </NavLink>
                <NavLink to={HELP_CONTACT} activeClassName={styled.Active} className={styled.Link}>
                    <div className={styled.Header}>
                        <img src={contactIcon} />
                        <p>{t('helpMenuContact')}</p>
                    </div>
                </NavLink>
            </div>
            <div className={styled.Content}>
                <Route path={HELP_INSTRUCTIONS} component={Instructions} />
                <Route path={HELP_CONTACT} component={Contact} />
            </div>
        </div>
    );
};

export default Help;
