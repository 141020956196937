import React, { FC } from 'react';
import styled from './Modal.module.css';

interface Props {
    title: string;
    subtitle?: string;
    text?: string;
    className?: string;
}

const Modal: FC<Props> = ({ title, subtitle, text, children, className }) => {
    return (
        <>
            <div className={styled.Backdrop} />
            <div className={[styled.Modal, className ? className : ''].join(' ')}>
                {title && <h4 className={styled.Title}>{title}</h4>}
                {subtitle && <p className={styled.SubTitle}>{subtitle}</p>}
                {text && <p className={styled.Text}>{text}</p>}
                <div className={styled.Options}>{children}</div>
            </div>
        </>
    );
};

export default Modal;
