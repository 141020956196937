import { AxiosResponse, AxiosError } from 'axios';
import { getApi } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';

const MiscService = () => {
    const axiosInstance = getApi();

    function getEnvironmentName(): Promise<string> {
        return axiosInstance
            .get<string>('/misc/envname')
            .then((response: AxiosResponse<string>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getOldApplicationBaseURL(): Promise<string> {
        return axiosInstance
            .get<string>('/misc/oldapplicationbaseurl')
            .then((response: AxiosResponse<string>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getLogoutURL(): Promise<string> {
        return axiosInstance
            .get<string>('/misc/logouturl')
            .then((response: AxiosResponse<string>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getCommit(): Promise<string> {
        return axiosInstance
            .get<string>('/misc/commit')
            .then((response: AxiosResponse<string>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getEnvironmentName,
        getCommit,
        getOldApplicationBaseURL,
        getLogoutURL,
    };
};

export default MiscService;
