import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from './FinishedRevision.module.css';
import useFinishedRevision from '../../../hooks/useFinishedRevision';
import { REVISION_UNFINISHED, REVISION_FINISHED, REVISION_EDIT } from '../../../constants/Routes';
import ongoingRevisionIcon from '../../../assets/images/ongoingRevisionIcon.svg';
import finishedRevisionIcon from '../../../assets/images/finishedRevisionIcon.svg';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import ContextMenu from '../../../components/ContextMenu/ContextMenu';
import Filterbar from '../../../components/Filterbar/Filterbar';
import Table from '../../../components/Table/Table';
import MenuCell, { isLastRow } from '../../../components/Table/MenuCell/MenuCell';
import { Column } from 'react-table';
import RevisionModel from '../../../models/RevisionModel';
import RevisionCell from '../../../components/Table/RevisionCell/RevisionCell';
import statusOkIcon from '../../../assets/images/statusOkIcon.svg';
import statusNotOkIcon from '../../../assets/images/statusNotOkIcon.svg';
import Button from '../../../components/Button/Button';
import { useHistory } from 'react-router-dom';
import RemoveRevisionModal from '../../../components/Modal/RemoveRevisionModal/RemoveRevisionModal';
import Modal from '../../../components/Modal/Modal';
import useAuth from '../../../hooks/useAuth';
import ExportRevisionModal from '../../../components/Modal/ExportRevisionModal/ExportRevisionModal';
import EmptyListView from '../../../components/EmptyListView/EmptyListView';
import { useTranslation } from 'react-i18next';
import { columnKeys } from '../../../constants/ExcelColumnWidth';
import EditRevisionModal from '../../../components/Modal/EditRevisionModal/EditRevisionModal';

const initialRemoveModal = { promptOpen: false, confirmOpen: false, id: -1 };

const FinishedRevision: FC = () => {
    const { t, i18n } = useTranslation();

    const CONTEXT_LINKS: {
        title: string;
        url: string;
        icon: string;
        active: boolean;
    }[] = [
        { title: t('contextMenuUnfinishedRevisions'), url: REVISION_UNFINISHED, icon: ongoingRevisionIcon, active: false },
        { title: t('contextMenuFinishedRevisions'), url: REVISION_FINISHED, icon: finishedRevisionIcon, active: true },
    ];

    const { isRevisor } = useAuth();
    const history = useHistory();
    const {
        loading,
        revisions,
        globalSearch,
        sorting,
        startDate,
        endDate,
        fetchData,
        handleGlobalSearchChange,
        handleStartDateChange,
        handleEndDateChange,
        handleSortingChange,
        handleRemoveRevision,
        handleExport,
        handleAlterRevision,
    } = useFinishedRevision();

    const [removeModal, setRemoveModal] = useState(initialRemoveModal);
    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [infoModal, setInfoModal] = useState<{ open: boolean; edit: { id: number; version: number; name: string; userId: number } | undefined }>({
        open: false,
        edit: undefined,
    });

    const openEditModal = (revision: RevisionModel) => {
        setInfoModal({ open: true, edit: { id: revision.id, version: revision.version, name: revision.name, userId: revision.responsibleUserId } });
    };

    const closeInfoModal = () => {
        setInfoModal({ open: false, edit: undefined });
        fetchData(startDate, endDate);
    };

    const handleSubmitRevision = (name: string, userId: number, username: string): void => {
        if (infoModal.edit) {
            handleAlterRevision(infoModal.edit.id, infoModal.edit.version, name, userId, closeInfoModal);
        }
    };

    const columns = useMemo(() => {
        const columns: Array<Column<RevisionModel> & { id: columnKeys; Header: string; languageId?: string }> = [
            {
                id: 'name',
                Header: t('columnName'),
                languageId: 'columnName',
                minWidth: 2,
                Cell: function nameCell(props) {
                    return <RevisionCell {...props} multiRow></RevisionCell>;
                },
                accessor: row => row.name,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.original.name?.toLowerCase() || '';
                    const lawB = rowB.original.name?.toLowerCase() || '';
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                id: 'createdAt',
                Header: t('columnCreated'),
                languageId: 'columnCreated',
                Cell: RevisionCell,
                accessor: row => row.createdAt,
                sortInverted: true,
            },
            {
                id: 'finishedAt',
                Header: t('columnFinishedAt'),
                languageId: 'columnFinishedAt',
                Cell: RevisionCell,
                accessor: row => row.finishedAt,
                sortInverted: true,
            },
            {
                id: 'revisedAt',
                Header: t('columnRevisedAt'),
                languageId: 'columnRevisedAt',
                Cell: RevisionCell,
                accessor: row => row.revisedAt,
                sortInverted: true,
            },
            {
                id: 'responsible',
                Header: t('columnResponsible'),
                languageId: 'columnResponsible',
                Cell: RevisionCell,
                accessor: row => row.responsibleName,
            },
            {
                id: 'count' as any,
                Header: t('columnLawCount'),
                languageId: 'columnLawCount',
                maxWidth: 0.5,
                accessor: row => row.subscriptionCount,
                Cell: RevisionCell,
                sortInverted: true,
            },
            {
                id: 'status',
                Header: t('columnStatus'),
                languageId: 'columnStatus',
                maxWidth: 0.5,
                Cell: function Cell(props) {
                    const value = <img src={props.row.original.revisionOk ? statusOkIcon : statusNotOkIcon} alt="status" />;
                    return (
                        <RevisionCell
                            {...{
                                ...props,
                                cell: { ...props.cell, value },
                            }}
                        />
                    );
                },
                accessor: row => +row.revisionOk,
            },
            {
                id: 'open' as any,
                Header: '',
                Cell: RevisionCell,
                accessor: function Accessor({ id }) {
                    return (
                        <Button variant="Outline" style={{ padding: '4px 20px 5px 20px' }} onClick={() => history.push(REVISION_EDIT + `/${id}`)}>
                            {t('buttonOpen')}
                        </Button>
                    );
                },
                disableSortBy: true,
            },
            {
                id: 'menu' as any,
                Header: '',
                accessor: row => row.id,
                Cell: function Cell({ row, sortedRows }) {
                    const { id } = row.original;
                    const lastRow = isLastRow(row, sortedRows);
                    return (
                        <MenuCell
                            title={t('buttonManageRevision')}
                            lastRow={lastRow}
                            items={[
                                ...(isRevisor() ? [{ text: t('buttonChangeName'), function: () => openEditModal(row.original) }] : []),
                                ...(isRevisor() ? [{ text: t('buttonRemove'), function: () => setRemoveModal({ promptOpen: true, confirmOpen: false, id }) }] : []),
                                { text: t('filterbarExportToExcel'), function: () => handleExport(id) },
                            ]}
                        />
                    );
                },
                disableSortBy: true,
            },
        ];

        return columns;
    }, [i18n.language]);

    useEffect(() => {
        fetchData(startDate, endDate);
    }, [startDate, endDate]);

    const handleConfirmRemoveModal = () => {
        handleRemoveRevision(removeModal.id);
        setRemoveModal(initialRemoveModal);
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            <ContextMenu title={t('contextMenuRevisionTitle')} links={CONTEXT_LINKS} visibleDataCount={0} totalDataCount={1} />
            <div className={styled.List}>
                <Filterbar
                    globalSearch={globalSearch}
                    onGlobalSearchChange={handleGlobalSearchChange}
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={handleStartDateChange}
                    onEndDateChange={handleEndDateChange}
                >
                    <Button variant="Primary" className={styled.ExportButton} onClick={() => setExportModalOpen(true)}>
                        {t('buttonCreateReport')}
                    </Button>
                </Filterbar>

                {!loading && revisions && revisions.length < 1 ? <EmptyListView title={t('emptyListViewNoFinishedRevisionsTitle')} /> : null}

                <Table
                    columns={columns}
                    data={revisions}
                    showColumnSearch={false}
                    columnSearch={[]}
                    globalSearch={globalSearch}
                    sorting={sorting}
                    rowHeight={64}
                    onSortingChange={handleSortingChange}
                    dense
                />
                {removeModal.promptOpen && (
                    <RemoveRevisionModal
                        onCancel={() => setRemoveModal(initialRemoveModal)}
                        onConfirm={() => setRemoveModal({ ...removeModal, promptOpen: false, confirmOpen: true })}
                        finishedRevision
                    />
                )}
                {removeModal.confirmOpen && (
                    <Modal title={t('removeRevisionWarningModalTitle')} subtitle={t('removeRevisionWarningModalText')}>
                        <div className={styled.ConfirmRemoveModalButtons}>
                            <Button variant="Primary" onClick={() => setRemoveModal(initialRemoveModal)}>
                                {t('buttonNo')}
                            </Button>
                            <Button variant="Outline" onClick={handleConfirmRemoveModal}>
                                {t('buttonYes')}
                            </Button>
                        </div>
                    </Modal>
                )}

                {exportModalOpen && <ExportRevisionModal initialStartDate={startDate} initialEndDate={endDate} onClose={() => setExportModalOpen(false)} />}
            </div>

            {infoModal.open && <EditRevisionModal edit={infoModal.edit} onCancel={closeInfoModal} onConfirm={handleSubmitRevision} />}
        </>
    );
};

export default FinishedRevision;
