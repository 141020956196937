import React from 'react';
import styled from './Navbar.module.css';
import UserMenu from './UserMenu/UserMenu';
import useAuth from '../../hooks/useAuth';
import MobileLogo from '../Mobile/Logo/MobileLogo';

const MobileNavbar = () => {
    const { user } = useAuth();

    return (
        <nav className={styled.Navbar}>
            <MobileLogo />
            {user && <UserMenu initials={user.firstName.substr(0, 1) + user.lastName.substr(0, 1)} fullName={user.fullName} />}
        </nav>
    );
};

export default MobileNavbar;
