import React, { createRef, FC, useState } from 'react';
import styled from './UploadCompanyDocument.module.css';
import Button from '../../../../components/Button/Button';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../assets/images/closeIcon.svg';
import errorIcon from '../../../../assets/images/errorIcon.svg';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { CompanyDocumentTypeEnum, CompanyDocumentTypeTranslationEnum, getDocumentTypesTranslationKey } from '../../../models/CompanyDocumentType';
import DocumentService from '../../../../services/DocumentService';
import CompanyDocumentModel from '../../../models/CompanyDocumentModel';

interface Props {
    documents?: CompanyDocumentModel[];
    onSave: (type: CompanyDocumentTypeEnum, file: File) => void;
    onClose: () => void;
}

const FILE_SIZE_5_MB = 5 * 1048576;

const UploadCompanyDocument: FC<Props> = ({ onClose, onSave, documents }) => {
    const { t } = useTranslation();

    const DocumentTypes = [
        {
            id: 0,
            enum: CompanyDocumentTypeEnum.CURRENT_AGREEMENT,
            text: CompanyDocumentTypeTranslationEnum.CURRENT_AGREEMENT,
            locked: !(documents && !documents.find(doc => doc.type === CompanyDocumentTypeEnum.CURRENT_AGREEMENT)),
        },
        {
            id: 1,
            enum: CompanyDocumentTypeEnum.TENDER,
            text: CompanyDocumentTypeTranslationEnum.TENDER,
            locked: !(documents && !documents.find(doc => doc.type === CompanyDocumentTypeEnum.TENDER)),
        },
        {
            id: 2,
            enum: CompanyDocumentTypeEnum.DOCUMENT,
            text: CompanyDocumentTypeTranslationEnum.DOCUMENT,
            locked: !(documents && !documents.find(doc => doc.type === CompanyDocumentTypeEnum.DOCUMENT)),
        },
    ];

    const [documentType, setDocumentType] = useState<CompanyDocumentTypeEnum>();
    const [documentTypeError, setDocumentTypeError] = useState<string>();
    const fileInputRef = createRef<HTMLInputElement>();
    const [file, setFile] = useState<{ value?: { name: string; data?: File }; error?: string }>({
        value: { name: '', data: undefined },
        error: undefined,
    });

    const handleFileChange = () => {
        const files = fileInputRef.current?.files;

        if (files && files.length) {
            const isValidCompanyDocument: boolean = DocumentService().isValidCompanyDocument(files[0].name);

            if (!isValidCompanyDocument) {
                setFile({
                    value: undefined,
                    error: 'Dokumentet måste vara en PDF (.pdf) eller Word (.doc, .docx).',
                });
            } else {
                if (files[0].size > FILE_SIZE_5_MB) {
                    setFile({
                        value: undefined,
                        error: t('inputFieldErrorFileTooBig', { fileSize: (files[0].size / 1048576).toFixed(), maxSize: (FILE_SIZE_5_MB / 1048576).toFixed() }),
                    });
                } else {
                    setFile({
                        value: { name: files[0].name, data: files[0] },
                        error: undefined,
                    });
                }
            }
        }
    };

    const handleDocumentTypeChange = (id: number) => {
        const documentType = DocumentTypes[id];
        setDocumentType(documentType.enum);
        documentType.locked ? setDocumentTypeError('Kategorin är upptagen, ta bort den gamla först.') : setDocumentTypeError(undefined);
    };

    const handleSave = () => {
        if (documentType && file.value && file.value.data && !file.error) {
            onSave(documentType, file.value.data);
        }
    };

    return (
        <div className={styled.Content}>
            <Dropdown
                className={styled.Dropdown}
                dropdownWidth={'100%'}
                title={documentType ? t(getDocumentTypesTranslationKey(documentType)).toString() : ''}
                content={DocumentTypes}
                onChange={handleDocumentTypeChange}
                translateText
            />
            <div className={styled.Requirements}>
                <p>{t('documentUploadMaxFileSize')}</p>
                <p>{'Endast filtyp PDF är tillåtet.'}</p>
                <p>{'Endast 1 dokument per kategori är tillåtet.'}</p>
            </div>
            <div className={styled.FileUpload}>
                <input type="file" className={styled.FileInput} ref={fileInputRef} disabled={false} onChange={handleFileChange} />
                <Button variant="Primary" onClick={() => fileInputRef.current?.click()}>
                    {t('buttonSelectFile')}
                </Button>
                {file.value?.name && (
                    <div className={styled.FileBadge}>
                        <p>{file.value.name}</p>
                        <CloseIcon onClick={() => setFile({ value: undefined, error: undefined })} />
                    </div>
                )}
                {file.error && (
                    <div className={styled.FileError}>
                        <img src={errorIcon} alt="Error" />
                        <p>{file.error}</p>
                    </div>
                )}
                {documentTypeError && (
                    <div className={styled.FileError}>
                        <img src={errorIcon} alt="Error" />
                        <p>{documentTypeError}</p>
                    </div>
                )}
            </div>

            <div className={styled.Options}>
                <Button variant="Primary" onClick={onClose}>
                    {t('buttonNo')}
                </Button>
                <Button variant="Outline" onClick={handleSave} disabled={!documentType || !file.value?.data || file.error !== undefined || documentTypeError !== undefined}>
                    {t('buttonYes')}
                </Button>
            </div>
        </div>
    );
};

export default UploadCompanyDocument;
