import useCancellablePromises from './useCancellablePromises';
import { cancellablePromise, delay } from '../utils/cancelablePromise';

const useClickPreventionOnDoubleClick = (onClick?: Function, onDoubleClick?: Function) => {
    const api = useCancellablePromises();

    if (!onClick || !onDoubleClick) {
        return [undefined, undefined];
    }

    const handleClick = (params: any) => {
        api.clearPendingPromises();
        const waitForClick = cancellablePromise(delay(200));
        api.appendPendingPromise(waitForClick);

        return waitForClick.promise
            .then(() => {
                api.removePendingPromise(waitForClick);
                onClick(params);
            })
            .catch(errorInfo => {
                api.removePendingPromise(waitForClick);
                if (!errorInfo.isCanceled) {
                    throw errorInfo.error;
                }
            });
    };

    const handleDoubleClick = (params: any) => {
        api.clearPendingPromises();
        onDoubleClick(params);
    };

    return [handleClick, handleDoubleClick];
};

export default useClickPreventionOnDoubleClick;
