import React, { createContext, useState, SetStateAction, Dispatch, FC, useMemo, useEffect } from 'react';
import AcceptanceLogModel from '../models/AcceptanceLogModel';
import LawTypeFilter from '../models/LawTypeFilter';
import { Column, SortingRule } from 'react-table';
import LawCell from '../components/Table/LawCell/LawCell';
import useAuth from '../hooks/useAuth';
import KeywordCell from '../components/Table/KeywordCell/KeywordCell';
import { ChangeActionEnum } from '../models/ChangeAction';
import { useTranslation } from 'react-i18next';
import HtmlCell from '../components/Table/HtmlCell/HtmlCell';
import { columnKeys } from '../constants/ExcelColumnWidth';

export interface State {
    data: AcceptanceLogModel[];
    filteredData: State['data'];
    visibleDataCount: number;
    selectedIndex: number;

    lawTypeFilter: LawTypeFilter[];

    columns: Array<
        Column<AcceptanceLogModel> & { id: columnKeys; Header: string; languageId?: string; visible: boolean; field?: string; alwaysVisible?: boolean; showNotification?: boolean }
    >;
    customColumns: State['columns'];

    sorting: SortingRule<object>[];

    globalSearch: string;
    columnSearch: {
        id: string;
        value: string | undefined;
    }[];
    showColumnSearch: boolean;

    startDate: Date;
    endDate: Date;

    loading: boolean;
}

interface Context {
    state: State;
    setState: Dispatch<SetStateAction<State>>;
}

const intialState: State = {
    data: [],
    filteredData: [],
    visibleDataCount: 0,
    selectedIndex: -1,

    lawTypeFilter: [],

    columns: [],
    customColumns: [],

    sorting: [{ id: 'logDate', desc: false }],

    globalSearch: '',
    columnSearch: [],
    showColumnSearch: true,

    startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    endDate: new Date(),

    loading: false,
};

const ChangeArchiveContext = createContext<Context>({
    state: intialState,
    setState: () => null,
});

const ChangeArchiveContextProvider: FC = ({ children }) => {
    const { t, i18n } = useTranslation();
    const { company } = useAuth();

    const columns = useMemo(() => {
        const columns: State['columns'] = [
            {
                id: 'law',
                Header: t('columnLaw'),
                languageId: 'columnLaw',
                accessor: row => `${row.name} ${row.subId}`,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.values.law;
                    const lawB = rowB.values.law;
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
                visible: true,
                width: 2,
                alwaysVisible: true,
                Cell: LawCell,
            },
            {
                id: 'logDate',
                Header: t('columnDate'),
                languageId: 'columnDate',
                accessor: 'logDate',
                Cell: function LogDateCell(props: any) {
                    const value = props.cell.value.split(' ')[0];
                    return (
                        <HtmlCell
                            {...{
                                ...props,
                                cell: { ...props.cell, value },
                            }}
                        />
                    );
                },
                visible: true,
                sortInverted: true,
            },
            {
                id: 'changeText',
                Header: t('columnChange'),
                languageId: 'columnChange',
                accessor: 'changeText',
                disableSortBy: true,
                visible: true,
            },
            {
                id: 'changeAction',
                Header: t('columnAction'),
                languageId: 'columnAction',
                accessor: row => t(ChangeActionEnum[row.changeAction]),
                disableSortBy: true,
                visible: true,
            },
            {
                id: 'comment',
                Header: t('columnComment'),
                languageId: 'columnComment',
                accessor: 'comment',
                disableSortBy: true,
                visible: true,
            },
            {
                id: 'userFullName',
                Header: t('columnAcceptedBy'),
                languageId: 'columnAcceptedBy',
                accessor: 'userFullName',
                disableSortBy: true,
                visible: true,
            },
            {
                id: 'changeHasImpact',
                Header: t('columnChangeOfImpact'),
                languageId: 'columnChangeOfImpact',
                accessor: row => (row.changeHasImpact === true ? t('buttonYes') : row.changeHasImpact === false ? t('buttonNo') : ''),
                disableSortBy: true,
                visible: true,
            },
        ];

        return columns;
    }, []);

    const customColumns = useMemo(() => {
        const customColumns: State['customColumns'] = [];

        if (company?.hasLawLists) {
            customColumns.push({
                id: 'lawLists',
                Header: t('columnLawLists'),
                languageId: 'columnLawLists',
                accessor: row => row.lawLists,
                visible: true,
                disableSortBy: true,
                Cell: KeywordCell,
            });
        }

        if (company?.hasKeyWords) {
            customColumns.push({
                id: 'keywords',
                Header: t('columnKeywords'),
                languageId: 'columnKeywords',
                accessor: row => row.keyWords,
                visible: true,
                disableSortBy: true,
                Cell: KeywordCell,
            });
        }

        return customColumns;
    }, []);

    const [state, setState] = useState<State>({ ...intialState, columns, customColumns });

    useEffect(() => {
        setState(s => ({
            ...s,
            columns: s.columns.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
            customColumns: s.customColumns.map(col => ({
                ...col,
                Header: col.languageId ? t(col.languageId) : col.Header,
            })),
        }));
    }, [i18n.language]);

    // Apply filtering
    useEffect(() => {
        setState(s => {
            const { lawTypeFilter, data } = s;

            // Apply lawGroup filtering
            const selectedLawGroupNames = lawTypeFilter.flatMap(lawType => lawType.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupName));
            const filteredData = data.filter(obj => {
                return selectedLawGroupNames.includes(obj.lawGroupName);
            });

            return {
                ...s,
                filteredData,
            };
        });
    }, [state.data, state.lawTypeFilter]);

    useEffect(() => {
        setState(s => ({
            ...s,
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
            endDate: new Date(),
        }));
    }, []);

    return <ChangeArchiveContext.Provider value={{ state, setState }}>{children}</ChangeArchiveContext.Provider>;
};

export { ChangeArchiveContext, ChangeArchiveContextProvider };
