import React, { FC } from 'react';
import Modal from '../Modal';
import styled from './AlterRevisionConfirmModal.module.css';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    selectedCount: number;
    username: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const AlterRevisionConfirmModal: FC<Props> = ({ title, selectedCount, username, onCancel, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Modal title={title} className={styled.Modal}>
            <div className={styled.Content}>

                {selectedCount == 1 && <p>{t('alterRevisionConfirmModalText_Singular', { selectedCount })}</p>}
                {selectedCount > 1 && <p>{t('alterRevisionConfirmModalText', { selectedCount })}</p>}

                <div className={styled.Options}>
                    <Button variant="Primary" onClick={onCancel}>
                        {t('buttonNo')}
                    </Button>
                    <Button variant="Outline" onClick={onConfirm}>
                        {t('buttonYes')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default AlterRevisionConfirmModal;
