import React, { FC } from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import styled from './UnmonitorSubscriptionModal.module.css';

interface Props {
    onAccept: () => void;
    onClose: () => void;
}

const UnmonitorSubscriptionModal: FC<Props> = ({ onAccept, onClose }) => {
    return (
        <Modal
            title="Avsluta bevakning"
            subtitle="När du avslutar en bevakning kommer lagen tas bort från dina laglistor samt inga notiser om ändringar kommer skickas ut. Är du säker på att du vill avsluta bevakningen?"
        >
            <div className={styled.Options}>
                <Button variant="Primary" onClick={onClose}>
                    Behåll bevakning
                </Button>
                <Button variant="Outline" onClick={onAccept}>
                    Avsluta bevakning
                </Button>
            </div>
        </Modal>
    );
};

export default UnmonitorSubscriptionModal;
