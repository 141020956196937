import React, { FC } from 'react';
import styled from './Settings.module.css';
import userIcon from '../../assets/images/userIcon.svg';
import companyIcon from '../../assets/images/companyIcon.svg';
import starIcon from '../../assets/images/starIcon.svg';
import { NavLink, useLocation, Link, Route } from 'react-router-dom';
import {
    SETTINGS_USER_EMAIL,
    SETTINGS_COMPANY_USERS,
    SETTINGS_COMPANY_LOGINS,
    SETTINGS_COMPANY_REVISIONS,
    SETTINGS_COMPANY_CUSTOMLAWS,
    SETTINGS_COMPANY_COLUMNS,
    SETTINGS_COMPANY_LAWLISTS,
    SETTINGS_COMPANY_KEYWORDS,
    SETTINGS_USER_INFO,
    SETTINGS_COMPANY_DETACHED_COPIES,
    SETTINGS_CONTRACT_AGREEMENT,
    SETTINGS_CONTRACT_CONTRACT,
    SETTINGS_USER,
    SETTINGS_COMPANY,
    SETTINGS_CONTRACT,
} from '../../constants/Routes';
import CompanyUsers from './Company/CompanyUsers/CompanyUsers';
import ReaderLogin from './Company/ReaderLogin/ReaderLogin';
import RevisionSettings from './Company/RevisionSettings/RevisionSettings';
import CustomLaws from './Company/CustomLaws/CustomLaws';
import CustomColumns from './Company/CustomColumns/CustomColumns';
import LawLists from './Company/LawLists/LawLists';
import useAuth from '../../hooks/useAuth';
import Keywords from './Company/Keywords/Keywords';
import UserInfo from './User/UserInfo/UserInfo';
import EmailNotifications from './User/EmailNotifications/EmailNotifications';
import DetachedCopies from './Company/DetachedCopies/DetachedCopies';
import Agreement from './Contract/Agreement/Agreement';
import ViewContract from './Contract/Contract/ViewContract';
import { useTranslation } from 'react-i18next';

const MenuLink = ({ icon, title, url, list, baseUrl }: { icon: string; title: string; url: string; list: { title: string; url: string }[]; baseUrl: string }): JSX.Element => {
    const location = useLocation();

    const active = location.pathname.indexOf(baseUrl) !== -1;

    return (
        <>
            <NavLink to={url} activeClassName={styled.Active} className={styled.Link}>
                <div className={styled.Header}>
                    <img src={icon} />
                    <p>{title}</p>
                </div>
            </NavLink>
            {active && list.length > 0 && (
                <div className={styled.List}>
                    {list.map(link => (
                        <Link key={link.title} to={link.url} className={location.pathname === link.url ? styled.Active : ''}>
                            {link.title}
                        </Link>
                    ))}
                </div>
            )}
        </>
    );
};

const Settings: FC = () => {
    const { t } = useTranslation();
    const { company, isAboveUser, isGroupCompany, isPlusCompany } = useAuth();

    const USER_LIST = [
        { title: t('settingsMenuUserInfo'), url: SETTINGS_USER_INFO },
        ...(company?.useContinousNotification ? [{ title: t('settingsMenuUserEmail'), url: SETTINGS_USER_EMAIL }] : []),
    ];

    const COMPANY_LIST: { title: string; url: string }[] = [
        { title: t('settingsMenuCompanyUsers'), url: SETTINGS_COMPANY_USERS },
        ...(company?.anonymousPassword ? [{ title: t('settingsMenuCompanyLogins'), url: SETTINGS_COMPANY_LOGINS }] : []),
        { title: t('settingsMenuCompanyRevisions'), url: SETTINGS_COMPANY_REVISIONS },
        { title: t('settingsMenuCompanyCustomLaws'), url: SETTINGS_COMPANY_CUSTOMLAWS },
        { title: t('settingsMenuCompanyColumns'), url: SETTINGS_COMPANY_COLUMNS },
        ...(company?.hasLawLists ? [{ title: t('settingsMenuCompanyLawLists'), url: SETTINGS_COMPANY_LAWLISTS }] : []),
        ...(company?.hasKeyWords ? [{ title: t('settingsMenuCompanyKeywords'), url: SETTINGS_COMPANY_KEYWORDS }] : []),
        ...(company?.hasDetachedCopies ? [{ title: t('settingsMenuCompanyDetachedCopies'), url: SETTINGS_COMPANY_DETACHED_COPIES }] : []),
    ];

    const CONTRACT_LIST: { title: string; url: string }[] = [
        ...(isAboveUser() ? [{ title: t('settingsMenuContractAgreement'), url: SETTINGS_CONTRACT_AGREEMENT }] : []),
        ...(isAboveUser() ? [{ title: t('settingsMenuContractContract'), url: SETTINGS_CONTRACT_CONTRACT }] : []),
    ];

    return (
        <div className={styled.Settings}>
            <div className={styled.Menu}>
                <MenuLink icon={userIcon} title={t('settingsMenuUserSettings')} url={SETTINGS_USER_INFO} list={USER_LIST} baseUrl={SETTINGS_USER} />
                {isAboveUser() && (
                    <MenuLink icon={companyIcon} title={t('settingsMenuCompanySettings')} url={SETTINGS_COMPANY_USERS} list={COMPANY_LIST} baseUrl={SETTINGS_COMPANY} />
                )}
                {CONTRACT_LIST.length > 0 && (
                    <MenuLink icon={starIcon} title={t('settingsMenuContractSettings')} url={SETTINGS_CONTRACT_AGREEMENT} list={CONTRACT_LIST} baseUrl={SETTINGS_CONTRACT} />
                )}
            </div>
            <div className={styled.Content}>
                <Route path={SETTINGS_USER_INFO} component={UserInfo} />
                <Route path={SETTINGS_USER_EMAIL} component={EmailNotifications} />

                {isAboveUser() && (
                    <>
                        <Route path={SETTINGS_COMPANY_USERS} component={CompanyUsers} />
                        <Route path={SETTINGS_COMPANY_LOGINS} component={ReaderLogin} />
                        <Route path={SETTINGS_COMPANY_REVISIONS} component={RevisionSettings} />
                        <Route path={SETTINGS_COMPANY_CUSTOMLAWS} component={CustomLaws} />
                        <Route path={SETTINGS_COMPANY_COLUMNS} component={CustomColumns} />
                        <Route path={SETTINGS_COMPANY_LAWLISTS} component={LawLists} />
                        <Route path={SETTINGS_COMPANY_KEYWORDS} component={Keywords} />
                        <Route path={SETTINGS_COMPANY_DETACHED_COPIES} component={DetachedCopies} exact />
                        <Route path={SETTINGS_CONTRACT_AGREEMENT} component={Agreement} />
                        <Route path={SETTINGS_CONTRACT_CONTRACT} component={ViewContract} />
                    </>
                )}
            </div>
        </div>
    );
};

export default Settings;
