import React, { createRef, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import closeIcon, { ReactComponent as CloseIcon } from '../../../assets/images/closeIcon.svg';
import styled from './InvoiceFullView.module.css';
import { useTranslation } from 'react-i18next';
import editIcon from '../../../assets/images/editIcon.svg';
import Accordion from '../../../components/Accordion/Accordion';
import EditFieldTextModal from '../../components/modals/EditFieldModal/EditFieldTextModal';
import AccordionUserItem from '../../components/Accordions/AccordionUserItem/AccordionUserItem';
import EditFieldNumberModal from '../../components/modals/EditFieldModal/EditFieldNumberModal';
import CompanyInvoiceFullModel from '../../models/CompanyInvoiceFullModel';
import Dropdown from '../../../components/Dropdown/Dropdown';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import AccordionTextItem from '../../../components/Accordion/AccordionTextItem/AccordionTextItem';
import EditDescriptionModal from '../../components/modals/EditDescriptionModal/EditDescriptionModal';
import InvoiceButton from '../../components/InvoiceButton/InvoiceButton';
import AccordionServiceItem from '../../components/Accordions/AccordionServiceItem/AccordionServiceItem';
import Button from '../../../components/Button/Button';
import UpdatePriceWarningModal from '../../components/modals/UpdatePriceWarningModal/UpdatePriceWarningModal';
import { CompanyTypeEnum } from '../../../models/CompanyType';
import { customerReferenceNumberMax, discountMax, additionalFeeMax, invoiceDescriptionMax, adminInfoMax, customerNumberNewMax } from '../../validation/Restrictions';
import ResetAllPricesForGroupWarningModal from '../../components/modals/ResetAllPricesForGroupWarningModal/ResetAllPricesForGroupWarningModal';
import AccordionCompanyDocumentItem from '../../components/Accordions/AccordionCompanyDocumentItem/AccordionCompanyDocumentItem';
import UploadCompanyDocumentModal from '../../components/modals/UploadCompanyDocumentModal/UploadCompanyDocumentModal';
import { CompanyDocumentTypeEnum } from '../../models/CompanyDocumentType';
import CompanyDocumentModel from '../../models/CompanyDocumentModel';
import InvoiceService from '../../services/InvoiceService';

interface Props {
    company: CompanyInvoiceFullModel;
    updateCustomerReferenceNumber: (companyId: number, customerReferenceNumber: string) => void;
    updateCustomerNumber: (companyId: number, customerNumber: number) => void;
    handleUpdatePrice: (companyId: number) => void;
    handleResetAllPricesForGroup: (companyId: number) => void;
    updateDiscount: (companyId: number, discount: number) => void;
    updateAdditionalFee: (companyId: number, discount: number) => void;
    initAttentionPerson: () => void;
    updateAttentionPerson: (companyId: number, customerId: number) => void;
    attentionPerson?: { id: number; text: string };
    attentionCandidates: { id: number; text: string }[];
    handleChangeAgreementFrom: (companyId: number, agreementFrom: Date) => void;
    handleChangeAgreementTo: (companyId: number, agreementTo: Date) => void;
    updateInvoiceDescription: (companyId: number, invoiceDescription: string) => void;
    updateAdminInfo: (companyId: number, description: string) => void;
    markCompanyAsInvoiced: (companyId: number) => void;
    markCompanyAsNotInvoiced: (companyId: number) => void;
    handleUploadCompanyDocument: (type: CompanyDocumentTypeEnum, file: File) => void;
    handleDeleteCompanyDocument: (documentId: number) => void;
    onClose: () => void;
    open: boolean;
}

const InvoiceFullView: FC<Props> = ({
    company,
    onClose,
    open,
    updateCustomerReferenceNumber,
    updateCustomerNumber,
    initAttentionPerson,
    updateAttentionPerson,
    attentionPerson,
    attentionCandidates,
    handleChangeAgreementFrom,
    handleChangeAgreementTo,
    handleUpdatePrice,
    handleResetAllPricesForGroup,
    updateDiscount,
    updateAdditionalFee,
    updateInvoiceDescription,
    updateAdminInfo,
    markCompanyAsInvoiced,
    markCompanyAsNotInvoiced,
    handleUploadCompanyDocument,
    handleDeleteCompanyDocument,
}) => {
    const { t, i18n } = useTranslation();

    const [showCustomerReferenceNumberModal, setShowCustomerReferenceNumberModal] = useState(false);
    const [showCustomerNumberModal, setShowCustomerNumberModal] = useState(false);
    const [showUpdatePriceWarningModal, setShowUpdatePriceWarningModal] = useState(false);
    const [showResetAllPricesForGroupWarningModal, setShowResetAllPricesForGroupWarningModal] = useState(false);
    const [showDiscountModal, setShowDiscountModal] = useState(false);
    const [showAdditionalFeeModal, setShowAdditionalFeeModal] = useState(false);
    const [showEditDescriptionModal, setShowEditDescriptionModal] = useState<{ show: boolean; text: string; invoice: boolean }>({ show: false, text: '', invoice: true });
    const [openAccordions, setOpenAccordions] = useState<number[]>([]);
    const [showUploadCompanyDocumentModal, setShowUploadCompanyDocumentModal] = useState(false);

    const handleDocumentDownload = (doc: CompanyDocumentModel) => {
        InvoiceService().downloadCompanyDocument(company.id, doc);
    };

    const handleUploadCompanyDocumentOnSave = (type: CompanyDocumentTypeEnum, file: File) => {
        handleUploadCompanyDocument(type, file);
        setShowUploadCompanyDocumentModal(false);
    };

    useEffect(() => {
        if (company) {
            initAttentionPerson();
            if (openAccordions.length === 0) {
                setOpenAccordions(s => [...s, 2, 4]);
            }
        }
    }, [company]);

    const accordionItems = useMemo(() => {
        const items: { title: string; value: ReactNode; edit?: { onEdit?: () => void; title?: string } }[] = [];

        items.push({
            title: t('Användare') + ': ' + (company && company.users && company.users.length ? company.users.length : 0),
            value: <AccordionUserItem data={company.users} />,
        });

        items.push({
            title: t('Externa användare') + ': ' + (company && company.externalUsers && company.externalUsers.length ? company.externalUsers.length : 0),
            value: <AccordionUserItem data={company.externalUsers} />,
        });

        items.push({
            title: 'Items: ' + (company && company.serviceItems && company ? company.serviceItems.length : 0),
            value: <AccordionServiceItem data={company?.serviceItems || ''} />,
        });

        items.push({
            title: 'Fakturakommentar',
            value: <AccordionTextItem data={company?.invoiceDescription || ''} />,
            edit: {
                onEdit: () => setShowEditDescriptionModal({ show: true, text: company.invoiceDescription, invoice: true }),
            },
        });

        items.push({
            title: 'Kommentar (ej med i fakturan)',
            value: <AccordionTextItem data={company?.adminInfo || ''} />,
            edit: {
                onEdit: () => setShowEditDescriptionModal({ show: true, text: company.adminInfo, invoice: false }),
            },
        });

        if (company.type !== 'STANDARD') {
            items.push({
                title: 'Dokument',
                value: <AccordionCompanyDocumentItem data={company.companyDocuments} onRemove={handleDeleteCompanyDocument} onDownload={handleDocumentDownload} />,
                edit: {
                    onEdit: () => setShowUploadCompanyDocumentModal(true),
                },
            });
        }

        return items;
    }, [company, i18n]);

    const handleUpdatePriceAccept = (value: number): void => {
        handleUpdatePrice(value);
        setShowUpdatePriceWarningModal(false);
    };

    const handleResetAllPricesForGroupAccept = (value: number): void => {
        handleResetAllPricesForGroup(value);
        setShowResetAllPricesForGroupWarningModal(false);
    };

    const handleSelectAttentionPerson = (value: number): void => {
        updateAttentionPerson(company.id, value);
    };

    const handleEditDescription = (value: string, invoice: boolean): void => {
        if (invoice) {
            updateInvoiceDescription(company.id, value);
        } else {
            updateAdminInfo(company.id, value);
        }
    };

    const handleToggleAccordion = (index: number) => {
        setOpenAccordions(s => (s.includes(index) ? s.filter(i => i !== index) : [...s, index]));
    };

    const invoiceButtonChanged = () => {
        if (company.invoiceSent) {
            markCompanyAsNotInvoiced(company.id);
        } else {
            markCompanyAsInvoiced(company.id);
        }
    };

    return (
        <div className={[styled.FullView, open ? styled.Open : ''].join(' ')}>
            <div className={styled.CloseBar}>
                <img src={closeIcon} alt="Close" onClick={onClose} />
                <p> {t('InvoicingFullViewTitle')}</p>
            </div>

            <div className={styled.Header} title={'companyId: ' + `${company.id}`}>
                <div className={styled.Title}>{company.name}</div>
                <InvoiceButton disabled={false} invoiced={company.invoiceSent} onChange={invoiceButtonChanged} />
            </div>
            <div className={styled.Content}>
                <div className={styled.Information}>
                    <table>
                        <tbody>
                            <tr>
                                <td>{t('customerNumber')}:</td>
                                <td>
                                    {company.customerNumberNew}
                                    <img
                                        className={styled.Pen}
                                        src={editIcon}
                                        alt="Edit"
                                        onClick={() => {
                                            setShowCustomerNumberModal(true);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{t('customerReferenceNumber')}:</td>
                                <td>
                                    {company.customerReferenceNumber}
                                    <img
                                        className={styled.Pen}
                                        src={editIcon}
                                        alt="Edit"
                                        onClick={() => {
                                            setShowCustomerReferenceNumberModal(true);
                                        }}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Referens på fakturan:</td>
                                <td>
                                    <Dropdown
                                        className={styled.Dropdown}
                                        title={attentionPerson?.text || 'Välj referens'}
                                        content={attentionCandidates}
                                        onChange={handleSelectAttentionPerson}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>{t('contractPeriod')}:</td>
                                <td>
                                    <DateRangePicker
                                        startDate={company.agreementFrom}
                                        onStartDateChange={(value: Date) => {
                                            handleChangeAgreementFrom(company.id, value);
                                        }}
                                        endDate={company.agreementTo}
                                        onEndDateChange={(value: Date) => {
                                            handleChangeAgreementTo(company.id, value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{t('companyType')}:</td>
                                <td>{CompanyTypeEnum[company.type]}</td>
                            </tr>
                            <tr>
                                <td>{t('uniqueSubscriptionCount')}:</td>
                                <td>{company.uniqueSubscriptionCount}</td>
                            </tr>
                            <tr>
                                <td>{t('discount')}: </td>
                                <td>
                                    {company.discount} kr
                                    <img src={editIcon} alt="Edit" className={styled.Pen} onClick={() => setShowDiscountModal(true)} />
                                </td>
                            </tr>
                            <tr>
                                <td>{t('additionalFee')}: </td>
                                <td>
                                    {company.additionalFee} kr
                                    <img src={editIcon} alt="Edit" className={styled.Pen} onClick={() => setShowAdditionalFeeModal(true)} />
                                </td>
                            </tr>
                            <tr>
                                <td>{t('price')}: </td>

                                <td>
                                    {company.totalPrice + ' kr'}
                                    <Button variant="Secondary" title={''} className={styled.UpdatePriceButton} onClick={() => setShowUpdatePriceWarningModal(true)}>
                                        Uppdatera pris
                                    </Button>
                                </td>

                                {company.corporationId && (
                                    <td>
                                        <Button
                                            variant="Secondary"
                                            title={''}
                                            className={styled.ResetAllPricesForGroupButton}
                                            onClick={() => setShowResetAllPricesForGroupWarningModal(true)}
                                        >
                                            {t('resetAllPricesForGroupWarningTitle')}
                                        </Button>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
                {company?.users && (
                    <div className={styled.AccordionContent}>
                        <Accordion id={company?.id || -1} data={accordionItems} initiallyOpenIndicies={openAccordions} onToggle={handleToggleAccordion} />
                    </div>
                )}
            </div>

            {showCustomerReferenceNumberModal && (
                <EditFieldTextModal
                    title="Kundreferensnummer:"
                    value={company.customerReferenceNumber}
                    maxLength={customerReferenceNumberMax}
                    onCancel={() => {
                        setShowCustomerReferenceNumberModal(false);
                    }}
                    onConfirm={value => {
                        updateCustomerReferenceNumber(company.id, value);
                        setShowCustomerReferenceNumberModal(false);
                    }}
                />
            )}
            {showCustomerNumberModal && (
                <EditFieldNumberModal
                    title="Kundnummer:"
                    value={company.customerNumberNew}
                    priceRestriction={customerNumberNewMax}
                    spinnerStep={1}
                    onCancel={() => {
                        setShowCustomerNumberModal(false);
                    }}
                    onConfirm={value => {
                        updateCustomerNumber(company.id, value);
                        setShowCustomerNumberModal(false);
                    }}
                />
            )}
            {showDiscountModal && (
                <EditFieldNumberModal
                    title="Rabatt:"
                    value={company.discount}
                    priceRestriction={discountMax}
                    spinnerStep={100}
                    onCancel={() => {
                        setShowDiscountModal(false);
                    }}
                    onConfirm={value => {
                        updateDiscount(company.id, value);
                        setShowDiscountModal(false);
                    }}
                />
            )}

            {showAdditionalFeeModal && (
                <EditFieldNumberModal
                    title="Tilläggsavgift:"
                    value={company.additionalFee}
                    priceRestriction={additionalFeeMax}
                    spinnerStep={100}
                    onCancel={() => {
                        setShowAdditionalFeeModal(false);
                    }}
                    onConfirm={value => {
                        updateAdditionalFee(company.id, value);
                        setShowAdditionalFeeModal(false);
                    }}
                />
            )}

            {showUpdatePriceWarningModal && (
                <UpdatePriceWarningModal
                    onAccept={() => {
                        handleUpdatePriceAccept(company.id);
                    }}
                    onClose={() => {
                        setShowUpdatePriceWarningModal(false);
                    }}
                />
            )}

            {showResetAllPricesForGroupWarningModal && (
                <ResetAllPricesForGroupWarningModal
                    onAccept={() => {
                        handleResetAllPricesForGroupAccept(company.id);
                    }}
                    onClose={() => {
                        setShowResetAllPricesForGroupWarningModal(false);
                    }}
                />
            )}

            {showEditDescriptionModal.show && (
                <div className={styled.Editor}>
                    <EditDescriptionModal
                        title={'Redigera kommentar'}
                        value={showEditDescriptionModal.text}
                        maxLength={showEditDescriptionModal.invoice ? invoiceDescriptionMax : adminInfoMax}
                        onCancel={() => {
                            setShowEditDescriptionModal({ show: false, text: '', invoice: true });
                        }}
                        onConfirm={value => {
                            handleEditDescription(value, showEditDescriptionModal.invoice);
                            setShowEditDescriptionModal({ show: false, text: '', invoice: true });
                        }}
                    />
                </div>
            )}

            {showUploadCompanyDocumentModal && (
                <UploadCompanyDocumentModal
                    documents={company.companyDocuments}
                    onSave={handleUploadCompanyDocumentOnSave}
                    onClose={() => {
                        setShowUploadCompanyDocumentModal(false);
                    }}
                />
            )}
        </div>
    );
    return <div></div>;
};

export default InvoiceFullView;
