import React, { FC, useState } from 'react';
import Modal from '../Modal';
import NewsFeedModel from '../../../models/NewsFeedModel';
import styled from './EditNewsFeedModal.module.css';
import Input from '../../Input/Input';
import TextEditor from '../../TextEditor/TextEditor';
import Button from '../../Button/Button';
import * as VALIDATION from '../../../constants/Validation';
import { useTranslation } from 'react-i18next';

interface Props {
    data?: NewsFeedModel;
    onCancel: () => void;
    onSubmit: (newsFeed: NewsFeedModel) => void;
    onRemove: () => void;
}

const EditNewsFeedModal: FC<Props> = ({ data, onCancel, onSubmit, onRemove }) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState<{ value: string; error?: string }>({ value: data ? data.title : '' });
    const [text, setText] = useState(data ? data.text : '');
    const [hasText, setHasText] = useState(data ? data.text.length > 0 : false);

    const handleSubmit = () => {
        const newsFeed: NewsFeedModel = {
            id: data ? data.id : undefined,
            title: title.value,
            text,
            createdAt: data ? data.createdAt : undefined,
            order: data ? data.order : 0,
        };
        onSubmit(newsFeed);
    };

    const handleTitleChange = (value: string): void => {
        setTitle(s => ({ ...s, value, error: VALIDATION.name(value) }));
    };

    return (
        <Modal title={data ? t('editNewsFeedModalTitle') : t('createNewsFeedModalTitle')} className={styled.EditNewsFeedModal}>
            <div className={styled.Content}>
                <div className={styled.TitleInput}>
                    <Input value={title.value} onChange={handleTitleChange} label={t('inputFieldTitle')} error={title.error} maxLength={99} autoFocus />
                </div>

                <div className={styled.TextEditor}>
                    <TextEditor title="Text" initialValue={text} onChange={setText} hasText={setHasText} maxLength={1000} />
                </div>

                <div className={styled.Buttons}>
                    {data && (
                        <Button variant="Outline" className={styled.RemoveButton} onClick={onRemove}>
                            {t('buttonRemove')}
                        </Button>
                    )}
                    <Button variant="Outline" onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Primary" onClick={handleSubmit} disabled={title.error !== undefined || title.value.length < 1 || !hasText}>
                        {data ? t('buttonSave') : t('buttonCreate')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EditNewsFeedModal;
