export type ChangeAction = 'CHAIN_STARTED' | 'CHANGE_ACCEPTED' | 'NEW_ACCEPTED' | 'NEW_DECLINED' | 'REMOVED' | 'DELEGATION_CHANGED' | 'NEW_DELEGATED' | 'ACCEPTED_DELAGATED' | 'NEW_ACCEPTED_DELAGATED' | 'DELEGATED_WITHOUT_DECISION' | 'NEW_DELEGATED_WITHOUT_DECISION';

export enum ChangeActionEnum {
    'CHAIN_STARTED' = 'changeActionChainStarted',
    'CHANGE_ACCEPTED' = 'changeActionChangeAccepted',
    'NEW_ACCEPTED' = 'changeActionNewAccept',
    'NEW_DECLINED' = 'changeActionNewDeclined',
    'NEW_DELEGATED' = 'changeActionNewDelegated',
    'DELEGATION_CHANGED' = 'changeActionDelegationChanged',
    'REMOVED' = 'changeActionRemoved',
    'MIGRATED' = 'changeActionMigratedSubscription',
    'ACCEPTED_DELAGATED' = 'changeActionAcceptAndDelegated',
    'NEW_ACCEPTED_DELAGATED' = 'changeActionAcceptAndDelegatedNew',
    'DELEGATED_WITHOUT_DECISION' = 'changeActionDelegateWithoutDecision',
    'NEW_DELEGATED_WITHOUT_DECISION' = 'changeActionDelegateWithoutDecisionNew',
}
