import React, { FC } from 'react';
import styled from './InstructionsVideo.module.css';
import DynamicVideoContent from '../../models/DynamicVideoContent';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

interface Props {
    data: DynamicVideoContent;
    onChange?: (id: number) => void;
    currentVideoIdPlaying: number;
}

const InstructionsVideo: FC<Props> = ({ data, currentVideoIdPlaying, onChange }) => {
    return (
        <div className={styled.InstructionsVideo}>
            <div className={styled.Text}>
                <h4>{data.title}</h4>
                <p>{data.text}</p>
            </div>
            <div className={styled.Media}>
                <VideoPlayer currentVideoIdPlaying={currentVideoIdPlaying} onChange={onChange} dynamicVideoContentId={data.id} />
            </div>
        </div>
    );
};

export default InstructionsVideo;
