import React, { FC, useEffect, useState } from 'react';
import useChangeArchive from '../../../hooks/useChangeArchive';
import eyeIcon from '../../../assets/images/eyeIcon.svg';
import archiveIcon from '../../../assets/images/archiveIcon.svg';
import { CHANGES_LIST, CHANGES_ARCHIVE, HELP_CONTACT } from '../../../constants/Routes';
import ContextMenu from '../../../components/ContextMenu/ContextMenu';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import styled from './ChangeArchive.module.css';
import Filterbar from '../../../components/Filterbar/Filterbar';
import Table from '../../../components/Table/Table';
import ChangeArchiveFullView from '../../../components/FullView/ChangeArchiveFullView/ChangeArchiveFullView';
import RevertChangeModal from '../../../components/Modal/RevertChangeModal/RevertChangeModal';
import useAuth from '../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import EditView from '../../../components/EditView/EditView';
import { useTranslation } from 'react-i18next';
import EmptyListView from '../../../components/EmptyListView/EmptyListView';

const ChangeArchive: FC = () => {
    const { t } = useTranslation();

    const CONTEXT_LINKS: {
        title: string;
        url: string;
        icon: string;
        active: boolean;
    }[] = [
        {
            title: t('navbarChanges'),
            url: CHANGES_LIST,
            icon: eyeIcon,
            active: false,
        },
        {
            title: t('navbarArchive'),
            url: CHANGES_ARCHIVE,
            icon: archiveIcon,
            active: true,
        },
    ];

    const history = useHistory();
    const { isAssessor, isAboveSuperUser, fetchNumberOfChangesForCompany, fetchNumberOfConsultantChangesForCompany, user } = useAuth();
    const [showRevertModal, setShowRevertModal] = useState(false);
    const [editComment, setEditComment] = useState<{ id?: number; value?: string; open: boolean }>({ open: false });
    const [hasRevertOwnRights, setHasRevertOwnRights] = useState(false);
    const [hasRevertAllRights, setHasRevertAllRights] = useState(false);
    const [isOwnAssessment, setIsOwnAssessment] = useState(false);
    const [subscriptionHasCopies, setSubscriptionHasCopies] = useState(false);

    const {
        data,
        filteredData,
        loading,
        columns,
        customColumns,
        lawTypeFilter,
        visibleDataCount,
        globalSearch,
        columnSearch,
        showColumnSearch,
        selectedIndex,
        sorting,
        hiddenColumns,
        startDate,
        endDate,
        fetchData,
        handleLawTypeOpen,
        handleLawTypeChecked,
        handleLawGroupChecked,
        handleGlobalSearchChange,
        handleResetLawTypeFilter,
        handleResetAllFilters,
        handleColumnSearchToggle,
        handleSelection,
        handleVisibleDataCountChange,
        handleSortingChange,
        handleColumnVisibleChange,
        handleRevertAllChange,
        handleRevertOwnChange,
        validateSubmitRevertOwnChange,
        handleStartDateChange,
        handleEndDateChange,
        handleExport,
        handleUpdateAcceptanceLogComment,
        handleAllLawTypesChecked,
        handleColumnSearchChange,
    } = useChangeArchive();

    useEffect(() => {
        fetchData(startDate, endDate);
    }, [startDate, endDate]);

    const handleSubmitRevertAllChange = async () => {
        if (isAboveSuperUser()) {
            await handleRevertAllChange(filteredData[selectedIndex].lawId);
            fetchNumberOfChangesForCompany();
            fetchNumberOfConsultantChangesForCompany();
            fetchData(startDate, endDate);
        }
        setShowRevertModal(false);
    };

    const handleSubmitRevertOwnChange = async () => {
        if (isAboveSuperUser() || filteredData[selectedIndex].userId === user?.userId) {
            await handleRevertOwnChange(filteredData[selectedIndex].acceptanceLogId, filteredData[selectedIndex].lawId);
            fetchNumberOfChangesForCompany();
            fetchNumberOfConsultantChangesForCompany();
            fetchData(startDate, endDate);
        }
        setShowRevertModal(false);
    };

    const handleSubmitToSupportRevertModal = async () => {
        history.push(HELP_CONTACT, { subject: 'REQUEST_ROLLBACK', acceptanceLog: filteredData[selectedIndex] });
        setShowRevertModal(false);
    };

    const handleShowRevertModal = () => {
        validateSubmitRevertOwnChange(filteredData[selectedIndex].lawId).then((valid: boolean) => {
            setSubscriptionHasCopies(!valid);
            setHasRevertOwnRights(isAboveSuperUser() || (isAssessor() && filteredData[selectedIndex].userId === user?.userId && valid));
        });
        setIsOwnAssessment(filteredData[selectedIndex].userId === user?.userId);
        setHasRevertAllRights(isAboveSuperUser());
        setShowRevertModal(true);
    };

    const handleSubmitAcceptanceLogComment = () => {
        const comment = editComment.value;
        const id = editComment.id;

        if (comment && id) {
            setEditComment({ open: false });
            handleUpdateAcceptanceLogComment(id, comment);
        }
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            <ContextMenu
                links={CONTEXT_LINKS}
                hasCustomLaws={false}
                lawTypeFilter={lawTypeFilter}
                visibleDataCount={visibleDataCount}
                totalDataCount={data.length}
                onToggleLawTypeOpen={handleLawTypeOpen}
                onToggleLawTypeChecked={handleLawTypeChecked}
                onToggleLawGroupChecked={handleLawGroupChecked}
                onResetAllFilters={handleResetAllFilters}
                onResetLawTypeFilter={handleResetLawTypeFilter}
                onToggleAllLawTypesChecked={handleAllLawTypesChecked}
            />
            <div className={styled.List}>
                <Filterbar
                    globalSearch={globalSearch}
                    onGlobalSearchChange={handleGlobalSearchChange}
                    showColumnSearch={showColumnSearch}
                    onColumnSearchToggle={handleColumnSearchToggle}
                    columns={customColumns}
                    onColumnShowChange={handleColumnVisibleChange}
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={handleStartDateChange}
                    onEndDateChange={handleEndDateChange}
                    onExport={handleExport}
                />
                <div className={styled.TableWrapper}>
                    {!loading && visibleDataCount !== undefined && visibleDataCount < 1 ? <EmptyListView emptyFilter /> : null}

                    <Table
                        data={filteredData}
                        columns={[...columns, ...customColumns]}
                        hiddenColumns={hiddenColumns}
                        showColumnSearch={showColumnSearch}
                        columnSearch={columnSearch}
                        globalSearch={globalSearch}
                        sorting={sorting}
                        selected={selectedIndex}
                        onSelect={handleSelection}
                        onVisibleDataCountChange={handleVisibleDataCountChange}
                        onSortingChange={handleSortingChange}
                        onColumnSearchChange={handleColumnSearchChange}
                    />
                    <ChangeArchiveFullView
                        data={filteredData[selectedIndex]}
                        open={selectedIndex > -1}
                        onEditComment={() => setEditComment({ open: true, value: filteredData[selectedIndex].comment, id: filteredData[selectedIndex].acceptanceLogId })}
                        onRevertChange={handleShowRevertModal}
                        onClose={() => handleSelection(-1)}
                    />

                    {selectedIndex > -1 && editComment.open && editComment.value && (
                        <EditView
                            title={t('columnComment')}
                            value={editComment.value}
                            field="comment"
                            onChange={value => setEditComment(s => ({ ...s, value }))}
                            onSubmit={handleSubmitAcceptanceLogComment}
                            onClose={() => setEditComment({ open: false })}
                        />
                    )}
                </div>
                {selectedIndex > -1 && showRevertModal && (
                    <RevertChangeModal
                        lawId={filteredData[selectedIndex].lawId}
                        onSubmitRevertAllChange={handleSubmitRevertAllChange}
                        onSubmitRevertOwnChange={handleSubmitRevertOwnChange}
                        onSubmitToSupport={handleSubmitToSupportRevertModal}
                        hasRevertAllRights={hasRevertAllRights}
                        hasRevertOwnRights={hasRevertOwnRights}
                        isOwnAssessment={isOwnAssessment}
                        subscriptionHasCopies={subscriptionHasCopies}
                        onClose={() => setShowRevertModal(false)}
                    />
                )}
            </div>
        </>
    );
};

export default ChangeArchive;
