import React, { FC, useState, useEffect, ReactNode, useMemo } from 'react';
import FullView from '../FullView';
import SubscriptionModel from '../../../models/SubscriptionModel';
import AcceptanceLogModel from '../../../models/AcceptanceLogModel';
import SubscriptionChangeService from '../../../services/SubscriptionChangeService';
import Accordion from '../../Accordion/Accordion';
import AccordionListItem from '../../Accordion/AccordionListItem/AccordionListItem';
import AccordionTextItem from '../../Accordion/AccordionTextItem/AccordionTextItem';
import AccordionCommentItem from '../../Accordion/AccordionCommentItem/AccordionCommentItem';
import styled from './ChangeFullView.module.css';
import FullViewFooter from '../FullViewFooter/FullViewFooter';
import { useTranslation } from 'react-i18next';
import ChangeTextLink from '../../ChangeTextLink/ChangeTextLink';
import useAuth from '../../../hooks/useAuth';
import _ from 'lodash';
import { UNGROUPED_LAWLIST_NAME } from '../../../models/LawListModel';

interface Props {
    data?: SubscriptionModel;
    open: boolean;
    hideFooter: boolean;
    onClose: () => void;
    onDelegateChange: () => void;
    onAcknowledgeChange: () => void;
    onAcknowledgeNewChange: () => void;
    onForceDelegation: () => void;
}

const ChangeFullView: FC<Props> = ({ data, open, hideFooter, onClose, onDelegateChange, onAcknowledgeChange, onAcknowledgeNewChange, onForceDelegation }) => {
    const { t, i18n } = useTranslation();
    const { company } = useAuth();
    const [acceptanceLogs, setAcceptanceLogs] = useState<AcceptanceLogModel[]>([]);
    const [openAccordions, setOpenAccordions] = useState<number[]>([1]);

    useEffect(() => {
        if (data && data.subscriptionId && open) {
            setAcceptanceLogs([]);
            SubscriptionChangeService()
                .getAcceptanceLog(data.subscriptionId)
                .then(acceptanceLogs => {
                    setAcceptanceLogs(acceptanceLogs);
                    if (acceptanceLogs.length > 0 && !openAccordions.includes(0)) {
                        setOpenAccordions(s => [...s, 0]);
                    }
                });
            if (data.waitingForAcceptance.length > 0 && !openAccordions.includes(2)) {
                setOpenAccordions(s => [...s, 2]);
            }
        }
    }, [data, open]);

    const accordionItems = useMemo(() => {
        const items: { title: string; value: ReactNode }[] = [];

        items.push({
            title: t('fullviewPreviousComments'),
            value: <AccordionCommentItem data={acceptanceLogs.map(al => ({ date: al.logDate, username: al.userFullName, comment: al.comment }))} />,
        });

        const link = data ? <ChangeTextLink lawId={data.lawId} lawSources={data.lawSources} /> : null;
        items.push({
            title: t('columnChange'),
            value: <AccordionTextItem data={data?.changeText || ''} link={link} />,
        });

        items.push({
            title: t('fullviewDelegated'),
            value: <AccordionListItem data={data?.waitingForAcceptance?.map(wfa => wfa.fullName) || []} />,
        });

        items.push({
            title: t('columnText'),
            value: <AccordionTextItem data={data?.text || ''} />,
        });

        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                items.push({
                    title,
                    value: <AccordionTextItem data={_.get(data, `customerText${i}`)} />,
                });
            }
        }
        if (company?.hasLawLists) {
            items.push({
                title: t('columnLawLists'),
                value: (
                    <AccordionListItem
                        data={
                            data?.lawLists?.map(lawList => {
                                if (lawList.lawListGroup && lawList.lawListGroup.name !== UNGROUPED_LAWLIST_NAME) {
                                    return lawList.lawListGroup.name + ': ' + lawList.name;
                                }
                                return lawList.name;
                            }) || []
                        }
                    />
                ),
            });
        }

        if (company?.hasKeyWords) {
            items.push({
                title: t('columnKeywords'),
                value: <AccordionListItem data={data?.keywordIds?.map(kwId => _.find(company.keyWords, kw => kw.id === kwId)?.text || '') || []} />,
            });
        }

        return items;
    }, [data, acceptanceLogs, i18n.language]);

    const handleToggleAccordion = (index: number) => {
        setOpenAccordions(s => (s.includes(index) ? s.filter(i => i !== index) : [...s, index]));
    };

    return (
        <FullView
            key={data?.subscriptionId}
            lawSourceId={data?.lawId || -1}
            title={data?.name || ''}
            subId={data?.subId || ''}
            isCustomLaw={false}
            lawSource={data?.lawSources.length ? data.lawSources[0] : undefined}
            open={open}
            onClose={onClose}
            hiddenIdBar={'Sid:' + data?.subscriptionId + ', Lid:' + data?.lawId}
        >
            <div className={styled.Content}>
                <Accordion id={data?.subscriptionId || -1} data={accordionItems} initiallyOpenIndicies={openAccordions} onToggle={handleToggleAccordion} />
            </div>
            {data?.acceptanceStatus && !hideFooter && (
                <FullViewFooter
                    key={data?.subscriptionId}
                    data={data}
                    onDelegateChange={onDelegateChange}
                    onAcknowledgeChange={onAcknowledgeChange}
                    onAcknowledgeNewChange={onAcknowledgeNewChange}
                    onForceDelegation={onForceDelegation}
                />
            )}
        </FullView>
    );
};

export default ChangeFullView;
