import React, { FC } from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import styled from './RemoveCustomLawModal.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    name: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const RemoveCustomLawModal: FC<Props> = ({ name, onCancel, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('removeCustomLawModalTitle')} subtitle={t('removeCustomLawModalSubTitle', { name })} className={styled.Modal}>
            <Button variant="Primary" onClick={onCancel}>
                {t('buttonNo')}
            </Button>
            <Button variant="Outline" onClick={onConfirm}>
                {t('buttonYes')}
            </Button>
        </Modal>
    );
};

export default RemoveCustomLawModal;
