import React, { FC, useState, useMemo, ReactNode, createRef } from 'react';
import styled from './EditCustomLawModal.module.css';
import Modal from '../Modal';
import Input from '../../Input/Input';
import useAuth from '../../../hooks/useAuth';
import LawListDropdown from '../../LawListDropdown/LawListDropdown';
import _ from 'lodash';
import Dropdown from '../../Dropdown/Dropdown';
import Button from '../../Button/Button';
import ReactDatePicker from 'react-datepicker';

import el from 'date-fns/locale/sv';

import LawListGroupFilter, { convertToLawListGroupFilter } from '../../../models/LawListGroupFilter';
import KeywordModel from '../../../models/KeywordModel';
import { ReactComponent as CloseIcon } from '../../../assets/images/closeIcon.svg';
import errorIcon from '../../../assets/images/errorIcon.svg';
import Accordion from '../../Accordion/Accordion';
import AccordionInputItem from '../../Accordion/AccordionInputItem/AccordionInputItem';

import 'react-datepicker/dist/react-datepicker.css';
import CustomLawModel, { EditCustomLawModel } from '../../../models/CustomLawModel';

import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

const FILE_SIZE_50_MB = 50 * 1048576;
const TOMORROW_DATE = new Date(new Date().setDate(new Date().getDate() + 1));

interface Props {
    edit?: CustomLawModel; // If undefined, this component will be used to create a new object. If this value is not undefined this component will be used to edit the defined object.
    onClose: () => void;
    onSave: (customLaw: EditCustomLawModel, file?: File) => void;
    onRemove: (customLawId: number) => void;
}

const EditCustomLawModal: FC<Props> = ({ edit, onClose, onSave, onRemove }) => {
    const { t, i18n } = useTranslation();
    const { company } = useAuth();

    const [loading, setLoading] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const fileInputRef = createRef<HTMLInputElement>();
    const [name, setName] = useState<{ value: string; error?: string }>({ value: edit ? edit.name : '', error: undefined });
    const [lawLists, setLawLists] = useState<LawListGroupFilter[]>(() => (company && company.hasLawLists ? convertToLawListGroupFilter(company.lawLists) : []));
    const [keywords, setKeywords] = useState<(KeywordModel & { checked: boolean })[]>(() =>
        company && company.hasKeyWords
            ? company.keyWords.map(kw => ({
                  ...kw,
                  checked: _.find(edit?.keywordIds, id => kw.id === id) ? true : false,
              }))
            : [],
    );
    const [text, setText] = useState(edit ? edit.text : '');
    const [customColumns, setCustomColumns] = useState(() => {
        const columns = [];
        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                columns.push({
                    index: i,
                    title,
                    value: edit ? _.get(edit, `customerText${i}`) : '',
                });
            }
        }
        return columns;
    });
    const [file, setFile] = useState<{ value?: { name: string; data?: File }; error?: string }>({
        value: edit?.lawSources.length ? { name: edit.lawSources[0].fileName, data: undefined } : undefined,
        error: undefined,
    });
    const [endDate, setEndDate] = useState<{ value: Date | null; error?: string }>({
        value: edit?.customLawEndDate ? new Date(edit.customLawEndDate) : null,
        error: edit && edit.customLawEndDate && new Date(edit?.customLawEndDate) < new Date() ? t('inputFieldErrorWarningDateExpired') : undefined,
    });

    const nameValidationRegExp = new RegExp(
        '^[a-zA-ZàáâäæãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÆÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒČŠŽ∂ð0-9:…·•,„¸˛\\.\\\'`¨’´˙‘’°˝˜ˆ¯˘¬º⁄ˇ«»“”\\(\\)\\*;:\\+@#\\/\\&\\|§\\<\\>€£\\$\\!\\?\\{\\}¶\\\\\\"\\=≠≈~≤≥÷±√πΩ%‰∞#¡αβγ‡¥¢Œ∏˚◊∑∆∫©™®ß∂ƒªﬁﬂ†µıœ‹›\\^\\[\\]\\t\\r\\n\\s\\_\\—\\–\\-]*$',
    );
    const inputError = t('inputFieldKeywordValidatonError2');

    const handleNameChange = (value: string): void => {
        let error;
        if (value.length < 1) {
            error = 'inputFieldErrorRequired';
        }
        if (!nameValidationRegExp.test(value)) {
            error = inputError;
        }
        setName({ value, error });
    };

    const handleLawListChange = (lawListId: number): void => {
        setLawLists(s =>
            s.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: lawList.lawListId === lawListId ? !lawList.checked : lawList.checked,
                })),
            })),
        );
    };

    const handleLawListGroupChange = (lawListGroupId: number): void => {
        setLawLists(s =>
            s.map(lawListGroup => {
                if (lawListGroup.lawListGroupId === lawListGroupId) {
                    return {
                        ...lawListGroup,
                        lawLists: lawListGroup.lawLists.map(lawList => ({
                            ...lawList,
                            checked: !lawListGroup.lawLists.every(l => l.checked),
                        })),
                    };
                }
                return lawListGroup;
            }),
        );
    };

    const handleKeywordChange = (keywordId: number) => {
        setKeywords(s => s.map(kw => (kw.id === keywordId ? { ...kw, checked: !kw.checked } : kw)));
    };

    const handleEndDateChange = (endDate: Date | null) => {
        let error;
        if (endDate && endDate < new Date()) {
            error = t('inputFieldErrorWarningDateExpired');
        }
        setEndDate({ value: endDate, error });
    };

    const handleCustomColumnChange = (index: number, value: string) => {
        setCustomColumns(s => s.map(col => (col.index === index ? { ...col, value } : col)));
    };

    const handleFileChange = () => {
        const files = fileInputRef.current?.files;
        if (files && files.length) {
            if (files[0].size > FILE_SIZE_50_MB) {
                setFile({
                    value: undefined,
                    error: t('inputFieldErrorFileTooBig', { fileSize: (files[0].size / 1048576).toFixed(), maxSize: (FILE_SIZE_50_MB / 1048576).toFixed() }),
                });
            } else {
                setFile(s => ({ ...s, value: { name: files[0].name, data: files[0] } }));
            }
        }
    };

    const handleSave = () => {
        setLoading(true);
        setTimeout(() => {
            setLoadingSpinner(true);
        }, 1000);

        let customerTexts = {};
        customColumns.forEach(cc => {
            customerTexts = { ...customerTexts, [`customerText${cc.index}`]: cc.value };
        });

        const customLaw: EditCustomLawModel = {
            customLawId: edit ? edit.customLawId : undefined,
            companyId: company?.id || -1,
            name: name.value,
            lawListIds: lawLists.flatMap(llg => llg.lawLists.filter(ll => ll.checked).map(ll => ll.lawListId)),
            keywordIds: keywords.filter(kw => kw.checked).map(kw => kw.id),
            ...(file.value ? { lawSource: { file: true, fileName: file.value.name, primary: true, externalUrl: undefined } } : []),
            newCustomLawEndDate: endDate.value?.toLocaleDateString('sv') || '',
            text,
            ...(customColumns.length ? customerTexts : []),
            removeFile: file.value === undefined,
        };

        onSave(customLaw, file.value && file.value.data ? file.value.data : undefined);
    };

    const accordionItems: { title: string; value: ReactNode }[] = useMemo(() => {
        const items: { title: string; value: ReactNode }[] = [];

        items.push({
            title: t('columnText'),
            value: <AccordionInputItem value={text} onChange={setText} />,
        });

        customColumns.forEach(col => {
            items.push({
                title: col.title,
                value: <AccordionInputItem value={col.value} onChange={value => handleCustomColumnChange(col.index, value)} />,
            });
        });

        return items;
    }, [text, setText, customColumns, i18n.language]);

    return (
        <Modal title={edit ? t('editCustomLawModalTitle') : t('createCustomLawModalTitle')} className={styled.Modal}>
            <div className={styled.Container}>
                <div className={styled.Content}>
                    <div className={styled.Section}>
                        <Input maxLength={499} value={name.value} onChange={handleNameChange} label={t('columnName')} error={name.error} className={styled.NameInput} autoFocus />
                        {company?.hasLawLists && (
                            <div className={styled.DropdownInput}>
                                <p>{t('columnLawLists')}</p>
                                <LawListDropdown
                                    title={t('buttonSelectLawList')}
                                    lawListGroupFilter={lawLists}
                                    onLawListFilterChange={handleLawListChange}
                                    onLawListGroupFilterChange={handleLawListGroupChange}
                                />
                            </div>
                        )}
                        {company?.hasKeyWords && (
                            <div className={styled.DropdownInput}>
                                <p>{t('columnKeywords')}</p>
                                <Dropdown title={t('buttonSelectKeywords').toString()} content={keywords} onChange={handleKeywordChange} multiSelect />
                            </div>
                        )}
                    </div>
                </div>

                <div className={styled.Content}>
                    <p>{t('inputFieldFileUpload')}</p>
                    <div className={styled.FileUpload}>
                        <input type="file" className={styled.FileInput} ref={fileInputRef} disabled={loading} onChange={handleFileChange} />
                        <Button variant="Primary" onClick={() => fileInputRef.current?.click()}>
                            {t('buttonUploadFile')}
                        </Button>
                        {file.value && (
                            <div className={styled.FileBadge}>
                                <p>{file.value.name}</p>
                                <CloseIcon onClick={() => setFile({ value: undefined, error: undefined })} />
                            </div>
                        )}
                        {file.error && (
                            <div className={styled.FileError}>
                                <img src={errorIcon} alt="Error" />
                                <p>{file.error}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className={styled.Content}>
                    <p>{t('columnWarningDate')}</p>
                    <div className={styled.DatePickerContainer}>
                        <ReactDatePicker
                            selected={endDate.value}
                            onChange={handleEndDateChange}
                            minDate={TOMORROW_DATE}
                            className={styled.DatePicker}
                            calendarClassName={styled.DatePickerCalendar}
                            dateFormat="yyyy-MM-dd"
                            locale={el}
                            selectsEnd
                            disabled={loading}
                            showDisabledMonthNavigation
                        />
                        {endDate.value && <CloseIcon onClick={() => handleEndDateChange(null)} />}
                    </div>
                    {endDate.error && (
                        <div className={styled.DateError}>
                            <img src={errorIcon} alt="Error" />
                            <p>{endDate.error}</p>
                        </div>
                    )}
                </div>

                <div className={styled.AccordionContent}>
                    <Accordion id={0} data={accordionItems} initiallyOpenIndicies={accordionItems ? accordionItems.map((_, i) => i) : []} />
                </div>

                <div className={styled.Buttons}>
                    {edit && (
                        <div className={styled.RemoveButton}>
                            <Button variant="Outline" onClick={() => onRemove(edit?.customLawId)}>
                                {t('removeCustomLawModalTitle')}
                            </Button>
                        </div>
                    )}
                    <Button variant="Outline" onClick={onClose}>
                        {t('buttonCancel')}
                    </Button>
                    <Button
                        variant="Primary"
                        onClick={handleSave}
                        disabled={endDate.error !== undefined || file.error !== undefined || name.error !== undefined || name.value.length < 1 || loading}
                    >
                        {t('buttonSave')}
                    </Button>
                </div>
                {loadingSpinner && <LoadingSpinner className={styled.Wrapper} message={t('spinnerEditCustomLawLoading')} />}
            </div>
        </Modal>
    );
};

export default EditCustomLawModal;
