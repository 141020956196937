import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from '../Home/HomeMobile.module.css';

import ongoingRevision from '../../../assets/images/ongoingRevisionIcon.svg';
import finishedRevisionIcon from '../../../assets/images/finishedRevisionIcon.svg';
import desktopIcon from '../../../assets/images/desktopViewIcon.svg';

import { HOME, REVISION_FINISHED, REVISION_UNFINISHED } from '../../../constants/Routes';

import useMobileDevice from '../../../hooks/useMobileDevice';
import useStorage from '../../../hooks/useStorage';

const HomeMobile = () => {
    const isMobileDevice = useMobileDevice();
    const { setDesktopView } = useStorage();

    const handleShowDesktopView = () => {
        setDesktopView(isMobileDevice);
    };

    const { t } = useTranslation();

    return (
        <>
            <div className={styled.ContentWrapper}>
                <p className={styled.Title}>{t('contextMenuRevisionTitle')}</p>

                <div className={styled.Section}>
                    <Link to={REVISION_UNFINISHED}>
                        <img className={styled.Image} src={ongoingRevision} alt="ongoing revision" />
                    </Link>
                    <p className={styled.SubTitle}>{t('ongoingText')}</p>
                </div>

                <div className={styled.Section}>
                    <Link to={REVISION_FINISHED}>
                        <img className={styled.Image} src={finishedRevisionIcon} alt="finished revision" />
                    </Link>
                    <p className={styled.SubTitle}>{t('finishedText')}</p>
                </div>

                <div className={styled.Section} onClick={handleShowDesktopView}>
                    <Link to={HOME}>
                        <img className={styled.DesktopImage} src={desktopIcon} alt="desktop version" />
                    </Link>
                    <p className={styled.SubTitle}>{t('navbarDesktopVersionOption')}</p>
                </div>
            </div>
        </>
    );
};

export default HomeMobile;
