import React, { FC } from 'react';
import { RevisionObjStatus } from '../../../../models/RevisionObjStatus';
import warningNotification from '../../../../assets/images/warningNotification.svg';
import styled from './RevisionNotification.module.css';

interface Props {
    revisionStatus: RevisionObjStatus;
}

const RevisionNotification: FC<Props> = ({ revisionStatus }) => {
    if (revisionStatus === 'NO_VALUE' || revisionStatus === 'NOT_ACCEPTED' || revisionStatus === 'OBSERVATION') {
        return <img src={warningNotification} alt="!" className={styled.RevisionNotification} />;
    }

    return null;
};

export default RevisionNotification;
