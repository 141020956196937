import { Role } from './Role';
import CompanyModel from './CompanyModel';
import CorporationModel from './CorporationModel';
import UserSettings from './UserSettings';
import EmailNotificationFilter from './EmailNotificationFilter';

interface UserModel {
    userId: number;
    firstName: string;
    lastName: string;
    fullName: string;
    userName: string;

    companyId: number;
    companyName: string;
    language: string;
    userSettings: UserSettings;
    roles: Role[];
    connectedCompanies: CompanyModel[];
    connectedCorporations: CorporationModel[];
    lastLogin: string;
    changePassword: boolean;
    loginCounter: number;
    createdAt: string;
    emailNotification: boolean;
    emailNoticeSettings: EmailNotificationFilter;
}

export interface EditUserModel {
    userId?: number;
    companyId: number;
    firstName: string;
    lastName: string;
    userName: string;
    language: string;
    emailNotification: boolean;
    roles: Role[];
    newPassword: string | undefined;
    newPasswordRepeated: string | undefined;
}

export const isEditUser = (obj: EditUserModel): obj is EditUserModel => {
    return (obj as EditUserModel).userId !== undefined;
};

export default UserModel;
