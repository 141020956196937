import LawModel from './LawModel';
import LawListModel from './LawListModel';
import CustomLawModel from './CustomLawModel';
import _ from 'lodash';
import AcceptanceStatus from './AcceptanceStatus';
import UserModel from './UserModel';
import RevisionSubscriptionModel from './RevisionSubscriptionModel';
import RevisionQuestion from './RevisionQuestion';

interface SubscriptionModel extends LawModel {
    subscriptionId: number;
    subscriptionVersion: number;
    companyId: number;
    sentFromConsultant: boolean;
    lawTemplateText: string;
    originalChangeText: string;
    customLawEndDate: string;
    hasRevisionWarning: boolean;
    latestRevisionDate: string;
    keywordIds: number[];
    lawLists: LawListModel[];
    waitingForAcceptance: UserModel[];
    acceptanceStatus: AcceptanceStatus;
    detachedCopy: boolean;
    publishedDate: string;

    companyNames: string[]; //Used for group customers, and lists which other companies have the subscription
    subscribedByAllInGroup: boolean; //Used for group customers when all companies in group have the subscription
    partlyAccepted: boolean;
    userCanAccept: boolean;
    excludedBaseRevisionQuestionIds: number[];
    customRevisionQuestions: RevisionQuestion[];
}

export const convertToLawModel = (subscription: SubscriptionModel): LawModel => {
    let lawModel: LawModel = { ...subscription } as LawModel;

    lawModel = _.omit(lawModel, 'subscriptionId');
    lawModel = _.omit(lawModel, 'companyId');
    lawModel = _.omit(lawModel, 'sentFromConsultant');
    lawModel = _.omit(lawModel, 'customLawEndDate');
    lawModel = _.omit(lawModel, 'waitingForAcceptance');
    lawModel = _.omit(lawModel, 'acceptanceStatus');

    return lawModel;
};

export const isSubscription = (obj: SubscriptionModel | CustomLawModel | LawModel): obj is SubscriptionModel => {
    return obj && (obj as SubscriptionModel).subscriptionId !== undefined;
};

export const getConsultantText = (field: string, subscription: SubscriptionModel | CustomLawModel | RevisionSubscriptionModel): string | undefined => {
    if (isSubscription(subscription)) {
        if (field === 'text') {
            return subscription.lawTemplateText || '';
        }
        if (field === 'originalChangeText') {
            return subscription.lawChangeText || '';
        }
    }
    return undefined;
};

export const isNewUnaccepted = (subscription: SubscriptionModel) => {
    return subscription.newLaw && subscription.acceptanceStatus != 'ACCEPTED';
};

export default SubscriptionModel;
