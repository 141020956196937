import React, { FC } from 'react';
import Checkbox from '../../Checkbox/Checkbox';
import styled from './CheckboxCell.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    disabled?: boolean;
    locked?: boolean;
    checked: boolean | undefined;
    indeterminate: boolean | undefined;
    onChange: (() => void) | undefined;
}

const CheckboxCell: FC<Props> = ({ disabled, locked, checked, onChange, indeterminate }) => {
    const { t } = useTranslation();
    return (
        <div className={styled.CheckboxCell} title={locked ? t('alterRevisionLockedLawTooltip') : undefined}>
            <Checkbox disabled={disabled} locked={locked} checked={checked} indeterminate={indeterminate} onChange={onChange} big />
        </div>
    );
};

export default CheckboxCell;
