import React, { FC } from 'react';
import styled from './EditOldWarningModal.module.css';
import Modal from '../Modal';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';

interface Props {
    onClose: () => void;
    onEditCurrent: () => void;
    onEditLatest: () => void;
}

const EditOldWarningModal: FC<Props> = ({ onClose, onEditCurrent, onEditLatest }) => {
    const { t } = useTranslation();
    return (
        <Modal title={t('editOldWarningModalTitle')} subtitle={t('editOldWarningModalSubTitle')} className={styled.EditOldWarningModal}>
            <div className={styled.Buttons}>
                <Button variant="Outline" onClick={onClose}>
                    {t('buttonCancel')}
                </Button>
                <Button variant="Outline" onClick={onEditCurrent}>
                    {t('editOldWarningModalButtonCurrent')}
                </Button>
                <Button variant="Primary" onClick={onEditLatest}>
                    {t('editOldWarningModalButtonLatest')}
                </Button>
            </div>
        </Modal>
    );
};

export default EditOldWarningModal;
