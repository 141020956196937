import React, { FC } from 'react';
import styled from './Input.module.css';
import errorIcon from '../../assets/images/errorIcon.svg';
import { useTranslation } from 'react-i18next';

interface Props {
    value: string;
    onChange: (value: string) => void;
    label?: string;
    subLabel?: string;
    placeholder?: string;
    error?: string;
    type?: string;
    className?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
    maxLength?: number;
    spinnerStep?: number;
}

const Input: FC<Props> = ({ value, label, subLabel, placeholder, error, type = 'text', className, onChange, disabled, autoFocus, autoComplete, maxLength, spinnerStep }) => {
    const { t } = useTranslation();

    return (
        <div className={[styled.Container, className ? className : ''].join(' ')}>
            {label && <p className={styled.Label}>{label}</p>}
            <div className={styled.InputContainer}>
                <input
                    type={type}
                    value={value}
                    autoComplete={autoComplete}
                    step={spinnerStep}
                    onChange={e => onChange(e.target.value)}
                    className={[styled.Input, error ? styled.Error : ''].join(' ')}
                    placeholder={placeholder}
                    disabled={disabled}
                    maxLength={maxLength}
                    autoFocus={autoFocus}
                />
                {error && <img src={errorIcon} alt="Error" className={styled.ErrorIcon} />}
            </div>
            <p className={[styled.SubLabel, error ? styled.Error : ''].join(' ')}>{error ? t(error) : subLabel}</p>
        </div>
    );
};

export default Input;
