const TOKEN_KEY = 'TOKEN';
const COMPANY_KEY = 'COMPANY';
const SIGNED_IN_AS_USER_ID = 'SIGNED_IN_AS_USER_ID';
const HIDE_WELCOME_POPUP = 'HIDE_WELCOME_POPUP';
const SSO_KEY = 'SSO';
const DESKTOP_VIEW = 'DESKTOP_VIEW';

const useStorage = () => {
    const getToken = (): string | null => {
        return sessionStorage.getItem(TOKEN_KEY) || null;
    };

    const setToken = (token: string): void => {
        sessionStorage.setItem(TOKEN_KEY, token);
    };

    const setSsoModeToStorage = (sso: boolean): void => {
        const ssoString = sso ? 'true' : 'false';
        sessionStorage.setItem(SSO_KEY, ssoString);
    };

    const getSsoMode = (): boolean => {
        return sessionStorage.getItem(SSO_KEY) === 'true';
    };

    const removeToken = (): void => {
        sessionStorage.removeItem(TOKEN_KEY);
    };

    const setCompanyId = (companyId: number | undefined): void => {
        if (companyId) {
            sessionStorage.setItem(COMPANY_KEY, companyId.toString());
        } else {
            sessionStorage.removeItem(COMPANY_KEY);
        }
    };

    const getCompanyId = (): number => {
        const companyId = sessionStorage.getItem(COMPANY_KEY);
        return companyId ? +companyId : -1;
    };

    const setSignedInAsUserId = (userId: number | undefined): void => {
        if (userId) {
            sessionStorage.setItem(SIGNED_IN_AS_USER_ID, userId.toString());
        } else {
            sessionStorage.removeItem(SIGNED_IN_AS_USER_ID);
        }
    };

    const getSignedInAsUserId = (): number => {
        const userId = sessionStorage.getItem(SIGNED_IN_AS_USER_ID);
        return userId ? +userId : -1;
    };

    const isSignedInUserIdStored = (): boolean => {
        return getSignedInAsUserId() > -1;
    };

    const isCompanyStored = (): boolean => {
        return getCompanyId() > -1;
    };

    const setHidePopup = (): void => {
        sessionStorage.setItem(HIDE_WELCOME_POPUP, 'true');
    };

    const isHidePopup = (): boolean => {
        return sessionStorage.getItem(HIDE_WELCOME_POPUP) != null;
    };

    const clearStorage = (): void => {
        sessionStorage.clear();
    };

    const setDesktopView = (forceView: boolean): void => {
        const viewString = forceView ? 'true' : 'false';
        sessionStorage.setItem(DESKTOP_VIEW, viewString);
    };

    const isDesktopView = (): boolean | null => {
        return sessionStorage.getItem(DESKTOP_VIEW) === 'true';
    };

    return {
        getToken,
        setToken,
        removeToken,
        setCompanyId,
        isCompanyStored,
        getCompanyId,
        clearStorage,
        setSignedInAsUserId,
        isSignedInUserIdStored,
        getSignedInAsUserId,
        setHidePopup,
        isHidePopup,
        getSsoMode,
        setSsoModeToStorage,
        setDesktopView,
        isDesktopView,
    };
};

export default useStorage;
