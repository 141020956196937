import React, {FC} from 'react';
import styled from './HardLock.module.css';
import useAuth from "../../hooks/useAuth";
import warningIcon from "../../assets/images/statusNotOkIcon.svg";
import Button from "../../components/Button/Button";

const HardLock: FC = () => {
    const {company, logout} = useAuth();

    return (
        <div className={styled.HardLockBackground}>

            <div className={styled.HardLock}>
                <div className={styled.Header}>
                    <p>Beklagar <span><img className={styled.Icon} src={warningIcon}/></span></p>
                </div>
                <div className={styled.Message}>
                    <p>Tjänsten är tyvärr låst för <strong>{company?.name}</strong> tills ert kontrakt är
                        förnyat/signerat.
                        Hör med er superanvändare för mer information.</p>
                </div>
                <div className={styled.Button}>
                <Button variant="Outline" onClick={logout}>Ok</Button>
                </div>

            </div>
        </div>

    );
};

export default HardLock;
