import { AxiosResponse, AxiosError } from 'axios';
import { getAuthApi, getApi } from '../config/axios.config';
import UserModel, { EditUserModel } from '../models/UserModel';
import UserSettings from '../models/UserSettings';
import ApiErrorModel from '../models/ApiErrorModel';
import EmailNotificationFilter from '../models/EmailNotificationFilter';
import useStorage from '../hooks/useStorage';

const UserService = () => {
    const axiosInstance = getAuthApi();
    const { getCompanyId } = useStorage();

    function getCurrentUser(): Promise<UserModel> {
        return axiosInstance
            .get<UserModel>('/currentuser')
            .then((response: AxiosResponse<UserModel>) => {
                const { data } = response;
                return {
                    ...data,
                    connectedCompanies: data.connectedCompanies ? data.connectedCompanies.sort((a, b) => (a.name > b.name ? 1 : -1)) : data.connectedCompanies,
                };
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getUser(userId: number): Promise<UserModel> {
        return axiosInstance
            .get<UserModel>(`/user?userId=${userId}`)
            .then((response: AxiosResponse<UserModel>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getUserByUserName(username: string): Promise<UserModel> {
        return axiosInstance
            .get<UserModel>(`/user?userName=${username}`)
            .then((response: AxiosResponse<UserModel>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function authenticateUser(username: string, password: string): Promise<AxiosResponse> {
        return getApi()
            .post(`/authenticate`, { username: username, password: password })
            .then((response: AxiosResponse) => {
                return response;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    function saveUserSettings(userId: number, userSettings: UserSettings): Promise<UserSettings> {
        return axiosInstance
            .post<UserSettings>(`/usersettings/update?userId=${userId}`, userSettings)
            .then((response: AxiosResponse<UserSettings>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getUsersWithAssessmentRights(companyId: number): Promise<UserModel[]> {
        return axiosInstance
            .get<UserModel[]>(`/assessmentusers?companyId=${companyId}`)
            .then((response: AxiosResponse<UserModel[]>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getCompanyUsers(companyId: number): Promise<UserModel[]> {
        return axiosInstance
            .get<UserModel[]>(`/companyusers?companyId=${companyId}`)
            .then((response: AxiosResponse<UserModel[]>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getCompanyUsersWithEmailNoticeSettings(companyId: number): Promise<UserModel[]> {
        return axiosInstance
            .get<UserModel[]>(`/companyusers/emailnoticesettings?companyId=${companyId}`)
            .then((response: AxiosResponse<UserModel[]>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getCompanySuperUsers(companyId: number): Promise<UserModel[]> {
        return axiosInstance
            .get<UserModel[]>(`/companysuperusers?companyId=${companyId}`)
            .then((response: AxiosResponse<UserModel[]>) => {
                const { data } = response;

                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getCompanyUsersCount(companyId: number): Promise<number> {
        return getCompanyUsers(companyId).then(res => {
            return Promise.resolve(res.length);
        });
    }

    function getCompanyExternalUsers(companyId: number): Promise<UserModel[]> {
        return axiosInstance
            .get<UserModel[]>(`/external?companyId=${companyId}`)
            .then((response: AxiosResponse<UserModel[]>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getUsersWithRevisionRights(companyId: number): Promise<UserModel[]> {
        return axiosInstance
            .get<UserModel[]>(`/revisionusers?companyId=${companyId}`)
            .then((response: AxiosResponse<UserModel[]>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function createUser(createUser: EditUserModel): Promise<UserModel> {
        return axiosInstance
            .post<UserModel>('/user', createUser)
            .then((response: AxiosResponse<UserModel>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateUser(editUser: EditUserModel): Promise<UserModel> {
        return axiosInstance
            .put<UserModel>('/user', editUser)
            .then((response: AxiosResponse<UserModel>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function removeUser(userId: number): Promise<AxiosResponse<void>> {
        return axiosInstance.delete<void>(`/user?userId=${userId}`).catch((error: AxiosError<ApiErrorModel>) => {
            throw error;
        });
    }

    function updateCurrentUser(user: EditUserModel): Promise<UserModel> {
        return axiosInstance
            .put<UserModel>('/currentuser', user)
            .then((response: AxiosResponse<UserModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function removeExternalUser(companyId: number, userId: number): Promise<AxiosResponse<void>> {
        return axiosInstance.delete<void>(`/external?companyId=${companyId}&userId=${userId}`).catch((error: AxiosError<ApiErrorModel>) => {
            throw error;
        });
    }

    function setEmailNoticeActive(userId: number, active: boolean): Promise<AxiosResponse<void>> {
        return axiosInstance.put<void>(`/emailnoticesettings?userId=${userId}&active=${active}`).catch((error: AxiosError<ApiErrorModel>) => {
            throw error;
        });
    }

    function saveEmailNoticeSettings(userId: number, notificationFilterType: string, selectedIds: number[]): Promise<EmailNotificationFilter> {
        console.log(userId, notificationFilterType, selectedIds);
        return axiosInstance
            .post<EmailNotificationFilter>(`/emailnoticesettings?userId=${userId}&notificationFilterType=${notificationFilterType}`, selectedIds)
            .then((response: AxiosResponse<EmailNotificationFilter>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getQR(): Promise<any> {
        return axiosInstance
            .get<string>(`/qr?targetCompanyId=${getCompanyId()}`)
            .then((response: AxiosResponse<string>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getCurrentUser,
        getUser,
        getUserByUserName,
        authenticateUser,
        saveUserSettings,
        getUsersWithAssessmentRights,
        getCompanyUsers,
        getCompanyUsersCount,
        getCompanyExternalUsers,
        getCompanySuperUsers,
        getUsersWithRevisionRights,
        createUser,
        updateUser,
        removeUser,
        updateCurrentUser,
        removeExternalUser,
        setEmailNoticeActive,
        saveEmailNoticeSettings,
        getCompanyUsersWithEmailNoticeSettings,
        getQR,
    };
};

export default UserService;
