import React, { FC, useState } from 'react';
import styled from './EditLawListGroupModal.module.css';
import Modal from '../Modal';
import Input from '../../Input/Input';
import TextEditor from '../../TextEditor/TextEditor';
import Button from '../../Button/Button';
import { EditLawListGroupModel } from '../../../models/LawListGroupModel';
import { Data } from '../../../containers/Settings/Company/LawLists/LawLists';
import { lawListGroupNameMaxLength, lawListGroupResponsibleMaxLength, lawListDescriptionMaxLength } from '../../../constants/Validation';
import { useTranslation } from 'react-i18next';

interface Props {
    edit?: Data; // If undefined, this component will be used to create a new object. If this value is not undefined this component will be used to edit the defined object.
    onSave: (lawListGroup: EditLawListGroupModel) => void;
    onCancel: () => void;
}

const EditLawListGroupModal: FC<Props> = ({ edit, onSave, onCancel }) => {
    const { t } = useTranslation();
    const [name, setName] = useState<{ value: string; error?: string }>({ value: edit && edit.name ? edit.name : '', error: undefined });
    const [responsible, setResponsible] = useState<{ value: string; error?: string }>({ value: edit && edit.responsible ? edit.responsible : '', error: undefined });
    const [description, setDescription] = useState<{ value: string; error?: boolean }>({ value: edit && edit.description ? edit.description : '', error: false });

    const handleNameChange = (value: string) => {
        let error;
        if (value.length < 1) {
            error = t('inputFieldErrorRequired');
        } else if (value.length > lawListGroupNameMaxLength) {
            error = t('inputFieldErrorTooLong', { max: lawListGroupNameMaxLength });
            value = name.value;
        }
        setName({ value, error });
    };

    const handleResponsibleChange = (value: string) => {
        let error;
        if (value.length > lawListGroupResponsibleMaxLength) {
            error = t('inputFieldErrorTooLong', { max: lawListGroupResponsibleMaxLength });
            value = responsible.value;
        }
        setResponsible({ value, error });
    };

    const handleSave = () => {
        const lawListGroup: EditLawListGroupModel = {
            lawListGroupId: edit ? edit.lawListGroupId : undefined,
            name: name.value,
            description: description.value,
            responsible: responsible.value,
        };
        onSave(lawListGroup);
    };

    return (
        <Modal title={edit ? t('editLawListGroupModalTitle') : t('createLawListGroupModalTitle')} className={styled.EditLawListGroupModal}>
            <div className={styled.Container}>
                <Input
                    value={name.value}
                    onChange={handleNameChange}
                    error={name.error}
                    label={t('inputFieldGroupName')}
                    className={styled.Input}
                    placeholder={t('inputFieldName')}
                    autoFocus
                />

                <Input
                    value={responsible.value}
                    onChange={handleResponsibleChange}
                    label={t('inputFieldResponsible')}
                    className={styled.Input}
                    placeholder={t('inputFieldResponsible')}
                    error={responsible.error}
                />

                <div className={styled.TextInput}>
                    <p>{t('inputFieldDescription')}</p>
                    <TextEditor
                        initialValue={description.value}
                        onChange={value => setDescription(s => ({ ...s, value }))}
                        noOptions
                        maxLength={lawListDescriptionMaxLength}
                        onMaxLengthError={error => setDescription(s => ({ ...s, error }))}
                    />
                </div>

                <div className={styled.Buttons}>
                    <Button variant="Outline" onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                    <Button
                        variant="Primary"
                        onClick={handleSave}
                        disabled={name.error !== undefined || name.value.length < 1 || description.error || responsible.error !== undefined}
                    >
                        {edit ? t('buttonSave') : t('buttonCreateGroup')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EditLawListGroupModal;
