import React, { useEffect, useMemo, useState } from 'react';
import useUnfinishedRevision from '../../../hooks/useUnfinishedRevision';
import { useHistory, useParams } from 'react-router-dom';
import { REVISION_UNFINISHED, REVISION_CREATE, REVISION_ALTER } from '../../../constants/Routes';
import styled from './CreateRevision.module.css';
import lawlistIcon from '../../../assets/images/lawlistIcon.svg';
import ContextMenu from '../../../components/ContextMenu/ContextMenu';
import Filterbar from '../../../components/Filterbar/Filterbar';
import Table from '../../../components/Table/Table';
import { isNewUnaccepted, isSubscription } from '../../../models/SubscriptionModel';
import _ from 'lodash';
import CreateRevisionToaster from '../../../components/CreateRevisionToaster/CreateRevisionToaster';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import CreateRevisionFullView from '../../../components/FullView/CreateRevisionFullView/CreateRevisionFullView';
import CreateRevisionConfirmModal from '../../../components/Modal/CreateRevisionConfirmModal/CreateRevisionConfirmModal';
import RevisionTopbar from '../../../components/RevisionTopbar/RevisionTopbar';
import { useTranslation } from 'react-i18next';
import RevisionService from '../../../services/RevisionService';
import FullRevisionModel from '../../../models/FullRevisionModel';
import AlterRevisionConfirmModal from '../../../components/Modal/AlterRevisionConfirmModal/AlterRevisionConfirmModal';
import EmptyListView from '../../../components/EmptyListView/EmptyListView';

const CreateRevision = () => {
    const { t } = useTranslation();
    const { revisionId } = useParams();

    const CONTEXT_LINKS = [
        {
            title: t('contextMenuLawLists'),
            url: revisionId ? REVISION_ALTER + `/${revisionId}` : REVISION_CREATE,
            icon: lawlistIcon,
            active: true,
        },
    ];

    const history = useHistory();
    const {
        loading,
        selectedIndex,
        subscriptions,
        filteredSubscriptions,
        createRevision,
        globalSearch,
        columnSearch,
        lawListGroupFilter,
        customLawsSelected,
        selectedKeywords,
        showColumnSearch,
        lawTypeFilter,
        columns,
        hiddenColumns,
        visibleSubscriptionCount,
        sorting,
        hasCustomLaws,
        customFilterActive,
        fetchSubscriptions,
        setCreateRevisionInfo,
        handleGlobalSearchChange,
        handleLawListFilterChange,
        handleLawListGroupFilterChange,
        handleSelectedKeywordChange,
        handleColumnSearchToggle,
        handleLawTypeOpen,
        handleLawTypeChecked,
        handleLawGroupChecked,
        handleSelection,
        handleColumnVisibleChange,
        handleCreateRevision,
        handleAlterRevision,
        handleVisibleSubscriptionCount,
        handleResetAllFilters,
        handleResetLawTypeFilter,
        handleSortingChange,
        handleCustomFilterToggle,
        handleAllLawListChange,
        handleSelectAllKeywords,
        handleAllLawTypesChecked,
    } = useUnfinishedRevision();

    const [lockedRows, setLockedRows] = useState<number[]>([]);
    const [triggerSelectedCount, setTriggerSelectedCount] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [alterRevisionId, setAlterRevisionId] = useState<number>();
    const [alterRevisionMode, setAlterRevisionMode] = useState<boolean>(false);
    const [fullRevisionModel, setFullRevisionModel] = useState<FullRevisionModel>();

    const selectedCount = useMemo(() => {
        const number = _.filter(selectedRows, obj => obj).length;
        const sum = number - lockedRows.length;
        return sum;
    }, [triggerSelectedCount]);

    const handleRowChange = (id: string) => {
        setSelectedRows(s => ({
            ...s,
            [id]: !s[id],
        }));
        setTriggerSelectedCount(!triggerSelectedCount);
    };

    const handleRowsChange = (filteredRowIds: string[]) => {
        setSelectedRows(sr => {
            const anyChecked = _.some(filteredRowIds, id => sr[id] === true && !lockedRows.includes(+id));
            return _.mapValues(sr, (value, id) => {
                if (_.includes(filteredRowIds, id) && !lockedRows.includes(+id)) {
                    const obj = subscriptions.find(d => isSubscription(d) && d.subscriptionId === +id);
                    if (obj !== undefined && isSubscription(obj) && isNewUnaccepted(obj)) {
                        return value;
                    }
                    return !anyChecked;
                }
                return value;
            });
        });
        setTriggerSelectedCount(!triggerSelectedCount);
    };

    const handleConfirmAlterRevision = () => {
        const selectedSubscriptionIds = _.filter(subscriptions, obj => {
            return isSubscription(obj) && selectedRows[obj.subscriptionId] === true && !lockedRows.includes(obj.subscriptionId);
        }).map(obj => _.get(obj, 'subscriptionId'));
        const selectedCustomLawIds = _.filter(subscriptions, obj => {
            return !isSubscription(obj) && selectedRows[obj.customLawId] === true && !lockedRows.includes(obj.customLawId);
        }).map(obj => _.get(obj, 'customLawId'));

        if (alterRevisionId) {
            handleAlterRevision(selectedSubscriptionIds, selectedCustomLawIds, alterRevisionId);
        }
    };
    const handleConfirmCreateRevision = () => {
        const selectedSubscriptionIds = _.filter(subscriptions, obj => isSubscription(obj) && selectedRows[obj.subscriptionId] === true).map(obj => _.get(obj, 'subscriptionId'));
        const selectedCustomLawIds = _.filter(subscriptions, obj => !isSubscription(obj) && selectedRows[obj.customLawId] === true).map(obj => _.get(obj, 'customLawId'));

        handleCreateRevision(selectedSubscriptionIds, selectedCustomLawIds);
    };

    const fetchData = (revisionId: number) => {
        fetchSubscriptions().then(all => {
            try {
                RevisionService()
                    .getRevision(revisionId)
                    .then(revision => {
                        setFullRevisionModel(revision);
                        const lockedRowsHolder: number[] = [];
                        revision.revisionSubscriptionsListModel.subscriptionRevisions.map(sub => {
                            const found = all?.find(s => isSubscription(s) && s.subscriptionId === sub.subscriptionId);
                            if (found) lockedRowsHolder.push(sub.subscriptionId);
                        });
                        revision.revisionSubscriptionsListModel.customLawRevisions.map(sub => {
                            lockedRowsHolder.push(sub.customLawId);
                        });
                        setLockedRows(lockedRowsHolder);
                    });
            } catch (error) {
                console.error(error);
            }
        });
    };

    useEffect(() => {
        if (revisionId && !alterRevisionMode) {
            setAlterRevisionMode(true);
            setAlterRevisionId(+revisionId);
            fetchData(+revisionId);
        }

        if (createRevision) {
            fetchSubscriptions();
        }

        if (!createRevision && !revisionId) {
            history.push(REVISION_UNFINISHED);
        }

        return () => {
            setCreateRevisionInfo(undefined);
        };
    }, [createRevision, revisionId]);

    useEffect(() => {
        const selection: Record<string, boolean> = {};
        subscriptions.forEach(item => {
            if (isSubscription(item)) {
                selection[item.subscriptionId] = lockedRows.includes(item.subscriptionId) || false;
            } else {
                selection[item.customLawId] = lockedRows.includes(item.customLawId) || false;
            }
        });

        setSelectedRows(selection);
    }, [subscriptions, lockedRows]);

    return (
        <div className={styled.CreateRevision}>
            {loading && <LoadingSpinner />}
            {!alterRevisionMode && <RevisionTopbar backUrl={REVISION_UNFINISHED} title={createRevision?.name || ''} />}
            {alterRevisionMode && <RevisionTopbar backUrl={REVISION_UNFINISHED} title={fullRevisionModel?.name || ''} />}
            <div className={styled.Content}>
                <ContextMenu
                    links={CONTEXT_LINKS}
                    lawTypeFilter={lawTypeFilter}
                    customLawsSelected={customLawsSelected}
                    visibleDataCount={visibleSubscriptionCount}
                    totalDataCount={subscriptions.length}
                    hasCustomLaws={hasCustomLaws}
                    onToggleLawTypeOpen={handleLawTypeOpen}
                    onToggleLawGroupChecked={handleLawGroupChecked}
                    onToggleLawTypeChecked={handleLawTypeChecked}
                    onResetAllFilters={handleResetAllFilters}
                    onResetLawTypeFilter={handleResetLawTypeFilter}
                    onToggleAllLawTypesChecked={handleAllLawTypesChecked}
                />
                <div className={styled.List}>
                    <Filterbar
                        globalSearch={globalSearch}
                        lawListGroupFilter={lawListGroupFilter}
                        selectedKeywords={selectedKeywords}
                        showColumnSearch={showColumnSearch}
                        customFilterActive={customFilterActive}
                        columns={columns.filter(col => !col.alwaysVisible)}
                        onLawListFilterChange={handleLawListFilterChange}
                        onLawListGroupFilterChange={handleLawListGroupFilterChange}
                        onGlobalSearchChange={handleGlobalSearchChange}
                        onSelectedKeywordChange={handleSelectedKeywordChange}
                        onColumnSearchToggle={handleColumnSearchToggle}
                        onColumnShowChange={handleColumnVisibleChange}
                        onCustomFilterToggle={handleCustomFilterToggle}
                        onAllLawListChange={handleAllLawListChange}
                        onSelectAllKeywords={handleSelectAllKeywords}
                    />
                    <div className={styled.TableWrapper}>
                        {!loading && visibleSubscriptionCount < 1 ? <EmptyListView emptyFilter /> : null}
                        <Table
                            data={filteredSubscriptions}
                            selected={selectedIndex}
                            columns={columns}
                            showColumnSearch={showColumnSearch}
                            columnSearch={columnSearch}
                            globalSearch={globalSearch}
                            selectedRows={selectedRows}
                            lockedRows={lockedRows}
                            hiddenColumns={hiddenColumns}
                            sorting={sorting}
                            onSelect={handleSelection}
                            onSelectedRowsChange={handleRowsChange}
                            onSelectedRowChange={handleRowChange}
                            onVisibleDataCountChange={handleVisibleSubscriptionCount}
                            onSortingChange={handleSortingChange}
                            hideDisabledCheckboxes
                            multiSelect
                        />

                        <CreateRevisionFullView open={selectedIndex > -1} data={filteredSubscriptions[selectedIndex]} onClose={() => handleSelection(-1)} />

                        <CreateRevisionToaster
                            selectedCount={selectedCount}
                            fullViewOpen={selectedIndex > -1}
                            alterRevisionMode={alterRevisionMode}
                            onCreate={() => setShowConfirmModal(true)}
                            onCancel={() => history.goBack()}
                        />
                    </div>

                    {showConfirmModal && createRevision && (
                        <CreateRevisionConfirmModal
                            title={createRevision.name}
                            selectedCount={selectedCount}
                            username={createRevision.username}
                            onCancel={() => setShowConfirmModal(false)}
                            onConfirm={handleConfirmCreateRevision}
                        />
                    )}

                    {showConfirmModal && alterRevisionMode && fullRevisionModel && (
                        <AlterRevisionConfirmModal
                            title={fullRevisionModel.name}
                            selectedCount={selectedCount}
                            username={fullRevisionModel.responsibleName}
                            onCancel={() => setShowConfirmModal(false)}
                            onConfirm={handleConfirmAlterRevision}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CreateRevision;
