import { useContext } from 'react';
import { LawLibraryContext } from '../contexts/LawLibraryContext';
import useAuth from './useAuth';
import { LawType, getLawMonitorDefaultSorting } from '../models/LawType';
import { SortingRule } from 'react-table';
import LawLibraryService from '../services/LawLibraryService';
import _ from 'lodash';
import SubscriptionModel, { isSubscription, convertToLawModel } from '../models/SubscriptionModel';
import useExport from './useExport';
import LawTypeFilter, { convertToLawTypeFilter } from '../models/LawTypeFilter';
import { useTranslation } from 'react-i18next';
import LawModel from '../models/LawModel';

const useLawLibrary = () => {
    const { state, setState } = useContext(LawLibraryContext);
    const { t } = useTranslation();
    const { user, company } = useAuth();
    const { exportList } = useExport();

    const setLoading = (loading: boolean): void => {
        setState(s => ({
            ...s,
            loading,
        }));
    };

    const fetchData = async () => {
        try {
            if (company && user) {
                setLoading(true);

                const { lawTypes, monitoredLaws, unmonitoredLaws } = await LawLibraryService().getLawLibraryList(company.id);

                const lawTypeFilter: LawTypeFilter[] = convertToLawTypeFilter(lawTypes);
                const data = [...monitoredLaws, ...unmonitoredLaws];

                setState(s => ({
                    ...s,
                    data: getLawMonitorDefaultSorting(data) as (SubscriptionModel | LawModel)[],
                    lawTypeFilter,
                    loading: false,
                }));
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleLawTypeOpen = (type: LawType): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                open: lawType.name === type ? !lawType.open : lawType.open,
            })),
        }));
    };

    const handleLawTypeChecked = (type: LawType): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => {
                if (lawType.name === type) {
                    return {
                        ...lawType,
                        lawGroups: lawType.lawGroups.map(lawGroup => ({
                            ...lawGroup,
                            checked: !lawType.lawGroups.every(l => l.checked),
                        })),
                    };
                }
                return lawType;
            }),
        }));
    };

    const handleLawGroupChecked = (lawGroupId: number): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: lawGroup.lawGroupId === lawGroupId ? !lawGroup.checked : lawGroup.checked,
                })),
            })),
        }));
    };

    const handleAllLawTypesChecked = (allChecked: boolean): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: !allChecked,
                })),
            })),
        }));
    };

    const handleSelection = (selectedIndex: number): void => {
        setState(s => ({
            ...s,
            selectedIndex,
        }));
    };

    const handleSortingChange = (sorting: SortingRule<object>[]): void => {
        setState(s => ({
            ...s,
            sorting,
        }));
    };

    const handleShowSubscriptionToggle = (): void => {
        setState(s => ({
            ...s,
            showSubscriptions: !s.showSubscriptions,
        }));
    };

    const handleGlobalSearchChange = (globalSearch: string): void => {
        setState(s => ({
            ...s,
            globalSearch,
        }));
    };

    const handleColumnSearchToggle = () => {
        setState(s => ({
            ...s,
            columnSearch: s.showColumnSearch ? s.columnSearch.map(c => ({ ...c, value: undefined })) : s.columnSearch,
            showColumnSearch: !s.showColumnSearch,
        }));
    };

    const handleResetLawTypeFilter = (): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: true,
                })),
            })),
            customLawsSelected: true,
        }));
    };

    const handleResetAllFilters = () => {
        setState(s => ({
            ...s,
            globalSearch: '',
            columnSearch: s.columnSearch.map(c => ({ ...c, value: undefined })),
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: false,
                })),
            })),
            showSubscriptions: false,
        }));
        handleResetLawTypeFilter();
    };

    const handleVisibleDataCountChange = (visibleDataCount: number): void => {
        setState(s => ({
            ...s,
            visibleDataCount,
        }));
    };

    const addAndRemoveSubscriptions = (subscriptionIdsToRemove: number[], lawIdsToAdd: number[]): void => {
        if (company) {
            setLoading(true);
            LawLibraryService()
                .addAndRemoveSubscriptions({ companyId: company.id, subscriptionIdsToRemove, lawIdsToAdd })
                .then((subscriptionsAdded: SubscriptionModel[]) => {
                    setState(s => ({
                        ...s,
                        data: s.data.map(obj => {
                            if (_.includes(lawIdsToAdd, _.get(obj, 'lawId'))) {
                                const subscription = subscriptionsAdded.find(sub => sub.lawId === obj.lawId);
                                if (subscription) return subscription;
                            }
                            if (_.includes(subscriptionIdsToRemove, _.get(obj, 'subscriptionId'))) {
                                return convertToLawModel(obj as SubscriptionModel);
                            }
                            return obj;
                        }),
                        loading: false,
                    }));
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    return {
        ...state,
        totalDataCount: state.data.length,
        fetchData,
        handleSelection,
        handleSortingChange,
        handleShowSubscriptionToggle,
        handleGlobalSearchChange,
        handleLawTypeOpen,
        handleLawTypeChecked,
        handleLawGroupChecked,
        handleColumnSearchToggle,
        handleResetLawTypeFilter,
        handleResetAllFilters,
        handleVisibleDataCountChange,
        addAndRemoveSubscriptions,
        handleAllLawTypesChecked,
    };
};

export default useLawLibrary;
