import React, { FC, useState } from 'react';

import styled from '../EditFieldModal/EditFieldModal.module.css';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import { toNumber } from 'lodash';

interface Props {
    title: string;
    value: number;
    spinnerStep?: number;
    onCancel: () => void;
    onConfirm: (value: number) => void;
    priceRestriction?: number;
}

const EditFieldNumberModal: FC<Props> = ({ title, value = 0, priceRestriction, spinnerStep, onCancel, onConfirm }) => {
    const { t } = useTranslation();
    const [fieldValue, setFieldValue] = useState(value);
    const [forbiddenAmount, setForbiddenAmount] = useState(false);

    const handleOnChangeValue = (value: number): void => {
        if (priceRestriction) {
            if (value <= priceRestriction) {
                setFieldValue(value);
                setForbiddenAmount(false);
            } else {
                setForbiddenAmount(true);
            }
        } else {
            setFieldValue(value);
        }
    };

    function handleOnClickConfirm() {
        onConfirm(fieldValue);
    }
    return (
        <Modal title={title} className={styled.Modal}>
            <div className={styled.Content}>
                <Input
                    className={styled.InputField}
                    value={fieldValue.toString()}
                    type="number"
                    onChange={value => handleOnChangeValue(toNumber(value))}
                    label={''}
                    spinnerStep={spinnerStep}
                    autoFocus
                />
                <div className={styled.Options}>
                    <Button variant="Outline" onClick={onCancel}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Primary" disabled={forbiddenAmount} onClick={handleOnClickConfirm}>
                        {t('buttonSave')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EditFieldNumberModal;
