import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LawSourceModel from '../../models/LawSourceModel';
import DocumentService from '../../services/DocumentService';
import styled from './ChangeTextLink.module.css';
import linkIcon from '../../assets/images/linkIcon.svg';

interface Props {
    lawId: number | undefined;
    lawSources: LawSourceModel[];
}

const ChangeTextLink: FC<Props> = ({ lawId, lawSources }) => {
    const { t } = useTranslation();

    const lawSource = useMemo(() => {
        for (const ls of lawSources) {
            if (!ls.primary && ls.fileName) {
                return ls;
            }
        }
        return undefined;
    }, [lawSources]);

    const handleDownload = () => {
        if (lawId && lawSource && lawSource.fileName) {
            DocumentService().getLawDocument(lawId, false, false, lawSource.fileName);
        }
    };

    if (!lawSource) {
        return null;
    }

    return (
        <div onClick={handleDownload} className={styled.ChangeTextLink}>
            {t('lawDocumentLink')} <img src={linkIcon} className={styled.LinkIcon} alt="Link" />
        </div>
    );
};

export default ChangeTextLink;
