import React, { FC } from 'react';
import styled from './AccordionRevisionQuestionItem.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    data: {
        title?: string;
        revisionQuestions: string[];
    }[];
    withCounter?: boolean;
}

const AccordionRevisionQuestionItem: FC<Props> = ({ data, withCounter }) => {
    const { t } = useTranslation();
    let counter = 1;
    return (
        <div className={styled.AccordionRevisionQuestionItem}>
            {data.length
                ? data.map((group, groupIndex) => {
                      return (
                          <React.Fragment key={groupIndex}>
                              {group.title && <div className={styled.Title}>{group.title}</div>}
                              {group.revisionQuestions.map((question, questionIndex) => (
                                  <div key={questionIndex} className={styled.Question}>
                                      {withCounter && `${counter++}. `}
                                      {question}
                                  </div>
                              ))}
                          </React.Fragment>
                      );
                  })
                : t('revisionQuestionsMissing')}
        </div>
    );
};

export default AccordionRevisionQuestionItem;
