import { AxiosResponse, AxiosError } from 'axios';
import { getAuthApi } from '../config/axios.config';
import { isIE, isMobileSafari, isSafari } from 'react-device-detect';

const DocumentService = () => {
    const axiosInstance = getAuthApi();

    const isFileTypePDF = (fileName: string): boolean => {
        const split: string[] = fileName.toLowerCase().split('.');
        const fileEnding: string = split[split.length - 1];
        return fileEnding !== null && fileEnding === 'pdf';
    };

    const isFileTypeWord = (fileName: string): boolean => {
        const split: string[] = fileName.toLowerCase().split('.');
        const fileEnding: string = split[split.length - 1];
        return fileEnding !== null && (fileEnding === 'doc' || fileEnding === 'docx');
    };

    const isValidCompanyDocument = (fileName: string): boolean => {
        const split: string[] = fileName.toLowerCase().split('.');
        const fileEnding: string = split[split.length - 1];
        const validFormats = fileEnding === 'pdf' || fileEnding === 'doc' || fileEnding === 'docx';
        return fileEnding !== null && validFormats;
    };

    const createAndSavePDF = (response: AxiosResponse, fileName: string): void => {
        if (isFileTypePDF(fileName)) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            if (isIE || (isSafari && !isMobileSafari)) {
                saveAs(blob, fileName);
            } else {
                const url = window.URL.createObjectURL(blob);
                const newWindow = window.open('_blank');
                //FIXME window dosen't show filename on customlaws and company documents
                if (newWindow) {
                    newWindow.location.href = url;
                } else {
                    window.alert('Du behöver tillåta Popups i din browser för att öppna en PDF.');
                }
            }
        } else {
            const blob = new Blob([response.data], {
                type: 'text/plain;charset=utf-8',
            });
            saveAs(blob, fileName);
        }
    };

    function getLawDocument(lawId: number, primary: boolean, isCustomLaw: boolean, fileName: string): Promise<void> {
        return axiosInstance
            .get(`/document/lawfile?lawId=${lawId}&primary=${primary}&isCustomLaw=${isCustomLaw}`, { responseType: 'blob' })
            .then((response: AxiosResponse) => {
                createAndSavePDF(response, fileName);
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    return {
        getLawDocument,
        createAndSavePDF,
        isFileTypePDF,
        isValidCompanyDocument,
    };
};

export default DocumentService;
