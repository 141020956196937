import { useContext, useMemo } from 'react';
import { ChangeListContext } from '../contexts/ChangeListContext';
import { LawType, LawTypeEnum } from '../models/LawType';
import useAuth from './useAuth';
import UserSettings, { getUserSettingsSelectedLawGroupsFallback } from '../models/UserSettings';
import UserService from '../services/UserService';
import { Column, SortingRule } from 'react-table';
import SubscriptionService from '../services/SubscriptionService';
import SubscriptionModel, { isSubscription } from '../models/SubscriptionModel';
import SubscriptionChangeService, { AssessmentChoice } from '../services/SubscriptionChangeService';
import LawTypeFilter, { convertToLawTypeFilter, getActiveLawTypeFilters } from '../models/LawTypeFilter';
import _ from 'lodash';
import LawListGroupFilter, { convertToLawListGroupFilter } from '../models/LawListGroupFilter';
import RevisionQuestion from '../models/RevisionQuestion';
import RevisionQuestionService from '../services/RevisionQuestionService';
import { useTranslation } from 'react-i18next';
import useExport from './useExport';
import ExcelColumnWidth, { columnKeys } from '../constants/ExcelColumnWidth';
import { getActiveFilterExportFromState } from '../models/ActiveFilterExport';
import filterExport from '../utils/filterExport';
import toggle from '../components/Toggle/Toggle';

const useChangeList = () => {
    const { state, setState } = useContext(ChangeListContext);
    const { company, user, signedInAsUser, getUserSettings, setUserSettings } = useAuth();
    const { t } = useTranslation();
    const { exportList } = useExport();
    const { includeSubscriptionInExport } = filterExport();

    const setLoading = (loading: boolean): void => {
        setState(s => ({
            ...s,
            loading,
        }));
    };
    const handleColumnSearchChange = (
        columnSearch: {
            id: string;
            value: string | undefined;
        }[],
    ): void => {
        setState(s => ({
            ...s,
            columnSearch,
        }));
    };

    const fetchData = async (subscriptionId: number | undefined) => {
        try {
            if (company && user) {
                setLoading(true);
                const { subscriptions, lawTypes } = await SubscriptionChangeService().getChanges(company.id);
                const assessmentUsers = await UserService().getUsersWithAssessmentRights(company.id);

                let selectedIndex = -1;
                if (subscriptionId) {
                    selectedIndex = _.findIndex(subscriptions, sub => sub.subscriptionId === subscriptionId);
                }

                const lawTypeFilter: LawTypeFilter[] = convertToLawTypeFilter(lawTypes, false);
                const lawListGroupFilter: LawListGroupFilter[] = convertToLawListGroupFilter(company.lawLists);

                setState(s => ({
                    ...s,
                    data: subscriptions,
                    lawTypeFilter,
                    lawListGroupFilter,
                    selectedKeywords: company.keyWords.map(kw => ({
                        ...kw,
                        checked: false,
                    })),
                    assessmentUsers: assessmentUsers.filter(u => u.userId !== user.userId),
                    selectedIndex,
                    loading: false,
                }));
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleCustomFilterToggle = (updatedUserSettings?: UserSettings) => {
        const userSettings = updatedUserSettings?.id !== undefined ? updatedUserSettings : getUserSettings();

        if (!userSettings?.id) {
            return;
        }

        const hiddenColumns = ['newLaw', 'acceptableBy'];

        if (!state.customFilterActive) {
            setState(s => ({
                ...s,
                customFilterActive: true,
                delegatedFilterActive: false,
                columns: s.columns.map(c => ({
                    ...c,
                    visible: hiddenColumns.includes(c.id)
                        ? false
                        : c.alwaysVisible
                        ? true
                        : (userSettings.bffColumnVisible && c.id === 'text') || (userSettings.descColumnVisible && c.id === 'description'),
                })),
                customColumns: s.customColumns.map(cc => ({
                    ...cc,
                    visible:
                        (userSettings.column1Visible && cc.id === 'customerText1') ||
                        (userSettings.column2Visible && cc.id === 'customerText2') ||
                        (userSettings.column3Visible && cc.id === 'customerText3') ||
                        (userSettings.column4Visible && cc.id === 'customerText4') ||
                        (userSettings.column5Visible && cc.id === 'customerText5') ||
                        (userSettings.keywordColumnVisible && cc.id === 'keywords') ||
                        (userSettings.lawListColumnVisible && cc.id === 'lawLists'),
                })),
                selectedKeywords: s.selectedKeywords.map(kw => ({
                    ...kw,
                    checked: userSettings.selectedKeywords.includes(kw.id),
                })),
                lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                    ...lawType,
                    lawGroups: lawType.lawGroups.map(lawGroup => ({
                        ...lawGroup,
                        checked: getUserSettingsSelectedLawGroupsFallback(userSettings.selectedLawGroups, s.data).includes(lawGroup.lawGroupId),
                    })),
                })),
                lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                    ...lawListGroup,
                    lawLists: lawListGroup.lawLists.map(lawList => ({
                        ...lawList,
                        checked: userSettings.selectedLawLists.includes(lawList.lawListId),
                    })),
                })),
            }));
        } else {
            setState(s => ({
                ...s,
                customFilterActive: false,
                columns: s.columns.map(c => ({
                    ...c,
                    visible: hiddenColumns.includes(c.id) ? false : ['law', 'changeText', 'publishedDate'].includes(c.id),
                })),
                customColumns: s.customColumns.map(cc => ({ ...cc, visible: ['lawLists', 'keywords'].includes(cc.id) })),
                selectedKeywords: s.selectedKeywords.map(kw => ({ ...kw, checked: false })),
                lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                    ...lawType,
                    lawGroups: lawType.lawGroups.map(lawGroup => ({
                        ...lawGroup,
                        checked: true,
                    })),
                })),
                lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                    ...lawListGroup,
                    lawLists: lawListGroup.lawLists.map(lawList => ({
                        ...lawList,
                        checked: false,
                    })),
                })),
            }));
        }
    };

    const onMyChangesToggle = () => {
        if (!state.myChangesFilterActive) {
            setState(s => ({
                ...s,
                myChangesFilterActive: true,
            }));
        } else {
            setState(s => ({
                ...s,
                myChangesFilterActive: false,
            }));
        }
    };

    const onDelegatedToggle = (updatedUserSettings?: UserSettings) => {
        const hiddenColumns = ['newLaw', 'acceptableBy'];
        const userSettings = updatedUserSettings?.id !== undefined ? updatedUserSettings : getUserSettings();

        if (!userSettings?.id) {
            return;
        }
        if (!state.delegatedFilterActive) {
            setState(s => ({
                ...s,
                delegatedFilterActive: true,
                customFilterActive: false,
                columns: s.columns.map(c => ({
                    ...c,
                    visible: hiddenColumns.includes(c.id) ? false : c.alwaysVisible ? true : c.id === 'delegated',
                })),
            }));
        } else {
            setState(s => ({
                ...s,
                delegatedFilterActive: false,
                columns: s.columns.map(c => ({
                    ...c,
                    visible: hiddenColumns.includes(c.id) ? false : ['law', 'changeText', 'publishedDate'].includes(c.id),
                })),
            }));
        }
    };

    const handleSaveUserSettings = (): void => {
        const userSettings: UserSettings = {
            id: signedInAsUser?.userSettings.id || user?.userSettings.id || undefined,
            delegateColumnVisible: state.customColumns.find(c => c.id === 'delegated')?.visible || false,
            descColumnVisible: state.columns.find(c => c.id === 'description')?.visible || false,
            bffColumnVisible: state.columns.find(c => c.id === 'text')?.visible || false,
            dateColumnVisible: state.columns.find(c => c.id === 'publishedDate')?.visible || false,
            column1Visible: state.customColumns.find(c => c.id === 'customerText1')?.visible || false,
            column2Visible: state.customColumns.find(c => c.id === 'customerText2')?.visible || false,
            column3Visible: state.customColumns.find(c => c.id === 'customerText3')?.visible || false,
            column4Visible: state.customColumns.find(c => c.id === 'customerText4')?.visible || false,
            column5Visible: state.customColumns.find(c => c.id === 'customerText5')?.visible || false,
            keywordColumnVisible: state.customColumns.find(c => c.id === 'keywords')?.visible || false,
            lawListColumnVisible: state.customColumns.find(c => c.id === 'lawLists')?.visible || false,
            revisionQuestionColumnVisible: user?.userSettings.revisionQuestionColumnVisible || false,
            latestRevisionDateColumnVisible: user?.userSettings.latestRevisionDateColumnVisible || false,
            customLawsSelected: user?.userSettings.customLawsSelected || false,
            useCustomEmailFilter: user?.userSettings.useCustomEmailFilter || false,
            selectedKeywords: state.selectedKeywords.filter(kw => kw.checked).map(kw => kw.id),
            selectedLawGroups: state.lawTypeFilter.flatMap(f => f.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupId)),
            selectedLawLists: state.lawListGroupFilter.flatMap(lawListGroup => lawListGroup.lawLists.filter(lawList => lawList.checked).map(lawList => lawList.lawListId)),
        };
        const userId = signedInAsUser?.userId || user?.userId || -1;
        UserService()
            .saveUserSettings(userId, userSettings)
            .then(updatedUserSettings => {
                setUserSettings(updatedUserSettings);
                if (!state.customFilterActive) {
                    handleCustomFilterToggle(updatedUserSettings);
                }
            });
    };

    const handleLawTypeOpen = (type: LawType): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                open: lawType.name === type ? !lawType.open : lawType.open,
            })),
        }));
    };

    const handleLawTypeChecked = (type: LawType): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => {
                if (lawType.name === type) {
                    return {
                        ...lawType,
                        lawGroups: lawType.lawGroups.map(lawGroup => ({
                            ...lawGroup,
                            checked: !lawType.lawGroups.every(l => l.checked),
                        })),
                    };
                }
                return lawType;
            }),
            customFilterActive: false,
            myChangesFilterActive: false,
        }));
    };

    const handleLawGroupChecked = (lawGroupId: number): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: lawGroup.lawGroupId === lawGroupId ? !lawGroup.checked : lawGroup.checked,
                })),
            })),
            customFilterActive: false,
            myChangesFilterActive: false,
        }));
    };

    const handleAllLawTypesChecked = (allChecked: boolean): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: !allChecked,
                })),
            })),
            customFilterActive: false,
            myChangesFilterActive: false,
        }));
    };

    const handleResetLawTypeFilter = (): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: true,
                })),
            })),
            customFilterActive: false,
            myChangesFilterActive: false,
        }));
    };

    const handleResetAllFilters = () => {
        setState(s => ({
            ...s,
            globalSearch: '',
            selectedKeywords: s.selectedKeywords.map(kw => ({ ...kw, checked: false })),
            columnSearch: s.columnSearch.map(c => ({ ...c, value: undefined })),
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: false,
                })),
            })),
            customFilterActive: false,
            myChangesFilterActive: false,
        }));
        handleResetLawTypeFilter();
    };

    const handleColumnSearchToggle = () => {
        setState(s => ({
            ...s,
            columnSearch: s.showColumnSearch ? s.columnSearch.map(c => ({ ...c, value: undefined })) : s.columnSearch,
            showColumnSearch: !s.showColumnSearch,
        }));
    };

    const handleGlobalSearchChange = (globalSearch: string): void => {
        setState(s => ({
            ...s,
            globalSearch,
        }));
    };

    const handleSelection = (selectedIndex: number) => {
        setState(s => ({
            ...s,
            selectedIndex,
        }));
    };

    const handleSelectedKeywordChange = (keywordId: number): void => {
        setState(s => ({
            ...s,
            selectedKeywords: s.selectedKeywords.map(keyword => ({
                ...keyword,
                checked: keyword.id === keywordId ? !keyword.checked : keyword.checked,
            })),
            customFilterActive: false,
            myChangesFilterActive: false,
        }));
    };

    const handleSelectAllKeywords = (checked: boolean): void => {
        setState(s => ({
            ...s,
            selectedKeywords: s.selectedKeywords.map(kw => ({
                ...kw,
                checked,
            })),
            customFilterActive: false,
            myChangesFilterActive: false,
        }));
    };

    const handleLawListGroupFilterChange = (lawListGroupId: number): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => {
                if (lawListGroup.lawListGroupId === lawListGroupId) {
                    return {
                        ...lawListGroup,
                        lawLists: lawListGroup.lawLists.map(lawList => ({
                            ...lawList,
                            checked: !lawListGroup.lawLists.every(l => l.checked),
                        })),
                    };
                }
                return lawListGroup;
            }),
            customFilterActive: false,
            myChangesFilterActive: false,
        }));
    };

    const handleLawListFilterChange = (lawListId: number): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: lawList.lawListId === lawListId ? !lawList.checked : lawList.checked,
                })),
            })),
            customFilterActive: false,
            myChangesFilterActive: false,
        }));
    };

    const handleAllLawListChange = (checked: boolean): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked,
                })),
            })),
            customFilterActive: false,
            myChangesFilterActive: false,
        }));
    };

    const handleColumnVisibleChange = (id: string): void => {
        setState(s => ({
            ...s,
            columns: s.columns.map(column => ({
                ...column,
                visible: column.id === id ? !column.visible : column.visible,
            })),
            customColumns: s.customColumns.map(column => ({
                ...column,
                visible: column.id === id ? !column.visible : column.visible,
            })),
            customFilterActive: false,
            myChangesFilterActive: false,
        }));
    };

    const handleSortingChange = (sorting: SortingRule<object>[]): void => {
        setState(s => ({
            ...s,
            sorting,
        }));
    };

    const handleVisibleDataCountChange = (visibleDataCount: number): void => {
        setState(s => ({
            ...s,
            visibleDataCount,
        }));
    };

    const handleUpdateSubscription = async (subscription: SubscriptionModel): Promise<void> => {
        return new Promise(resolve => {
            return SubscriptionService()
                .updateSubscription(subscription)
                .then(({ subscriptionVersion }) => {
                    setState(s => ({
                        ...s,
                        data: s.data.map(obj => {
                            if (obj.subscriptionId === subscription.subscriptionId) {
                                return {
                                    ...obj,
                                    ...subscription,
                                    subscriptionVersion,
                                };
                            }
                            return obj;
                        }),
                    }));
                    resolve();
                });
        });
    };

    const handleUpdateFullSubscription = async (subscriptionId: number, subscription?: SubscriptionModel, keywordIds?: number[], lawListIds?: number[]): Promise<void> => {
        return new Promise(resolve => {
            return SubscriptionService()
                .updateFullSubscription(subscriptionId, subscription, keywordIds, lawListIds)
                .then(updatedSubscription => {
                    setState(s => ({
                        ...s,
                        data: s.data.map(obj => {
                            if (obj.subscriptionId === subscriptionId) {
                                return {
                                    ...obj,
                                    ...updatedSubscription,
                                };
                            }
                            return obj;
                        }),
                    }));
                    resolve();
                });
        });
    };

    const handleAcceptSubscription = (
        subscriptionId: number,
        changeHasImpact: boolean,
        comment: string | undefined,
        callback: (subscription: SubscriptionModel) => void,
        addedAssessmentUsers?: number[],
        sendMail?: boolean,
    ): void => {
        if (user) {
            SubscriptionChangeService()
                .acceptSubscription(subscriptionId, user.userId, changeHasImpact, comment, addedAssessmentUsers, sendMail)
                .then(subscription => {
                    if (subscription.acceptanceStatus && subscription.acceptanceStatus === 'ACCEPTED') {
                        setState(s => ({
                            ...s,
                            data: s.data.filter(sub => sub.subscriptionId !== subscriptionId),
                        }));
                    } else {
                        setState(s => ({
                            ...s,
                            data: s.data.map(sub => {
                                if (sub.subscriptionId === subscription.subscriptionId) {
                                    return {
                                        ...sub,
                                        ...subscription,
                                    };
                                }
                                return sub;
                            }),
                        }));
                    }
                    callback(subscription);
                });
        }
    };

    const handleDelegateSubscription = (
        subscriptionId: number,
        comment: string | undefined,
        delegateUserIds: number[],
        assessmentChoice: AssessmentChoice,
        notifyEmail: boolean,
        callback: (subscription: SubscriptionModel) => void,
    ): void => {
        if (user) {
            SubscriptionChangeService()
                .delegateSubscription(subscriptionId, user.userId, comment, delegateUserIds, assessmentChoice, notifyEmail)
                .then(subscription => {
                    setState(s => ({
                        ...s,
                        data: s.data.map(sub => {
                            if (sub.subscriptionId === subscription.subscriptionId) {
                                return {
                                    ...sub,
                                    ...subscription,
                                };
                            }
                            return sub;
                        }),
                    }));
                    callback(subscription);
                });
        }
    };

    const handleChangeDelegates = async (
        subscriptionId: number,
        subscriptionVersion: number,
        comment: string | undefined,
        delegateUserIds: number[],
        notifyEmail: boolean,
    ): Promise<SubscriptionModel | undefined> => {
        if (user) {
            return await SubscriptionChangeService()
                .changeDelegation(subscriptionId, subscriptionVersion, user?.userId, comment, delegateUserIds, notifyEmail)
                .then(subscription => {
                    setState(s => {
                        if (subscription.acceptanceStatus === 'ACCEPTED') {
                            return { ...s, data: s.data.filter(obj => obj.subscriptionId !== subscription.subscriptionId), selectedIndex: -1 };
                        }
                        return {
                            ...s,
                            data: s.data.map(obj => {
                                if (obj.subscriptionId === subscriptionId) {
                                    return {
                                        ...obj,
                                        ...subscription,
                                    };
                                }
                                return obj;
                            }),
                        };
                    });
                    return subscription;
                });
        }
    };

    const handleAssessmentOnNew = async (
        subscriptionId: number,
        assessmentChoice: AssessmentChoice,
        comment: string,
        delegateUserIds: number[],
        sendMailToDelegates: boolean,
    ): Promise<void> => {
        if (user) {
            await SubscriptionChangeService()
                .handleAssessmentOnNew(subscriptionId, user.userId, assessmentChoice, comment, delegateUserIds, sendMailToDelegates)
                .then(subscription => {
                    setState(s => {
                        if (subscription.acceptanceStatus === 'ACCEPTED') {
                            return { ...s, data: s.data.filter(obj => obj.subscriptionId !== subscription.subscriptionId), selectedIndex: -1 };
                        }
                        return {
                            ...s,
                            data: s.data.map(obj => {
                                if (obj.subscriptionId === subscription.subscriptionId) {
                                    return {
                                        ...obj,
                                        ...subscription,
                                    };
                                }
                                return obj;
                            }),
                        };
                    });
                });
        }
    };

    const handleUpdateKeywords = async (subscriptionId: number, keywordIds: number[]): Promise<void> => {
        await SubscriptionService()
            .updateSubscriptionKeywords(subscriptionId, false, keywordIds)
            .then(subscription => {
                setState(s => ({
                    ...s,
                    data: s.data.map(obj => {
                        if (obj.subscriptionId === subscriptionId) {
                            return {
                                ...obj,
                                keywordIds: subscription.keywordIds,
                                subscriptionVersion: _.get(subscription, 'subscriptionVersion'),
                            };
                        }
                        return obj;
                    }),
                }));
            });
    };

    const handleUpdateLawLists = async (subscriptionId: number, lawListIds: number[]): Promise<void> => {
        await SubscriptionService()
            .updateSubscriptionLawLists(subscriptionId, false, lawListIds)
            .then(subscription => {
                setState(s => ({
                    ...s,
                    data: s.data.map(obj => {
                        if (obj.subscriptionId === subscriptionId) {
                            return {
                                ...obj,
                                lawLists: subscription.lawLists,
                                subscriptionVersion: _.get(subscription, 'subscriptionVersion'),
                            };
                        }
                        return obj;
                    }),
                }));
            });
    };

    const handleCreateRevisionQuestion = async (subscriptionId: number, revisionQuestion: Partial<RevisionQuestion>): Promise<number> => {
        setLoading(true);
        const createdRevisionQuestion: RevisionQuestion = await RevisionQuestionService().createCustomRevisionQuestion(subscriptionId, revisionQuestion);
        setState(s => ({
            ...s,
            loading: false,
            data: s.data.map(obj =>
                obj.subscriptionId === subscriptionId
                    ? {
                          ...obj,
                          customRevisionQuestions: [...obj.customRevisionQuestions, createdRevisionQuestion],
                      }
                    : obj,
            ),
        }));
        return createdRevisionQuestion.id;
    };

    const handleUpdateRevisionQuestion = async (subscriptionId: number, revisionQuestion: RevisionQuestion) => {
        setLoading(true);
        const updatedRevisionQuestion: RevisionQuestion = await RevisionQuestionService().updateCustomRevisionQuestion(revisionQuestion);
        setState(s => ({
            ...s,
            loading: false,
            data: s.data.map(obj =>
                obj.subscriptionId === subscriptionId
                    ? {
                          ...obj,
                          customRevisionQuestions: obj.customRevisionQuestions.map(crq => (crq.id === updatedRevisionQuestion.id ? { ...crq, ...updatedRevisionQuestion } : crq)),
                      }
                    : obj,
            ),
        }));
    };

    const handleRemoveRevisionQuestion = async (subscriptionId: number, revisionQuestionId: number) => {
        setLoading(true);
        await RevisionQuestionService().removeCustomRevisionQuestion(revisionQuestionId);
        setState(s => ({
            ...s,
            loading: false,
            data: s.data.map(obj =>
                obj.subscriptionId === subscriptionId
                    ? {
                          ...obj,
                          customRevisionQuestions: obj.customRevisionQuestions.filter(crq => crq.id !== revisionQuestionId),
                      }
                    : obj,
            ),
        }));
    };

    const setEditRevisionQuestionsOpen = (editRevisionQuestionsOpen: boolean): void => {
        setState(s => ({
            ...s,
            editRevisionQuestionsOpen,
        }));
    };

    const handleSubmitRevisionQuestionExclusions = async (excludedRevisionQuestionIds: number[], excludedCustomRevisionQuestionIds: number[]) => {
        const selected = state.filteredData[state.selectedIndex];
        if (selected) {
            setLoading(true);
            const customRevisionQuestions = await RevisionQuestionService().updateCustomRevisionQuestions(
                selected.customRevisionQuestions.map(crq => ({
                    ...crq,
                    excluded: excludedCustomRevisionQuestionIds.includes(crq.id),
                })),
            );
            const subscriptionModel = await RevisionQuestionService().updateExcludedRevisionQuestions(selected.subscriptionId, excludedRevisionQuestionIds);
            setState(s => ({
                ...s,
                data: s.data.map(obj =>
                    isSubscription(obj) && obj.subscriptionId === selected.subscriptionId
                        ? {
                              ...obj,
                              excludedIds: subscriptionModel.excludedBaseRevisionQuestionIds,
                              subscriptionVersion: subscriptionModel.subscriptionVersion,
                              customRevisionQuestions,
                              revisionQuestionGroups: obj.revisionQuestionGroups.map(rqg => ({
                                  ...rqg,
                                  revisionQuestions: rqg.revisionQuestions.map(rq => ({
                                      ...rq,
                                      changedNotification:
                                          rq.changedNotification === true ? subscriptionModel.excludedBaseRevisionQuestionIds.includes(rq.id) : rq.changedNotification,
                                      excluded: subscriptionModel.excludedBaseRevisionQuestionIds.includes(rq.questionBaseId || rq.id),
                                  })),
                              })),
                          }
                        : obj,
                ),
                loading: false,
                editRevisionQuestionsOpen: false,
            }));
        }
    };

    const handleExport = () => {
        const { filteredData, columns, customColumns, columnSearch, globalSearch } = state;

        //default columns that always exist
        let exportColumns: Array<{ key: columnKeys; Header: string; width: number }> = [
            { key: 'lawType', Header: t('columnLawGroup'), width: ExcelColumnWidth.lawType },
            { key: 'name', Header: t('columnLawName'), width: ExcelColumnWidth.name },
            { key: 'subId', Header: t('columnSubId'), width: ExcelColumnWidth.subId },
            { key: 'newLaw', Header: t('columnNewLaw'), width: ExcelColumnWidth.newLaw },
        ];

        //Get all column ids for columns that are visible
        columns.filter(c => c.visible).forEach(c => exportColumns.push({ key: c.id, Header: c.Header, width: ExcelColumnWidth[c.id] }));
        customColumns.filter(c => c.visible).forEach(c => exportColumns.push({ key: c.id, Header: c.Header, width: ExcelColumnWidth[c.id] }));

        //Remove column Law. This is instead replaced by the 3 static columns
        exportColumns = exportColumns.filter(c => c.key !== 'law');
        exportColumns = exportColumns.filter(c => c.key !== 'delegated');

        //If new columns are added in table, they need to be added here also for export to work#f1f1e9
        const exportData = filteredData
            .filter(obj => {
                const searchColumnIds = columnSearch.map(cs => cs.id);
                const searchColumns = [...columns, ...customColumns].filter(c => searchColumnIds.includes(c.id));
                // Filter data from columnSearch, globalSearch, lawLists and keywords
                return includeSubscriptionInExport(obj, columnSearch, searchColumns, columns, globalSearch);
            })
            .map(obj => ({
                lawType: t(LawTypeEnum[obj.lawType]) + ': ' + obj.lawGroupName,
                name: obj.name,
                subId: obj.subId ? t('lawSubId') + ` ${obj.subId}` : '',
                newLaw: obj.newLaw ? t('columnNewLaw') : '',
                changeText: obj.changeText,
                publishedDate: obj.publishedDate,
                description: obj.description,
                text: obj.text,
                customerText1: obj.customerText1,
                customerText2: obj.customerText2,
                customerText3: obj.customerText3,
                customerText4: obj.customerText4,
                customerText5: obj.customerText5,
                lawLists: company?.hasLawLists && obj.lawLists ? obj.lawLists.map(ll => (ll.lawListGroup ? ll.lawListGroup.name + ': ' : '') + ll.name).join('<br>') : '',
                keywords: company?.hasKeyWords && obj.keywordIds ? obj.keywordIds.map(id => _.find(company.keyWords, kw => kw.id === id)?.text).join('<br>') : '',
            }));
        const activeFilterExport = getActiveFilterExportFromState(state, exportData.length);
        exportList(exportColumns, exportData, 'Ändringar', activeFilterExport);
    };

    const hiddenColumns: string[] = useMemo(() => [...state.columns, ...state.customColumns].filter(column => !column.visible).map(column => column.id), [
        state.columns,
        state.customColumns,
    ]);

    return {
        ...state,
        totalDataCount: state.data.length,
        hiddenColumns,
        fetchData,
        setLoading,
        handleColumnSearchToggle,
        handleGlobalSearchChange,
        handleSelection,
        handleLawGroupChecked,
        handleLawTypeChecked,
        handleLawTypeOpen,
        handleResetLawTypeFilter,
        handleResetAllFilters,
        handleSelectedKeywordChange,
        handleLawListGroupFilterChange,
        handleLawListFilterChange,
        handleColumnVisibleChange,
        handleCustomFilterToggle,
        handleSaveUserSettings,
        handleSortingChange,
        handleVisibleDataCountChange,
        handleUpdateSubscription,
        handleAcceptSubscription,
        handleDelegateSubscription,
        handleUpdateKeywords,
        handleUpdateLawLists,
        handleChangeDelegates,
        handleAssessmentOnNew,
        handleCreateRevisionQuestion,
        handleUpdateRevisionQuestion,
        handleRemoveRevisionQuestion,
        setEditRevisionQuestionsOpen,
        handleSubmitRevisionQuestionExclusions,
        handleAllLawListChange,
        handleSelectAllKeywords,
        handleUpdateFullSubscription,
        handleAllLawTypesChecked,
        handleExport,
        handleColumnSearchChange,
        onMyChangesToggle,
        onDelegatedToggle,
    };
};

export default useChangeList;
