import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'ie11-custom-properties'; // Enables css variables in IE11

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './containers/App';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import './assets/styles/colors.css';
import './assets/styles/fonts.css';
import './index.css';
import './config/i18n';
import { AuthContextProvider } from './contexts/AuthContext';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import { MiscContextProvider } from './contexts/MiscContext';

ReactDOM.render(
    <Suspense fallback={LoadingSpinner}>
        <AuthContextProvider>
            <MiscContextProvider>
                <Router basename="/">
                    <App />
                </Router>
            </MiscContextProvider>
        </AuthContextProvider>
    </Suspense>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
