import React, { FC } from 'react';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import styled from './ConfirmModal.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
    lawName: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmModal: FC<Props> = ({ lawName, onConfirm, onCancel }) => {
    const { t } = useTranslation();
    return (
        <Modal className={styled.ConfirmModal} title={t('acceptNewLawModalTitle')} subtitle={t('acceptNewLawModalSubTitle', { lawName })}>
            <div className={styled.Buttons}>
                <Button variant="Primary" onClick={onCancel}>
                    {t('buttonNo')}
                </Button>
                <Button variant="Outline" onClick={onConfirm}>
                    {t('buttonYes')}
                </Button>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
