import { useContext, useMemo } from 'react';
import { EditRevisionContext } from '../contexts/EditRevisionContext';
import RevisionService from '../services/RevisionService';
import useAuth from './useAuth';
import LawTypeFilter, { convertToLawTypeFilter } from '../models/LawTypeFilter';
import { LawType } from '../models/LawType';
import LawListGroupFilter, { convertToLawListGroupFilter } from '../models/LawListGroupFilter';
import LawListModel from '../models/LawListModel';
import _ from 'lodash';
import { RevisionObjStatus, isAcceptableRevisionStatus } from '../models/RevisionObjStatus';
import SubscriptionService from '../services/SubscriptionService';
import { isRevisionSubscription } from '../models/RevisionSubscriptionModel';
import CustomLawService from '../services/CustomLawService';
import { getUserSettingsSelectedLawGroupsFallback } from '../models/UserSettings';
import RevisionMediaAttachmentModel from '../models/RevisionMediaAttachmentModel';

const useEditRevision = () => {
    const { state, setState } = useContext(EditRevisionContext);
    const { company, user, getUserSettings } = useAuth();

    const setLoading = (loading: boolean): void => {
        setState(s => ({
            ...s,
            loading,
        }));
    };

    const fetchData = async (revisionId: number) => {
        try {
            if (company && user) {
                setLoading(true);

                const revision = await RevisionService().getRevision(revisionId);
                const { lawTypes, subscriptionRevisions, customLawRevisions } = revision.revisionSubscriptionsListModel;
                const data = [...subscriptionRevisions, ...customLawRevisions];
                const lawTypeFilter: LawTypeFilter[] = convertToLawTypeFilter(lawTypes, customLawRevisions.length > 0);
                const lawListGroupFilter: LawListGroupFilter[] = convertToLawListGroupFilter(company.lawLists);

                setState(s => ({
                    ...s,
                    hasCustomLaws: customLawRevisions.length > 0,
                    data,
                    revision,
                    lawTypeFilter,
                    lawListGroupFilter,
                    selectedKeywords: company.keyWords.map(kw => ({
                        ...kw,
                        checked: false,
                    })),
                    customLawsSelected: true,
                    totalCompleted: revision.finished ? data.filter(obj => !isAcceptableRevisionStatus(obj.revisionStatus)).length : data.length,
                    loading: false,
                }));
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleGlobalSearchChange = (globalSearch: string): void => {
        setState(s => ({
            ...s,
            globalSearch,
        }));
    };

    const handleLawListGroupFilterChange = (lawListGroupId: number): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => {
                if (lawListGroup.lawListGroupId === lawListGroupId) {
                    return {
                        ...lawListGroup,
                        lawLists: lawListGroup.lawLists.map(lawList => ({
                            ...lawList,
                            checked: !lawListGroup.lawLists.every(l => l.checked),
                        })),
                    };
                }
                return lawListGroup;
            }),
            customFilterActive: false,
        }));
    };

    const handleLawListFilterChange = (lawListId: number): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: lawList.lawListId === lawListId ? !lawList.checked : lawList.checked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleAllLawListChange = (checked: boolean): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleVisibleDataCountChange = (visibleDataCount: number) => {
        setState(s => ({
            ...s,
            visibleDataCount,
        }));
    };

    const handleSelectedKeywordChange = (keywordId: number): void => {
        setState(s => ({
            ...s,
            selectedKeywords: s.selectedKeywords.map(keyword => ({
                ...keyword,
                checked: keyword.id === keywordId ? !keyword.checked : keyword.checked,
            })),
            customFilterActive: false,
        }));
    };

    const handleSelectAllKeywords = (checked: boolean): void => {
        setState(s => ({
            ...s,
            selectedKeywords: s.selectedKeywords.map(kw => ({
                ...kw,
                checked,
            })),
            customFilterActive: false,
        }));
    };

    const handleColumnSearchToggle = () => {
        setState(s => ({
            ...s,
            columnSearch: s.showColumnSearch ? s.columnSearch.map(c => ({ ...c, value: undefined })) : s.columnSearch,
            showColumnSearch: !s.showColumnSearch,
        }));
    };

    const handleLawTypeOpen = (type: LawType): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                open: lawType.name === type ? !lawType.open : lawType.open,
            })),
        }));
    };

    const handleLawTypeChecked = (type: LawType): void => {
        if (type === 'CUSTOM_LAW') {
            setState(s => ({
                ...s,
                customLawsSelected: !s.customLawsSelected,
                customFilterActive: false,
            }));
        } else {
            setState(s => ({
                ...s,
                lawTypeFilter: s.lawTypeFilter.map(lawType => {
                    if (lawType.name === type) {
                        return {
                            ...lawType,
                            lawGroups: lawType.lawGroups.map(lawGroup => ({
                                ...lawGroup,
                                checked: !lawType.lawGroups.every(l => l.checked),
                            })),
                        };
                    }
                    return lawType;
                }),
                customFilterActive: false,
            }));
        }
    };

    const handleAllLawTypesChecked = (allChecked: boolean): void => {
        setState(s => ({
            ...s,
            customLawsSelected: !allChecked,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: !allChecked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleLawGroupChecked = (lawGroupId: number): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: lawGroup.lawGroupId === lawGroupId ? !lawGroup.checked : lawGroup.checked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleColumnVisibleChange = (id: string): void => {
        setState(s => ({
            ...s,
            columns: s.columns.map(column => ({
                ...column,
                visible: column.id === id ? !column.visible : column.visible,
            })),
            customFilterActive: false,
        }));
    };

    const handleResetLawTypeFilter = (): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: true,
                })),
            })),
            customLawsSelected: true,
            customFilterActive: false,
        }));
    };

    const handleResetAllFilters = () => {
        setState(s => ({
            ...s,
            globalSearch: '',
            selectedKeywords: s.selectedKeywords.map(kw => ({ ...kw, checked: false })),
            columnSearch: s.columnSearch.map(c => ({ ...c, value: undefined })),
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: false,
                })),
            })),
            customFilterActive: false,
        }));
        handleResetLawTypeFilter();
    };

    const handleSelection = (selectedIndex: number): void => {
        setState(s => ({
            ...s,
            selectedIndex,
        }));
    };

    const handleCustomFilterToggle = () => {
        const userSettings = getUserSettings();

        if (!userSettings?.id) {
            return;
        }

        if (!state.customFilterActive) {
            setState(s => ({
                ...s,
                customFilterActive: true,
                columns: s.columns.map(c => ({
                    ...c,
                    visible: c.alwaysVisible
                        ? true
                        : (userSettings.bffColumnVisible && c.id === 'text') ||
                          (userSettings.descColumnVisible && c.id === 'description') ||
                          (userSettings.column1Visible && c.id === 'customerText1') ||
                          (userSettings.column2Visible && c.id === 'customerText2') ||
                          (userSettings.column3Visible && c.id === 'customerText3') ||
                          (userSettings.column4Visible && c.id === 'customerText4') ||
                          (userSettings.column5Visible && c.id === 'customerText5') ||
                          (userSettings.delegateColumnVisible && c.id === 'delegated') ||
                          (userSettings.keywordColumnVisible && c.id === 'keywords') ||
                          (userSettings.lawListColumnVisible && c.id === 'lawLists') ||
                          (userSettings.latestRevisionDateColumnVisible && c.id === 'latestRevisionDate'),
                })),
                selectedKeywords: s.selectedKeywords.map(kw => ({
                    ...kw,
                    checked: userSettings.selectedKeywords.includes(kw.id),
                })),
                lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                    ...lawType,
                    lawGroups: lawType.lawGroups.map(lawGroup => ({
                        ...lawGroup,
                        checked: getUserSettingsSelectedLawGroupsFallback(userSettings.selectedLawGroups, s.data).includes(lawGroup.lawGroupId),
                    })),
                })),
                customLawsSelected: userSettings.customLawsSelected,
                lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                    ...lawListGroup,
                    lawLists: lawListGroup.lawLists.map(lawList => ({
                        ...lawList,
                        checked: userSettings.selectedLawLists.includes(lawList.lawListId),
                    })),
                })),
            }));
        } else {
            setState(s => ({
                ...s,
                customFilterActive: false,
                columns: s.columns.map(c => ({ ...c, visible: ['law', 'status', 'media', 'comment'].includes(c.id) })),
                selectedKeywords: s.selectedKeywords.map(kw => ({ ...kw, checked: false })),
                lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                    ...lawType,
                    lawGroups: lawType.lawGroups.map(lawGroup => ({
                        ...lawGroup,
                        checked: true,
                    })),
                })),
                lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                    ...lawListGroup,
                    lawLists: lawListGroup.lawLists.map(lawList => ({
                        ...lawList,
                        checked: false,
                    })),
                })),
                customLawsSelected: true,
            }));
        }
    };

    const handleSaveEdit = (editKey: string, subscription: any): Promise<any> => {
        return new Promise(resolve => {
            if (editKey === 'keywordIds') {
                return SubscriptionService()
                    .updateSubscriptionKeywords(subscription.subscriptionId || subscription.customLawId, !!subscription.customLaw, subscription.keywordIds)
                    .then(sub => {
                        setState(s => ({
                            ...s,
                            data: s.data.map(obj => {
                                if (
                                    (isRevisionSubscription(obj) && obj.subscriptionId === subscription.subscriptionId) ||
                                    (!isRevisionSubscription(obj) && obj.customLawId === subscription.customLawId)
                                ) {
                                    return {
                                        ...obj,
                                        ...subscription,
                                        [!!subscription.customLaw ? 'subscriptionVersion' : 'version']: !!subscription.customLaw
                                            ? _.get(sub, 'subscriptionVersion')
                                            : _.get(sub, 'version'),
                                    };
                                }
                                return obj;
                            }),
                        }));
                        resolve();
                    });
            } else if (editKey === 'lawLists') {
                const lawListIds = subscription.lawLists.map((lawList: LawListModel) => lawList.lawListId);
                return SubscriptionService()
                    .updateSubscriptionLawLists(subscription.subscriptionId || subscription.customLawId, !!subscription.customLaw, lawListIds)
                    .then(sub => {
                        setState(s => ({
                            ...s,
                            data: s.data.map(obj => {
                                if (
                                    (isRevisionSubscription(obj) && obj.subscriptionId === subscription.subscriptionId) ||
                                    (!isRevisionSubscription(obj) && obj.customLawId === subscription.customLawId)
                                ) {
                                    return {
                                        ...obj,
                                        ...subscription,
                                        [!!subscription.customLaw ? 'subscriptionVersion' : 'version']: !!subscription.customLaw
                                            ? _.get(sub, 'subscriptionVersion')
                                            : _.get(sub, 'version'),
                                    };
                                }
                                return obj;
                            }),
                        }));
                        resolve();
                    });
            } else {
                if (subscription.customLaw) {
                    return CustomLawService()
                        .updateCustomLawTexts(subscription)
                        .then(({ version }) => {
                            setState(s => ({
                                ...s,
                                data: s.data.map(obj => {
                                    if (!isRevisionSubscription(obj) && obj.customLawId === subscription.customLawId) {
                                        return {
                                            ...obj,
                                            ...subscription,
                                            version,
                                        };
                                    }
                                    return obj;
                                }),
                            }));
                            resolve();
                        });
                } else {
                    return SubscriptionService()
                        .updateSubscription(subscription)
                        .then(({ subscriptionVersion }) => {
                            setState(s => ({
                                ...s,
                                data: s.data.map(obj => {
                                    if (isRevisionSubscription(obj) && obj.subscriptionId === subscription.subscriptionId) {
                                        return {
                                            ...obj,
                                            ...subscription,
                                            subscriptionVersion,
                                        };
                                    }
                                    return obj;
                                }),
                            }));
                            resolve();
                        });
                }
            }
        });
    };

    const handleUpdateSubscriptionRevisionStatus = (comment: string, revisionStatus: RevisionObjStatus, callback: () => void): void => {
        setLoading(true);
        const selected = state.filteredData[state.selectedIndex];
        if (selected && state.revision) {
            if (isRevisionSubscription(selected)) {
                RevisionService()
                    .updateSubscriptionRevisionStatus(state.revision.id, selected.subscriptionId, comment, revisionStatus)
                    .then(revisionObjUpdate => {
                        setState(s => ({
                            ...s,
                            data: s.data.map(obj => {
                                if (isRevisionSubscription(obj) && obj.subscriptionId === selected.subscriptionId) {
                                    return {
                                        ...obj,
                                        comments: revisionObjUpdate.comments,
                                        revisionStatus,
                                    };
                                }
                                return obj;
                            }),
                            revision: s.revision
                                ? {
                                      ...s.revision,
                                      revisionOk: revisionObjUpdate.revisionOk,
                                      version: revisionObjUpdate.revisionVersion,
                                  }
                                : s.revision,
                        }));
                        setLoading(false);
                        callback();
                    });
            } else {
                RevisionService()
                    .updateCustomLawRevisionStatus(state.revision.id, selected.customLawId, comment, revisionStatus)
                    .then(revisionObjUpdate => {
                        setState(s => ({
                            ...s,
                            data: s.data.map(obj => {
                                if (!isRevisionSubscription(obj) && obj.customLawId === selected.customLawId) {
                                    return {
                                        ...obj,
                                        comments: revisionObjUpdate.comments,
                                        revisionStatus,
                                    };
                                }
                                return obj;
                            }),
                            revision: s.revision
                                ? {
                                      ...s.revision,
                                      revisionOk: revisionObjUpdate.revisionOk,
                                      version: revisionObjUpdate.revisionVersion,
                                  }
                                : s.revision,
                        }));
                        setLoading(false);
                        callback();
                    });
            }
        }
    };

    const handleUpdateRevisionComment = (comment: string): void => {
        if (state.revision) {
            RevisionService()
                .updateRevisionComment(state.revision.id, state.revision.version, comment)
                .then(revision => {
                    setState(s => ({
                        ...s,
                        revision: s.revision
                            ? {
                                  ...s.revision,
                                  version: revision.version,
                                  comment: revision.comment,
                              }
                            : undefined,
                    }));
                });
        }
    };

    const updateRevisionMediaState = (selectedSubRevId: number, revisionMediaAttachmentModel: RevisionMediaAttachmentModel, custom?: boolean): void => {
        setState(s => ({
            ...s,
            data: s.data.map(obj => {
                if (
                    (isRevisionSubscription(obj) && !custom && obj.subscriptionId === selectedSubRevId) ||
                    (!isRevisionSubscription(obj) && custom && obj.customLawId === selectedSubRevId)
                ) {
                    return {
                        ...obj,
                        mediaAttachments: obj.mediaAttachments ? [...obj.mediaAttachments, revisionMediaAttachmentModel] : [revisionMediaAttachmentModel],
                    };
                }
                return obj;
            }),
            revision: s.revision
                ? {
                      ...s.revision,
                      mediaAttachmentsCount: s.revision.mediaAttachmentsCount + 1,
                  }
                : s.revision,
        }));
    };

    const updateRevisionMediaCommentState = (selectedSubRevId: number, revisionMediaAttachmentModel: RevisionMediaAttachmentModel, custom?: boolean): void => {
        setState(s => ({
            ...s,
            data: s.data.map(obj => {
                if (
                    (isRevisionSubscription(obj) && !custom && obj.subscriptionId === selectedSubRevId) ||
                    (!isRevisionSubscription(obj) && custom && obj.customLawId === selectedSubRevId)
                ) {
                    return {
                        ...obj,
                        mediaAttachments: obj.mediaAttachments.map((ma, index) => {
                            return ma.mediaId === revisionMediaAttachmentModel.mediaId ? revisionMediaAttachmentModel : ma;
                        }),
                    };
                }
                return obj;
            }),
        }));
    };

    const updateRevisionMediaFullDataState = (selectedSubRevId: number, mediaId: number, fullData: any, custom?: boolean): void => {
        setState(s => ({
            ...s,
            data: s.data.map(obj => {
                if (
                    (isRevisionSubscription(obj) && !custom && obj.subscriptionId === selectedSubRevId) ||
                    (!isRevisionSubscription(obj) && custom && obj.customLawId === selectedSubRevId)
                ) {
                    return {
                        ...obj,
                        mediaAttachments: obj.mediaAttachments.map((ma, mId) => {
                            if (ma.mediaId === mediaId) {
                                ma.fullData = fullData;
                            }
                            return ma;
                        }),
                    };
                }
                return obj;
            }),
        }));
    };

    const removeRevisionMedia = (selectedSubRevId: number, mediaId: number, custom?: boolean): void => {
        setState(s => ({
            ...s,
            data: s.data.map(obj => {
                if (
                    (isRevisionSubscription(obj) && !custom && obj.subscriptionId === selectedSubRevId) ||
                    (!isRevisionSubscription(obj) && custom && obj.customLawId === selectedSubRevId)
                ) {
                    return {
                        ...obj,
                        mediaAttachments: obj.mediaAttachments.filter((ma, mId) => {
                            // debugger;
                            if (ma.mediaId === mediaId) {
                                return false;
                            }

                            return true;
                        }),
                    };
                }
                return obj;
            }),
        }));
    };

    const handleAddRevisionMedia = (file: File, comment: string): void => {
        setLoading(true);
        const selected = state.filteredData[state.selectedIndex];
        if (selected && state.revision && company) {
            if (isRevisionSubscription(selected)) {
                RevisionService()
                    .addRevisionMedia(company?.id, state.revision?.id, selected.subscriptionId, file, comment)
                    .then(revisionMediaAttachmentModel => {
                        updateRevisionMediaState(selected.subscriptionId, revisionMediaAttachmentModel);
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                        throw error;
                    });
            } else {
                RevisionService()
                    .addRevisionMedia(company?.id, state.revision?.id, selected.customLawId, file, comment, true)
                    .then(revisionMediaAttachmentModel => {
                        updateRevisionMediaState(selected.customLawId, revisionMediaAttachmentModel, true);
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                        throw error;
                    });
            }
        }
    };

    const handleEditRevisionMediaComment = (mediaId: number, comment: string): void => {
        setLoading(true);
        const selected = state.filteredData[state.selectedIndex];
        if (company && mediaId && comment) {
            if (isRevisionSubscription(selected)) {
                RevisionService()
                    .editRevisionMedia(company.id, mediaId, comment)
                    .then(revisionMediaAttachmentModel => {
                        updateRevisionMediaCommentState(selected.subscriptionId, revisionMediaAttachmentModel);
                        setLoading(false);
                    });
            } else {
                RevisionService()
                    .editRevisionMedia(company.id, mediaId, comment)
                    .then(revisionMediaAttachmentModel => {
                        updateRevisionMediaCommentState(selected.customLawId, revisionMediaAttachmentModel, true);
                        setLoading(false);
                    });
            }
        }
    };

    const handleGetRevisionMediaFullData = (mediaId: number): void => {
        setLoading(true);
        const selected = state.filteredData[state.selectedIndex];

        if (company && mediaId) {
            if (isRevisionSubscription(selected)) {
                RevisionService()
                    .getRevisionMediaFullData(company.id, mediaId)
                    .then(data => {
                        updateRevisionMediaFullDataState(selected.subscriptionId, mediaId, data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                    });
            } else {
                RevisionService()
                    .getRevisionMediaFullData(company.id, mediaId)
                    .then(data => {
                        updateRevisionMediaFullDataState(selected.customLawId, mediaId, data, true);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                    });
            }
        }
    };

    const handleDeleteRevisionMedia = (mediaId: number): void => {
        setLoading(true);
        const selected = state.filteredData[state.selectedIndex];
        if (company && mediaId) {
            if (isRevisionSubscription(selected)) {
                RevisionService()
                    .deleteRevisionMedia(company.id, mediaId)
                    .then(() => {
                        removeRevisionMedia(selected.subscriptionId, mediaId);
                        setLoading(false);
                    });
            } else {
                RevisionService()
                    .deleteRevisionMedia(company.id, mediaId)
                    .then(() => {
                        removeRevisionMedia(selected.customLawId, mediaId, true);
                        setLoading(false);
                    });
            }
        }
    };

    const hiddenColumns: string[] = useMemo(() => state.columns.filter(column => !column.visible).map(column => column.id), [state.columns]);

    const progressCompleted: number = useMemo(
        () =>
            state.revision?.finished
                ? state.totalCompleted - state.data.filter(obj => !isAcceptableRevisionStatus(obj.revisionStatus)).length
                : state.data.filter(obj => obj.revisionStatus !== 'NO_VALUE').length,
        [state.totalCompleted, state.data, state.revision],
    );

    const columns = useMemo(() => {
        let columns = state.columns;
        const showNotification: boolean = state.revision?.finished as boolean;
        columns = columns.map(col => (col.id === 'law' ? { ...col, showNotification } : col));
        return columns;
    }, [state.columns, state.revision]);

    return {
        ...state,
        hiddenColumns,
        columns,
        progressCompleted,
        fetchData,
        handleGlobalSearchChange,
        handleLawListGroupFilterChange,
        handleLawListFilterChange,
        handleSelectedKeywordChange,
        handleColumnSearchToggle,
        handleLawTypeOpen,
        handleLawTypeChecked,
        handleLawGroupChecked,
        handleColumnVisibleChange,
        handleResetLawTypeFilter,
        handleResetAllFilters,
        handleCustomFilterToggle,
        handleSelection,
        handleSaveEdit,
        handleUpdateSubscriptionRevisionStatus,
        handleUpdateRevisionComment,
        handleVisibleDataCountChange,
        handleSelectAllKeywords,
        handleAllLawListChange,
        handleAllLawTypesChecked,
        handleAddRevisionMedia,
        handleEditRevisionMediaComment,
        handleDeleteRevisionMedia,
        handleGetRevisionMediaFullData,
    };
};

export default useEditRevision;
