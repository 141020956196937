import React, { useState, useEffect, FC, useMemo } from 'react';
import Table from '../../../components/Table/Table';
import EditView, { trimHtmlString } from '../../../components/EditView/EditView';
import _ from 'lodash';
import styled from './LawList.module.css';
import Filterbar from '../../../components/Filterbar/Filterbar';
import useLawList from '../../../hooks/useLawList';
import ContextMenu from '../../../components/ContextMenu/ContextMenu';
import { LAWS_LISTS, LAWS_LIBRARY } from '../../../constants/Routes';
import lawlistIcon from '../../../assets/images/lawlistIcon.svg';
import lawlibraryIcon from '../../../assets/images/lawlibraryIcon.svg';
import UnmonitorSubscriptionModal from '../../../components/UnmonitorSubscriptionModal/UnmonitorSubscriptionModal';
import LawListFullView from '../../../components/FullView/LawListFullView/LawListFullView';
import SubscriptionModel, { isSubscription, getConsultantText } from '../../../models/SubscriptionModel';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { getMaxLengthValidations } from '../../../constants/Validation';
import useAuth from '../../../hooks/useAuth';
import EditRevisionQuestionView from '../../../components/EditRevisionQuestionView/EditRevisionQuestionView';
import CustomLawModel from '../../../models/CustomLawModel';
import { useTranslation } from 'react-i18next';
import EmptyListView from '../../../components/EmptyListView/EmptyListView';
import SaveSignedInAsUserFilterModal from '../../../components/Modal/SaveSignedInAsUserFilterModal/SaveSignedInAsUserFilterModal';

const initialEdit = { key: '', title: '', value: '' };

const LawLists: FC = () => {
    const { t } = useTranslation();

    const CONTEXT_LINKS: {
        title: string;
        url: string;
        icon: string;
        active: boolean;
    }[] = [
        {
            title: t('contextMenuLawLists'),
            url: LAWS_LISTS,
            icon: lawlistIcon,
            active: true,
        },
        {
            title: t('contextMenuLawLibrary'),
            url: LAWS_LIBRARY,
            icon: lawlibraryIcon,
            active: false,
        },
    ];

    const [edit, setEdit] = useState(initialEdit);
    const [hideFilterbar, setHideFilterbar] = useState(false);
    const [unmonitorModalOpen, setUnmonitorModalOpen] = useState(false);
    const [saveSignedInAsUserFilterModalOpen, setSaveSignedInAsUserFilterModalOpen] = useState(false);

    const { isAboveSuperUser, signedInAsUser } = useAuth();
    const {
        loading,
        data,
        filteredData,
        columns,
        customColumns,
        editRevisionQuestionsOpen,
        columnSearch,
        lawTypeFilter,
        lawListGroupFilter,
        customLawsSelected,
        fetchData,
        selectedIndex,
        handleSelection,
        hasCustomLaws,
        selectedKeywords,
        handleSelectedKeywordChange,
        handleLawTypeOpen,
        handleLawTypeChecked,
        handleLawGroupChecked,
        handleResetLawTypeFilter,
        showColumnSearch,
        handleColumnSearchToggle,
        handleVisibleDataCountChange,
        visibleDataCount,
        totalDataCount,
        handleResetAllFilters,
        handleLawListFilterChange,
        handleLawListGroupFilterChange,
        customFilterActive,
        handleCustomFilterToggle,
        handleColumnVisibleChange,
        hiddenColumns,
        globalSearch,
        handleGlobalSearchChange,
        handleSaveUserSettings,
        handleExport,
        sorting,
        handleSortingChange,
        handleSaveSubscription,
        handleUnsubscribe,
        handleCreateRevisionQuestion,
        handleCreateCustomLawRevisionQuestion,
        handleUpdateRevisionQuestion,
        handleRemoveRevisionQuestion,
        setEditRevisionQuestionsOpen,
        handleSubmitRevisionQuestionExclusions,
        handleSelectAllKeywords,
        handleAllLawListChange,
        handleAllLawTypesChecked,
        handleColumnSearchChange,
    } = useLawList();

    useEffect(() => {
        fetchData();
    }, []);

    // Reset state on select changes
    useEffect(() => {
        if (selectedIndex < 0) {
            setEdit(initialEdit);
            setEditRevisionQuestionsOpen(false);
        }
    }, [selectedIndex]);

    const handleSubmit = async () => {
        let editedSubscription;
        const selectedObj = filteredData[selectedIndex];
        if (isSubscription(selectedObj)) {
            editedSubscription = {
                subscriptionId: selectedObj.subscriptionId,
                [edit.key]: trimHtmlString(edit.value),
                subscriptionVersion: selectedObj.subscriptionVersion,
            };
        } else {
            editedSubscription = {
                customLawId: selectedObj.customLawId,
                version: selectedObj.version,
                customLaw: true,
                [edit.key]: trimHtmlString(edit.value),
            };
        }

        await handleSaveSubscription(edit.key, editedSubscription);
        setEdit(initialEdit);
        setHideFilterbar(false);
    };

    const onSaveUserSettings = () => {
        if (signedInAsUser !== undefined && isAboveSuperUser()) {
            setSaveSignedInAsUserFilterModalOpen(true);
        } else {
            handleSaveUserSettings();
        }
    };

    const handleSubmitRevisionQuestionExclusionsCallback = (excludedRevisionQuestionIds: number[], excludedCustomRevisionQuestionIds: number[]) => {
        handleSubmitRevisionQuestionExclusions(excludedRevisionQuestionIds, excludedCustomRevisionQuestionIds);
        setHideFilterbar(false);
    };

    const selected: SubscriptionModel | CustomLawModel | undefined = useMemo(() => filteredData[selectedIndex], [selectedIndex, filteredData[selectedIndex]]);

    return (
        <>
            {loading && <LoadingSpinner />}
            <ContextMenu
                lawTypeFilter={lawTypeFilter}
                hasCustomLaws={hasCustomLaws}
                customLawsSelected={customLawsSelected}
                onToggleLawTypeOpen={handleLawTypeOpen}
                onToggleLawTypeChecked={handleLawTypeChecked}
                onToggleLawGroupChecked={handleLawGroupChecked}
                onResetLawTypeFilter={handleResetLawTypeFilter}
                onResetAllFilters={handleResetAllFilters}
                onToggleAllLawTypesChecked={handleAllLawTypesChecked}
                visibleDataCount={visibleDataCount}
                totalDataCount={totalDataCount}
                links={CONTEXT_LINKS}
            />
            <div className={styled.List}>
                {!hideFilterbar && (
                    <Filterbar
                        globalSearch={globalSearch}
                        onGlobalSearchChange={handleGlobalSearchChange}
                        customFilterActive={customFilterActive}
                        onCustomFilterToggle={handleCustomFilterToggle}
                        selectedKeywords={selectedKeywords}
                        onSelectedKeywordChange={handleSelectedKeywordChange}
                        showColumnSearch={showColumnSearch}
                        onColumnSearchToggle={handleColumnSearchToggle}
                        lawListGroupFilter={lawListGroupFilter}
                        onLawListFilterChange={handleLawListFilterChange}
                        onLawListGroupFilterChange={handleLawListGroupFilterChange}
                        onColumnShowChange={handleColumnVisibleChange}
                        columns={[...columns, ...customColumns].filter(column => !column.alwaysVisible)}
                        onExport={handleExport}
                        onSaveUserSettings={onSaveUserSettings}
                        onSelectAllKeywords={handleSelectAllKeywords}
                        onAllLawListChange={handleAllLawListChange}
                    />
                )}
                <div className={styled.TableWrapper}>
                    {!loading && data && data.length > 0 && visibleDataCount < 1 ? <EmptyListView emptyFilter /> : null}

                    <Table
                        columns={[...columns, ...customColumns]}
                        data={filteredData}
                        selected={selectedIndex}
                        onSelect={handleSelection}
                        showColumnSearch={showColumnSearch}
                        globalSearch={globalSearch}
                        columnSearch={columnSearch}
                        onVisibleDataCountChange={handleVisibleDataCountChange}
                        hiddenColumns={hiddenColumns}
                        sorting={sorting}
                        onSortingChange={handleSortingChange}
                        onColumnSearchChange={handleColumnSearchChange}
                    />
                    {edit.key && selectedIndex > -1 ? (
                        <EditView
                            title={edit.title}
                            value={edit.value}
                            field={edit.key}
                            consultantText={isAboveSuperUser() ? getConsultantText(edit.key, filteredData[selectedIndex]) : undefined}
                            maxLength={getMaxLengthValidations(isSubscription(filteredData[selectedIndex]), edit.key)}
                            onSubmit={handleSubmit}
                            onChange={value => setEdit(e => ({ ...e, value }))}
                            onClose={() => {
                                setEdit(initialEdit);
                                setHideFilterbar(false);
                            }}
                        />
                    ) : null}
                    <LawListFullView
                        onEdit={(key, title) => {
                            setEdit({
                                key,
                                title,
                                value: _.get(filteredData[selectedIndex], key),
                            });
                            setHideFilterbar(true);
                        }}
                        onEditRevisionQuestions={() => {
                            setEditRevisionQuestionsOpen(true);
                            setHideFilterbar(true);
                        }}
                        onMonitorChange={() => {
                            const subscriptionId = _.get(filteredData[selectedIndex], 'subscriptionId');
                            if (subscriptionId) {
                                setUnmonitorModalOpen(true);
                            }
                        }}
                        data={filteredData[selectedIndex]}
                        open={selectedIndex > -1}
                        onClose={() => {
                            setEdit(initialEdit);
                            setEditRevisionQuestionsOpen(false);
                            handleSelection(-1);
                            setHideFilterbar(false);
                        }}
                    />

                    {editRevisionQuestionsOpen && selected && (
                        <EditRevisionQuestionView
                            data={selected}
                            onCreateRevisionQuestion={data =>
                                isSubscription(selected)
                                    ? handleCreateRevisionQuestion(selected.subscriptionId, data)
                                    : handleCreateCustomLawRevisionQuestion(selected.customLawId, data)
                            }
                            onUpdateRevisionQuestion={data => handleUpdateRevisionQuestion(isSubscription(selected) ? selected.subscriptionId : selected.customLawId, data)}
                            onRemoveRevisionQuestion={id => handleRemoveRevisionQuestion(isSubscription(selected) ? selected.subscriptionId : selected.customLawId, id)}
                            onCancel={() => {
                                setEditRevisionQuestionsOpen(false);
                                setHideFilterbar(true);
                            }}
                            onSubmit={handleSubmitRevisionQuestionExclusionsCallback}
                        />
                    )}
                </div>

                {unmonitorModalOpen && (
                    <UnmonitorSubscriptionModal
                        onClose={() => setUnmonitorModalOpen(false)}
                        onAccept={() => {
                            const subscriptionId = _.get(filteredData[selectedIndex], 'subscriptionId');
                            if (subscriptionId) {
                                setUnmonitorModalOpen(false);
                                handleUnsubscribe(subscriptionId);
                            }
                            handleSelection(-1);
                        }}
                    />
                )}

                {saveSignedInAsUserFilterModalOpen && (
                    <SaveSignedInAsUserFilterModal
                        onCancel={() => setSaveSignedInAsUserFilterModalOpen(false)}
                        onConfirm={() => {
                            handleSaveUserSettings();
                            setSaveSignedInAsUserFilterModalOpen(false);
                        }}
                    />
                )}
            </div>
        </>
    );
};

export default LawLists;
