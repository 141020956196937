import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from './UserInfo.module.css';
import Input from '../../../../components/Input/Input';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Button from '../../../../components/Button/Button';
import useAuth from '../../../../hooks/useAuth';
import { EditUserModel } from '../../../../models/UserModel';
import * as VALIDATION from '../../../../constants/Validation';
import UserService from '../../../../services/UserService';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { qrCodeTime, qrCodeTimeMs } from '../../../../constants/Validation';

interface InputState {
    value: string;
    error: string | undefined;
}

const UserInfo: FC = () => {
    const { t } = useTranslation();
    const BASE_64_STRING = 'data:image/png;base64,';
    const PREVENT_PASSWORD_AUTOFILL_KEY = 'new-password';

    const LANGUAGES = [
        { id: 0, text: t('languageSwedish'), value: 'sv' },
        { id: 1, text: t('languageEnglish'), value: 'en' },
    ];

    const { user, setUser } = useAuth();
    const [showQr, setShowQr] = useState(false);
    const [qrData, setQrData] = useState();
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState<InputState>({ value: user?.firstName || '', error: VALIDATION.name(user?.firstName || '') });
    const [lastName, setLastName] = useState<InputState>({ value: user?.lastName || '', error: VALIDATION.name(user?.lastName || '') });
    const [userName, setUserName] = useState<InputState>({ value: user?.userName || '', error: VALIDATION.userName(user?.userName || '') });
    const [language, setLanguage] = useState<number>(LANGUAGES.find(lang => lang.value === user?.language)?.id || 0);
    const [password, setPassword] = useState<InputState>({ value: '', error: undefined });
    const [confirmPassword, setConfirmPassword] = useState<InputState>({ value: '', error: undefined });
    const [counter, setCounter] = useState();
    const handleFirstNameChange = (value: string): void => {
        setFirstName(s => ({ ...s, value, error: VALIDATION.name(value) }));
    };

    const handleLastNameChange = (value: string): void => {
        setLastName(s => ({ ...s, value, error: VALIDATION.name(value) }));
    };

    const handleUserNameChange = (value: string): void => {
        setUserName(s => ({ ...s, value, error: VALIDATION.userName(value) }));
    };

    const handlePasswordChange = (value: string): void => {
        setPassword(s => ({ ...s, value, error: VALIDATION.password(value) }));
        setConfirmPassword(s => ({ ...s, error: VALIDATION.confirmPassword(s.value, value) }));
    };

    const handleConfirmPasswordChange = (value: string): void => {
        setConfirmPassword(s => ({ ...s, value, error: VALIDATION.confirmPassword(value, password.value) }));
        setPassword(s => ({ ...s, error: VALIDATION.password(s.value) }));
    };

    const handleSave = async () => {
        try {
            setLoading(true);

            let hasErrors = false;
            if (VALIDATION.name(firstName.value)) {
                setFirstName(s => ({ ...s, error: VALIDATION.name(s.value) }));
                hasErrors = true;
            }
            if (VALIDATION.name(lastName.value)) {
                setLastName(s => ({ ...s, error: VALIDATION.name(s.value) }));
                hasErrors = true;
            }
            if (VALIDATION.userName(userName.value)) {
                setUserName(s => ({ ...s, error: VALIDATION.userName(s.value) }));
                hasErrors = true;
            }
            if (VALIDATION.password(password.value)) {
                setPassword(s => ({ ...s, error: VALIDATION.password(s.value) }));
                hasErrors = true;
            }
            if (VALIDATION.confirmPassword(confirmPassword.value, password.value)) {
                setConfirmPassword(s => ({ ...s, error: VALIDATION.confirmPassword(s.value, password.value) }));
                hasErrors = true;
            }
            if (hasErrors) return;

            if (user) {
                const editUser: EditUserModel = {
                    companyId: user.companyId,
                    userId: user.userId,
                    firstName: firstName.value,
                    lastName: lastName.value,
                    userName: userName.value,
                    language: LANGUAGES[language].value,
                    newPassword: password.value.length ? password.value : undefined,
                    newPasswordRepeated: confirmPassword.value.length ? confirmPassword.value : undefined,
                    emailNotification: user.emailNotification,
                    roles: user.roles,
                };

                const savedUser = await UserService().updateCurrentUser(editUser);
                setUser(savedUser);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const resetData = () => {
        setFirstName({ value: user?.firstName || '', error: VALIDATION.name(user?.firstName || '') });
        setLastName({ value: user?.lastName || '', error: VALIDATION.name(user?.lastName || '') });
        setUserName({ value: user?.userName || '', error: VALIDATION.userName(user?.userName || '') });
        setLanguage(LANGUAGES.find(lang => lang.value === user?.language)?.id || 0);
        setPassword({ value: '', error: undefined });
        setConfirmPassword({ value: '', error: undefined });
    };

    useEffect(() => {
        if (showQr) {
            UserService()
                .getQR()
                .then(rqData => {
                    setQrData(rqData);
                });
        }
    }, [showQr]);

    const noValuesChanged = useMemo(() => {
        return (
            user?.firstName === firstName.value &&
            user?.lastName === lastName.value &&
            user?.userName === userName.value &&
            user?.language === LANGUAGES[language].value &&
            !password.value &&
            !confirmPassword.value
        );
    }, [user, firstName.value, lastName.value, userName.value, language, password.value, confirmPassword.value]);

    const handleShowQr = () => {
        setShowQr(true);
        setCounter(qrCodeTime);

        setTimeout(() => {
            setShowQr(false);
        }, qrCodeTimeMs);
    };
    useEffect(() => {
        if (counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
    }, [counter]);

    return (
        <div className={styled.UserInfo}>
            <div>
                {loading && <LoadingSpinner />}
                <h3 className={styled.Title}>{t('settingsMenuUserInfo')}</h3>
                <div className={styled.Box}>
                    <div className={styled.BoxSection}>
                        <h6 className={styled.BoxTitle}>{t('editUserInformationTitle')}</h6>
                        <div className={styled.BoxRow}>
                            <Input value={firstName.value} onChange={handleFirstNameChange} label={t('inputFieldFirstName')} error={firstName.error} autoFocus />
                            <Input value={lastName.value} onChange={handleLastNameChange} label={t('inputFieldLastName')} error={lastName.error} />
                        </div>
                        <div className={styled.BoxRow}>
                            <Input type="email" value={userName.value} onChange={handleUserNameChange} label={t('inputFieldEmail')} error={userName.error} />
                            <div className={styled.DropdownInput}>
                                <p>{t('inputFieldLanguage')}</p>
                                <Dropdown title={LANGUAGES[language].text} content={LANGUAGES} onChange={setLanguage} />
                            </div>
                        </div>
                    </div>
                    <div className={styled.BoxSection}>
                        <div className={styled.BoxRow}>
                            <Input
                                type="password"
                                value={password.value}
                                onChange={handlePasswordChange}
                                label={t('inputFieldPassword')}
                                error={password.error}
                                subLabel={t('inputFieldErrorPassword')}
                                autoComplete={PREVENT_PASSWORD_AUTOFILL_KEY}
                            />
                            <Input
                                type="password"
                                value={confirmPassword.value}
                                onChange={handleConfirmPasswordChange}
                                label={t('inputFieldConfirmPassword')}
                                error={confirmPassword.error}
                                autoComplete={PREVENT_PASSWORD_AUTOFILL_KEY}
                            />
                        </div>
                    </div>
                    <div className={styled.Buttons}>
                        <Button variant="Outline" onClick={resetData} disabled={noValuesChanged}>
                            {t('buttonRevert')}
                        </Button>
                        <Button
                            variant="Primary"
                            onClick={handleSave}
                            disabled={
                                password.error !== undefined ||
                                confirmPassword.error !== undefined ||
                                userName.error !== undefined ||
                                firstName.error !== undefined ||
                                lastName.error !== undefined ||
                                noValuesChanged
                            }
                        >
                            {t('buttonSave')}
                        </Button>
                    </div>

                    <div className={styled.QrContainer}>
                        <p>{t('qrButtonTitle')}</p>
                        <div className={styled.QrButton}>
                            <Button variant={'Primary'} disabled={showQr} onClick={() => handleShowQr()}>
                                {t('qrButtonText')}
                            </Button>
                            {showQr && <h4 className={styled.Counter}>{counter}</h4>}
                        </div>

                        {qrData && showQr && (
                            <div>
                                <span>{t('qrImageText')}</span>
                                <img className={styled.Image} src={BASE_64_STRING + qrData} title={'QR code'} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserInfo;
