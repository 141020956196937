import React, { FC, ReactNode, useEffect, useState } from 'react';
import styled from './SendExternalMail.module.css';
import SubscriptionModel from '../../models/SubscriptionModel';
import contactIcon from '../../assets/images/contactIconWhite.svg';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import copyIcon from '../../assets/images/copyIconWhite.svg';
import copy from 'copy-to-clipboard';
import { htmlToText } from 'html-to-text';
import SendExternalMailConfirmModal from '../Modal/SendExternalMailConfirmModal/SendExternalMailConfirmModal';

interface Props {
    data?: SubscriptionModel;
    link?: ReactNode;
}

const SendExternalMail: FC<Props> = ({ data }) => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [txtModel, setTxtModel] = useState('');
    const [subscriptionModel, setSubscriptionModel] = useState(data);
    // let href = '';
    let textToCopy = '';
    const MAXIMUM_MAIL_LENGTH = 1900;

    const newLine = '%0D';
    const sendersName = user?.fullName;
    // const greatigMaessage = sendersName + ' vill delge er information ang en lagändring.' + newLine + newLine;
    // let cleanedHREF;

    // const constructMailText = () => {
    //     cleanedHREF = '';
    //     href = '';
    //
    //     href = 'mailto:?subject=';
    //     href += 'Ändring från Rambolls Lagbevakning';
    //     href += '&body=';
    //     href += greatigMaessage + 'LAGNAMN:' + newLine + subscriptionModel?.name + newLine + newLine;
    //     href += 'ÄNDRAD T.O.M: ' + subscriptionModel?.subId + newLine + newLine;
    //     href += 'ÄNDRING:' + newLine + subscriptionModel?.changeText + newLine + newLine;
    //     href += '_________________________________________' + newLine;
    //     href += 'Hälsningar Lagbevakningsteamet på Ramboll';
    //
    //     cleanedHREF = href
    //         .split('&nbsp;')
    //         .join(' ')
    //         .split('<p>')
    //         .join('')
    //         .split('</p>')
    //         .join(newLine + newLine)
    //         .split('<ol>')
    //         .join('')
    //         .split('<li>')
    //         .join(newLine)
    //         .split('</li>')
    //         .join(newLine)
    //         .split('</ol>')
    //         .join('')
    //         .split('<ul>')
    //         .join('')
    //         .split('</ul>')
    //         .join('')
    //         .split('<br>')
    //         .join(newLine)
    //         .split('<strong>')
    //         .join('')
    //         .split('</strong>')
    //         .join('')
    //         .split('<em>')
    //         .join('')
    //         .split('</em>')
    //         .join('');
    //
    //     setMailModelHREF(cleanedHREF);
    // };

    const constructCopyText = () => {
        const newLineCopy = '\n';

        const sanitizedChangeText = htmlToText(subscriptionModel?.changeText, { wordwrap: false });

        textToCopy += 'Ändring från Rambolls Lagbevakning';
        textToCopy += newLineCopy;
        textToCopy += sendersName + ' vill delge er information ang en lagändring.';
        textToCopy += newLineCopy + newLineCopy;
        textToCopy += 'LAGNAMN:' + newLineCopy + subscriptionModel?.name;
        textToCopy += newLineCopy + newLineCopy;
        textToCopy += 'ÄNDRAD T.O.M: ' + subscriptionModel?.subId;
        textToCopy += newLineCopy + newLineCopy;
        textToCopy += 'ÄNDRING:' + newLineCopy + sanitizedChangeText;
        textToCopy += newLineCopy + newLineCopy;
        textToCopy += '__________________________________________' + newLineCopy;
        textToCopy += 'Hälsningar Lagbevakningsteamet på Ramboll';
        textToCopy += newLineCopy + newLineCopy;

        setTxtModel(textToCopy);
    };

    const handleCopy = () => {
        copy(txtModel);
        setOpenModal(true);
    };

    useEffect(() => {
        setSubscriptionModel(data);
        // constructMailText();
        constructCopyText();
    }, [subscriptionModel]);

    return (
        <div className={styled.Container}>
            <div className={styled.LinkContainer}>
                <div className={styled.Link} onClick={handleCopy}>
                    <img title={t('changeListMailToExternalDescription')} src={contactIcon} />
                    <p>{t('changeListMailToExternal')}</p>
                </div>
            </div>
            {openModal && <SendExternalMailConfirmModal onConfirm={() => setOpenModal(false)}></SendExternalMailConfirmModal>}
        </div>
    );
};

export default SendExternalMail;
