import React, { FC, useState } from 'react';
import styled from './CustomToaster.module.css';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';

interface Props {
    headerTextI18Key: string;
    mainTextI18tKey: string;
    buttonText: string;
}

//Dynamic toaster for custom use.
//Uses i18 key as input for language support.
const CustomToaster: FC<Props> = ({ headerTextI18Key, mainTextI18tKey, buttonText }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);

    const handleClosePopup = () => {
        setOpen(!open);
    };

    return (
        <>
            {open && (
                <div>
                    <div className={styled.Backdrop} />
                    <div className={[styled.Popup, open ? styled.Open : ''].join(' ')}>
                        <div className={styled.PopupContent}>
                            <div className={styled.Header}>
                                <h1>{t(headerTextI18Key)}</h1>
                            </div>
                            <div className={styled.Text}>
                                <p>{t(mainTextI18tKey)}</p>
                            </div>
                            <div className={styled.Footer}>
                                <Button variant="Primary" onClick={handleClosePopup}>
                                    {t(buttonText)}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CustomToaster;
